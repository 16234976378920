import { Alert } from '@mui/material'
import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link ,useNavigate,useParams} from 'react-router-dom'
import { pageUrl, getOrders } from '../../../api/api'
import { Spinner } from 'react-bootstrap';
import { orderDetail, orders as setUserOrders, detail } from '../../../redux/slice/orderSlice'
import Table from '../../../components/Table';
import OrderDetails from './orderDetails'

function ProductOrders(){
    const {shop} = useParams()
	const shopId = localStorage.getItem('shopId');
    const navigate = useNavigate()
    const {userOrders} = useSelector(state => state)
    const [deleteState,setDelState] = useState(null)
    const [showImportModal,handleImportModal] = useState(false)
    const dispatch = useDispatch()
    const [orders, setOrders] = useState(userOrders.orders)
	const order = useMemo(() => userOrders.order, [userOrders.order]);
    const [receipt, setReceipt] = useState(null);
	const fetchOrders = async () => {
        const response = await getOrders({shopId})
        console.log(response);
        if(response.success){
            dispatch(setUserOrders(response.data));
        }
    }
	const hideDetail = () => {
		dispatch(orderDetail(false));
		dispatch(detail(false));
		localStorage.setItem('oDetail', false);
	}

	const columns = [
		{
			accessor: 'id',
			Header : '#ID'
		},
		{
			accessor: 'name',
			Header : 'Name'
		},
		 {
		 	accessor: 'email',
		 	Header : 'Email'
		},
		 {
		 	accessor: 'total_quantity',
		 	Header : 'Total Quantity'
		 },
		 {
		 	accessor: 'total_amount',
		 	Header : 'Total (NGN)'
		 },
		{
			accessor: (row) => {
				if(row.status == 'Pending'){
					return <span className="badge rounded-pill alert-warning text-warning">Pending</span>
				}else if(row.status == 'success' || row.status == 'Success' || row.status == 'Shipped'|| row.status == 'Confirmed' ){
					return <span className="badge rounded-pill alert-success text-success">{row.status}</span>
				}else{
					return <span className="badge rounded-pill alert-danger text-danger">Canceled</span>
				}
			} ,
			Header : 'Status'
		},
		{
			accessor: 'created_at',
			Header : 'Date'
		},
		{
			accessor: () => (
					<span className="text-end">
										<a href="#" className="btn btn-light"
										 onClick={() =>{
											dispatch(detail(true));
											localStorage.setItem('oDetail', 'true');
										}}
										>Details</a>
										{/* <div className=" dropstart "
										onClick={()=> {
											
										}}
										>
											<a href="#" 
											
											data-bs-toggle="dropdown" className="form-select btn-light"> <i className="material-icons md-more_horiz"></i> </a>
											<div className=" dropdown-menu "
											onClick={()=> {
											}}
											>
													<a className="btn btn-dark text-center t-label dropdown-item" href="#">Edit </a>
													<a className="btn btn-dark text-center t-label dropdown-item text-danger" href="#">Delete</a>
											</div>
										</div> */}
									</span>
				),
			Header : "Action"
		}
	]
	const data = useMemo(() => orders, [orders]);

	useEffect(() => {
		setOrders(userOrders.orders);
	}, [userOrders.orders])
	useEffect(() => {
        userOrders.orders === null && fetchOrders();
        
    }, [])
    return (
        userOrders.loading ?
        <>
            <div className='page-preloader' style={{width:"max-content"}}>
    <Spinner animation="border" >
    <>{ "Network failure,Please refresh"}</></Spinner>
        </div>
        </>
        :
        <React.Fragment>
            <section className="content-main">
	
    <div className="content-header">
        <h2 className="content-title">Orders</h2>
        
    </div>
    <div className="card mb-4">
			<div className="card-body">
			<div className="container">
				{order && <OrderDetails data={order} show={userOrders.showDetail} onHide={hideDetail} />}
				{data ? <Table body={data} columns = {columns} path="orders" />
				
				: <Alert  severity='warning' style={{
					display:'flex',justifyContent:"center",alignItems:"center",
				}}><h5 className='text-center text-muted'> You do not have any order record </h5> </Alert>
				}
			</div> 
			</div> 
		</div>
  
    
    
    
    </section>
        </React.Fragment>
    )
}

export default ProductOrders