import { Alert } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link ,useNavigate,useParams, useResolvedPath} from 'react-router-dom'
import { useState } from 'react'
import { Spinner } from 'react-bootstrap';
// import { getCurrentStoreReview, setCurrentStore } from '../../../redux/action/shop'
import {shopReviews as setShopReviews} from '../../../redux/slice/shopSlice';
import { getUserData } from '../../../redux/action/user';
import { getReviews } from '../../../api/api';
import { useMemo } from 'react'
import Table from '../../../components/Table';
import ReviewDetails from './ReviewDetails'
import { reviewDetail } from '../../../redux/slice/reviewSlice'

function ReviewsContent(){
    // const {shop} = useParams()
    const navigate = useNavigate()
    const {currentShop,currentUser,currentShopProducts, shop, reviews} = useSelector(state => state)
    const shopReviews = useSelector((state) => state.shop.shopReviews);
    const dispatch = useDispatch()
    // const [reviews, setReviews] = useState(shopReviews);
    const data = useMemo(() => shopReviews, [shopReviews]);
    const review = useMemo(() => reviews.review, [reviews.review]);
    const fetchReviews = async () => {
        const response = await getReviews({shopId:localStorage.getItem('shopId')});
        console.log(response);
        if(response.success){
            dispatch(setShopReviews(response.reviews));
        }
    }
    const hideDetail = () => {
		dispatch(reviewDetail(false));
	}
    const ratingFlow = rating => {
        var output = []
        for (let index = 0; index < 5; index++) {
            if (index + 1 <= rating) {
                output.push(<i class="fa fa-star" style={{color:"orange"}} aria-hidden="true"></i>)
            }else {
               output.push(<i class="fa fa-star-o" aria-hidden="true"></i>)
            }
        }
        return output
    }
    const columns = [
        {
            accessor: 'id',
            Header: "#ID"
        },
        {
            accessor: 'fullname',
            Header: "Customer"
        },
        {
            accessor: 'title',
            Header: "product Name"
        },
        {
            accessor: 'message',
            Header: "Message"
        },
        {
            accessor: (row)=> (
                <>
                {
                    ratingFlow(row.rating).map((val,o)=>{
                        return val
                    })
                }
                </>
            ),
            Header: "Rating"
        },
        {
            accessor: 'date_created',
            Header: "Date"
        },
        {
            Header: "Action",
            accessor: () => (
                <span className="text-end">
                    <a href="#" className="btn btn-sm btn-light"
                    >Details
                    </a>
                </span>
            )
        }

    ]
    
    useEffect(()=>{
        !currentUser.data && dispatch(getUserData());
        // !currentShop.data && dispatch(setCurrentStore(shop,()=>{
        // }));
        return ()=>{
            return null
        }
    },[currentShop.id, shopReviews])
    useEffect(() => {
        
       shopReviews == null && fetchReviews();
        console.log(shopReviews);
    }, [])
    return (
        currentShopProducts.loading ?
        <>
            <div className='page-preloader' style={{width:"max-content"}}>
    <Spinner animation="border" >
    <>{currentUser.err && "Network failure,Please refresh"}</></Spinner>
        </div>
        </>
        :
        <React.Fragment>
            <section className="content-main">
	
    <div className="content-header">
        <h2 className="content-title">Reviews </h2>
        
    </div>
    
    <div className="card mb-4">
			{review &&
            <ReviewDetails data = {review} show={reviews.showDetail} onHide ={hideDetail} />}
			<div className="card-body">
              { data ?  <Table columns={columns} body={data} path="reviews" />
              
                : <Alert  severity='warning'><h5 className='text-center text-muted'> Reviews is empty. </h5> </Alert>
                }
			{/* <div className="table-responsive" style={{marginTop:70}}>
            <table className="table table-hover">
<thead>
     <tr>
          <th>
           <div className="form-check">
                <input className="form-check-input" type="checkbox" value="" />
           </div>
          </th>
          <th>#ID</th>
          <th>Product Name</th>
          <th>Brand</th>
          <th>Rating</th>
          <th>Date</th>
          <th className="text-end">Action</th>
     </tr>
</thead>
<tbody>
{
        reviews ? (reviews.map(function(review,i){
            return (
                <tr key={review.id}>
                <td>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" />
                    </div>
                </td>
                <td>{review.id}</td>
                <td><b>{review.title}</b></td>
                <td>{review.brand}</td>
                <td>
                    {review.rating}
                </td>
                <td>10.03.2020</td>
                <td className="text-end">
                    <a href="#" className="btn btn-sm btn-light"
                    // onClick={() => setReceipt(reveiw)}
                    >Details
                    </a>
                </td>
        </tr>
            )
        })) :
        <Alert  severity='warning'>Review is empty!</Alert>
}

</tbody>
</table>
			</div>  */}
			</div> 
		</div>
  
    
    
    
    </section>
        </React.Fragment>
    )
}

export default ReviewsContent