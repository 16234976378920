import React, { useEffect,useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import logoImg from "../../../assets/images/logo.png"
import '../../../assets/css/shop.css'
import avatar from "../../../assets/images/people/avatar1.jpg";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Alert } from '@mui/material';
import { Axios, token } from '../../../api/axios';
import { setCurrentStore, userShopData } from '../../../redux/action/shop';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
const theme = createTheme();

function CreateShop(){
  const navigate = useNavigate();
const [isShopCreated,setIsShopCreated] = useState(false)
const [createState,setCreateState] = useState(null)
const [storename,setName] = useState("")
const sanitizeStoreName = (name) => {
  const sanitizedName = name.replace(/\s/g, "-"); // Replace all spaces with -
  const any = sanitizedName.replace(/'/g, ""); // Replace all spaces with -
  setName(any);
  return any;
}

const dispatch = useDispatch()
    const darkMode = (btn_this) => {
        var body_el = document.body;
        if(body_el.classList.contains('dark')) {
            // Add darkmode 
          localStorage.removeItem("darkmode");
          body_el.classList.toggle("dark");
            btn_this.classList.remove('active');
      
        } else {
            // remove darkmode 
          localStorage.setItem("darkmode", "active");
          body_el.classList.toggle("dark");
            btn_this.className += ' active'; 
        }
      }
      const createShopForUser =async e => {
        e.preventDefault()
        setCreateState(null)
        setIsShopCreated(true)
        const data = new FormData(e.currentTarget)
          try {
            const res = await Axios({
              url :'api?createStore',
              method:"POST",
              data,
              headers: { "Authorization": 'Bearer ' + token()}

            })
            if (res.data.success) {
              dispatch(userShopData())
              dispatch(setCurrentStore(res.data.name))
            document.querySelectorAll('.form-control').forEach(field=>{
              field.value = ""
            });
            Swal.fire({
              text: res.data.message,
              icon: 'success',
              timer: 3000,
              toast: true,
              showConfirmButton: false,
              position: 'top-end'
          });
          navigate('/my-shops');
            } else {
              Swal.fire({
                text: res.data.message,
                icon: 'error',
                timer: 5000,
                toast: true,
                showConfirmButton: false,
                position: 'top-end'
            });
            }
            setIsShopCreated(false);
          } catch (error) {
            // console.log(error);
            Swal.fire({
              text: error.response.message,
              icon: 'error',
              timer: 3000,
              toast: true,
              showConfirmButton: false,
              position: 'top-end'
          });
            setIsShopCreated(false)
          }
      }
return (
    <>
    <b className="screen-overlay"></b>
    <React.Fragment>
    <main>

<section className="content-main">
	<div className="card shadow mx-auto" style={{maxWidth:500,marginTop:60}}>
      <div className="card-body">
      <h4 className="card-title mb-4">Create Store</h4>
      
          <div className="mb-3">
          <form method='POST' encType='multipart/form-data' onSubmit={(e)=>{
            createShopForUser(e);
          }}>
      	
          <div className="mb-3">
          	 <label className="form-label">Store Name</label>
			 <input required className="form-control" value={storename} onChange={(e)=>sanitizeStoreName(e.target.value)} placeholder="Enter shop name" name='name' type="text"/>
          </div> 
          <div>
            <Alert severity='info'>Store name will be used to create your store URL</Alert>
          </div>
          <div className="mb-3">
          	<label className="form-label">Description</label>
			<textarea className="form-control" name='description' placeholder="Description of your store"  />
          </div> 
          <div className="mb-3">
          	<label className="form-label">Type</label>
      <select name='type' required className='form-control' >
        <option value=''>-- Select option --</option>
        <option value='E-Commerce'>E-Commerce</option>
        <option value='Online-Store'>Online store</option>
        <option value='Advert Center'>Advert Center</option>
      </select>
          </div> 
          <div className="mb-3">
          	<label className="form-label">Store Logo</label>
			<input required className="form-control" name='file' placeholder="" type="file" accept='image/*'/>
          </div> 
          <div className="mb-3">
          	<label className="form-label">Store Address</label>
			<input required className="form-control" name='address' placeholder="Enter shop address" type="text"/>
          </div> 
          <div className="mb-3">
          	<label className="form-label">City</label>
			<input required className="form-control" name='city' placeholder="City" type="text"/>
          </div>
          <div className="mb-3">
          	<label className="form-label">State</label>
			<input required className="form-control" name='state' placeholder="State" type="text"/>
          </div>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="YES" color="primary" />}
                  label="This store is a registered business"
                />
              </Grid>
          <div className="mb-2">
              <button type="submit" disabled={isShopCreated} className="btn text-light w-100"
              style={{ 
                backgroundColor: '#1BBC9B',
                fontWeight: 'bold'
               }}
              > {
                isShopCreated?"Creating store..." :"Create store"
              }</button>
          </div> 
          <Link to={"/my-shops"} style={{
            display:'flex',justifyContent:'center'
          }}>Go back</Link>
      </form>
        
          </div>
    </div>
    </div>
</section>
    </main>

    </React.Fragment>
    </>
)
}


export default CreateShop


