import React, { useEffect, useState } from 'react'
import Nav, { Header } from '../../../components/dashboard/Nav'
import '../../../assets/css/products.css'
import { useSelector,useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import testimg from '../../../assets/images/items/1.jpg'
import {  orders as setUserOrders } from '../../../redux/slice/orderSlice'
import { createKey, getOrder, getOrders } from '../../../api/api'
import { Axios } from '../../../api/axios'
import Swal from 'sweetalert2';
import { Spinner } from 'react-bootstrap'
import { setCurrentStore } from '../../../redux/action/shop'
function OrderDetails(){
    const {cartId,shop} = useParams()
    const {currentUser,currentShop} = useSelector(state => state)
    const dispatch = useDispatch()
    const [order, setOrder] = useState(null);
    const shopId = localStorage.getItem("storeId")
    const [loading,setLoading] = useState(true)
    const fetchOrder = async () => {
      const response = await getOrder({orderId: cartId});
      if(response.success){
        setOrder(response.data);
        setLoading(false)
      }
    }
    const fetchOrders = async () => {
      const response = await getOrders({shopId})
      console.log(response);
      if(response.success){
          dispatch(setUserOrders(response.data));
      }
  }
    const updateOrderStatus = async (status,storeId,orderId) => {
      try {
        const resp = await Axios.post("api?updateOrderStatus",{status,storeId,orderId})
        const res = resp.data
        if(res.status == true){
          fetchOrders()
          Swal.fire({
            text: res.message,
            icon: 'success',
            timer: 5000,
            toast: true,
            showConfirmButton: false,
            position: 'top-end'
        });
        fetchOrder();
        }else{
          Swal.fire({
            text: res.message,
            icon: 'error',
            timer: 5000,
            toast: true,
            showConfirmButton: false,
            position: 'top-end'
        });
        }
      } catch (error) {
        Swal.fire({
          text: error.message,
          icon: 'error',
          timer: 5000,
          toast: true,
          showConfirmButton: false,
          position: 'top-end'
      });
      }
    }
    useEffect(()=>{ 
      fetchOrder();
      !currentShop.data && dispatch(setCurrentStore(shop))
        return ()=> {
            return null
        }
    },[currentUser.loading]);

    return  (
        <>
          {
            loading ?
            <>
               <div className='page-preloader'>
                  <Spinner animation="border" >
                  <>{currentUser.err && "Network failure,Please refresh"}</></Spinner>
              </div>
            </>:
            <>
            <>
            <Nav activeState={'orders'}/>
            <>
            <main className='main-wrap'>
                <Header />
                <React.Fragment>
                <React.Fragment>
            <section className="content-main">
	
    <div className="content-header">
        <h2 className="content-title">Order details</h2>
    </div>
    <div className="card">
          <header className="card-header">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6">
                <span>
                  <i className="material-icons md-calendar_today"></i> <b>{order.created_at}</b>  
                </span> <br/>
                <small className="text-muted">Order ID: {order.cartId}</small>
              </div>
              <div className="col-lg-6 col-md-6 ms-auto text-md-end">
                <select className="form-select d-inline-block mx-1" style={{maxWidth:200}} onChange={(e)=>{
                  updateOrderStatus(e.target.value,order.storeId,order.cartId)
                }}>
                  <option>Change status</option>
                  <option value={'Confirmed'}>Confirmed</option>
                  <option value={'Shipped'}>Shipped</option>
                  <option value={'Canceled'}>Canceled</option>
                </select>
                <a className="btn btn-light d-none" href="#">Save</a>
                <a className="btn btn-secondary ms-2" href="#"
                onClick={()=> window.print()}
                ><i className="icon material-icons md-print"></i></a>
              </div>
            </div>
          </header> 
          <div className="card-body" style={{marginTop:135}}>

            <div className="row mb-5 order-info-wrap">
              <div className="col-md-4">
                <article className="icontext align-items-start">
                  <span className="icon icon-sm rounded-circle bg-primary-light">
                    <i className="text-primary material-icons md-person"></i>
                  </span>
                  <div className="text">
                    <h6 className="mb-1">Customer</h6> 
                    {
                      order.location &&
                      <p className="mb-1">
                      {order.location[0].firstname + " " + order.location[0].lastname} <br/> {order.location[0].email} <br/> {order.location[0].phone}
                    </p>
                    }
                    {/*
                      {
    "success": true,
    "data": {
        "id": "87",
        "user_id": "13",
        "shopId": "23",
        "cartId": "651aba0d54e3f1696250381",
        "product_id": null,
        "quantity": null,
        "status": "Pending",
        "created_at": "2023-10-02 13:39:41",
        "updated_at": "2023-10-02 13:39:41",
        "details": [
            {
                "id": "138",
                "cartId": "651aba0d54e3f1696250381",
                "userId": "13",
                "productId": "41",
                "quantity": "1",
                "variantId": "0",
                "price": "5000",
                "date_created": "2023-10-02 13:39:41",
                "date_updated": "2023-10-02 13:39:41",
                "variant": null,
                "image": "https:\/\/cloudtech.striderng.xyz\/eComm-api\/uploads\/products\/textng_01690441203.jpg",
                "title": "Digital Watch",
                "total": 5000
            },
            {
                "id": "139",
                "cartId": "651aba0d54e3f1696250381",
                "userId": "13",
                "productId": "43",
                "quantity": "1",
                "variantId": "0",
                "price": "5500",
                "date_created": "2023-10-02 13:39:41",
                "date_updated": "2023-10-02 13:39:41",
                "variant": null,
                "image": "https:\/\/cloudtech.striderng.xyz\/eComm-api\/uploads\/products\/textng_01690527167.jpg",
                "title": "Sneakers",
                "total": 5500
            }
        ],
        "gTotal": 10500,
        "location": [
            {
                "id": "75",
                "cartId": "651aba0d54e3f1696250381",
                "firstname": "Victor",
                "lastname": "Muoghalu",
                "email": "Testinglavana10@gmail.com",
                "address": "akoka",
                "town": "Lagos",
                "postcode": "0011",
                "phone": "07035324669",
                "note": "please call",
                "type": "BILLING",
                "is_shipping_billing": "1",
                "date_created": "2023-10-02 13:39:41",
                "date_updated": "2023-10-02 13:39:41",
                "userId": "13"
            }
        ]
    }
}
                    
                    */}
                  </div>
                </article> 
              </div>
            
              <div className="col-md-4">
                <article className="icontext align-items-start">
                  <span className="icon icon-sm rounded-circle bg-primary-light">
                    <i className="text-primary material-icons md-local_shipping"></i>
                  </span>
                  <div className="text">
                    <h6 className="mb-1">Billing Info</h6> 
                    {
                      order.location &&
                      <p className="mb-1">
                     Address :{order.location[0].address} <br/>
                     State: {order.location[0].name}<br/>
                     PostCode {order.location[0].postcode ?? 'Not available'}<br/>
                     Note: {order.location[0].note}
                    </p>
                    }
                    {/* <a href="#">Download info</a> */}
                  </div>
                </article> 
              </div>
              <div className="col-md-4">
                {
                  order.shipping_mode == "HOME_DELIVERY" ?
                  <>
                    <article className="icontext align-items-start">
                  <span className="icon icon-sm rounded-circle bg-primary-light">
                    <i className="text-primary material-icons md-place"></i>
                  </span>
                  <div className="text">
                    <h6 className="mb-1">Deliver to</h6> 
                    {
                      order.location &&
                      <p className="mb-1">
                     Name: {order.location[order.location.length - 1].firstname} {order.location[order.location.length - 1].lastname} <br/>
                     Email: {order.location[order.location.length - 1].email} <br/>
                     Address: {order.location[order.location.length - 1].address} <br/>
                     State: {order.location[order.location.length - 1].name}<br/>
                     PostCode: {order.location[order.location.length - 1].postcode ?? 'Not available'}<br/>
                    </p>
                    }
                    {/* <a href="#">View profile</a> */}
                  </div>
                </article> 
                  </>:
                  <>
                    <article className="icontext align-items-start">
                  <span className="icon icon-sm rounded-circle bg-primary-light">
                    <i className="text-primary material-icons md-place"></i>
                  </span>
                  <div className="text">
                    <h6 className="mb-1">Customer Picks up at :</h6> 
                    <p className="mb-1">{currentShop && currentShop.pickup_location}</p>
                  </div>
                </article> 
                  </>
                }
              </div>
            </div> 

            <div className="row">
              <div className="col-lg-12">
                <div className="table-responsive">
                <table className="table border table-hover table-lg">
                  <thead>
                    <tr>
                      <th width="40%">Product</th>
                      <th width="20%">Unit Price</th>
                      <th width="20%">Quantity</th>
                      <th width="20%" className="text-end">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                    order.details &&  order.details.map((detail, index) => {
                        return (
                          <tr key={createKey()}>
                            <td>
                              <a className="itemside" href="#">
                                  <div className="left">
                                      <img src={detail.image} width="40" height="40" className="img-xs" alt="Item"/>
                                  </div>
                                  <div className="info"> {detail.title} {
                                    detail.variant && 
                                    <>
                                    <p style={{fontSize:12}}><b>({`Size: ${detail.variant.size}, Color :${detail.variant.color}`} )</b></p>
                                    </>
                                  }  </div>
                              </a>
                            </td>
                            <td> ₦{detail.price} </td>
                            <td> {detail.quantity} </td>
                            <td className="text-end">₦ {detail.total}  </td>
                          </tr>
                        )
                      }) 
                    }
                    <tr>
                      <td colSpan="4"> 
                          <article className="float-end">
                            <dl className="dlist"> 
                                <dt>Subtotal:</dt> <dd>₦ {order.gTotal}</dd> 
                              </dl>
                              {
                                order.shipping_mode === 'HOME_DELIVERY' &&
                                <dl className="dlist"> 
                                <dt>Shipping cost:</dt> <dd>₦ {order.location && <>{order.location[order.location.length - 1].charge ?? (currentShop.default_charge_amount) }</>}</dd> 
                              </dl>
                              }
                              <dl className="dlist"> 
                                <dt>Grand total:</dt> <dd> <b className="h5">₦ {(new Number(order.gTotal) + (order.shipping_mode == 'HOME_DELIVERY' ? new Number(order.location[order.location.length - 1].charge ?? currentShop.default_charge_amount ) : 0 )) }</b> </dd> 
                              </dl>
                              <dl className="dlist"> 
                                <dt className="text-muted">Payment Status:</dt> 
                                <dd>  
                                  {
                                    (order.status === 'success')  || order.is_paid && order.reference && ( order.is_paid == 1 || order.is_paid == true) ?
                                    <span className="badge rounded-pill alert-success text-success"> Payment Completed </span> :
                                    <span className="badge rounded-pill alert-warning text-warning">Awaiting Payment</span>
                                  }
                                  
                                </dd> 
                              </dl>
                              <dl className="dlist"> 
                                <dt className="text-muted">Order Status:</dt> 
                                <dd>  
                                  {
                                    order.status === 'Canceled'  &&
                                    <span className="badge rounded-pill alert-danger text-danger">Canceled</span>
                                  }
                                  {
                                    (order.status === 'Shipped' || order.status == 'Confirmed')   &&
                                    <span className="badge rounded-pill alert-success text-success">{order.status}</span> 
                                  }
                                  {
                                    (order.status === 'Pending')   &&
                                    <span className="badge rounded-pill alert-warning text-warning">Pending</span> 
                                  }
                                  
                                </dd> 
                              </dl>
                          </article>
                      </td>
                    </tr>
                  </tbody>
                </table>
                </div>
              </div> 
              <div className="col-lg-4 d-none">
                <div className="box shadow-sm bg-light d-none">
                   <h6>Payment info</h6>
                   <p> 
                    <img src={testimg} className="border" height="20" /> Master Card **** **** 4768  <br/>
                    Business name: Grand Market LLC <br/>
                    Phone: +1 (800) 555-154-52
                   </p>
                </div>
                <div className="h-25 pt-4">
                  <div className="mb-3">
                    <label>Notes</label>
                    <textarea className="form-control" name="notes" id="notes" placeholder="Type some note"></textarea>
                  </div>
                  <button className="btn btn-primary">Save note</button>
                </div>
              </div> 

            </div>
          </div> 
        </div>
  
    
    
    
    </section>
        </React.Fragment>
                </React.Fragment>
            </main>
            </>

        </>
            </>
          }
        </>
    )
}

export default OrderDetails