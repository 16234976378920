import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentStore } from '../../../../../../redux/action/shop'
import { useParams } from 'react-router-dom'
import { useEffect,useState } from 'react'
import { getUserData } from '../../../../../../redux/action/user'
import { dispatchMediaStyleForComponent } from '../../../../../../components/shop/theme-constants'
import { DefaultThemeMobileNav } from '../../../../../../components/shop/themes/defaultThemeComponents'
import 'grapesjs/dist/css/grapes.min.css';
import grapesjs from 'grapesjs';

function T1_Landing() {
    const {shop} = useParams()
    const {currentShop,currentUser,defaultTheme,themePreviewState } = useSelector(state => state)
    const [navBarSettingShow,setNavBarSettingShow] = useState(false)
    const dispatch = useDispatch()
    useEffect(()=>{
        !currentUser.data && dispatch(getUserData())
        !currentShop.data && dispatch(setCurrentStore(shop))
//defaultTheme.landing.header.navStyle
    },[currentShop.data,])
    return (
        <>
            <DefaultThemeMobileNav
            show={navBarSettingShow}
            onHide={()=>setNavBarSettingShow(false)}
            />
                    <>
                    <section className="main-navbar-jevelin" 
                    style={dispatchMediaStyleForComponent(themePreviewState.state,defaultTheme,'landing','header','navStyle')}>
                        <img
                            src={currentShop.image}
                            height={50}
                            width={100}
                            alt='shop logo'
                            className="sh-header-builder-logo-standard"
                            style={dispatchMediaStyleForComponent(themePreviewState.state,defaultTheme,'landing','header','siteImageStyle')}
                        />

                        <nav className="navbar-jevelin">
                            {
                                defaultTheme.landing['header'].navLinks.map((link,i)=>{
                                    return (
                                        <a href='#'style={{
                                            color:dispatchMediaStyleForComponent(themePreviewState.state,defaultTheme,'landing','header','navStyle')['color']
                                        }} key={i}>{link}</a>
                                    )
                                })
                            }
                        </nav>
                            {
                                
                                <>
                                    <div className="social-media-navbar">
                            {
                                defaultTheme.landing['header'].socialMedia.length > 0 &&
                                defaultTheme.landing['header'].socialMedia.map((med,i)=>{
                                   if(med.link !== ""){
                                    return (
                                        <a key={i} href={med.link} style={{
                                            color:dispatchMediaStyleForComponent(themePreviewState.state,defaultTheme,'landing','header','navStyle')['color']
                                        }}><span className={`fa ${med.icon} social-media`}></span></a>
                                    )
                                   }
                                })
                            }
                                        <span className="fa fa-bars menu-bar" onClick={()=>{
                                                setNavBarSettingShow(true)
                                        }}></span>
                                        <span className="fa fa-shopping-cart"></span>
                                        <strong className="cart-info">0</strong>
                                    </div>
                                </>
                            }
                        
                    </section>
                    <section className="first-hero-section" >
                        <div style={dispatchMediaStyleForComponent(themePreviewState.state,defaultTheme,'landing','bannerHero','bannerStyle')}>
                            <h1 style={defaultTheme.landing.bannerHero.topTextStyle}>{defaultTheme.landing.bannerHero.topText}</h1>
                            <p style={defaultTheme.landing.bannerHero.text2Style}>{defaultTheme.landing.bannerHero.text2}</p>
                            <div className="explore-button">
                                <button 
                                style={dispatchMediaStyleForComponent(themePreviewState.state,defaultTheme,'landing','bannerHero','button')}
                                ><a href={defaultTheme.landing.bannerHero.button.link}>{defaultTheme.landing.bannerHero.button.text}</a></button>
                            </div>
                        </div>
                    </section>
                    <h3 className='collection-name' style={defaultTheme.landing.miniNotification.headingStyle}>{defaultTheme.landing.miniNotification.heading}</h3>
                    <p className='collection-descr' style={defaultTheme.landing.miniNotification.descStyle}>{defaultTheme.landing.miniNotification.description }</p>
                    <div className="great-deals-image">
                        <div>
                            <img
                                src="https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-300x300.jpg"
                                className=""
                                alt=""
                                loading="lazy"
                                srcSet="
https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-300x300.jpg 300w,
https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-150x150.jpg 150w,
https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-100x100.jpg 100w,
https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-660x660.jpg 660w
"
                            />
                            <div className="item-details">
                                <div>
                                    <p>Table succulent seed</p>
                                    <strong>$5.00</strong>
                                </div>
                                <p><span className="fa fa-shopping-cart"></span></p>
                            </div>
                        </div>

                        <div>
                            <img
                                src="https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-300x300.jpg"
                                className=""
                                alt=""
                                loading="lazy"
                                srcSet="
https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-300x300.jpg 300w,
https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-150x150.jpg 150w,
https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-100x100.jpg 100w,
https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-660x660.jpg 660w
"
                            />
                            <div className="item-details">
                                <div>
                                    <p>Table succulent seed</p>
                                    <strong>$5.00</strong>
                                </div>
                                <p><span className="fa fa-shopping-cart"></span></p>
                            </div>
                        </div>
                        <div>
                            <img
                                src="https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-300x300.jpg"
                                className=""
                                alt=""
                                loading="lazy"
                                srcSet="
https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-300x300.jpg 300w,
https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-150x150.jpg 150w,
https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-100x100.jpg 100w,
https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-660x660.jpg 660w
"
                            />
                            <div className="item-details">
                                <div>
                                    <p>Table succulent seed</p>
                                    <strong>$5.00</strong>
                                </div>
                                <p><span className="fa fa-shopping-cart"></span></p>
                            </div>
                        </div>
                        <div>
                            <img
                                src="https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-300x300.jpg"
                                className=""
                                alt=""
                                loading="lazy"
                                srcSet="
https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-300x300.jpg 300w,
https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-150x150.jpg 150w,
https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-100x100.jpg 100w,
https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-660x660.jpg 660w
"
                            />
                            <div className="item-details">
                                <div>
                                    <p>Table succulent seed</p>
                                    <strong>$5.00</strong>
                                </div>
                                <p><span className="fa fa-shopping-cart"></span></p>
                            </div>
                        </div>
                    </div>
                    <div className="great-deals-image">
                        <div>
                            <img
                                src="https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-300x300.jpg"
                                className=""
                                alt=""
                                loading="lazy"
                                srcSet="
https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-300x300.jpg 300w,
https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-150x150.jpg 150w,
https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-100x100.jpg 100w,
https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-660x660.jpg 660w
"
                            />
                            <div className="item-details">
                                <div>
                                    <p>Table succulent seed</p>
                                    <strong>$5.00</strong>
                                </div>
                                <p><span className="fa fa-shopping-cart"></span></p>
                            </div>
                        </div>

                        <div>
                            <img
                                src="https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-300x300.jpg"
                                className=""
                                alt=""
                                loading="lazy"
                                srcSet="
https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-300x300.jpg 300w,
https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-150x150.jpg 150w,
https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-100x100.jpg 100w,
https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-660x660.jpg 660w
"
                            />
                            <div className="item-details">
                                <div>
                                    <p>Table succulent seed</p>
                                    <strong>$5.00</strong>
                                </div>
                                <p><span className="fa fa-shopping-cart"></span></p>
                            </div>
                        </div>
                        <div>
                            <img
                                src="https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-300x300.jpg"
                                className=""
                                alt=""
                                loading="lazy"
                                srcSet="
https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-300x300.jpg 300w,
https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-150x150.jpg 150w,
https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-100x100.jpg 100w,
https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-660x660.jpg 660w
"
                            />
                            <div className="item-details">
                                <div>
                                    <p>Table succulent seed</p>
                                    <strong>$5.00</strong>
                                </div>
                                <p><span className="fa fa-shopping-cart"></span></p>
                            </div>
                        </div>
                        <div>
                            <img
                                src="https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-300x300.jpg"
                                className=""
                                alt=""
                                loading="lazy"
                                srcSet="
https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-300x300.jpg 300w,
https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-150x150.jpg 150w,
https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-100x100.jpg 100w,
https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-660x660.jpg 660w
"
                            />
                            <div className="item-details">
                                <div>
                                    <p>Table succulent seed</p>
                                    <strong>$5.00</strong>
                                </div>
                                <p><span className="fa fa-shopping-cart"></span></p>
                            </div>
                        </div>
                    </div>
                    <section className="newsletter-section">
                        <h2>{defaultTheme.landing.newsLetter.headerText}</h2>
                        <p>{defaultTheme.landing.newsLetter.subText}</p>
                        <div className="subscribe-section">
                            <input
                                type="text"
                                placeholder="Email"
                            />
                            <button>Subscribe</button>
                        </div>
                    </section>

                    <footer className="footer-jevelin">
                        <div className="first-section-footer">
                            <div className="payment-method">
                                <h4>PAYMENT METHOD</h4>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
                                    nonummy nibh euismod tincidunt ut laoreet dolore.
                                </p>
                                <div className="payment-icons">
                                    <span className="fa fa-cc-visa"></span>
                                    <span className="fa fa-cc-visa"></span>
                                    <span className="fa fa-cc-visa"></span>
                                    <span className="fa fa-cc-visa"></span>
                                </div>
                                <div>
                                    <p><span className="fa fa-lock"></span> Secure online payment</p>
                                </div>
                            </div>
                            <div className="top-rated">
                                <h4>TOP RATED</h4>
                                <div className="top-rated-all-items">
                                    <img
                                        src="https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-300x300.jpg"
                                        className=""
                                        alt=""
                                        loading="lazy"
                                        srcSet="
https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-300x300.jpg 300w,
https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-150x150.jpg 150w,
https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-100x100.jpg 100w,
https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-660x660.jpg 660w
"
                                    />
                                    <div className="top-rated-details">
                                        <h2>Table succulent seeds</h2>
                                        <h2>$5</h2>
                                    </div>
                                </div>
                                <div className="top-rated-all-items">
                                    <img
                                        src="https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-300x300.jpg"
                                        className=""
                                        alt=""
                                        loading="lazy"
                                        srcSet="
https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-300x300.jpg 300w,
https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-150x150.jpg 150w,
https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-100x100.jpg 100w,
https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-660x660.jpg 660w
"
                                    />
                                    <div className="top-rated-details">
                                        <h2>Table succulent seeds</h2>
                                        <h2>$5</h2>
                                    </div>
                                </div>
                                <div className="top-rated-all-items">
                                    <img
                                        src="https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-300x300.jpg"
                                        className=""
                                        alt=""
                                        loading="lazy"
                                        srcSet="
https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-300x300.jpg 300w,
https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-150x150.jpg 150w,
https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-100x100.jpg 100w,
https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-660x660.jpg 660w
"
                                    />
                                    <div className="top-rated-details">
                                        <h2>Table succulent seeds</h2>
                                        <h2>$5</h2>
                                    </div>
                                </div>
                            </div>


                            <div className="shopping-guide">
                                <h4>SHOPPING GUIDE</h4>
                                <div className="shopping-guide-link">
                                    <a href="">FAQ</a>
                                    <a href="">Shipments</a>
                                    <a href="">Returns</a>
                                    <a href="">Policies</a>
                                    <a href="">Gift cards</a>
                                    <a href="">Clothing care</a>
                                    <a href="">Purchase conditions</a>
                                </div>
                            </div>

                            <div className="shopping-guide">
                                <h4>COMPANY</h4>
                                <div className="shopping-guide-link">
                                    <a href="">FAQ</a>
                                    <a href="">Shipments</a>
                                    <a href="">Returns</a>
                                    <a href="">Policies</a>
                                    <a href="">Gift cards</a>
                                    <a href="">Clothing care</a>
                                    <a href="">Purchase conditions</a>
                                </div>
                            </div>
                        </div>
                        <hr/>
                            <p className="copyright-jevelin">Copyright © 2023 Textng - Theme by Marvis...</p>
                    </footer>
                    </>
            

            
        </>
        )
}

export default T1_Landing