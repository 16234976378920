import {createSlice} from '@reduxjs/toolkit';


export const userSlice = createSlice({
    name: 'user',
    initialState: {
        payload: {

        }
    },
    reducers: {
        userData: (state, action) => {
            state.payload = action.payload
        }
    }
})

export const {userData} = userSlice.actions;
export default userSlice.reducer;