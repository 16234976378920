import { Alert } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { product, productCategories, productFashions, } from '../../../redux/slice/productSlice'
import { Link ,useNavigate,useParams} from 'react-router-dom'
import { createKey, getCategories, getProduct, pageUrl } from '../../../api/api'
import  item1 from "../../../assets/images/items/1.jpg"
import { addProduct, fetchShopProducts, getProductById } from '../../../redux/action/products'
import  Carousel  from 'react-bootstrap/Carousel'
import { Axios } from '../../../api/axios'
import { useState } from 'react'
import { Spinner } from 'react-bootstrap';
import ImportProducts from '../../../components/dashboard/ImportProducts'
import Swal from 'sweetalert2'
import { ArrowCircleLeft, ArrowCircleRightSharp } from '@mui/icons-material'

function ProductsContent(){
    const {shop} = useParams()
    const navigate = useNavigate()
    const {currentShop,currentUser,currentShopProducts, products} = useSelector(state => state)
    const [deleteState,setDelState] = useState(null)
    const [showImportModal,handleImportModal] = useState(false)
    const dispatch = useDispatch()
    const [productSearched, setProductSearched] = React.useState(RegExp(''));
    const shopId = localStorage.getItem("shopId")
    const [productCategory, setProductCategory] = React.useState(null);
    const [categories,setCategories] = useState(null)
    const fetchCategories = async () => {
        const response = await getCategories({shopId});
        setCategories(response);
    }
    const deleteProduct = async (productId, title) => {
        Swal.fire({
            // title: 'Delete Product',
            html: `<h5 class="text-danger">Delete ${title}?</h5>`,
            showCancelButton: true,
            confirmButtonText: 'Delete',
            confirmButtonColor: 'red',
            position: 'center',
            icon: 'question'
          }).then( async (response) => {
            /* Read more about isConfirmed, isDenied below */
            if (response.isConfirmed) {
                try {
                    const res = await Axios({
                        url:"api?deleteProduct",
                        method:"POST",
                        data:{productId}
                    })
                    Swal.fire({
                        text: res.data.message,
                        icon: 'success',
                        timer: 5000,
                        toast: true,
                        showConfirmButton: false,
                        position: 'top-end'
                    });
                    dispatch(fetchShopProducts(currentShop.id))
                } catch (err) {
                    Swal.fire({
                        text: err.response.message,
                        icon: 'error',
                        timer: 5000,
                        toast: true,
                        showConfirmButton: false,
                        position: 'top-end'
                    });
                    // setDelState({success:false,message:error.message})
                }
            } 
          })
        
    }
    const fetchProduct = async (data) => {
        const response = await getProduct(data);
         // console.log(response.data);
         dispatch(product(response.data));
     }
  
    const getProductCategory = (e) => {
        let category = RegExp(e.target.value, 'i').test("All Categories") ? null : e.target.value;
        setProductCategory(category);
    }
    const getPrice = (price) => {
        let oldPrice = new Number(price).toFixed(2)
        let currency = new Intl.NumberFormat(
            // 'en-US', 
        // {
        //     style: 'currency',
        //     currency: 'NGN',
        // }
        );
        let newPrice = currency.format(oldPrice);
        return newPrice;
    }

    const searchProduct= (e) => {
        setProductSearched(RegExp(e.target.value, 'i'));
    }
    useEffect(()=>{

        return ()=>{
            return null
        }
    },[currentShop.id])
    useEffect(() => {
        fetchCategories()
    }, [])
    return (
        currentShopProducts.loading ?
        <>
            <div className='page-preloader' style={{width:"max-content"}}>
    <Spinner animation="border" >
    <>{currentUser.err && "Network failure,Please refresh"}</></Spinner>
        </div>
        </>
        :
        <React.Fragment>
            <>
                <ImportProducts
                    show={showImportModal}
                    handleClose={()=>handleImportModal(false)}
                />
            </>
            <section className="content-main">
	
    <div className="content-header">
        <h2 className="content-title">Products</h2>
        <div>
            {/* <a href="#" className="btn btn-light mx-1"
            >Export</a>
            <button className="btn btn-light mx-1" onClick={()=>handleImportModal(true)}
            >Import</button> */}
            <Link className="btn t-l-btn mx-1"
            to={pageUrl(shop,'/add-product')}>Create Product</Link>
        </div>
    </div>
    
    <div className="card mb-4">
    <header className="card-header">
        <div className="row gx-3">
            <div className="col-lg-4 col-md-6 me-auto">
                <input
                onChange={searchProduct}
                type="text" placeholder="Search..." className="form-control"/>
            </div>
            <div className="col-lg-3 col-6 col-md-4">
                <select className="form-select"
                onChange={(e)=>getProductCategory(e)}
                >
                    <option value={''}> All Category </option>
                {
                   categories && categories.store_categories  && categories.store_categories.map((cat)=>{
                    return <option key={cat.category_id} value={cat.title}>{cat.title}</option>
                   })
                }
                </select>
            </div>
        </div>
    </header>
    <div className="card-body">
    
        {
            currentShopProducts.loading ?
            <>
                <Alert severity='info'>Loading...</Alert>
            </>:
            <>
                    <div style={{marginTop:80}} className="row gx-3 row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-xl-4 row-cols-xxl-5 productContainer">
        {
            currentShopProducts && currentShopProducts.products  && currentShopProducts.products.length > 0 ?
            <>
                {
                    currentShopProducts.products.map(function(product,i){
                        return productSearched.test(product.title) &&
                        !productCategory ?
                        (
                            <div className="col" key={createKey()} style={{cursor:'pointer'}}>
                                <div className="card card-product-grid" key={product.id+i}>
                                    { 
                                        product.images && product.images.length > 0 ?
                                        <>
                                            <Carousel variant='dark'
                                            prevIcon={ 
                                                    <ArrowCircleLeft fontSize='medium' className='t-arrow-icon'/>
                                                    }
                                            nextIcon={
                                                <ArrowCircleRightSharp fontSize="medium" className="t-arrow-icon" />
                                            }
                                            >
                                                {
                                                    product.images.map(function(productImage,u){
                                                        return (
                                                            
                                                            <Carousel.Item key={productImage.id}
                                                            variant='dark'
                                                            >
                                                            <img onClick={()=>{
                                                                    dispatch(getProductById(product.id,product.shopId))
                                                                    fetchProduct({shopId:product.shopId, productId: product.id});
                                                                    navigate(`/shop/${shop}/dashboard/products/${product.id}`)
                                                                }}
                                                            
                                                            className="d-block w-100"
                                                            style={{
                                                                height:200
                                                            }}
                                                            src={productImage.image}
                                                            alt={`${product.title} - Image - ${productImage.product_id}`}
                                                            />
                                                            </Carousel.Item>
                                                        
                                                        )
                                                    })
                                                }

                                            </Carousel>
                                        </>:
                                        <a href="#" className="img-wrap"> No image </a>
                                    }

                                    


                                    <div className="info-wrap">
                                        <a href="#" className="title text-truncate">{product.title}</a>
                                        <div className="price mb-2"> ₦{
                                        getPrice(product.pricing.sellingPrice)
                                        }</div> 

                                        <button data-bs-toggle="dropdown" className="btn btn-sm btn-light mx-1" onClick={()=>{
                                            dispatch(getProductById(product.id,product.shopId))
                                            fetchProduct({shopId:product.shopId, productId: product.id});
                                            navigate(`/shop/${shop}/dashboard/products/${product.id}`)
                                        }}> 
                                                <i className="material-icons md-eye"></i> View
                                        </button>
                                        <button className="btn btn-sm btn-outline-danger" onClick={(e)=>{
                                            // e.target.disabled= true;
                                            deleteProduct(product.id, product.title);
                                        }}> 
                                                <i className="material-icons md-delete_forever"></i>  Delete  
                                        </button>
                                    </div>
                                </div>
                            </div> 
                        ) :
                        RegExp(productCategory, 'i').test(product.categories) && (
                            
                            <div className="col" key={product.id} style={{cursor:'pointer'}} onClick={()=>{
                                dispatch(getProductById(product.id,product.shopId))
                                fetchProduct({shopId:product.shopId, productId: product.id});
                                navigate(`/shop/${shop}/dashboard/products/${product.id}`)
                            }}>
                                <div className="card card-product-grid" key={product.id+i}>
                                    { 
                                        product.images && product.images.length > 0 ?
                                        <>
                                            <Carousel variant='dark'
                                            prevIcon={ 
                                                    <ArrowCircleLeft fontSize='medium' className='t-arrow-icon'/>
                                                    }
                                            nextIcon={
                                                <ArrowCircleRightSharp fontSize="medium" className="t-arrow-icon" />
                                            }
                                            >
                                                {
                                                    product.images.map(function(productImage,u){
                                                        return (
                                                            
                                                            <Carousel.Item key={productImage.id}
                                                            variant='dark'
                                                            >
                                                            <img
                                                            className="d-block w-100"
                                                            style={{
                                                                height:200
                                                            }}
                                                            src={productImage.image}
                                                            alt={`${product.title} - Image - ${productImage.product_id}`}
                                                            />
                                                            </Carousel.Item>
                                                        
                                                        )
                                                    })
                                                }

                                            </Carousel>
                                        </>:
                                        <a href="#" className="img-wrap"> No image </a>
                                    }

                                    


                                    <div className="info-wrap">
                                        <a href="#" className="title text-truncate">{product.title}</a>
                                        <div className="price mb-2">NGN {new Number(product.pricing.sellingPrice).toFixed(2)}</div> 

                                        <button data-bs-toggle="dropdown" className="btn btn-sm btn-light mx-1" onClick={()=>{
                                            dispatch(getProductById(product.id,product.shopId))
                                            navigate(`/shop/${shop}/dashboard/products/${product.id}`)
                                        }}> 
                                                <i className="material-icons md-eye"></i> View
                                        </button>
                                        <button className="btn btn-sm btn-outline-danger" onClick={(e)=>{
                                            // e.target.disabled= true;
                                            deleteProduct(product.id, product.title);
                                        }}> 
                                                <i className="material-icons md-delete_forever"></i>  Delete  
                                        </button>
                                    </div>
                                </div>
                            </div> 
                        )
                    })
                }
            </>:
            <>
            <div className='container-fluid'>
                <Alert severity='warning'>You do not have any product in this store</Alert>
            </div>
            </>
        }
    </div> 
            </>
        }    
        </div>
    </div> 
    
    
    
    </section>
        </React.Fragment>
    )
}

export default ProductsContent