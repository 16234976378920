import { Alert } from '@mui/material'
import React, { useEffect,useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate,useParams} from 'react-router-dom'
import { addProduct, fetchShopProducts } from '../../../redux/action/products'
import { createKey } from '../../../api/api'


function DraftContents(){
    const {shop} = useParams()
    const navigate = useNavigate()
    const {currentShop,currentShopProducts} = useSelector(state => state)
    const dispatch = useDispatch()
    const [drafts,setDrafts] = useState([])
    const deleteDraft = num => {
        const drafts = (JSON.parse(localStorage.getItem('drafts'))).filter((product,i)=>{return product.shopId == currentShop.id
        }).filter(function(p,n){
            return n != num
        })
        localStorage.removeItem('drafts')
        localStorage.setItem("drafts",JSON.stringify(drafts))
        setDrafts(drafts)

    }
    useEffect(()=>{
        dispatch(fetchShopProducts(currentShop.id))
        setDrafts( localStorage.getItem("drafts") ? (JSON.parse(localStorage.getItem('drafts'))).filter((product,i)=>{
            return product.shopId == currentShop.id
        }) : [])
        return ()=>{
            return null
        }
    },[shop,currentShop.id])
    return (
        <React.Fragment>
            <section className="content-main">
	
    <div className="content-header">
        <h2 className="content-title">Drafts</h2>
    </div>
    <div className="card-body">
                <div className="table-responsive">
                    {
                        drafts.length > 0 ?
                        <>
                        <table className="table table-hover">
                    <thead>
                        <tr>
                            <th>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox"/>
                                </div>
                            </th>
                            <th>Title</th>
                            <th>Brand</th>
                            <th>Description</th>
                            <th>Categories</th>
                            <th>Status</th>
                            <th className="text-end"> Action </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                        
                            <>
                            {
                                drafts.map(function(draft,i){
                                    return (
                                    <tr key={createKey()}>
                            <td>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox"/>
                                </div>
                            </td>
                            <td><b>{draft.title || 'Not Specified'}</b></td>
                            <td>{draft.brand || 'Not specified'}</td>
                            <td>{draft.description}</td>
                            <td>{draft.categories.length > 0 && draft.categories.toString()}</td>
                            <td><span className="badge rounded-pill alert-warning">Draft</span></td>
                            <td className="text-end">
                                <button type='button' className="btn btn-sm btn-light mx-1 mt-1" onClick={()=>{
                                    dispatch(addProduct(draft))
                                    navigate(`/shop/${shop}/dashboard/add-product`)
                                }}> 
                                    <i className="material-icons md-edit"></i>  Edit  
                                </button>
                                <button type='submit' className="btn btn-sm btn-outline-danger mt-1" onClick={()=>{
                                    deleteDraft(i)
                                }}> 
                                    <i className="material-icons md-delete_forever"></i>  Delete  
                                </button>
                            </td>
                                    </tr>
                                    )
                                })
                            }
                            </>
                        }
            
                    </tbody>
                </table>
                        </>
                        :<>
                        <Alert  severity='warning' className='d-flex justify-content-center align-items-center'>No product saved to draft!</Alert> 
                        </>
                    }
                
                </div>

            </div> 

    </section>
           


        </React.Fragment>
    )
}

export default DraftContents