import { Link, Navigate, useLocation, useParams } from "react-router-dom";
import { Axios } from "../../../api/axios";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Spinner } from "react-bootstrap";

function PaymentStatus(){
    const {planId} = useParams();
    //status=successful&txn_ref=SHOPREF_65116c9c2eda64.065515571695640732&msft_ref=ref_IEL3UEBBBOJ16956407366091801
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const status = queryParams.get("status")
    const reference = queryParams.get("txn_ref")
    const [payment,setPayment] = useState(null)
    const msft_ref = queryParams.get("msft_ref")
    const [loading,setLoading] = useState(true)
    const verify_subscription = async () => {
        setLoading(true)
        try {
            const res  = await Axios.get('api?verify_subscription',{params:{status,reference,msft_ref}})
            const response = res.data
            if (response.status) {
                setPayment(response.data)
            }else{
                setPayment(response.data)
                Swal.fire({
                    text: response.message,
                    icon: 'error',
                    timer: 5000,
                    toast: true,
                    showConfirmButton: false,
                    position: 'top-end'
                });
            }
        } catch (error) {
            Swal.fire({
                text: error.message,
                icon: 'error',
                timer: 5000,
                toast: true,
                showConfirmButton: false,
                position: 'top-end'
            });
        }finally{
            setLoading(false)
        }
        //
    }
    useEffect(()=>{
        setTimeout(() => {
            verify_subscription()
        }, 3000);
    },[])
    return (
        planId ?
        <>
        {
            loading ?
            <>
            <div className='page-preloader'>
                <Spinner animation="border" >
                </Spinner>
            </div>
            </>:
            <>
                {
                    payment && 
                    <div className="vh-100 d-flex justify-content-center align-items-center">
            <div>
                <div className="mb-4 text-center">
                    {
                        payment.status == 'success' ?
                        <svg xmlns="http://www.w3.org/2000/svg" className="text-success bi bi-times" width="75" height="75"
                        fill="currentColor"  viewBox="0 0 16 16">
                        <path
                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg> :
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
                    <path fill="#F44336" d="M21.5 4.5H26.501V43.5H21.5z" transform="rotate(45.001 24 24)"></path><path fill="#F44336" d="M21.5 4.5H26.5V43.501H21.5z" transform="rotate(135.008 24 24)"></path>
                    </svg>
                    }
                </div>
                <div className="text-center">
                    <h1>{payment.status == 'success' ? 'Payment Completed!':"Payment Failed!"}</h1>
                    <p>{payment.message}</p>
                    <Link to={'/subscription'} className="btn t-btn">View Subscription</Link>
                   </div>
            </div>
        </div>
                }
            </>
        }
        </>:
        <>
        <Navigate to={'/subscription'} replace={true} />
        </>
    )
}
export default PaymentStatus