import { Axios ,token} from "../../api/axios"
import { LOADING_USER_DATA,FETCH_USER_DATA,FETCH_CURRENT_USER_FAILED } from "./types"

 const fetchUserData = () => ({
    type:LOADING_USER_DATA
})

export const getUserData =  () => async dispatch => {
    dispatch(fetchUserData())
    
    return await Axios.get("api?userData").then(user => {
        dispatch({
        type:FETCH_USER_DATA,
        payload:{...user.data.data,data:true}
    })
    })
    .catch(err=> {
        return dispatch({type:FETCH_CURRENT_USER_FAILED,payload:{err:err.response,data:false}})
    })
}