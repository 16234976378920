import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import {Modal, Form, Table} from 'react-bootstrap';

function orderDetails({data, show, onHide}) {


  return (
    <>
    <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>
            Order Details
        </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Table>
                {/* <form className='form-control d-flex row'> */}
                <tbody>
                    <tr>
                        <th className='text-start'>
                             #ID: 
                        </th>
                        <td width={'90%'} >
                            {data.id}
                        </td>
                    </tr>
                    <tr>
                        <th className='text-start'>
                             Name: 
                        </th>
                        <td width={'90%'} >
                            {data.name}
                        </td>
                    </tr>
                    <tr>
                        <th className='text-start'>
                             Email: 
                        </th>
                        <td width={'90%'} >
                            {data.email}
                        </td>
                    </tr>
                    <tr>
                        <th className='text-start'>
                             Quantity: 
                        </th>
                        <td width={'90%'} >
                            {data.quantity}
                        </td>
                    </tr>
                    <tr>
                        <th className='text-start'>
                             Status: 
                        </th>
                        <td width={'90%'} >
                            {data.status}
                        </td>
                    </tr>
                    <tr>
                        <th className='text-start'>
                             Date: 
                        </th>
                        <td width={'90%'} >
                            {data.created_at}
                        </td>
                    </tr>
                </tbody>
                {/* </form> */}
            </Table>
            
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
        </Modal.Footer>
    </Modal>
    </>
  );
}

export default orderDetails;