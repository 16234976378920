
import  React, { useEffect, useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useDispatch, useSelector } from 'react-redux';
import { setDefaultThemeChanges } from '../../../redux/action/themes-actions';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Axios, token } from '../../../api/axios';
import { ToastAlert } from '../../status/ToastAlert';
import { dispatchMediaStyleForComponent } from '../theme-constants';
import Nav from 'react-bootstrap/Nav';

export function DefaultThemeMobileNav({ show, onHide }) {
    const dispatch = useDispatch();
    const {currentShop,currentUser,defaultTheme,themePreviewState } = useSelector(state => state)

  
    useEffect(() => {
      // Add any necessary logic here
    }, []);
  
    return (
      <>
        {show && ( // Only render the sidebar if "show" is true
          <div
          className='nav-bar-side-template'
            style={{
              position: 'fixed', // Set the position to fixed
              top: 0,
              bottom: 0,
              background:'white',
              right: show ? 0 : -250, // Use the "show" prop to control the right position
              width: 250,
              zIndex: 9999,
              transition: 'right 3s ease-in-out'
            }}
          >
            <div className='icon-close'>
            <span className="fa fa-times icon-close" onClick={onHide}></span>
            </div>
            <ul className="navbar-jevelin" style={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'center',
                alignItems:'center'
            }}>
                            {
                                defaultTheme.landing['header'].navLinks.map((link,i)=>{
                                    return (
                                        <a href='#'style={{
                                            textDecoration:"none",
                                            color:dispatchMediaStyleForComponent(themePreviewState.state,defaultTheme,'landing','header','navStyle')['color'],
                                            marginTop:10
                                        }} key={i}>{link}</a>
                                    )
                                })
                            }
            </ul>
            
          </div>
        )}
      </>
    );
  }
  

export function NavBarSetting({show,onHide}){
    const [menuVal,setMenuVal] = useState("")
    const [addSocials,setAddSocials] = useState(false)
    const dispatch = useDispatch()
    const {defaultTheme} = useSelector((state) => state)

    useEffect(()=>{
        return ()=>{
            setAddSocials(addSocials?true:false)
        }
    },[])
    return(
        <>
        <Offcanvas show={show} onHide={onHide} backdrop={false} style={{
            maxWidth:300
        }}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{fontsize:16}}>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body style={{padding:0}}>
          <div className='container-fluid'>
          <div className='form-group mt-2 mb-2'>
                <label>Background Color</label>
                <input type='color' value={defaultTheme.landing.header.navStyle.desk.backgroundColor || ''} className='form-control' onChange={(e)=>{
                    if(e.target.value !="") {
                        dispatch(setDefaultThemeChanges('landing','header','navStyle',{
                            ...defaultTheme['landing']['header']['navStyle'],
                            desk:{
                                ...defaultTheme['landing']['header']['navStyle']['desk'],
                                backgroundColor:e.target.value,color:e.target.value !=='white' ? 'white':'black'
                            },tab:{
                                ...defaultTheme['landing']['header']['navStyle']['tab'],
                                backgroundColor:e.target.value,color:e.target.value !=='white' ? 'white':'black'
                            },
                            mobile:{
                                ...defaultTheme['landing']['header']['navStyle']['tab'],
                                backgroundColor:e.target.value,color:e.target.value !=='white' ? 'white':'black'
                            }
                        }))
                    }
                }}>
                
                </input>
            </div>
            <hr/>
            <div className='form-group mt-2 mb-2'>
                <label>Color Scheme</label>
                <select className='form-control' name='accent' onChange={(e)=>{
                    if(e.target.value !="") {
                        dispatch(setDefaultThemeChanges('landing','header','navStyle',{
                            ...defaultTheme['landing']['header']['navStyle'],
                            desk:{
                                ...defaultTheme['landing']['header']['navStyle']['desk'],
                               color:e.target.value 
                            },tab:{
                                ...defaultTheme['landing']['header']['navStyle']['tab'],
                                color:e.target.value 
                            },
                            mobile:{
                                ...defaultTheme['landing']['header']['navStyle']['mobile'],
                                color:e.target.value 
                            }
                        }))
                    }
                }}>
                    <option value='black'>Default</option>
                    <option value='grey'>Color 1</option>
                    <option value='blue'>Color 2</option>
                    <option value='white'>Color 3</option>
                </select>
            </div>
    
            <hr/>
            <b>Logo setting</b>
            <React.Fragment>
                        <Tabs
                className="mb-3"
                >
                <Tab eventKey="desktop" title={<i className="fa fa-desktop"></i>}>
                <div className='row form-group mt-2 mb-2'>
                <div className='col-6'>
                <label>Width (px)</label>
                <input type='number'  className='form-control' defaultValue={60} min={60} max={150} onChange={(e)=>{
                    dispatch(setDefaultThemeChanges('landing','header','siteImageStyle',
                    {
                        ...defaultTheme['landing']['header']['siteImageStyle'],
                        desk:{
                        ...defaultTheme['landing']['header']['siteImageStyle']['desk'],
                        width:e.target.value+'px'
                    }}))
                }} />
                </div>
                <div className='col-6'>
                <label>Height (px)</label>
                <input type='number'  className='form-control' defaultValue={40} min={40} max={60} onChange={(e)=>{
                     dispatch(setDefaultThemeChanges('landing','header','siteImageStyle',
                     {
                         ...defaultTheme['landing']['header']['siteImageStyle'],
                         desk:{
                         ...defaultTheme['landing']['header']['siteImageStyle']['desk'],
                         height:e.target.value+'px'
                     }}))
                }} />
                </div>
            </div>
            <div className='form-group mt-2 mb-2'>
                <label>Margin Left ({defaultTheme['landing']['header']['siteImageStyle']['desk'].marginLeft})</label>
                <input type='range'  className='form-control' max={100} onChange={(e)=>{
                    dispatch(setDefaultThemeChanges('landing','header','siteImageStyle',
                    {
                        ...defaultTheme['landing']['header']['siteImageStyle'],
                        desk:{
                        ...defaultTheme['landing']['header']['siteImageStyle']['desk'],
                        marginLeft:e.target.value+'px'
                    }}))
                }} />
            </div>
                </Tab>
                <Tab eventKey="tablet" title={<i className="fa fa-tablet"></i>}>
                <div className='row form-group mt-2 mb-2'>
                <div className='col-6'>
                <label>Width (px)</label>
                <input type='number'  className='form-control' defaultValue={60} min={60} max={150} onChange={(e)=>{
                    dispatch(setDefaultThemeChanges('landing','header','siteImageStyle',
                    {
                        ...defaultTheme['landing']['header']['siteImageStyle'],
                        tab:{
                        ...defaultTheme['landing']['header']['siteImageStyle']['tab'],
                        width:e.target.value+'px'
                    }}))
                }} />
                </div>
                <div className='col-6'>
                <label>Height (px)</label>
                <input type='number'  className='form-control' defaultValue={40} min={40} max={60} onChange={(e)=>{
                    dispatch(setDefaultThemeChanges('landing','header','siteImageStyle',
                    {
                        ...defaultTheme['landing']['header']['siteImageStyle'],
                        tab:{
                        ...defaultTheme['landing']['header']['siteImageStyle']['tab'],
                        height:e.target.value+'px'
                    }}))
                }} />
                </div>
            </div>
            <div className='form-group mt-2 mb-2'>
                <label>Margin Left ({defaultTheme['landing']['header']['siteImageStyle']['tab'].marginLeft})</label>
                <input type='range'  className='form-control' max={100} onChange={(e)=>{
                    dispatch(setDefaultThemeChanges('landing','header','siteImageStyle',
                    {
                        ...defaultTheme['landing']['header']['siteImageStyle'],
                        tab:{
                        ...defaultTheme['landing']['header']['siteImageStyle']['tab'],
                        marginLeft:e.target.value+'px'
                    }}))
                }} />
            </div>
                </Tab>
                <Tab eventKey="mobile" title={<i className="fa fa-mobile"></i>}>
                <div className='row form-group mt-2 mb-2'>
                <div className='col-6'>
                <label>Width (px)</label>
                <input type='number'  className='form-control' defaultValue={60} min={60} max={150} onChange={(e)=>{
                    dispatch(setDefaultThemeChanges('landing','header','siteImageStyle',
                    {
                        ...defaultTheme['landing']['header']['siteImageStyle'],
                        mobile:{
                        ...defaultTheme['landing']['header']['siteImageStyle']['mobile'],
                        width:e.target.value+'px'
                    }}))
                }} />
                </div>
                <div className='col-6'>
                <label>Height (px)</label>
                <input type='number'  className='form-control' defaultValue={40} min={40} max={60} onChange={(e)=>{
                    dispatch(setDefaultThemeChanges('landing','header','siteImageStyle',
                    {
                        ...defaultTheme['landing']['header']['siteImageStyle'],
                        mobile:{
                        ...defaultTheme['landing']['header']['siteImageStyle']['mobile'],
                        height:e.target.value+'px'
                    }}))
                }} />
                </div>
            </div>
            <div className='form-group mt-2 mb-2'>
                <label>Margin Left ({defaultTheme['landing']['header']['siteImageStyle'].marginLeft})</label>
                <input type='range'  className='form-control' max={100} onChange={(e)=>{
                    dispatch(setDefaultThemeChanges('landing','header','siteImageStyle',
                    {
                        ...defaultTheme['landing']['header']['siteImageStyle'],
                        mobile:{
                        ...defaultTheme['landing']['header']['siteImageStyle']['mobile'],
                        marginLeft:e.target.value+'px'
                    }}))
                }} />
            </div>
                </Tab>
               
                </Tabs>
            </React.Fragment>
            
            <hr/>
            <div className='form-group mt-2 mb-2'>
                <label>Add Social media on navbar ? </label>
                <input type='checkbox' className='form-check' onChange={(e)=>{
                    setAddSocials(e.target.checked)
                }} />
            </div>
            <div className='media-container' style={{
                display:` ${addSocials ? 'block' :'none'}`
            }}>
            <div className='form-group mt-2 mb-2'>
                <label>Facebook </label>
                <input type='text' className='form-control' value={defaultTheme.landing.header.socialMedia.filter((med,i)=>{
                        return med.name =='Facebook'
                    })[0] && defaultTheme.landing.header.socialMedia.filter((med,i)=>{
                        return med.name =='Facebook'
                    })[0].link } 
                    placeholder='Enter your facebook link' onChange={(e)=>{
                    if (e.target.value ==="") {
                        dispatch(setDefaultThemeChanges('landing','header','socialMedia',
                        [...defaultTheme.landing.header.socialMedia.filter((med,i)=>{
                            return med.name !=='Facebook'
                        })
                        ]
                        ))
                    } else {
                        dispatch(setDefaultThemeChanges('landing','header','socialMedia',
                    [...defaultTheme.landing.header.socialMedia.filter((med,i)=>{
                        return med.name !=='Facebook'
                    }),{name:"Facebook",icon:'fa-facebook',link:e.target.value}]
                    ))
                    }
                }} />
            </div>
            <div className='form-group mt-2 mb-2'>
                <label>Twitter </label>
                <input type='text' className='form-control' placeholder='Enter your twitter link' 
                value={defaultTheme.landing.header.socialMedia.filter((med,i)=>{
                    return med.name =='Twitter'
                })[0] && defaultTheme.landing.header.socialMedia.filter((med,i)=>{
                    return med.name =='Twitter'
                })[0].link }  onChange={(e)=>{
                if (e.target.value ==="") {
                    dispatch(setDefaultThemeChanges('landing','header','socialMedia',
                    [...defaultTheme.landing.header.socialMedia.filter((med,i)=>{
                        return med.name !=='Twitter'
                    })
                    ]
                    ))
                } else {
                    dispatch(setDefaultThemeChanges('landing','header','socialMedia',
                [...defaultTheme.landing.header.socialMedia.filter((med,i)=>{
                    return med.name !=='Twitter'
                }),{name:"Twitter",icon:'fa-twitter',link:e.target.value}]
                ))
                }
            }} 

                />
            </div>
            <div className='form-group mt-2 mb-2'>
                <label>Instagram </label>
                <input type='text' className='form-control'
                
                value={defaultTheme.landing.header.socialMedia.filter((med,i)=>{
                    return med.name =='Instagram'
                })[0] && defaultTheme.landing.header.socialMedia.filter((med,i)=>{
                    return med.name =='Instagram'
                })[0].link } 
                 
                onChange={(e)=>{
                if (e.target.value ==="") {
                    dispatch(setDefaultThemeChanges('landing','header','socialMedia',
                    [...defaultTheme.landing.header.socialMedia.filter((med,i)=>{
                        return med.name !=='Instagram'
                    })
                    ]
                    ))
                } else {
                    dispatch(setDefaultThemeChanges('landing','header','socialMedia',
                [...defaultTheme.landing.header.socialMedia.filter((med,i)=>{
                    return med.name !=='Instagram'
                }),{name:"Instagram",icon:'fa-instagram',link:e.target.value}]
                ))
                }
            }} 

                
                
                placeholder='Enter your instagram link' />
            </div>
            </div>
            
          </div>
        </Offcanvas.Body>
      </Offcanvas>
        </>
    )
}

export function ImageBannerSettings({show,onHide}){
    const [isUploadingImage,setUploadStatus] = useState(false)
    const [statusUpload,setStatusUpload] = useState(null)
    const dispatch = useDispatch()
    const [alert,setAlertStatus] = useState(false)
    const {defaultTheme,currentShop} = useSelector((state) => state)
    const uploadBanner = async () => {
    
        const form = document.getElementById('uploadForm')
        setUploadStatus(true)
        try{
            const res = await Axios({
                url:"api?addBannerImage",
                method:"POST",
                data:new FormData(form),
                headers:{
                    'Authorization':'Bearer '+token()
                }
            })
            const {data} = res
            setAlertStatus(true)
            setUploadStatus(false)
            setStatusUpload(data)
            if(data.success){
                dispatch(setDefaultThemeChanges('landing','bannerHero','bannerStyle',
                {
                    ...defaultTheme.landing.bannerHero.bannerStyle,
                    desk:{
                        ...defaultTheme.landing.bannerHero.bannerStyle.desk,
                        backgroundImage:`url(${data.imageURL})`
                    },
                    tab:{
                        ...defaultTheme.landing.bannerHero.bannerStyle.tab,
                        backgroundImage:`url(${data.imageURL})`
                    },mobile:{
                        ...defaultTheme.landing.bannerHero.bannerStyle.mobile,
                        backgroundImage:`url(${data.imageURL})`
                    }
                }
                    ))
            }
        }catch(error){
            setAlertStatus(true)
            setUploadStatus(false)
        }
    }
    const [customSetting,setCustom] = useState(false)
    useEffect(()=>{
        
    },[])
    return(
        <>
        <>
        <ToastAlert
        show = {alert}
        setShow = {()=> setAlertStatus(false)}
        position = 'buttom-left'
        status = {statusUpload? statusUpload.success :false}
        message = {statusUpload?statusUpload.message:'Error!'}
        />
        </>
        <Offcanvas show={show} onHide={onHide} backdrop={false} style={{
            maxWidth:300
        }}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{fontsize:16}}>Banner </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body style={{padding:0}}>
          <div className='container-fluid'>
        
            <div className='form-group mt-2 mb-2' style={{
                borderRadius:25,
            }}>        
                <form className='form' method='POST' id='uploadForm' encType='multipart/form-data' onSubmit={(e) => uploadBanner(e)}>
                <input type='hidden' name='shopId' value={currentShop.id} className='form-control'  />
                <div className='send w-100'>
                                    <div className="mt-0 align-items-center d-flex justify-content-between">
                                        <label className='upload-label col-12'  style={{
                                display:'flex',                                            
                                flexDirection: "column",
                                justifyContent: "center",
                                flexWrap: "wrap",
                                textAlign: "center",
                                border: "0.5px solid #d5d2d2",
                                height:120,
                                borderRadius: 10,
                                cursor: "pointer",
                                paddingLeft:10,
                                paddingRight:10
                                    
                        }} htmlFor="upload-image">

                                                {
                                                    isUploadingImage ?
                                                    <>Uploading...</>:
                                                    <><span style={{fontSize:15}}>Click this area to upload</span>
                                                    <p style={{fontSize:10}}>Formats allowed are PNG, JPG or JPEG</p></>
                                                }
                                        </label>
                                        <input type='file' name='file' id='upload-image' onChange={()=>{
                                            uploadBanner()
                                        }} className='d-none form-control'  />
                                    </div>
                        </div>
                <div className='row mt-1'>
                    <div className='col-6 justify-content-end d-flex' >
                        <button type='button' className='btn btn-danger btn-sm' onClick={()=>{
                             dispatch(setDefaultThemeChanges('landing','bannerHero','bannerStyle',
                             {
                                 ...defaultTheme.landing.bannerHero.bannerStyle,
                                 desk:{
                                     ...defaultTheme.landing.bannerHero.bannerStyle.desk,
                                     backgroundImage:null
                                 },
                                 tab:{
                                     ...defaultTheme.landing.bannerHero.bannerStyle.tab,
                                     backgroundImage:null
                                 },mobile:{
                                     ...defaultTheme.landing.bannerHero.bannerStyle.mobile,
                                     backgroundImage:null
                                 }
                             }
                                 ))
                        }}>Remove</button>
                    </div>
                </div>
                </form>
            </div>
            <hr />
            <b>Banner position</b>
            <React.Fragment>
                        <Tabs
                className="mb-3"
                >
                <Tab eventKey="desktop" title={<i className="fa fa-desktop"></i>}>
                
            {
        
                <>
            <div className='form-group mt-2 mb-2'>
                <label>Position Vertical </label>
                <input type='range'  className='form-control' min={-500} max={1000} onChange={(e)=>{
                    dispatch(setDefaultThemeChanges('landing','bannerHero','bannerStyle',
                    {
                        ...defaultTheme['landing']['bannerHero']['bannerStyle'],
                        desk:{
                            ...defaultTheme['landing']['bannerHero']['bannerStyle']['desk'],
                            backgroundPositionY:e.target.value+"px",
                    }
                }))
                }} />
            </div>
            <div className='form-group mt-2 mb-2'>
                <label>Position Horizontal </label>
                <input type='range'  className='form-control' min={-500} max={1000}  onChange={(e)=>{
                    dispatch(setDefaultThemeChanges('landing','bannerHero','bannerStyle',
                    {
                        ...defaultTheme['landing']['bannerHero']['bannerStyle'],
                        desk:{
                        ...defaultTheme['landing']['bannerHero']['bannerStyle']['tab'],
                        backgroundPositionX:e.target.value+"px",
                }
                }))
                }} />
            </div>
            <div className='col12 mt-1'>
                    <label>Height </label>
                    <input type='range' value={defaultTheme.landing.bannerHero.bannerStyle.desk.height} className='form-control'  max={1000} onChange={(e)=>{
                    dispatch(setDefaultThemeChanges('landing','bannerHero','bannerStyle',
                    {
                        ...defaultTheme['landing']['bannerHero']['bannerStyle'],
                        desk:{
                            ...defaultTheme['landing']['bannerHero']['bannerStyle']['desk'],
                            height:e.target.value+"px",
                    }
                }))
                }} />
                </div>
                </>
            }
            
                </Tab>
                <Tab eventKey="tablet" title={<i className="fa fa-tablet"></i>}>
                <>
            <div className='form-group mt-2 mb-2'>
                <label>Position Vertical </label>
                <input type='range'  className='form-control' min={-500} max={1000} onChange={(e)=>{
                    dispatch(setDefaultThemeChanges('landing','bannerHero','bannerStyle',
                    {
                        ...defaultTheme['landing']['bannerHero']['bannerStyle'],
                        tab:{
                        ...defaultTheme['landing']['bannerHero']['bannerStyle']['tab'],
                        backgroundPositionY:e.target.value+"px",
                }
                }))
                }} />
            </div>
            <div className='form-group mt-2 mb-2'>
                <label>Position Horizontal </label>
                <input type='range'  className='form-control' min={-500} max={1000}  onChange={(e)=>{
                    dispatch(setDefaultThemeChanges('landing','bannerHero','bannerStyle',
                    {
                        ...defaultTheme['landing']['bannerHero']['bannerStyle'],
                        tab:{
                        ...defaultTheme['landing']['bannerHero']['bannerStyle']['tab'],
                        backgroundPositionX:e.target.value+"px",
                }
                }))
                }} />
            </div>
            <div className='col12 mt-1'>
                    <label>Height </label>
                    <input type='range' value={defaultTheme.landing.bannerHero.bannerStyle.tab.height} className='form-control'  max={1000} onChange={(e)=>{
                    dispatch(setDefaultThemeChanges('landing','bannerHero','bannerStyle',
                    {
                        ...defaultTheme['landing']['bannerHero']['bannerStyle'],
                        tab:{
                            ...defaultTheme['landing']['bannerHero']['bannerStyle']['tab'],
                            height:e.target.value+"px",
                    }
                }))
                }} />
                </div>
                </>
                </Tab>
                <Tab eventKey="mobile" title={<i className="fa fa-mobile"></i>}>
                <>
            <div className='form-group mt-2 mb-2'>
                <label>Position Vertical </label>
                <input type='range'  className='form-control' min={-500} max={1000} onChange={(e)=>{
                    dispatch(setDefaultThemeChanges('landing','bannerHero','bannerStyle',
                    {
                        ...defaultTheme['landing']['bannerHero']['bannerStyle'],
                        mobile:{
                        ...defaultTheme['landing']['bannerHero']['bannerStyle']['mobile'],
                        backgroundPositionY:e.target.value+"px",
                }
                }))
                }} />
            </div>
            <div className='form-group mt-2 mb-2'>
                <label>Position Horizontal </label>
                <input type='range'  className='form-control' min={-500} max={1000}  onChange={(e)=>{
                    dispatch(setDefaultThemeChanges('landing','bannerHero','bannerStyle',
                    {
                        ...defaultTheme['landing']['bannerHero']['bannerStyle'],
                        mobile:{
                        ...defaultTheme['landing']['bannerHero']['bannerStyle']['mobile'],
                        backgroundPositionX:e.target.value+"px",
                }
                }))
                }} />
            </div>
            <div className='col12 mt-1'>
                    <label>Height </label>
                    <input type='range' value={defaultTheme.landing.bannerHero.bannerStyle.mobile.height} className='form-control'  max={1000} onChange={(e)=>{
                    dispatch(setDefaultThemeChanges('landing','bannerHero','bannerStyle',
                    {
                        ...defaultTheme['landing']['bannerHero']['bannerStyle'],
                        mobile:{
                            ...defaultTheme['landing']['bannerHero']['bannerStyle']['mobile'],
                            height:e.target.value+"px",
                    }
                }))
                }} />
                </div>
                </>
                </Tab>
                </Tabs>
            </React.Fragment>
            
            
          </div>
        </Offcanvas.Body>
      </Offcanvas>
        </>
    )
}

export function CustomeDetailsSettings({show,onHide}){
    const dispatch = useDispatch()
    const {defaultTheme} = useSelector((state) => state)
    useEffect(()=>{
        
    },[])
    return(
        <>
        <Offcanvas show={show} onHide={onHide} backdrop={false} style={{
            maxWidth:300
        }}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{fontsize:18}}>Banner Texts</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body style={{padding:0}}>
          <div className='container-fluid'>
            <hr/>
            <div className='form-group mt-2 mb-2'>
                <label>Background</label>
                <input type='color' className='form-control' defaultValue={'#f6f6f6'} name='accent' onChange={(e)=>{
                    if(e.target.value !="") {
                        dispatch(setDefaultThemeChanges('landing','bannerHero','bannerStyle',{
                            ...defaultTheme['landing']['bannerHero']['bannerStyle'],
                            desk:{
                                ...defaultTheme['landing']['bannerHero']['bannerStyle']['desk'],
                            backgroundColor:e.target.value,
                            color:e.target.value === '#f6f6f6' ? 'black':'white'
                            },tab:{
                                ...defaultTheme['landing']['bannerHero']['bannerStyle']['tab'],
                            backgroundColor:e.target.value,
                            color:e.target.value === '#f6f6f6' ? 'black':'white'
                            },
                            mobile:{
                                ...defaultTheme['landing']['bannerHero']['bannerStyle']['mobile'],
                            backgroundColor:e.target.value,
                            color:e.target.value === '#f6f6f6' ? 'black':'white'
                            }
                        }))
                    }
                }}>
                </input>
            </div>
            <hr />
            <b>Text</b>
            <div className='row form-group mt-2 mb-2'>
                <div className='col-12'>
                <label>Header text</label>
                <input type='text' value={defaultTheme.landing.bannerHero.topText}  className='form-control' onChange={(e)=>{
                    dispatch(setDefaultThemeChanges('landing','bannerHero','topText',e.target.value))
                }} />
                <div className='row'>
                <div className='col-12 m-1'>
                    <label>Color</label>
                    <input type='color' value={defaultTheme.landing.bannerHero.topTextStyle.color} className='form-control'  onChange={(e)=>{
                    dispatch(setDefaultThemeChanges('landing','bannerHero','topTextStyle',{
                        ...defaultTheme['landing']['bannerHero']['topTextStyle'],
                        color:e.target.value
                    }))
                }} />
                </div>
                <div className='col-6'>
                    <label>Font size({defaultTheme.landing.bannerHero.topTextStyle.fontSize&&defaultTheme.landing.bannerHero.topTextStyle.fontSize})</label>
                    <input type='range' value={defaultTheme.landing.bannerHero.topTextStyle.fontSize} className='form-control'  onChange={(e)=>{
                    dispatch(setDefaultThemeChanges('landing','bannerHero','topTextStyle',{
                        ...defaultTheme['landing']['bannerHero']['topTextStyle'],
                        fontSize:e.target.value+'px'
                    }))
                }} />
                </div>
                <div className='col-6'>
                    <label>Weight({defaultTheme.landing.bannerHero.topTextStyle.fontWeight ?? defaultTheme.landing.bannerHero.topTextStyle.fontWeight}) </label>
                    <input type='range' value={defaultTheme.landing.bannerHero.topTextStyle.fontWeight} className='form-control' min={100} max={900} onChange={(e)=>{
                    dispatch(setDefaultThemeChanges('landing','bannerHero','topTextStyle',{
                        ...defaultTheme['landing']['bannerHero']['topTextStyle'],
                        fontWeight:e.target.value
                    }))
                }} />
                </div>
                </div>
                </div>
                <br/>
                <hr/>
                <div className='col-12'>
                <label>Paragraph text</label>
                <input type='text' value={defaultTheme.landing.bannerHero.text2}  className='form-control' onChange={(e)=>{
                    dispatch(setDefaultThemeChanges('landing','bannerHero','text2',e.target.value))
                }} />
                <div className='row'>
                <div className='col-12 m-1'>
                    <label>Color</label>
                    <input type='color' value={defaultTheme.landing.bannerHero.text2Style.color} className='form-control'  onChange={(e)=>{
                    dispatch(setDefaultThemeChanges('landing','bannerHero','text2Style',{
                        ...defaultTheme['landing']['bannerHero']['text2Style'],
                        color:e.target.value
                    }))
                }} />
                </div>
                <div className='col-6'>
                    <label>Font size({defaultTheme.landing.bannerHero.text2Style.fontSize&&defaultTheme.landing.bannerHero.text2Style.fontSize})</label>
                    <input type='range' value={defaultTheme.landing.bannerHero.text2Style.fontSize} className='form-control'  onChange={(e)=>{
                    dispatch(setDefaultThemeChanges('landing','bannerHero','text2Style',{
                        ...defaultTheme['landing']['bannerHero']['text2Style'],
                        fontSize:e.target.value+'px'
                    }))
                }} />
                </div>
                <div className='col-6'>
                    <label>Weight({defaultTheme.landing.bannerHero.text2Style.fontWeight ?? defaultTheme.landing.bannerHero.text2Style.fontWeight}) </label>
                    <input type='range' value={defaultTheme.landing.bannerHero.text2Style.fontWeight} className='form-control' min={100} max={900} onChange={(e)=>{
                    dispatch(setDefaultThemeChanges('landing','bannerHero','text2Style',{
                        ...defaultTheme['landing']['bannerHero']['text2Style'],
                        fontWeight:e.target.value
                    }))
                }} />
                </div>
                </div>
                </div>
            </div>
            
            
          </div>
        </Offcanvas.Body>
      </Offcanvas>
        </>
    )
}

export function CustomButtonSetting({show,onHide}){
    const dispatch = useDispatch()
    const {defaultTheme} = useSelector((state) => state)
    useEffect(()=>{
        
    },[])
    return(
        <>
        <Offcanvas show={show} onHide={onHide} backdrop={false} style={{
            maxWidth:300
        }}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{fontsize:16}}>Button</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body style={{padding:0}}>
          <div className='container-fluid'>
          <div className='form-group mt-2 mb-2'>
                <label>Remove button? </label><br/>
                <input type='checkbox'   onChange={(e)=>{
                    dispatch(setDefaultThemeChanges('landing','bannerHero','button',
                    {
                     ...defaultTheme.landing.bannerHero.button,
                        desk:{
                            ...defaultTheme.landing.bannerHero.button.desk,
                            display:e.target.checked ? 'none':'block'
                         },tab:{
                            ...defaultTheme.landing.bannerHero.button.tab,
                            display:e.target.checked ? 'none':'block'
                         },mobile:{
                            ...defaultTheme.landing.bannerHero.button.mobile,
                            display:e.target.checked ? 'none':'block'
                         }
                     
                     
                    }
                    ))
                }} />
            </div>
            <div className='form-group mt-2 mb-2'>
                <label>Label </label>
                <input type='text'  className='form-control' 
                value={defaultTheme.landing.bannerHero.button.text}  onChange={(e)=>{
                    dispatch(setDefaultThemeChanges('landing','bannerHero','button',
                    {
                     ...defaultTheme.landing.bannerHero.button,
                     text:e.target.value
                    }
                    ))
                }} />
            </div>
            <hr/>
            <div className='form-group mt-2 mb-2'>
                <label>Target page</label>
                <select className='form-control' onChange={(e)=>{
                    if(e.target.value !="") {
                        dispatch(setDefaultThemeChanges('landing','bannerHero','button',
                    {
                     ...defaultTheme.landing.bannerHero.button,
                     link:e.target.value
                    }
                    ))
                    }
                }}>
                    <option value=''>Home page</option>
                    <option value=''>Shop</option>
                    <option value=''>About</option>
                </select>
            </div>
            <hr/>
            <div className='form-group mt-2 mb-2'>
                <label>Background </label>
                <input type='color'  className='form-control' 
                value={defaultTheme.landing.bannerHero.button.text}  onChange={(e)=>{
                    dispatch(setDefaultThemeChanges('landing','bannerHero','button',
                    {
                     ...defaultTheme.landing.bannerHero.button,
                        desk:{
                            ...defaultTheme.landing.bannerHero.button.desk,
                            backgroundColor:e.target.value
                         },tab:{
                            ...defaultTheme.landing.bannerHero.button.tab,
                            backgroundColor:e.target.value
                         },mobile:{
                            ...defaultTheme.landing.bannerHero.button.mobile,
                            backgroundColor:e.target.value
                         }
                     
                     
                    }
                    ))
                }} />
            </div>
            <hr />
            <b>Button styles</b>
            <>
            <div className='col-12'>
                <label>Width (px)</label>
                <input type='number'  className='form-control'  min={20}  onChange={(e)=>{
                    dispatch(setDefaultThemeChanges('landing','bannerHero','button',
                    {
                     ...defaultTheme.landing.bannerHero.button,
                        desk:{
                            ...defaultTheme.landing.bannerHero.button.desk,
                            width:e.target.value+'px'
                         },
                         tab:{
                            ...defaultTheme.landing.bannerHero.button.tab,
                            width:e.target.value+'px'
                         },
                         mobile:{
                            ...defaultTheme.landing.bannerHero.button.mobile,
                            width:e.target.value+'px'
                         }
                    }
                    ))
                }} />
            </div>
            <div className='col-12'>
                <label>Height(px)</label>
                <input type='number'  className='form-control'  min={20} max={150} onChange={(e)=>{
                    dispatch(setDefaultThemeChanges('landing','bannerHero','button',
                    {
                     ...defaultTheme.landing.bannerHero.button,
                        desk:{
                            ...defaultTheme.landing.bannerHero.button.desk,
                            height:e.target.value+'px'
                         },
                         tab:{
                            ...defaultTheme.landing.bannerHero.button.tab,
                            height:e.target.value+'px'
                         },
                         mobile:{
                            ...defaultTheme.landing.bannerHero.button.mobile,
                            height:e.target.value+'px'
                         }
                    }
                    ))
                }} />
            </div>
            <div className='col-12'>
                <label>Margin Vertical</label>
                <input type='range'  className='form-control'  min={-1000} max={1000}  onChange={(e)=>{
                    dispatch(setDefaultThemeChanges('landing','bannerHero','button',
                    {
                     ...defaultTheme.landing.bannerHero.button,
                        desk:{
                            ...defaultTheme.landing.bannerHero.button.desk,
                            marginTop:e.target.value+'px'
                         },
                         tab:{
                            ...defaultTheme.landing.bannerHero.button.tab,
                            marginTop:e.target.value+'px'
                         },
                         mobile:{
                            ...defaultTheme.landing.bannerHero.button.mobile,
                            marginTop:e.target.value+'px'
                         }
                    }
                    ))
                }} />
            </div>
            </>


          </div>
        </Offcanvas.Body>
      </Offcanvas>
        </>
    )
}

export function CustomFeatureDetails({ show, onHide }) {
    const dispatch = useDispatch();
    const {currentShop,currentUser,defaultTheme,themePreviewState } = useSelector(state => state)

  
    useEffect(() => {
      // Add any necessary logic here
    }, []);
  
    return (
        <>
        <Offcanvas show={show} onHide={onHide} backdrop={false} style={{
            maxWidth:300
        }}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{fontsize:16}}>Feature</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body style={{padding:0}}>
          <div className='container-fluid'>
            <span>Heading</span>
          <div className='form-group mt-2 mb-2'>
                <input type='text' className='form-control' value={defaultTheme.landing.miniNotification.heading}   onChange={(e)=>{
                    dispatch(setDefaultThemeChanges('landing','miniNotification','heading',e.target.value))
                }} />
          </div>
          <div className='form-group mt-2 mb-2'>
            <label>Size</label>
                <select className='form-control' onChange={(e)=>{
                    dispatch(setDefaultThemeChanges('landing','miniNotification','headingStyle',{
                        ...defaultTheme.landing.miniNotification.headingStyle,
                        fontSize:e.target.value+'px'
                    }))
                }}>
                    <option value={20}>Small</option>
                    <option value={30}>Medium</option>
                    <option value={40}>Large</option>
                </select>
          </div>


          </div>
          <hr/>
          <div className='container-fluid'>
            <span>Description</span>
          <div className='form-group mt-2 mb-2'>
                <input type='text' className='form-control' value={defaultTheme.landing.miniNotification.description}   onChange={(e)=>{
                    dispatch(setDefaultThemeChanges('landing','miniNotification','description',e.target.value))
                }} />
          </div>
          <div className='form-group mt-2 mb-2'>
            <label>Size</label>
                <select className='form-control' onChange={(e)=>{
                    dispatch(setDefaultThemeChanges('landing','miniNotification','descStyle',{
                        ...defaultTheme.landing.miniNotification.descStyle,
                        fontSize:e.target.value+'px'
                    }))
                }}>
                    <option value={16}>Small</option>
                    <option value={20}>Medium</option>
                    <option value={25}>Large</option>
                </select>
          </div>


          </div>
        </Offcanvas.Body>
      </Offcanvas>
        </>
    );
  }
  export function CustomNewsletterDetails({ show, onHide }) {
    const dispatch = useDispatch();
    const {currentShop,currentUser,defaultTheme,themePreviewState } = useSelector(state => state)

  
    useEffect(() => {
      // Add any necessary logic here
    }, []);
  
    return (
        <>
        <Offcanvas show={show} onHide={onHide} backdrop={false} style={{
            maxWidth:300
        }}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{fontsize:16}}>Newsletter</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body style={{padding:0}}>
          <div className='container-fluid'>
          <div className='form-group mt-2 mb-2'>
                <input type='text' className='form-control' value={defaultTheme.landing.newsLetter.headerText}   onChange={(e)=>{
                    dispatch(setDefaultThemeChanges('landing','newsLetter','headerText',e.target.value))
                }} />
          </div>
          </div>
          <hr/>
          <div className='container-fluid'>
            <span>Sub-text</span>
          <div className='form-group mt-2 mb-2'>
                <input type='text' className='form-control' value={defaultTheme.landing.newsLetter.subText}   onChange={(e)=>{
                    dispatch(setDefaultThemeChanges('landing','newsLetter','subText',e.target.value))
                }} />
          </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
        </>
    );
  }