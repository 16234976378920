import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import {Modal, Form, Table} from 'react-bootstrap';

function ReviewDetails({data, show, onHide}) {


  return (
    <>
    <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>
            Review Details
        </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Table>
                <tbody>
                <tr>
                        <th className='text-start'>
                             Name: 
                        </th>
                        <td width={'90%'} >
                            {data.fullname}
                        </td>
                    </tr>
                    <tr>
                        <th className='text-start'>
                             Email: 
                        </th>
                        <td width={'90%'} >
                            {data.email}
                        </td>
                    </tr>
                    <tr>
                        <th className='text-start'>
                             Phone Number: 
                        </th>
                        <td width={'90%'} >
                            {data.phone_number}
                        </td>
                    </tr>
                    <tr>
                        <th className='text-start'>
                             Product Name: 
                        </th>
                        <td width={'90%'} >
                            {data.title}
                        </td>
                    </tr>
                    <tr>
                        <th className='text-start'>
                             Message: 
                        </th>
                        <td width={'90%'} >
                            {data.message}
                        </td>
                    </tr>
                    <tr>
                        <th className='text-start'>
                             Rating: 
                        </th>
                        <td width={'90%'} >
                            <b>{data.rating} / 5</b>
                        </td>
                    </tr>
                    <tr>
                        <th className='text-start'>
                             Date: 
                        </th>
                        <td width={'90%'} >
                            {data.date_created}
                        </td>
                    </tr>
                    
                </tbody>
                {/* </form> */}
            </Table>
            
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
        </Modal.Footer>
    </Modal>
    </>
  );
}

export default ReviewDetails;