import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Axios } from '../../api/axios'
import Swal from 'sweetalert2'
import { Spinner } from 'react-bootstrap'
import '../../assets/css/verif.css';
import { SHOP_AFRICA_LOGO } from '../../components/contants'

export default function Verification() {
    const {token } = useParams()
    const [verify_email,setVerifStatus] = useState(null)
    const [loading,setLoading] = useState(true)
    const navigator = useNavigate()
    const verifiyEmail = async () => {
        try {
            const res = await Axios.post("api?verify_email",{token})
            const response = res.data
            setVerifStatus(response)
        } catch (error) {
            Swal.fire({
                text: error.message,
                icon: 'error',
                timer: 5000,
                toast: true,
                showConfirmButton: false,
                position: 'top-end'
            })
            // }).then(()=> {
            //     navigator('/login',{replace:true})
            // });
        }
        finally {
            setLoading(false)
        }
    }
    useEffect(()=> {
        verifiyEmail();
        return () => {
            return null
        }
    },[])
  return (
    loading ?
    <>
            <div className='page-preloader'>
                <Spinner animation="border" >
                </Spinner>
            </div>
    </>:
    <>
    {
        verify_email &&
        <>
            {
                verify_email.success === true ?
                <>
                <div class="container my-3 col-md-8 col-lg-7 mx-auto mt-5">
					<div class="v-container v-success">
						<div class="v-container-inner">
							<div>
								<div class="v-logo-container">
									<img src={SHOP_AFRICA_LOGO} alt="shop africa" class="img-fluid" />
								</div>
							</div>
							<div class="v-icon-container">
								<svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
									<path
										d="M128 447.1V223.1c0-17.67-14.33-31.1-32-31.1H32c-17.67 0-32 14.33-32 31.1v223.1c0 17.67 14.33 31.1 32 31.1h64C113.7 479.1 128 465.6 128 447.1zM512 224.1c0-26.5-21.48-47.98-48-47.98h-146.5c22.77-37.91 34.52-80.88 34.52-96.02C352 56.52 333.5 32 302.5 32c-63.13 0-26.36 76.15-108.2 141.6L178 186.6C166.2 196.1 160.2 210 160.1 224c-.0234 .0234 0 0 0 0L160 384c0 15.1 7.113 29.33 19.2 38.39l34.14 25.59C241 468.8 274.7 480 309.3 480H368c26.52 0 48-21.47 48-47.98c0-3.635-.4805-7.143-1.246-10.55C434 415.2 448 397.4 448 376c0-9.148-2.697-17.61-7.139-24.88C463.1 347 480 327.5 480 304.1c0-12.5-4.893-23.78-12.72-32.32C492.2 270.1 512 249.5 512 224.1z" />
								</svg>
							</div>
							<div class="v-text-container col-sm-11 col-md-10 mx-auto col-lg-8">
								<h5 class="v-close-to-icon">Account successfully verified!</h5>
								<span class="v-subtext">{verify_email.message}</span>
                                <div className="ban-buttons">
                                <Link to={'/login'} className="btn btn-sm mt-2">Start Selling</Link>
                            </div>
							</div>
						</div>
					</div>
				</div>
                </>:
                <>
                <div className="container my-3 col-md-8 col-lg-7 mx-auto mt-5">
				<div className="v-container v-error">
					<div className="v-container-inner">
                    <div>
								<div class="v-logo-container">
									<img src={SHOP_AFRICA_LOGO} alt="shop africa" class="img-fluid" />
								</div>
							</div>
						<div className="v-icon-container">
							<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
								<path
									fill="currentColor"
									d="M3 4v12c0 1.103.897 2 2 2h3.5l3.5 4l3.5-4H19c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2H5c-1.103 0-2 .897-2 2zm8 1h2v6h-2V5zm0 8h2v2h-2v-2z" />
							</svg>
						</div>
						<div className="v-text-container">
							<h5 className="v-text-container-title">Verification error</h5>
							<span className="v-subtext"> {verify_email.message} </span>
							<div className="v-ctas">
								<Link className='btn btn-danger' to={'/login'}>Go back</Link>
							</div>
						</div>
					</div>
				</div>
                </div>
                </>
            }
        </>
    }
    </>
  )
}
