import { Link } from "react-router-dom"
import MainFooter from "../../../components/MainFooter"
import Nav from "../../../components/landing/Nav"
function About(){
    return (
        <>
        <Nav />
        <>
        <>
        <section className="hand-crafted py-5">
        <div className="container mt-5">
            <div className="inner-sec py-md-2 px-lg-5">
                <h3 className="tittle text-center"><span className="sub-tittle"></span> Welcome To ShopAfrica</h3>
                <p className="mb-4 text-center px-lg-4"> At SHOPAFRICA, we believe in the power of your dreams. We are not just an e-commerce platform; we are the bridge between your aspirations and a world of opportunities. Here, you don't just sell products; you build an online presence that opens doors to a global audience. </p>
            </div>
            <div className="row banner-grids mt-4">
            <div className="col-md-6 last-img pl-lg-5 p-3 d-flex justify-content-center align-items-center flex-column">
                    <h3 className="tittle mb-lg-5 mb-3"><span className="sub-tittle"> </span>Our Journey</h3>
                    <p className="mb-4 text-center px-lg-4">Founded by a passionate team of entrepreneurs who understand the unique dynamics of the African marketplace, SHOPAFRICA was conceived to empower businesses and individuals to reach new heights. We started this journey with a vision of creating a platform that resonates with the diverse cultures, talents, and aspirations that make Africa extraordinary.</p>
            
            </div>
                <div className="col-md-6 banner-image">
                    <div className="effect-w3">
                        <img src={require("../../../assets/images/icons/journey.jpg")} alt="" className="img-fluid image1"/>
                    </div>
                </div>
            </div>
            <div className="row banner-grids mt-4">
            <div className="col-md-6 banner-image">
                    <div className="effect-w3">
                        <img src={require("../../../assets/images/icons/mission.png")} alt="" className="img-fluid image1"/>
                    </div>
                </div>
            <div className="col-md-6 last-img pl-lg-5 p-3 d-flex justify-content-center align-items-center flex-column">
                    <h3 className="tittle mb-lg-5 mb-3"><span className="sub-tittle"> </span>Our Mission</h3>
                    <p className="mb-4 text-center px-lg-4">
                    Our mission is clear yet transformative: to empower you to showcase your products, your talents and your vision to the world. Whether you are a seasoned entrepreneur or a budding artisan, ShopAfrica provides you with the tools, technology, and reach to turn your dreams into reality.
                </p>
            </div>
            </div>
        </div>
        </section>
        <section className="about py-md-5 py-5 bg-white">
        <div className="container py-md-3">
            <h3 className="tittle text-center">What We Offer</h3>
            <div className="feature-grids row">
                <div className="col-sm-12 col-md-4 gd-bottom mt-lg-4">
                    <div className="bottom-gd p-lg-3 p-2">
                        <span className="fa fa-globe" aria-hidden="true"></span>
                        <h3 className="my-3">ONLINE PRESENCE</h3>
                        <p>SHOPAFRICA expands your global reach with a professional online store, reaching customers worldwide.</p>
                     </div>
                </div>
                <div className="col-sm-12 col-md-4 gd-bottom mt-lg-4">
                    <div className="bottom-gd2-active p-lg-3 p-2">
                        <span className="fa fa-tags" aria-hidden="true"></span>
                        <h3 className="my-3">SELL ANYTHING</h3>
                        <p>SHOPAFRICA makes marketing diverse products easy, from handcrafted items to tech gadgets fashion and many more.</p>
                     </div>
                </div>
                <div className="col-sm-12 col-md-4 gd-bottom mt-lg-4">
                    <div className="bottom-gd p-lg-3 p-2">
                        <span className="fa fa-line-chart" aria-hidden="true"></span>
                        <h3 className="my-3">BUSINESS ANALYTICS </h3>
                       <p>SHOPAFRICA offers analytics for sales trends, revenue tracking, and top-selling products, aiding informed decisions.</p>
                       </div>
                </div>

            </div>
        </div>
        <>
        <div className="inner-sec py-md-2 px-lg-5" style={{marginTop:30}}>
                <h4 className="tittle text-center"><span className="sub-tittle"></span> Join The ShopAfrica Community</h4>
                <p className="mb-4 text-center px-lg-4 mt-3">
                    Become a part of the SHOPAFRICA community. Let's redefine the future of commerce together, showcasing the incredible talent, products, and innovations that our continent has to offer. 
                    Thank you for choosing SHOPAFRICA as your partner in commmerce. Let's emback othis exciting journey of growth, innovation and shared success together.
                </p>
                <div className="ban-buttons d-flex justify-content-center">
                        <Link to="/login" className="btn mt-2">START SELLING</Link>
                    </div>
            </div>
        </>
    </section>
        </>
        <div className="container">
            <MainFooter />
        </div>
        </>
        </>
        )
}

export default About