import { Axios, token } from "../../api/axios"
import { fetchShopProducts } from "./products"
import { FETCH_SHOP_DATA, FETCH_SHOP_FAILED,
     GET_CURRENT_STORE_REVIEWS_FAILED,
     GET_CURRENT_STORE_REVIEWS_SUCCESS,
     LOADING_SHOP_DATA, 
     SET_CURRENT_STORE,
     SET_CURRENT_STORE_ERROR,
     SET_STORE_DELIVERY_DATA
    } from "./types"

const fetchUserShops = () => ({
        type:LOADING_SHOP_DATA
})

export const userShopData =  () => async dispatch => {
    dispatch(fetchUserShops());
    return Axios.get("api?getShops",{
        headers: {
            'Authorization': 'Bearer ' + token()
        }
    }).then(res => {
        const {shops} = res.data
        dispatch({
        type:FETCH_SHOP_DATA,
        payload: shops ?  {shops:(Array.isArray(shops) ? shops : [shops]) ,data:true} :{data:true,shops:[]}
    })
    })
    .catch(err=> {
        return dispatch({type:FETCH_SHOP_FAILED,payload:{err:err.response.status,data:false,shops:[]}})
    })
}
export const setCurrentStore = (shopId,cb) => async dispatch =>{
    return Axios({
        url:"api?getShop",
        data:{shopId},
        method:"POST"
    }).then((shop) => {
        dispatch({
            type:SET_CURRENT_STORE,
            payload:{...shop.data.shop,data:true}
        })
        dispatch(fetchShopProducts(shop.data.shop.id));
        cb()
    }).catch((err)=>{
        dispatch({
            type:SET_CURRENT_STORE_ERROR,
            payload:{data:false}
        })
    })
}
export const getCurrentStoreReview = (storeId) => async dispatch => {
    return Axios({
        url:"api?getReviews",
        data:{storeId},
        method:"POST",
        headers: {
            'Authorization': 'Bearer ' + token()
        }
    }).then((shop) => {
        dispatch({
            type:GET_CURRENT_STORE_REVIEWS_SUCCESS,
            payload:{...shop.data,data:true}
        })
    }).catch((err)=>{
        dispatch({
            type:GET_CURRENT_STORE_REVIEWS_FAILED,
            payload:{data:false}
        })
    })
}
export const setStoreDeliveryState = (data) => {
    return {
        type:SET_STORE_DELIVERY_DATA,
        payload:data
    }
}
