import React from 'react';
import './index.css'
import ReactDOM from 'react-dom/client';
import './assets/css/bootstrap.css'
import './assets/css/ui.css'
import './assets/css/responsive.css'
import './assets/fonts/material-icon/css/round.css' 
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider,} from 'react-redux'
import {legacy_createStore as createStore,applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
//const store = createStore(rootReducer,applyMiddleware(thunk))
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
