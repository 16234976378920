import {Axios} from './axios';

export const login = async (data) => {
    
    try {
        const response = await Axios.post('api?login',data)
        return response.data;
    } catch (e) {
        return e.response.data;
    }
}

export const register = async (data) => {
    
    try {
        const response = await Axios.post('api?register',data)
        return response.data;
    } catch (e) {
        return e.response.data;
    }
}

export const userData = async () => {
    
    try {
        const response = await Axios.get('api?userData')
        return response.data;
    } catch (e) {
        return e.response.data;
    }
}

export const getCategories = async (data) => {
    try {
        const response = await Axios.post('api?getCategories', data);
        return response.data;
    }catch (err) {
        console.log(err.response);
    }
}

export const getProducts = async (data) => {
try {
    const response = await Axios.post('api?getProducts', data);
    console.log(response.data);
    return response.data
} catch (error) {
    console.log(error.response);
    // return error.response;
}
}

export const getProduct = async (data) => {
    try {
        const response = await Axios.post('api?getProduct', data);
        return response.data
    } catch (error) {
        // console.log(error.response);
        return error.request;
    }
}
export const updateProduct = async (data) => {
    try {
        const response = await Axios.post('api?updateProduct', data);
        return response.data;
    }catch (err) {
        console.log(err.response);
        return err.response;
    }
}
export const createProduct = async (data) => {
    try {
        const response = await Axios.post('api?createProduct', data);
        return response.data;
    }catch (err) {
        if (err.response && err.response.data) {
            return err.response.data;
        } else {
            return {success:false,message:err.message}
        }
        
    }
}
export const addCategory = async (data) => {
    try {
        const response = await Axios.post('api?addCategory', data);
        return response.data;
    }catch (err) {
        console.log(err.response);
        return err.response;
    }
}
export const updateCategory = async (data) => {
    try{
        const response = await Axios.post('api?updateCategory', data);
        return response.data;   
    }catch(err){
        console.log(err.response);
        return err.response;
    }
}
export const deleteCategory = async (data) => {
    try{
        const response = await Axios.post('api?deleteCategory', data);
        return response.data;   
    }catch(err){
        console.log(err.response);
        return err.response;
    }
}
export const getReviews = async (data) => {
    try {
        const response = await Axios.post('api?getReviews', data);
        return response.data;
    } catch (err) {
        console.log(err.response);
        return err.response
    }
}
export const getTransactions = async (data) => {
    try {
        const response = await Axios.post('api?getTransactions', data);
        return response.data;
    } catch (err) {
        console.log(err.response);
        return err.response
    }
}
export const fetchTransactionRecords = async (data) => {
    try {
        const response = await Axios.post('api?getTransactionRecords', data);
        return response.data;
    } catch (err) {
        console.log(err.response);
        return err.response
    }
}
export const getOrders = async (data) => {
    try {
        const response = await Axios.post('api?getOrders', data);
        return response.data;
    } catch (err) {
        console.log(err.response);
        return err.response
    }
}
export function cleanme(data) {
    // This removes all the HTML tags from a string. This will sanitize the input string, and block any HTML tag from entering into the database.
    let input = data;
    
    // filter_var is not available in JavaScript, so we skip this line
    
    // Trim leading and trailing whitespaces, tabs, newlines, and carriage returns
    input = input.trim();

    // Convert special characters to HTML entities
    input = input.replace(/[<>&'"]/g, function(match) {
        return {
            '<': '&lt;',
            '>': '&gt;',
            '&': '&amp;',
            "'": '&#39;',
            '"': '&quot;'
        }[match];
    });

    // prevent javascript codes, Convert some characters to HTML entities
    input = input.replace(/[^\w\s]/gi, function(match) {
        return '&#' + match.charCodeAt(0) + ';';
    });

    // strip_tags is not available directly, so we use a regular expression to remove HTML tags
    input = input.replace(/<\/?[^>]+(>|$)/g, "");

    return input;
}


export const updateShop = async (data) => {
    try {
        const response = await Axios.post('api?updateShop', data);
        return response.data;
    } catch (err) {
        console.log(err.response);
        return err.response
    }
}
export const updateShopImage = async (data) => {
    try {
        const response = await Axios.post('api?updateShopImage', data);
        return response.data;
    } catch (err) {
        console.log(err.response);
        return err.response
    }
}
export const deactivateShop = async (data) => {
    try {
        const response = await Axios.post('api?deactivateShop', data);
        return response.data;
    } catch (err) {
        console.log(err.response);
        return err.response
    }
}
export const activateShop = async (data) => {
    try {
        const response = await Axios.post('api?activateShop', data);
        return response.data;
    } catch (err) {
        console.log(err.response);
        return err.response
    }
}
export const deleteProductImage = async (data) => {
    try {
        const response = await Axios.post('api?deleteProductImage', data);
        return response.data;
    } catch (err) {
        console.log(err.response);
        return err.response.data
    }
}
export const getOrder = async (data) => {
    try {
        const response = await Axios.post('api?getOrder', data);
        return response.data;
    } catch (err) {
        console.log(err.response);
        return err.response.data
    }
}
export const pageUrl = (shopName,url) => {
    return `/shop/${shopName}/dashboard${url}`
}
export const sanitizeStoreName = (name) => {
    const sanitizedName = name.replace(/\s/g, "-"); // Replace all spaces with -
    const any = sanitizedName.replace(/'/g, ""); // Replace all spaces with -
    //setName(any);
    return any;
  }

export function createKey() {
    const chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ"
    let pass = '';
    
    for (let i = 0; i <= 10; i++) {
        const num = Math.floor(Math.random() * chars.length);
        const tmp = chars.substring(num, num + 1);
        pass += tmp;
    }
    return pass;
  }
  const STORE_DOMAIN_NAME = 'shopafrica.store/index';

  export const store_link = storename => {
    return `https://${storename}.${STORE_DOMAIN_NAME}`
  }

 export function validatePassword(password) {
    // Password should be at least 6 characters long
    if (password.length < 6) {
        return false;
    }

    // Check for at least one special character
    var specialCharacterRegex = /[!@#$%^&*(),.?":{}|<>]/;
    if (!specialCharacterRegex.test(password)) {
        return false;
    }

    // Check for at least one uppercase letter
    var uppercaseRegex = /[A-Z]/;
    if (!uppercaseRegex.test(password)) {
        return false;
    }

    // Check for at least one lowercase letter
    var lowercaseRegex = /[a-z]/;
    if (!lowercaseRegex.test(password)) {
        return false;
    }

    // Check for at least one digit
    var digitRegex = /\d/;
    if (!digitRegex.test(password)) {
        return false;
    }

    // If all conditions are met, return true
    return true;
}

export function base64toFile(base64String, fileName, fileType = "image/jpeg") {
    const blob = dataURItoBlob(base64String);
    const file = new File([blob], fileName, { type: fileType });
    return file;
}

// Helper function to convert data URI to Blob
function dataURItoBlob(dataURI) {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
        uint8Array[i] = byteString.charCodeAt(i);
    }

    return new Blob([arrayBuffer], { type: mimeString });
}

export function isBase64(str) {
    try {
        if (!str.startsWith("data:") || !str.includes(";base64,")) {
            return false;
        }
        const base64Part = str.split(";base64,")[1];
        return btoa(atob(base64Part)) === base64Part;
    } catch (err) {
        return false;
    }
}
