import React, { useState } from 'react'
import { Spinner, Table } from 'react-bootstrap';
import Nav, { Header } from '../../../components/dashboard/Nav'
import { addCategory, getCategories, deleteCategory as deleteCat, pageUrl, createKey, userData } from '../../../api/api';
import Swal from 'sweetalert2';
import '../../../assets/css/category.css'
import { useNavigate, useParams } from 'react-router-dom';
import { Axios } from '../../../api/axios';
import { useDispatch, useSelector } from 'react-redux';
import { getUserData } from '../../../redux/action/user';
import NeedHelp from '../../../components/dashboard/NeedHelp';
const AddCategory = () => {
    const navigate = useNavigate();
    const {shop} = useParams();
    const [ready, setReady] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState();
    const {currentShop,currentUser} = useSelector(state => state)
    const [shopId, setShopId] = React.useState(localStorage.getItem('shopId'));
    const [categories, setCategories] = React.useState(null);
    const [isSubCategory,setIsSubCatState] = React.useState(false)
    const dispatch = useDispatch()
    const updateData = (e) => {
        let key = e.target.name;
        let value = e.target.value;
        setData({
            ...data, [key]: value
        });
        console.log(data);
    }
    const [imagePreview,setImagePreview] = useState(null)
  const toBase64Image = event =>{ 
    const fileInput = event.target;
            if (fileInput.files.length > 0) {
                const selectedImage = fileInput.files[0];
                const reader = new FileReader();
                reader.onload = function (e) {
                    const base64URL = e.target.result;
                    setImagePreview(base64URL)
                };

                reader.readAsDataURL(selectedImage);
            } else {
                setImagePreview(null)
            }
  }
    const createCategory = async (e) => {
        e.preventDefault()
        setLoading(true);
        const response = await addCategory(data);
        console.log(response);
        if(response.success){
            fetchCategories()
            Swal.fire({
                text: response.message,
                icon: 'success',
                timer: 3000,
                toast: true,
                showConfirmButton: false,
                position: 'top-end'
            });

        }else{
            Swal.fire({
                text: response.message,
                icon: 'error',
                timer: 3000,
                toast: true,
                showConfirmButton: false,
                position: 'top-end'
            });
        }
        setLoading(false);
    }
    const fetchCategories = async () => {
        const response = await getCategories({shopId});
        console.log(response);  
        setCategories(response);
    }
    const deleteCategory = async (id) => {
        const response = await deleteCat({id});
        console.log(response);
        if(response.success){
            fetchCategories();
            Swal.fire({
                text: response.message,
                icon: 'success',
                timer: 3000,
                toast: true,
                showConfirmButton: false,
                position: 'top-end'
            })
        }else {
            Swal.fire({
                text: response.message,
                icon: 'error',
                timer: 3000,
                toast: true,
                showConfirmButton: false,
                position: 'top-end'
            })
        }
    }
    const add_predefined = async (id,type,catType = 'PREDEFINED') => {
        try {
            const res = await Axios.post("api?addPredefined",{shopId,id,type,catType})
            const data = res.data
            if(data.status){
                Swal.fire({
                    text: data.message,
                    icon: 'success',
                    timer: 5000,
                    toast: true,
                    showConfirmButton: false,
                    position: 'top-end'
                });
                fetchCategories()
            }else{
                Swal.fire({
                    text: data.message,
                    icon: 'error',
                    timer: 5000,
                    toast: true,
                    showConfirmButton: false,
                    position: 'top-end'
                });
            }
        } catch (error) {
            Swal.fire({
                text: error.message,
                icon: 'error',
                timer: 5000,
                toast: true,
                showConfirmButton: false,
                position: 'top-end'
            });
        }
    }
    const [addingCat,setAddingCatStatus] = useState(false)
    const addCustomCategory = async (event) => {
        setAddingCatStatus(true)
        const form_data = new FormData(event.currentTarget)
        form_data.append("shopId",shopId)
        try {
            const res = await Axios.post("api?addCategory",form_data,{
                headers:{
                    'Content-Type':'multi-part/formdata'
                }
            })
            const data = res.data
            if(data.status){
                Swal.fire({
                    text: data.message,
                    icon: 'success',
                    timer: 5000,
                    toast: true,
                    showConfirmButton: false,
                    position: 'top-end'
                });    
                fetchCategories()
               const inputs = document.getElementsByClassName("form-control")
               for (let index = 0; index < inputs.length; index++) {
                const element = inputs.item(index);
                element.setAttribute("value",'')
               }
               const close = document.getElementsByClassName("btn-close")
               close.item(0).click()
            }else{
                Swal.fire({
                    text: data.message,
                    icon: 'error',
                    timer: 5000,
                    toast: true,
                    showConfirmButton: false,
                    position: 'top-end'
                });
            }
        } catch (error) {
            Swal.fire({
                text: error.message,
                icon: 'error',
                timer: 5000,
                toast: true,
                showConfirmButton: false,
                position: 'top-end'
            });
        }finally{
            setAddingCatStatus(false)
        }
    }

    React.useEffect(() => {
        fetchCategories()
        setData({
            ...data, shopId
        })
        !currentUser.data && dispatch(getUserData())
    }, [])
    return (
                !ready ?
            <>
            <div className='page-preloader'>
            <Spinner animation="border" >
            {/* <>{shops.err && "Network failure,Please refresh"}</> */}
            </Spinner>
        </div></> 
        :
                <>

        <Nav activeState={'categories'} />
        <>

        <main className='main-wrap'>
        <Header />
        <React.Fragment>
<section className="content-main">
    <div className="content-header">
        <h2 className="content-title">Categories</h2> 
        <button type="button" className="btn t-btn" data-bs-toggle="modal" data-bs-target="#addNewProduct" >Add New Item</button>
    </div>
    <div className="card">
     <div className="card-body">
          <div className="row">
          <div className="col-md-8">
                    
                    <table className="table table-hover">
                    <thead>
                      <tr>
                           <th>Thumbnail</th>
                           <th>Name</th>
                           <th>Description</th>
                           <th className="text-end"></th>
                      </tr>
                    </thead>
                    <tbody>
                        {
                            categories && categories.store_categories && categories.store_categories.length > 0 ?
                            <>
                            {
                                 categories.store_categories.map((category, index) =>  (
                                    <tr key={index}>
                                        <td>
                                        <div className="left">
                                            <img src={category.image_url} className="img-sm img-thumbnail" alt={category.title}/>
                                        </div>
                                        </td>
                                        <td>
                                            {category.title}
                                        </td>
                                        <td>
                                            {category.description}
                                        </td>
                                        <td className="text-end">
                                            <div className="dropdown">
                                                <a href="#" data-bs-toggle="dropdown" className="btn btn-light"> <i className="material-icons md-more_horiz"></i> </a>
                                                <div className="dropdown-menu">
                                                    
                                                    {
                                                      //  category.type == 'CUSTOM' && <button type="button" className="dropdown-item" data-bs-toggle="modal" data-bs-target="#exampleModal">View detail</button>
                                                    }
                                                    {
                                                    //category.type == 'CUSTOM' && <a className="dropdown-item" href="#">Edit info</a>
                                                    }
                                                    <a className="dropdown-item text-danger" href="#" onClick={(e)=>{
                                                        e.preventDefault()
                                                        add_predefined(category.id,'REMOVE',category.type)
                                                    }}>Delete</a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    ))
                            } 
                            </>:
                            <>
                            <tr>
                                <td colSpan={4}><div className='alert alert-warning'>No category in this store</div></td>
                            </tr>
                            </>
                        
                        }
                    </tbody>
                    </table>
                    
                                   </div>
               <div className="col-md-4">
                <h5>Select categories</h5>
               <div className="v-list-of-options mt-2 row col-12 m-0">
                {
                    categories && categories.categories ?
                     <>
                     {
                        categories.categories.map((category) =>  {
                            if (category.type == 'PREDEFINED') {
                                return (
                                    <div className="col p-0" key={createKey()}>
                                    <label className="v-option w-100">
                                        <input type="checkbox" onClick={(e) => {
                                            console.log(e.target.checked);
                                             const type = e.target.checked ? 'ADD' : 'REMOVE'
                                            add_predefined(category.id,type)
                                        }} className="btn-check" name="Anime" checked={category.store_id != "" && category.store_id != null && category.store_id == shopId} />
                                        <span className="v-text-name"><img style={{width:30,maxWidth:30,maxHeight:30}} src={category.image_url} alt={category.title} /> {category.title} </span>
                                    </label>
                                </div>
                                )
                            }
                            })
                     }
                     </>:<>
                     </>
                }
               </div>
               </div>
          </div>
     </div>
</div> 
    
</section>
        </React.Fragment>
        <div className="modal fade" id="addNewProduct" tabindex="-1" aria-labelledby="addNewProductLabel" data-bs-backdrop="static" data-bs-keyboard="false"   aria-hidden="true">
			<div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
				<div className="modal-content">
					<div className="modal-header border-0">
						<h1 className="modal-title fs-5" id="addNewProductLabel">Add Category</h1>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
                    <form  method='POST' style={{overflowY:'scroll'}}  onSubmit={(e) => {
                        e.preventDefault()
                        addCustomCategory(e)
                    }} >
					<div className="modal-body" >
                         <div className="mb-4">
                              <label for="product_name" className="form-label">Name</label>
                              <input type="text" name='name' required placeholder="Type here" className="form-control" id="product_name" />
                         </div>
                         <div className="mb-4">
                         <label className='form-label'>Image</label>
                         <div className='d-flex justify-content-center align-items-center'>
                        <input type="file" accept="image/*" required onChange={(e)=>{
                            toBase64Image(e)
                        }} style={{display:"none"}} placeholder="Type here" name='file' className="form-control" id="uploadImages"/>
                            <label  htmlFor='uploadImages' className='upload-images-label d-flex justify-content-center align-items-center' style={{
                                    display: "block",
                                    width: "90%",
                                    height:140,
                                    borderStyle:'dashed',
                                    borderColor:'GrayText',
                                    borderWidth:0.5,
                                    borderRadius:5,
                                }
                            }>
                                <span style={{fontSize:14}}>Click to Select image to represent your category </span>
                            </label>
                            </div>
                            {
                                imagePreview &&
                                <img src={imagePreview} height={60} width={60} alt='Preview image' className='mt-2' />
                            }
                         </div>
                         <div className="mb-4">
                              <label className="form-label">Description</label>
                              <textarea placeholder="Type here" name='description' required className="form-control" ></textarea>
                         </div>
                         <div className="d-grid">
                              <button className="btn t-btn" disabled={addingCat}>{addingCat ? 'Adding category...':"Create category"}</button>
                         </div>
					</div>
                    </form>
				</div>
			</div>
		</div>
    </main>
    </>
<NeedHelp />
</>
    )
}

export default AddCategory