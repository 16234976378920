import React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentStore } from '../../../redux/action/shop'
import { useParams } from 'react-router-dom'
import Table from '../../../components/Table';
import { orders as setUserOrders } from '../../../redux/slice/orderSlice'
import { getOrders, getProducts, getTransactions } from '../../../api/api'
import { transactions as setUserTransactions } from '../../../redux/slice/transactionSlice'
import { products as setUserProducts } from '../../../redux/slice/productSlice' 
import { Alert } from '@mui/material'
//import { registerables, Chart, } from "chart.js";
//import { Line } from 'react-chartjs-2';
import Chart from "react-apexcharts";
function DashboardContent(){
	const {shop} = useParams()
	const shopId = localStorage.getItem('shopId');
	const {currentShop, userOrders, transactionData} = useSelector(state => state)
	const [filterState,setFilterState] = useState('TODAY')
	const dispatch = useDispatch()
	//Chart.register(...registerables);
	const [config, setConfig] = useState({
		options: {
		  chart: {
			id: "basic-bar"
		  },
		  xaxis: {
			categories: []
		  }
		},
		series: [
		  {
			name: "Amount",
			data: []
		  }
		]
	})
	
	const fetchOrders = async () => {
        const response = await getOrders({shopId})
        if(response.success){
            dispatch(setUserOrders(response.data));
        }else{
            dispatch(setUserOrders(''));
        }
    }
	/* This function is meant to call the transaction records from a different endpoint
	and should be different from the `filterTransaction` function
	*/
	const fetchTransactions = async () => {
        const response = await getTransactions({shopId})
    }
	const getPrice = (price) => {
        let oldPrice = new Number(price).toFixed(2)
        let currency = new Intl.NumberFormat(
            // 'en-US', 
        // {
        //     style: 'currency',
        //     currency: 'NGN',
        // }
        );
        let newPrice = currency.format(oldPrice);
        return newPrice;
    }
	const columns = [
		{
			accessor: 'id',
			Header : '#ID'
		},
		{
			accessor: 'name',
			Header : 'Name'
		},
		 {
		 	accessor: 'email',
		 	Header : 'Email'
		},
		 {
		 	accessor: 'total_quantity',
		 	Header : 'Total Quantity'
		 },
		 {
		 	accessor: 'total_amount',
		 	Header : 'Total (NGN)'
		 },
		{
			accessor:  (row) => {
				if(row.status == 'Pending'){
					return <span className="badge rounded-pill alert-warning text-warning">Pending</span>
				}else if(row.status == 'success' || row.status == 'Success' || row.status == 'Shipped'|| row.status == 'Confirmed' ){
					return <span className="badge rounded-pill alert-success text-success">{row.status}</span>
				}else{
					return <span className="badge rounded-pill alert-danger text-danger">Canceled</span>
				}
			} ,
			Header : 'Status'
		},
		{
			accessor: 'created_at',
			Header : 'Date'
		}
	]
	const fetchProducts = async () => {
        const response = await getProducts({shopId});
         dispatch(setUserProducts(response.data));
     }
	 const filterTransaction = async (filterBy,label) => {
		setFilterState(filterBy ?? 'TODAY')
		try {
			const response = await getTransactions({shopId,filterBy})
			setConfig({
				options: {
				  chart: {
					id: "basic-bar"
				  },
				  stroke: {
					width: 4,
					curve: 'smooth',
				  },
				  xaxis: {
					categories: response.labels
				  },
				  colors: ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800']
				},
				series: [
				  {
					name: "Amount",
					data: response.data
				  }
				]
			})
		} catch (error) {
			console.log(error);
		}

	 }
	
	useEffect(()=>{
	filterTransaction()
	fetchOrders();
	!transactionData.transactions && fetchTransactions();
	},[])
    return (
        <>
            <section className="content-main">
	<div className="content-header">
		<h2 className="content-title"> Dashboard </h2>
		{/* <div>
			<a href="#" className="btn t-btn"
			>Create report</a>
		</div> */}
	</div>
	<div className="row">
		<div className="col-lg-3">
			<div className="card card-body mb-4">
        <article className="icontext">
          <span className="icon icon-sm rounded-circle bg-primary-light"><i className="text-primary material-icons md-monetization_on"></i></span>
          <div className="text">
            <h6 className="mb-1">Wallet Balance</h6>  <span>₦ {currentShop && currentShop.wallet_balance ? new Number(currentShop.wallet_balance).toLocaleString('en-US', {
									minimumFractionDigits: 2,
									maximumFractionDigits: 2,
								  }) : 0}</span>
          </div>
        </article> 
	               
	    </div>
		</div>
		<div className="col-lg-3">
			<div className="card card-body mb-4">
        <article className="icontext">
          <span className="icon icon-sm rounded-circle bg-primary-light"><i className="text-primary material-icons md-monetization_on"></i></span>
          <div className="text">
            {/* <h6 className="mb-1">Total Sales</h6>  <span>₦{transactionData.transactions ? getPrice(transactionData.transactions[0].totalSales) : 0}</span> */}
            <h6 className="mb-1">Total Sales</h6>  <span>₦ {currentShop && currentShop.total_sales ? new Number(currentShop.total_sales).toLocaleString('en-US', {
									minimumFractionDigits: 2,
									maximumFractionDigits: 2,
								  }) : 0}</span>
          </div>
        </article> 
	               
	    </div>
		</div>
		<div className="col-lg-3">
			<div className="card card-body mb-4">
        <article className="icontext">
          <span className="icon icon-sm rounded-circle bg-primary-light"><i className="text-primary material-icons md-monetization_on"></i></span>
          <div className="text">
            {/* <h6 className="mb-1">Total Sales</h6>  <span>₦{transactionData.transactions ? getPrice(transactionData.transactions[0].totalSales) : 0}</span> */}
            <h6 className="mb-1">Total Withdrawal</h6>  <span>₦ {currentShop && currentShop.total_withdrawal ? new Number(currentShop.total_withdrawal).toLocaleString('en-US', {
									minimumFractionDigits: 2,
									maximumFractionDigits: 2,
								  }) : 0}</span>
          </div>
        </article> 
	               
	    </div>
		</div>
		<div className="col-lg-3">
			<div className="card card-body mb-4">
        <article className="icontext">
          <span className="icon icon-sm rounded-circle bg-success-light"><i className="text-success material-icons md-local_shipping"></i></span>
          <div className="text">
            <h6 className="mb-1">Total Orders</h6> <span>{userOrders.orders ? userOrders.orders.length : 0}</span>
          </div>
        </article> 
	    </div>
		</div>
		<div className="col-lg-3">
			<div className="card card-body mb-4">
        <article className="icontext">
          <span className="icon icon-sm rounded-circle bg-warning-light"><i className="text-warning material-icons md-shopping_basket"></i></span>
          <div className="text">
            <h6 className="mb-1">Total Products</h6>  <span>{currentShop.data && currentShop.noOfProduct}</span>
          </div>
        </article>
	    </div>
		</div>
	</div>


	<div className="row">
		<div className="col-12">
			<div className="card mb-4">
			<div className="d-flex align-items-center justify-content-start action__buttons mt-3">
										    <button onClick={async ()=> await filterTransaction('TODAY','Today')} type="button" className={`btn mx-lg-2 m-1 btn-light ${filterState =='TODAY' && 'active'} `}>Today</button>
                                            <button type="button" onClick={async ()=> await filterTransaction('L7DAYS','Last 7 days')} className={`btn mx-lg-2 m-1 btn-light ${filterState =='L7DAYS' && 'active'} `}>Last 7 days</button>
                                            <button type="button" onClick={async ()=> await filterTransaction('L30DAYS','30 days')} className={`btn mx-lg-2 m-1 btn-light ${filterState =='L30DAYS' && 'active'} `} >30 days</button>
                                            <button type="button" onClick={async ()=> await filterTransaction('YEAR','1 year')} className={`btn mx-lg-2 m-1 btn-light ${filterState =='YEAR' && 'active'} `} >1 year</button>
								</div>
	              <article className="card-body">
	              	<h5 className="card-title">Sale statistics</h5>
					  <div className='w-100' style={{maxHeight:800,minHeight:500}}>
					  <Chart
						options={config.options}
						series={config.series}
						type="area"
						width="100%"
						height={'100%'}
					/>
					  </div>
	              </article> 
	        </div>
		</div>
	</div>


	<div className="card mb-4">
          <div className="card-body">
          	<h5 className="card-title">Latest orders</h5>
          	{userOrders.orders ? 
			<Table columns={columns} body={userOrders.orders} path="dashboard" />
			:  
			<Alert  severity='warning' className='d-flex align-items-center justify-content-center'><h5 className='text-center text-muted'>You do not have any order record.</h5> </Alert>
			}
          </div>
    </div> 

</section>
        </>
    )
}

export default DashboardContent