import MainFooter from "../../../components/MainFooter"
import Nav from "../../../components/landing/Nav"
import  { Axios as axios } from '../../../api/axios'
import Swal from "sweetalert2"
import { useState } from "react"
//AIzaSyDL-dgWUHaIs1X2paKTURPM2QyglEnJ9Gs
function Contact (){
    const [loading,setLoading] = useState(false)
    const submitForm = async (e) => {
        setLoading(true)
        e.preventDefault()
        const form = new FormData(e.currentTarget)
        try {
            const response = await axios.post("api?sendMessage",form)
            const res = response.data
            if (res.success) {
                Swal.fire({
                    text: res.message,
                    icon: 'success',
                    timer: 5000,
                    toast: true,
                    showConfirmButton: false,
                    position: 'top-end'
                });
                const formElement = document.getElementById("messageForm");
                formElement.reset();
            }else{
                Swal.fire({
                    text: res.message,
                    icon: 'error',
                    timer: 4000,
                    toast: true,
                    showConfirmButton: false,
                    position: 'top-end'
                });
            }
        } catch (error) {
            Swal.fire({
                text: error.message,
                icon: 'error',
                timer: 4000,
                toast: true,
                showConfirmButton: false,
                position: 'top-end'
            });
        }
        setLoading(false)
    }
    return (
        <>
        <Nav />
        <div className="container">
        <section className="ab-info-main py-5">
        <div className="container py-3 mt-5">
            <h3 className="tittle text-center"><span className="sub-tittle">Find Us</span> Contact Us</h3>
            <div className="row contact-main-info mt-5">
                <div className="col-md-6 contact-right-content">
                    <form action="#" method="post" id="messageForm" onSubmit={(e)=>{
                        submitForm(e)
                    
                    }}>
                        <input type="text" name="name" className="form-control" placeholder="Name" required/>
                        <input type="email" className="form-control" name="email" placeholder="Email" required/>
                        <input type="text" className="form-control" name="phone" placeholder="Phone" required/>
                        <textarea name="message" className="form-control" placeholder="Message" required></textarea>
                        <div className="read mt-3">
                            <input type="submit" disabled={loading} value={loading ? 'Submitting...':"Submit"}/>
                        </div>
                    </form>
                </div>
                <div className="col-md-6 contact-left-content">
                    <div className="address-con d-none">
                        <h4 className="mb-2"><span className="fa fa-phone-square" aria-hidden="true"></span> Phone</h4>
                        <p>+121 098 8907 9987</p>
                        <p>+121 098 8907 9987</p>
                    </div>
                    <div className="address-con my-4 d-none">
                        <h4 className="mb-2"><span className="fa fa-envelope-o" aria-hidden="true"></span> Email </h4>
                        <p><a href="mailto:info@example.com">info@example.com</a></p>
                        <p><a href="mailto:info@example.com">info@example.com</a></p>
                    </div>
                    <div className="address-con mb-4 d-none">
                        <h4 className="mb-2"><span className="fa fa-fax" aria-hidden="true"></span> Fax</h4>

                        <p>(800) 123-80088</p>
                    </div>
                    <div className="address-con">
                        <h4 className="mb-2"><span className="fa fa-map-marker" aria-hidden="true"></span> Location </h4>

                        <p>Alternative Route, Cheveron Drive, Lekki, Lagos State.</p>
                    </div>

                </div>

            </div>
            <div className="map-fo mt-lg-5 mt-4">
                <iframe src="https://www.google.com/maps/embed/v1/place?q=Textng%20communications&key=AIzaSyDL-dgWUHaIs1X2paKTURPM2QyglEnJ9Gs" allowFullScreen></iframe>
            </div>
        </div>
    </section>
        </div>
        <div className="container">
            <MainFooter />
        </div>
        </>
    )
}
export default Contact