import React from 'react';
import T1_Landing from '../pages/shop/themes/default/pages/landing/T1_Landing';
import T1_Shop from '../pages/shop/themes/default/pages/shop/T1_Shop';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { defaultThemePages } from '../components/shop/allthemes';


export default function ThemeGroup(){
  const {themePage}  = useSelector(state => state)
  useEffect(()=>{
    
  })
    return (
      <>
      {themePage == defaultThemePages.LANDING && <T1_Landing />}
      {themePage == defaultThemePages.SHOP && <T1_Shop />}
      </>
    )
}