import { Link, Navigate, useParams } from "react-router-dom"
function SubscriptionIndex(){
    const  {storename} = useParams();
    return (
        storename ? 
        <>
        <header className="main-header navbar">
            <div className="col-nav w-100 d-flex justify-content-end">
            <ul className="nav">
                <li className="nav-item">
                <Link className="nav-link" to={'/my-shops'}>Stores</Link>
                </li>
            </ul> 
            </div>
        </header>
        <section className="content-main bg-white">

          <div className="row" style={{marginTop:60}}>
              <div className="col-sm-12">
                  <div className="w-50 mx-auto text-center">
                      <img src={require('../../../assets/images/card-brands/2.png')} width="350" alt="Subscription Image" />
              <h3 className="mt-4">Get back to business for just little Money</h3>
              <p>At ShopAfrica, we have made it easy for you to keep your store up and running. With our subscription plans, you have your store live on top of the globe.</p>
              <Link className="btn t-btn mt-4" to={`/store/${storename}/plans`}>Choose Plan</Link>
                  </div>
              </div>
          </div>


</section>

        </> :
        <Navigate to={"/my-stores"} replace={true} />
    )
}
export default SubscriptionIndex