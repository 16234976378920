import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Axios } from '../../../api/axios';
import Swal from 'sweetalert2';
import { createKey } from '../../../api/api';

export default function Customers() {
    const [customerLoading,setCustomerLoadingStatus] = useState(true)
    const [customers,setCustomers]  = useState(null)
    const fetchCustomers = async () => {
        try {
            const response = await Axios.get('api?getCustomers',{
                params:{
                    storeId:localStorage.getItem('shopId')
                }
            })
            const res = response.data
            if (res.status && res.status === true) {
                setCustomers(res.data)
            } else {
                setCustomers(null)
            }
        } catch (error) {
            Swal.fire({
                text: error.message,
                icon: 'error',
                timer: 5000,
                toast: true,
                showConfirmButton: false,
                position: 'top-end'
            });
        }finally{
            setCustomerLoadingStatus(false)
        }
    }
    useEffect(() => {
        fetchCustomers()
      return () => {
        return null
      }
    }, [])
    
    return (
        customerLoading ?
        <>
            <div className='page-preloader' style={{width:"max-content"}}>
                    <Spinner animation="border" >
                    </Spinner>
            </div>
        </>
        :
        <React.Fragment>
            <section className="content-main">
	
    <div className="content-header">
        <h2 className="content-title">Customers</h2>
        <span>Total Customers : <b>{customers && customers.length}</b></span>
    </div>
        <div className="card mb-4">
			<div className="card-body">
            <div className='container'>
            <div className="table-responsive">
				<table className="table table-hover">
					<thead>
						<tr>
							<th>#ID</th>
							<th scope="col">Name</th>
							<th scope="col">Email</th>
							<th scope="col">Total Orders</th>
							<th scope="col">Status</th>
							<th scope="col">Date Registered</th>
						</tr>
					</thead>
					<tbody>
						{
                            customers &&
                            customers.length > 0 ?
                            <>
                           {
                            customers.map(function(customer){
                                return (
                                    <tr key={createKey()} style={{paddingTop:5,paddingBottom:5,}}>
                                    <td>{customer.id}</td>
                                    <td style={{fontWeight:600}}>{customer.firstname} {customer.lastname}</td>
                                    <td>{customer.email}</td>
                                    <td>{customer.total_orders}</td>
                                    <td>{
                                        customer.status == 1 ? 
                                        <><span className="badge rounded-pill alert-success">Active</span></>:
                                        <span className="badge rounded-pill alert-secondary">Inactive</span>
                                        }</td>
                                    <td>{customer.created_at}</td>
                                </tr>
                                )
                            })
                           }
                            </>:
                            <>
                            <tr>
                                <td colSpan={6}>
                                    <div className='alert alert-warning'>You do not have any customer yet!</div>
                                </td>
                            </tr>
                            </>
                        }
					</tbody>
				</table>
			</div>
            </div>
			</div> 
		</div>
  
    
    
    
    </section>
        </React.Fragment>
    )
}
