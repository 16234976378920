import React ,{useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userData } from '../../redux/slice/userSlice';
import Cookie from 'react-cookies';
import { cleanme, register } from '../../api/api';
import { Link, useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Copyright from '../../components/Copyright';
import { Alert } from '@mui/material';
import Swal from 'sweetalert2';
import VerificationMessage from './VerificationMessage';


const theme = createTheme();
    
export default function Signup() {
const navigate = useNavigate()
  const dispatch = useDispatch();
const [loading, setLoading] = React.useState(false)
const [loginStatus,setLoginStatus] = React.useState(null)


    useEffect(()=>{
        return ()=>{
            return null
        }
    })
    const [regState,setRegState] = useState(null)
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const input = new FormData(event.currentTarget);
    const data = {
      name: input.get('firstName') + " " + input.get('lastName'),
      email: input.get('email'),
      phone: input.get('phone'),
      address: input.get('address'),
      password: (cleanme(input.get('password'))),
      confirm: cleanme(input.get('confirmPassword'))

    };
    if(data.password == data.confirm){
      const response = await register(data);
      if(response.success){
        //dispatch(userData(response.data));
        // Cookie.save('token', response.token, {
        //   maxAge: 6000
        // })
      setRegState(response)
        setLoading(false);
      }else {
        setLoading(false);
        Swal.fire({
          text: response.message,
          icon: 'error',
          timer: 5000,
          toast: true,
          showConfirmButton: false,
          position: 'top-end'
      });
        // setLoginStatus({success:false,message:response.message})
        console.log(response.message);
      }

    }else{
      Swal.fire({
        text: "Password does not match with Comfirm Password!'",
        icon: 'error',
        timer: 5000,
        toast: true,
        showConfirmButton: false,
        position: 'top-end'
    })
      setLoading(false)
      
    }
  };

  return (
    regState ?
      <>
      <VerificationMessage data = {regState} />
      </>
    :<ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <i className="fa fa-user" aria-hidden="true"></i>
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <div className='container'>
            {
              loginStatus ?
              <>
              <Alert severity={loginStatus.success ? 'success' :'error'}>{loginStatus.message}!</Alert>
              </>: ""
            }
          </div>
          <Box component="form"  onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="phone"
                  label="phone"
                  type="number"
                  id="phone"
                  autoComplete="phone"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="address"
                  label="address"
                  type="text"
                  id="address"
                  autoComplete="address"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  id="confirm_password"
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" />}
                  label="I want to receive marketing promotions and updates via email."
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              disabled = {loading}
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              style={{paddingTop:10,paddingBottom:10,backgroundColor:'#1bbc9b'}}
            >
              {loading ? 'Loading...' : 'Sign Up'}
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link to="/login" variant="body2">
                  Already have an account? Login
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}