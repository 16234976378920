import axios from "axios";
import Cookie from 'react-cookies';
import Swal from "sweetalert2";

export const token = () => {return Cookie.load('token')};
export  const Axios = axios.create({
    baseURL: 'https://api.shopafrica.world'
})

Axios.interceptors.request.use(
    async (config) => {
      const tk = token()
      if (tk) {
        config.headers.Authorization = `Bearer ${tk}`;
        config.headers['SUBTOKEN'] = `${tk}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  // Response interceptor to handle 401 errors
Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      Swal.fire({
        text: "Session expired! Proceed to login.",
        icon: 'error',
        timer: 5000,
        toast: true,
        showConfirmButton: false,
        position: 'top-end'
    });
      window.location.reload();
      Cookie.remove("token");
    }
    return Promise.reject(error);
  }
);
  //"https://api-node.themesbrand.website"
 // https://api.shopafrica.store/uploads/products/shopafrica_01704838496.jpg