import React,{useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cookie from 'react-cookies';
import { userData } from '../../redux/slice/userSlice';
import { Link, useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
//import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Copyright from '../../components/Copyright';
import { login } from '../../api/api';
import { useEffect } from 'react';
import { Alert } from '@mui/material';
import Swal from 'sweetalert2';
import { Axios } from '../../api/axios';


const theme = createTheme();

export default function ForgotPassword() {
const navigate = useNavigate();
const [loginStatus,setLoginStatus] = useState(null)
const user = useSelector(state => state.user);
const dispatch = useDispatch();
const [loading, setLoading] = React.useState(false)
  const handleSubmit = async (event) => {
      event.preventDefault();
      setLoginStatus(null)
      setLoading(true);
      const input = new FormData(event.currentTarget);
      const data = {
                      email: input.get('email'),
                  };
      const res = await Axios.post("api?forgotPassword",data);
      const response = res.data
      if(response.success){
        Swal.fire({
          text: response.message,
          icon: 'success',
          timer: 5000,
          toast: true,
          showConfirmButton: false,
          position: 'top-end'
      });
        setLoading(false);
        // setLoginStatus({success:true,message:response.message})
      } else {
        Swal.fire({
          text: response.message,
          icon: 'error',
          timer: 5000,
          toast: true,
          showConfirmButton: false,
          position: 'top-end'
      });
        // setLoginStatus({success:false,message:response.message})
        setLoading(false)
      }

  };

  useEffect(() => {
    return ()=>{
      return null
    }
  })
  return (
    <>
      <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />

        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <i className="fa fa-user" aria-hidden="true"></i>
          </Avatar>
          <Typography component="h1" variant="h5">
          Forgot Password ?
          </Typography>
          <div className='container'>
            {
              loginStatus ?
              <>
              <Alert severity={loginStatus.success ? 'success' :'error'}>{loginStatus.message}!</Alert>
              </>: ""
            }
          </div>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              placeholder='Enter email to get notification..'
              autoFocus
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }} 
              style={{paddingTop:10,paddingBottom:10,backgroundColor:'#1bbc9b'}}
              disabled = {loading}
            >
              {loading ? 'Loading...' : 'Sign in' }
            </Button>
            <Grid container>
              <Grid item xs>
              </Grid>
              <Grid item>
                <Link to="/login" variant="body2">
                  {"Already have an account? Login"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
    </>
  );
}