import React, { useEffect, useState } from 'react'
import Nav, { Header } from '../../../components/dashboard/Nav'
import '../../../assets/css/products.css'
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux'
import { getUserData } from '../../../redux/action/user'
import { addProduct, fetchShopProducts } from '../../../redux/action/products'
import { userShopData } from '../../../redux/action/shop'
import { setCurrentStore } from '../../../redux/action/shop'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Spinner } from 'react-bootstrap';
import { Axios } from '../../../api/axios'
import { getProduct, pageUrl, updateProduct } from '../../../api/api'
import { Alert } from '@mui/material';
import { initProductData, productStatus } from '../../../redux/reducer/productReducer'
import  Carousel  from 'react-bootstrap/Carousel'
import { Toast } from 'react-bootstrap';
import { getProductById } from '../../../redux/action/products'
import { ArrowCircleLeft, ArrowCircleRight, PlusOne } from '@mui/icons-material';
import {FaPlus, FaPlusCircle} from 'react-icons/fa'
import { getCategories, deleteProductImage } from '../../../api/api';
import { productCategories, productFashions, product, products } from '../../../redux/slice/productSlice';
import Swal from 'sweetalert2';
import ReactQuill from 'react-quill';
function EditProduct(){
    const navigate = useNavigate();
    const [shopId, setShopId] = useState(localStorage.getItem('shopId'));
    const {shop,productId} = useParams()
    const [show,handleShow] = useState(false)
    const [isEdit,setEditState] = useState(true)
    const [currentPreviewImage,handleCurrentPreviewImage] = useState(null)
    const {currentUser,shops,currentShop,singleProduct, products} = useSelector((state) => state)
    const [createState,setCreateState] = useState(null)
    const [toastShow,setToastShow] = useState(false)
    const [delStatus,setDelStatus] = useState(false)
    const [saveToDraftState,setSaveToDraftState] = useState(false)
    const [isUploading,setIsUploading] = useState(false)
    const [tagVal,setTagVal] = useState("")
    const [isdeleting,setIsDeleting] = useState(false)
    const dispatch = useDispatch()
    const [productPricing, setProductPricing] = useState({
        sellingPrice: null,
        costPerItem: null
    })
    const [productData, setProductData] = useState(singleProduct.data ? singleProduct.product : null);
    const [customCat, setCustomCat] = useState(false);
    const addField = field => {
        dispatch(addProduct({[field.name]:field.value}))
    }
    const fetchProduct = async () => {
       const response = await getProduct({
            shopId: currentShop.id,
            productId,
        });
        dispatch(product(response.data));
    }
    const fetProductCategories = async () => {
        const response = await getCategories({shopId});
        dispatch(productCategories(response.store_categories));
        setCategories(response.store_categories); 
    }
    const updateProductData = (e) => {
        let key = e.target.name;
        let value = e.target.value; 
        setProductData({...productData, [key]: value});
    }
    const updateProductDataMetaDimensions = (e) => {
        setProductData({
            ...productData, meta : {
                ...productData.meta,  dimensions: {
                    ...productData.meta.dimensions, [e.target.name]: e.target.value,
                }
            }
        })
    }
    const updateProductDataCategories = (e) => {
        let key = e.target.name;
        let value = e.target.value;
        if(e.target.checked){
                setProductData({...productData, categories: [
                    ...productData.categories, value
                ]
                });
            }else{
                setProductData({...productData, categories: [
                    ...productData.categories.filter((catetory)=> catetory !== value )
                ]
                });
            }
    }
    const updateProductDataPricing = (e) => {
        let key = e.target.name;
        let value = e.target.value;
        setProductData({
            ...productData, pricing : {
                ...productData.pricing, [e.target.name]: e.target.value
            }
        })
        if(key === 'sellingPrice' || key === 'costPerItem'){
            setProductPricing({...productPricing, [key]: value});
        }
    }
    const update = async (e) => {
        setIsUploading(true);
        e.preventDefault();
        if(productData){
            if(JSON.stringify(productData) === JSON.stringify(singleProduct.product)){
                // Swal.fire({
                //     text: 'No changes made yet.',
                //     icon: 'warning',
                //     timer: 5000,
                //     toast: true,
                //     showConfirmButton: false,
                //     position: 'top-end'
                // });
                setIsUploading(false);
            }else{
                delete productData.date;
                delete productData.images
                const response = await updateProduct(productData);
                if(response.success){
                    
                    dispatch(getProductById(productId,currentShop.id));
                    Swal.fire({
                        text: response.message,
                        icon: 'success',
                        timer: 5000,
                        toast: true,
                        showConfirmButton: false,
                        position: 'top-end'
                    });
                    setIsUploading(false);
                }else{
                    Swal.fire({
                        text: response.data.message,
                        icon: 'error',
                        timer: 5000,
                        toast: true,
                        showConfirmButton: false,
                        position: 'top-end'
                    });
                    setIsUploading(false);
                }
            }
        }else{
            console.log("Nothing to update yet.")
            setIsUploading(false);
        }
    } 
    const selectCategory = (e,ind) => {
        if(e.target.checked){
        dispatch(addProduct({categories:[...singleProduct.product.categories,e.target.value]}))
        }else{
        dispatch(addProduct({categories:singleProduct.product.categories.filter((value,i) => i != ind)}))
        }
    }
    const publishProduct = async () => {
        setCreateState(null)
        setIsUploading(true)
        try {
            const res = await Axios({
                url:"api?addProduct",
                method:"POST",
                data:product,
                headers:{
                    'content-type':'application/json'
                }
            })
            console.log(res);
            setCreateState({success:res.data.success,message:res.data.message,data:res.data})
            setIsUploading(false)
            res.data.success && dispatch(addProduct(initProductData))
        } catch (error) {
            console.log(error);
            setCreateState({success:false,message:error.message})
            setIsUploading(false)
        }
    }
    const saveToDraft = (product) => {
        var drafts = JSON.parse(localStorage.getItem("drafts"))
        if(drafts){
            drafts.push(product)
            localStorage.setItem("drafts",JSON.stringify(drafts))
        }else{
            localStorage.setItem("drafts",JSON.stringify([product]))
        }
        setSaveToDraftState(true)
        setTimeout(()=>{
            setSaveToDraftState(false)
        },5000)
    }
    const deleteImage = async (imageId) => {
        setIsDeleting(true)
        const response = await deleteProductImage({imageId});
        if(response.success){
            handleShow(false);
            dispatch(getProductById(productId,currentShop.id));
            Swal.fire({
                text: response.message,
                icon: 'success',
                timer: 5000,
                toast: true,
                showConfirmButton: false,
                position: 'top-end'
            });
        }else{
            Swal.fire({
                text: response.message,
                icon: 'error',
                timer: 5000,
                toast: true,
                showConfirmButton: false,
                position: 'top-end'
            });
        }
        setIsDeleting(false)
    }
    // const deleteProductImage = async (imageId,u) =>{
    //     setIsDeleting(true)
    //     try {
    //         const res = await Axios({
    //             url:"api?deleteProductImage",
    //             method:"POST",
    //             data:{imageId,shopId:currentShop.id},
    //             headers:{
    //                 'content-type':'application/json'
    //             }
    //         })
    //         res.data.success &&
    //         dispatch(addProduct({
    //             ...product,
    //             images:singleProduct.images.filter(function(val,ind){
    //                 console.log(u);
    //                 return ind != u
    //             })
    //         }))
    //         if(res.data.success){
    //             dispatch(addProduct(initProductData))
    //             dispatch(getProductById(productId,currentShop.id))
    //             dispatch(fetchShopProducts(currentShop.id))
    //             setToastShow(true)
    //             setDelStatus(true)
    //         }else{
    //             setDelStatus(false)
    //         }
    //         setTimeout(() => {
    //             setToastShow(false)
    //         }, 10000);
    //         handleShow(false)
        
    //     } catch (error) {
    //         console.log(error);
    //     }
    //     setIsDeleting(false)
    // }
    const [categories,setCategories] = useState(null)
    const fetchCategories = async () => {
        const response = await getCategories({shopId});
        console.log(response);
        setCategories(response);
    }
    useEffect(()=>{
      !currentUser.data && dispatch(getUserData())
      !currentShop.data && dispatch(setCurrentStore(shop))
      !shops.data && dispatch(userShopData());
      dispatch(addProduct({shopId:currentShop.id}));
      !singleProduct.data && dispatch(getProductById(productId,currentShop.id))
      !singleProduct.data && setProductData(singleProduct.product);
      return () =>{
        return setCreateState(null);
      }

    },[shops.loading,productId,])
    useEffect(() => {
        fetProductCategories();
        fetchProduct();
    }, [])
    useEffect(() => {
        
        if(productData && productPricing.sellingPrice && productPricing.costPerItem ){
            setProductData({...productData, pricing: {
                ...productData.pricing, 
                profit: Number(productPricing.sellingPrice - productPricing.costPerItem).toFixed(2),
                margin: Number((productPricing.sellingPrice - productPricing.costPerItem) / productPricing.costPerItem * 100).toFixed(2),
                }
            })
        }
    }, [ productPricing])
    useEffect(() => {
    }, [productData]);
    
    return (
        shops.loading || currentShop.loading || currentUser.loading || singleProduct.loading ?
    <>
    <div className='page-preloader'>
    <Spinner animation="border" >
    <>{shops.err && "Network failure,Please refresh"}</></Spinner>
</div></> :
        <> 
            <Nav activeState={''}/>
            <>
            {
                singleProduct.product && singleProduct.product != null
                && singleProduct.product.title != null ?
                <>
                    <main className='main-wrap'>
                <Header />
                <React.Fragment>
                <section className="content-main" style={{maxWidth:1200}}>
<form method='POST' onSubmit={update
//     (e) =>{
//     e.preventDefault()
//     publishProduct()
// }
}>
<div className="content-header">
    <h2 className="content-title">Product : {singleProduct.product.title}</h2>
    <div>
        <button type='button' onClick={()=>{
            ///saveToDraft(product);
        isEdit ? setEditState(false):setEditState(true)
            if(isEdit && !productData){
                setProductData(products.product);
                setTimeout(() => {
                    console.log(productData);
                }, 2000)
            }
        }} 
        className="btn btn-light mx-1">{isEdit ? "Edit Mode" :"View Mode"}
        </button>
        <button type='submit' disabled={isUploading} className="btn t-btn">{isUploading? "Updating...":"Update Product"}</button>
    </div>
</div>

<div className="row mb-4">
    <div className="col-xl-8 col-lg-8">
        <div className="card mb-4">
            <div className="card-body">
                {
                    saveToDraftState && <Alert severity={'success'}>This product has been saved to draft!</Alert>
                }
                {
          createState != null ?
          <>
          <Alert severity={createState.success ? 'success' :'error'}>Product and pricing saved. <Link to={`${pageUrl(shop,'/product-otherdetails')}?productId=${createState.data.productId}`}>Click to upload product images!</Link>
!</Alert>
          </>
          :""
    }
                    <div className="mb-4">
                        <label htmlFor="product_title" className="form-label">Product title</label>
                        <input disabled={isEdit}
                          required type="text"
                           placeholder="Type here" 
                           name='title'
                            value={isEdit ? singleProduct.product.title : ( productData && productData.title ? productData.title : null )}
                            onChange={updateProductData}
                             className="form-control" 
                              id="product_title"
                              />
                    </div>
                    {/* <div className="row gx-3">
                        <div className="col-md-4  mb-3">
                            <label htmlFor="product_sku" className="form-label">SKU</label>
                            <input
                            disabled={isEdit}
                            // required 
                            type="text" 
                            placeholder="Type here" 
                            name='sku' 
                            value={isEdit ? singleProduct.product.sku : ( productData && productData.sku ? productData.sku : null )} 
                            onChange={updateProductData} 
                            className="form-control" 
                            id="product_sku" 
                            />
                        </div>
                        <div className="col-md-4  mb-3">
                            <label htmlFor="product_color" className="form-label">Colors (Seperated by ,)</label>
                            <input
                            disabled={isEdit}  
                            // required 
                            type="text" 
                            placeholder="E.g red,blue" 
                            name='color' 
                            value={isEdit ? singleProduct.product.color : ( productData && productData.color ? productData.color : null )} 
                            onChange={updateProductData} 
                            className="form-control" 
                            id="product_color" 
                            />
                        </div>
                        <div className="col-md-4  mb-3">
                            <label htmlFor="product_size" className="form-label">Sizes (Seperated by ,)</label>
                            <input 
                            disabled={isEdit}  
                            // required 
                            type="text" 
                            placeholder="E.g 42,43,45" 
                            name='size' 
                            value={isEdit ? singleProduct.product.size : ( productData && productData.size ? productData.size : null )} 
                            onChange={updateProductData} 
                            className="form-control" 
                            id="product_size"
                            />
                        </div>
                    </div> */}
                    {/* <div className="row mb-4">
                        <div className='col-lg-4'>
                            <label htmlFor="product_brand" className="form-label">Barcode (ISBN, UPC, etc.)</label>
                            <input 
                            disabled={isEdit}  
                            // required 
                            type="text" 
                            name='barcode' 
                            value={isEdit ? singleProduct.product.barcode : ( productData && productData.barcode ? productData.barcode : null )} 
                            onChange={updateProductData} 
                            placeholder="Enter Barcode" 
                            className="form-control" 
                            id="product_brand"
                            />
                        </div>
                        <div className='col-lg-4'>
                            <label htmlFor="product_brand" className="form-label">Brand</label>
                            <input 
                            disabled={isEdit}  
                            // required 
                            type="text" 
                            placeholder="Type here"
                            name='brand' 
                            value={isEdit ? singleProduct.product.brand : ( productData && productData.brand ? productData.brand : null )} 
                            onChange={updateProductData}
                            className="form-control" />
                        </div>
                        <div className='col-lg-4'>
                            <label htmlFor="product_brand" className="form-label">Quantity</label>
                            <input 
                            disabled={isEdit}  
                            // required
                            type="number"
                            name='quantity' 
                            value={isEdit ? singleProduct.product.quantity : ( productData && productData.quantity ? productData.quantity : null )} 
                            onChange={updateProductData}
                            placeholder="Quantity" 
                            min={1} 
                            className="form-control" 
                            />
                        </div>
                    </div> */}
            </div>
        </div>
        <div className="card mb-4">
            <div className="card-body">
                <div>
                    <label className="form-label">Description</label>
                    {
                        !isEdit ?
                        (
                            <ReactQuill 
                            className="form-control" 
                            // placeholder='Enter product description' 
                            value={productData && productData.description ? productData.description : null }
                                onChange={(value) =>  setProductData({...productData, description: value}) }
                            />
                        ) :
                        <p> {singleProduct.product.description} </p>
                    }
                    {/* <textarea 
                    disabled={isEdit} required 
                    placeholder="Type here" 
                    name='description' 
                    value={isEdit ? singleProduct.product.description : ( productData && productData.description ? productData.description : null )} 
                    onChange={updateProductData}
                    className="form-control" 
                    rows="4">
                    </textarea> */}
                </div>
            </div>
        </div>
        <div className="card mb-4">
            <div className="card-body">
                <div>
                    <label className="form-label">Pricing</label>
                </div>
                <div className='row'>
                    <div className='form-group col-lg-6'>
                    <label className="form-label">Selling Price (NGN)</label>
                    <input 
                    disabled={isEdit}  
                    // required 
                    className="form-control" 
                    type="number"
                    name='sellingPrice' 
                    value={isEdit ? singleProduct.product.pricing.sellingPrice : ( productData && productData.pricing && productData.pricing.sellingPrice ? productData.pricing.sellingPrice : null )} 
                    onChange={updateProductDataPricing}
                    placeholder='NGN 0.00' 
                    />
                    </div>
                    <div className='form-group col-lg-6'>
                    <label className="form-label">Compare Price (NGN)</label>
                    <input 
                    disabled={isEdit}  
                    // required 
                    className="form-control" 
                    type="number"
                    name='comparePrice' 
                    value={isEdit ? singleProduct.product.pricing.comparePrice : ( productData && productData.pricing && productData.pricing.comparePrice ? productData.pricing.comparePrice : null )} 
                    onChange={updateProductDataPricing}
                    placeholder='NGN 0.00' 
                    />
                    </div>
                </div><div className='row'>
                    <div className='form-group col-lg-4'>
                    <label className="form-label">Cost per item (NGN)</label>
                    <input 
                    disabled={isEdit}  
                    // required 
                    className="form-control" 
                    type="number"
                    name='costPerItem' 
                    value={isEdit ? singleProduct.product.pricing.costPerItem : ( productData && productData.pricing && productData.pricing.costPerItem ? productData.pricing.costPerItem : null )} 
                    onChange={updateProductDataPricing}
                    placeholder='NGN 0.00' 
                    />
                    </div>
                    <div className='form-group col-lg-4'>
                    <label className="form-label">Profit</label>
                    <input 
                    className="form-control" 
                    type="number" 
                    value={isEdit ? singleProduct.product.pricing.profit : ( productData && productData.pricing && productData.pricing.profit ? productData.pricing.profit : null )} 
                    placeholder='NGN 0.00' 
                    disabled
                    />
                    </div>
                    <div className='form-group col-lg-4'>
                    <label className="form-label">Margin</label>
                    <input  
                    className="form-control" 
                    type="text" 
                    value={isEdit ? `${singleProduct.product.pricing.margin}%` : ( productData && productData.pricing && productData.pricing.margin ? `${productData.pricing.margin}%` : null )} 
                    disabled
                    />
                    </div>
                </div>
                <hr />

            </div>
        </div>
        <div className="card mb-4">
            <div className="card-body">
                <div>
                    <label className="form-label">Dimensions</label>
                </div>
                <div className='row'>
                    <div className='form-group col-lg-6'>
                    <label className="form-label">Width</label>
                    <input  className="form-control" type="text"
                    name='width'
                    value={isEdit ? singleProduct.product.meta.dimensions.width : (productData && productData.meta && productData.meta.dimensions.width ? productData.meta.dimensions.width : null )}
                    onChange={updateProductDataMetaDimensions}
                    placeholder='in mm' />
                    </div>
                    <div className='form-group col-lg-6'>
                    <label className="form-label">Length</label>
                    <input  
                    className="form-control" 
                    type="text"
                    name='length'
                    value={isEdit ? singleProduct.product.meta.dimensions.length : (productData && productData.meta && productData.meta.dimensions.length ? productData.meta.dimensions.length : null )} 
                    onChange={updateProductDataMetaDimensions}
                    placeholder='in mm' />
                    </div>
                </div>
                <div className='row'>
                    <div className='form-group col-lg-6'>
                    <label className="form-label">Weight</label>
                    <input  
                    className="form-control" 
                    type="text"
                    name='weight'
                    value={isEdit ? singleProduct.product.meta.dimensions.weight : (productData && productData.meta && productData.meta.dimensions.weight ? productData.meta.dimensions.weight : null )} 
                    onChange={updateProductDataMetaDimensions}
                    placeholder='in kg' 
                    />
                    </div>
                    <div className='form-group col-lg-6'>
                    <label className="form-label">Depth</label>
                    <input  
                    className="form-control" 
                    type="text"
                    name='depth'
                    value={isEdit ? singleProduct.product.meta.dimensions.depth : (productData && productData.meta && productData.meta.dimensions.depth ? productData.meta.dimensions.depth : null )} 
                    onChange={updateProductDataMetaDimensions}
                    placeholder='in mm' 
                    />
                    </div>
                </div>
                <hr />

            </div>
        </div>
        <div className="card mb-4">
            <div className="card-header d-flex justify-content-between">
                <h5 className="card-title mb-0">Variants</h5>
            </div>
                <div className="card-body">
                    
                        {
                            <>
                    <div className='table-responsive mt-2'>
                        <table class="table">
                        <thead>
                            <tr>
                                <th className='w-25'>Size</th>
                                <th className='w-25'>Color(s)</th>
                                <th className='w-25'>Price</th>
                                <th className='w-25'>Quantity</th>
                                <th className='w-25'></th>
                            </tr>
                        </thead>
                        <tbody>
                            {  isEdit ? (
                                        singleProduct.product.variants &&
                                        singleProduct.product.variants.map(function(variant,variantIndex){
                                            return (
                                                <tr key={variantIndex}>
                                        <td>
                                            <input type={'text'} className='form-control' value={variant.size} onChange={(e)=>{
                                                
                                                var data = singleProduct.product.variants.slice(); 
                                                var currentVariant = data[variantIndex];
                                                data[variantIndex] = { ...currentVariant, size: e.target.value };
                                                setProductData({...productData, variants: data });
                                            }} />
                                               
                                        </td>
                                        <td>
                                            <input type={'text'} className='form-control' value={variant.color} onChange={(e)=>{
                                                var data = singleProduct.product.variants.slice();
                                                var currentVariant = data[variantIndex];
                                            data[variantIndex] = { ...currentVariant, color: e.target.value };
                                                setProductData({...productData, variants: data });
                                            }} />
                                                {/* {
                                                    product.product_color.map((val,i)=>{
                                                        return <option value={val}>{val}</option>
                                                    })
                                                }
                                            </select> */}
                                        </td>
                                        <td><input type='number' min={0} defaultValue={singleProduct.product.pricing.sellingPrice} className='form-control' value={variant.price} onChange={(e)=>{
                                                var data = singleProduct.product.variants.slice();
                                                var currentVariant = data[variantIndex];
                                                data[variantIndex] = { ...currentVariant, price: e.target.value } 
                                                setProductData({...productData, variants: data });
                                            }} placeholder='NGN 0.00' /></td>
        
                                        <td><input type='number' className='form-control' min={1} defaultValue={1} value={variant.quantity} onChange={(e)=>{
                                                var data = singleProduct.product.variants.slice();
                                                var currentVariant = data[variantIndex];
                                                data[variantIndex] = { ...currentVariant, quantity: e.target.value };
                                                setProductData({...productData, variants: data });
                                            }} />
                                        </td>
                                        
                                                </tr>
                                            )
                                        })
                                ) : (   productData &&
                                        productData.variants &&
                                        productData.variants.map(function(variant,variantIndex){
                                            return (
                                                <tr key={variantIndex}>
                                        <td>
                                            <input type={'text'} className='form-control' value={variant.size} onChange={(e)=>{
                                                
                                                var data = productData.variants.slice(); 
                                                var currentVariant = data[variantIndex];
                                                data[variantIndex] = { ...currentVariant, size: e.target.value };
                                                setProductData({...productData, variants: data });
                                            }} />
                                                {/* {
                                                    product.product_size.map((val,i)=>{
                                                        return <option value={val}>{val}</option>
                                                    })
                                                }
                                            </select> */}
                                        </td>
                                        <td>
                                            <input type={'text'} className='form-control' value={variant.color} onChange={(e)=>{
                                                var data = productData.variants.slice();
                                                var currentVariant = data[variantIndex];
                                            data[variantIndex] = { ...currentVariant, color: e.target.value };
                                                setProductData({...productData, variants: data });;
                                            }} />
                                                {/* {
                                                    product.product_color.map((val,i)=>{
                                                        return <option value={val}>{val}</option>
                                                    })
                                                }
                                            </select> */}
                                        </td>
                                        <td><input type='number' min={0} className='form-control' value={variant.price} 
                                        onChange={(e)=>{
                                                var data = productData.variants.slice();
                                                var currentVariant = data[variantIndex];
                                                data[variantIndex] = { ...currentVariant, price: e.target.value } 
                                                setProductData({...productData, variants: data });;
                                            }} placeholder='NGN 0.00' /></td>

                                        <td><input type='number' className='form-control' min={1} defaultValue={1} value={variant.quantity} onChange={(e)=>{
                                                var data = productData.variants.slice();
                                                var currentVariant = data[variantIndex];
                                                data[variantIndex] = { ...currentVariant, quantity: e.target.value };
                                                setProductData({...productData, variants: data });;
                                            }} /></td>
                                        <td >
                                            <div className='d-flex justify-content-between align-items-center'>
                                            <button type='button' onClick={()=>{
                                                var data = productData.variants.slice();
                                                setProductData({...productData, variants: data.filter((_,ind) => ind != variantIndex ) });
                                                }} className='btn btn-sm btn-danger'><i className="material-icons md-delete_forever"></i>
                                            </button>
                                            </div>
                                        </td>
                                                </tr>
                                            )
                                        })
                                )
                            }
                            
                        </tbody>
                        
                        </table>
                        {
                            !isEdit && 
                            <button type='button' onClick={()=>{
                                let data = productData.variants !== null ? [...productData.variants,{
                                    size:"",
                                    color:"",
                                    price:'',
                                    quantity:'',
                                    sku:"",
                                    barcode:""
                                }] :[ {
                                    size:"",
                                    color:"",
                                    price:'',
                                    quantity:'',
                                    sku:"",
                                    barcode:""
                                }]

                                setProductData({...productData, variants: data})

                            }} className='btn btn-sm t-btn2 mx-1 mt-2'>{ singleProduct.product.variants && singleProduct.product.variants.length > 0 ? 'Add More' : "Add Option" }
                            </button>
                        }
                        </div>
                            </>
                        }
                </div>
                { isEdit ? ( singleProduct.product.meta.specifications &&
                            <div className="card mb-4">
                            <div className="card-body">
                                <div>
                                    <label className="form-label text text-bold">Specifications</label>
                                </div>
                                <div className='specs'>
                                    {
                                        singleProduct.product.meta.specifications.map(function(specification,i){
                                            return (
                                                <div className='row' key={i}>
                                                    <div className='form-group col-6'>
                                                        <label className="form-label text text-bold">Property</label>
                                                        <input id={i} className="form-control" type="text"
                                                        value={specification.property}
                                                            
                                                        placeholder='Enter spec key / name' 
                                                        />
                                                    </div>
                                                    <div className='form-group col-6'>
                                                    <label className="form-label text text-bold">Value</label>
                                                    <input  className="form-control" type="text" name={i}
                                                    value={specification.value}
                                                    placeholder='Enter spec value' 
                                                    />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                
                                </div>
                            </div>
                        </div>
                        
                    ) : (
                            <div className="card mb-4">
                            <div className="card-body">
                                <div>
                                    <label className="form-label text text-bold">Specifications</label>
                                </div>
                                <div className='specs'>
                                    {   productData && productData.meta &&
                                        productData.meta.specifications.map(function(specification,i){
                                            return (
                                                <div className='row' key={i}>
                                                    <div className='form-group col-5'>
                                                        <label className="form-label text text-bold">Property</label>
                                                        <input id={i} className="form-control" type="text"
                                                            onChange={(e) => 
                                                            {
                                                                var id = e.target.id
                                                                setProductData({
                                                                    ...productData, meta:{
                                                                    ...productData.meta,
                                                                    specifications:productData.meta.specifications.map(function(sp,a){
                                                                        if (a == id) {
                                                                            return {...sp,property:e.target.value}
                                                                        }else{
                                                                            return sp
                                                                        }
                                                                    })
                                                                    
                                                                }})
                                                                
                                                            
                                                            }
                                                            }
                                                        placeholder='Enter spec key / name' />
                                                    </div>
                                                    <div className='form-group col-5'>
                                                    <label className="form-label text text-bold">Value</label>
                                                    <input  className="form-control" type="text" name={i}
                                                    onChange={(e) => 
                                                        {
                                                            var id = e.target.name
                                                            setProductData({
                                                                ...productData, meta:{
                                                                ...productData.meta,
                                                                specifications:productData.meta.specifications.map(function(sp,i){
                                                                    if (i == id) {
                                                                        return {...sp,value:e.target.value}
                                                                    }else{
                                                                        return sp
                                                                    }
                                                                })
                                                                
                                                            }})
                                                        }
                                                        }
                                                    placeholder='Enter spec value' />
                                                    </div>
                                                    <div className='col-2 mt-4 d-flex align-items-center justify-contents-center'>
                                                    <button type='button' onClick={()=>{
                                                        var data = productData.meta.specifications.slice();
                                                        setProductData({...productData, meta: {
                                                            ...productData.meta.specifications, specifications : data.filter((_,ind) => ind != i )
                                                        } });
                                                        }} className='btn btn-sm btn-danger'><i className="material-icons md-delete_forever"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                
                                </div>
                                <button className='btn btn-sm mt-2 t-btn2'
                                type='button' onClick={()=>{
                                    setProductData({
                                        ...productData, meta:{
                                        ...productData.meta,
                                        specifications:[...productData.meta.specifications,{property:"",value:""}]
                                    }})
                                }}>Add more</button>
                                
        
                            </div>
                        </div>
                    )

                }
                
                {
                    isEdit ? (
                            <div className="card mb-4">
                            <div className="card-body">
                            <div>
                            <label className="form-label text text-bold">Features</label>
                            </div>
                            {
                                singleProduct.product.meta.features.map(function(feature,i){
                                return (
                                    <div key={i}  style={{position:'relative'}}>
                                        <div className="mb-2">
                                            <input type="text" disabled placeholder="Enter product feature"  name='title' value={feature} 
                                             className="form-control" id={i} />
                                        </div>
                                        <hr />
                                    </div>
                                )
                            })
                        }
                        </div>
                    </div>
                    ) : (
                        <div className="card mb-4">
                        <div className="card-body">
                            <div>
                            <label className="form-label text text-bold">Features</label>
                        </div>
                        {   productData &&
                            productData.meta &&
                            productData.meta.features &&
                            productData.meta.features.map(function(feature,i){
                                return (
                                    <div key={i}  style={{position:'relative'}}>
                                        <div className="mb-2">
                                            <input type="text" placeholder="Enter product feature"  name='title' value={feature} 
                                            onChange={(e) => {
                                                var id = new Number(e.target.id);
                                                setProductData({
                                                    ...productData, meta:{
                                                    ...productData.meta,
                                                    features:productData.meta.features.map(function(val,i){
                                                        return id == i ? e.target.value : val
                                                    })
                                                }})
                                            }} className="form-control" id={i} />
                                        </div>
                                        <hr />
                                        <button id={i} type='button' onClick={(e)=>{
                                                var id= e.target.id
                                                setProductData({
                                                    ...productData, meta:{
                                                    ...productData.meta,
                                                    features:productData.meta.features.filter((val,i) => i != id)
                                                }})
                                        }} style={{
                                            position:'absolute',top:6,right:7
                                        }} className='btn btn-sm btn-danger'><i className="material-icons md-delete_forever"></i></button>
                                    </div>
                                )
                            })
                        }
                        
                        <button className='btn btn-sm t-btn2'
                        type='button' onClick={()=>{
                            setProductData({
                                ...productData, meta:{
                                ...productData.meta,
                                features:[...productData.meta.features,'']
                            }})
                        }}>Add more</button>
                        

                            </div>
                        </div>
                    )
                }
                
            </div>
    </div>
    <aside className="col-xl-4 col-lg-4">
        <div className="card mb-4">
            <div className="card-body">
                    <div className="mb-4">
                        <label className="form-label">Status</label>
                        <select 
                        // required
                        disabled={isEdit} 
                        name='status' 
                        className="form-select" 
                        onChange={updateProductData} defaultValue={singleProduct.product.status}>
                            
                            {
                            isEdit ? (
                                <option> {singleProduct.product.status}</option>
                            ) : (
                                productStatus.map(function(status,i){
                                    return (
                                        <>
                                        {i === 0 && <option>Select Product Status</option>}
                                        <option key={i}  value={status}>{status}</option>
                                        </>
                                    )
                                })
                            )
                        }
                        </select>
                    </div>
                    {   isEdit ? (

                                singleProduct.product.tags.length > 0 ?
                                <>
                                <label className="form-label">Tags</label> <br/>
                                {
                                    singleProduct.product.tags &&
                                    singleProduct.product.tags.map((tag,i)=>{
                                        return(
                                            <button type='button' disabled onClick={()=>{
                                        dispatch(addProduct({tags:product.tags.filter((val,ind) => i != ind)}))
                                            }} key={tag+i} className="btn btn-sm btn-light mb-1">{tag}
                                            <i className="material-icons md-close" ></i>
                                            </button>
                                        )
                                    })
                                }
                                </>:
                                "No tag available"
                        ) : 
                        (
                            <>
                            <div className="mb-4">
                            <label className="form-label text text-bold">Tags(Click on 'Add' to add tag)</label>
                            <input   type="text" name='tags' placeholder="Enter Tag" value={tagVal}
                            onChange={(e)=> setTagVal(e.target.value)}
                            className="form-control"/>
                                {
                                tagVal !=""  && 
                                <button type='button' disabled className='btn t-btn2 mt-2 float-end ' style={{
                                    padding:"0 12px",
                                    margin:"2px 0"
                                }} onClick={()=>{
                                    setProductData({
                                        ...productData, tags: [
                                            ...productData.tags, tagVal
                                        ]})
                                    setTagVal("")
                                }}
                                >
                                    Add
                                </button>
                                }
                            </div>
                            {
                            productData.tags &&
                            productData.tags.length > 0 ?
                                <>
                                   { 

                                   productData &&
                                   productData.tags && 
                                   productData.tags.map((tag,i)=>{
                                        return(
                                            <button type='button' onClick={()=>{
                                            setProductData({
                                            ...productData, tags:productData.tags.filter((val,ind) => i != ind)})
                                            }} key={tag+i} className="btn btn-sm btn-light mb-1">{tag}
                                            <i className="material-icons md-close" ></i>
                                            </button>
                                        )
                                    })
                                    }
                                </>:
                                "No tag available"
                            }
                            </>  
                        )

                    }
                    {/* <div className="mb-4">
                        <label className="form-label">Tags(Separated with spacing)</label>
                        <input 
                        disabled={isEdit}  
                        required = {singleProduct.product.tags > 0} 
                        type="text" 
                        name='tags' 
                        placeholder="Enter tag without spacing"
                        value={isEdit ? singleProduct.product.tags : ( productData && productData.tags ? productData.tags : null )}
                        onChange={updateProductData}
                        onKeyPress= {(e)=>{
                            if(e.charCode === 13){
                            dispatch(addProduct({tags:[...singleProduct.product.tags,e.target.value]}))
                            setTagVal("")
                            }
                        }}
                        className="form-control"/>
                    </div>
                        {
                            singleProduct.product.tags.length > 0 ?
                            <>
                            {
                                singleProduct.product.tags.split(" ").map((tag,i)=>{
                                    return(
                                        <button type='button' onClick={()=>{
                                    dispatch(addProduct({tags:singleProduct.product.tags.filter((val,ind) => i !== ind)}))
                                        }} key={i} className="btn btn-sm btn-light mb-1">{tag}
                                        <i className="material-icons md-close" ></i></button>
                                    )
                                })
                            }
                            </>:
                            "No tag available"
                        } */}
                    <hr/>
                    <h5 className="mb-3">Categories</h5>
                    {/* <select className='form-control' disabled={isEdit}
                    onChange={updateProductData}
                    name="categories"
                    >
                        {
                            isEdit ? (
                                <option> {singleProduct.product.categories}</option>
                            ) : (
                                    categories && categories.map((category, index) => (
                                        <option value={category.title} key={index}>
                                            {category.title}
                                        </option>
                                    ))

                            )
                        }
                    </select>
                    <h5 className="mb-3">Categories</h5> */}
                    {
                            isEdit ? (
                                <input className='form-control' disabled={isEdit} value={singleProduct.product.categories}/>
                            ) : (
                                    categories && categories.map((category, index) =>  (
                                        <div class="form-check">
                                        <input 
                                        onChange={updateProductDataCategories}
                                        class="form-check-input has-sub" 
                                        type="checkbox" 
                                        id={category.title}
                                        name={category.title}
                                        value={category.title}
                                        />
                                        <label class="form-check-label" 
                                        for={category.title}>
                                            {/* <input type={'checkbox'} value={category.title} key={index}> */}
                                            {category.title}
                                        {/* </option>  */}
                                            {/* <span role="button">
                                                <i>
                                                    <FaPlus
                                                     size={'15'}
                                                     className="ms-2"
                                                     />
                                                </i>
                                            </span> */}
                                        </label>
                                    </div>
                                        
                                    ))

                            )
                        }
                     <div>
                        <div  className='btn form-control text-end' 
                        style={{ 
                            fontWeight: 'bold',
                            border: 0,
                            justifyContent: 'center'
                         }}
                         onClick={() => {
                            setCustomCat(!customCat)
                            navigate(pageUrl(shop,'/categories'))
                        }}
                        >
                            <FaPlus size={'14'} className='mb-1'/>
                             Add new category
                        </div>
                     </div>
                    {/* {
                        customCat && 
                    <div className="mb-4">
                    <h5 className="mb-1">Custom Category</h5>
                    <small className='text-muted'> Add new category (optional).</small>
                        <input
                        disabled={isEdit}
                        className='form-control'
                        type={'text'} 
                        placeholder='Enter Category'
                        name='customCategory'
                        onChange={updateProductData}
                        />
                    </div>
                    } */}
                    <hr/>
                    <div className="mb-4">
                    <h5 className="mb-3">Images</h5>
                    {
                            <div className="col">
                                <div className="card card-product-grid">
                                    {
                                        singleProduct.images && singleProduct.images.length > 0 ?
                                        <>
                                <Carousel 
                                
                                prevIcon={
                                    <ArrowCircleLeft fontSize='medium' className='t-arrow-icon' />
                                }
                                nextIcon={
                                    <ArrowCircleRight fontSize='medium' className='t-arrow-icon' />
                                }
                                pause={'hover'} style={{cursor:"pointer"}}>
                                    {
                                        singleProduct.images.map(function(productImage,i){
                                            return (
                                                
                                                <Carousel.Item  key={i} onClick={()=>{
                                                    if(!isEdit){
                                                        handleCurrentPreviewImage({productImageId:productImage.imageId,image:productImage.image,
                                                            title:singleProduct.product.title,productId:productImage.product_id,i})
                                                            handleShow(true)
                                                    }
                                                        
                                                }}>
                                                    
                                                <img
                                                className="d-block w-100"
                                                src={productImage.image}
                                                alt={`${singleProduct.product.title} - Image - ${productImage.product_id}`}
                                                />
                                                
                                                </Carousel.Item>
                                            
                                            )
                                        })

                                    }
                                    
                                </Carousel>
                                        </>:
                                        <a href="#" className="img-wrap"> No images </a>
                                    }
                                </div>
                            </div> 
                    }
                    
                    <Link to={`/shop/${shop}/dashboard/product-otherdetails?productId=${productId}`} className='btn btn-sm t-l-btn' >Click to Add More Images</Link>
                    </div>
            </div>
        </div>
    </aside> 
</div> 
</form>

</section>


                </React.Fragment>
            </main>
                </>:
                <>
                    <Alert severity='warning' style={{
                        position:"absolute",left:"50%",top:"50%"
                    }}>No product found!</Alert>
                </>
            }
            </>
            <>
                    <Modal show={show} onHide={()=>handleShow(false)} 
                    centered

      >
        <Modal.Body>
                {
                    currentPreviewImage &&
                    <img
                    className="d-block w-100"
                    height={450}
                    src={currentPreviewImage.image}
                    alt={`${currentPreviewImage.title} - Image - ${currentPreviewImage.productId}`}
                 />
                }
        </Modal.Body>
        <Modal.Footer>
        <button type='button' disabled={isdeleting} className='btn btn-sm btn-danger' onClick={()=>{
                deleteImage(currentPreviewImage.productImageId);
            }}>
                    {isdeleting ? 'Deleting...' :<i className="material-icons md-delete_forever"></i>}
        </button>
        </Modal.Footer>
        
                    </Modal>
                   
                    </>

        </>
    )
};

export default EditProduct