import React from 'react'
import { useEffect,useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import avatar from "../../../src/assets/images/people/avatar1.jpg"
import { pageUrl } from '../../api/api'
import '../../assets/css/nav.css'
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { Button } from 'react-bootstrap'
import Cookie from 'react-cookies';
import Collapse from 'react-bootstrap/Collapse';
// import logo from '../../assets/images/logo.png'
import logo from "../../assets/images/shopafrica-logo-2.png"
// import {Dra}
// const activeState?
function Nav({activeState}){
  const {shop} = useParams();
  const currentShop = useSelector(state => state.currentShop)
  const [open, setOpen] = useState(false);
  const [pathname, setPathName] = (activeState);
  const minimizeOnDesktop = function(){
    if( window.innerWidth < 768) {
      document.querySelector('body').classList.remove('aside-mini');
      document.querySelector(".screen-overlay").classList.remove("show");
      document.querySelector(".navbar-aside").classList.remove("show");
        document.body.classList.remove("offcanvas-active");
      } 
      else {
        // minimize sideber on desktop
      document.querySelector('body').classList.toggle('aside-mini');
    }
  }
  const checkPath = (path) => {
    // let pathname = window.location.pathname.split('/')[3];
    // console.log(pathname);
    if(path === activeState){
      return 'menu-item active'
    }else{
      return 'menu-item'
    } 
  }
  useEffect(()=>{
  },[currentShop])

    return (
        <>
    <b className="screen-overlay"></b>
<aside className="navbar-aside" id="offcanvas_aside">
<div className="aside-top"
style={{ 
  backgroundColor: '#2c3e50'
 }}
>
  <Link to={pageUrl(shop,'/')} className="brand-wrap "
  
  >
    <img src={logo} height="46" className="logo" alt="Textng Shop" style={{width:190}} />
  </Link>
  <div>
    <button className="btn btn-icon btn-aside-minimize" onClick={minimizeOnDesktop}> <i className="text-muted material-icons md-menu_open"></i> </button>
  </div>
  </div>
<nav>
  <ul className="menu-aside cusTom" style={{width:"100%"}}>
    <li className={checkPath('dashboard')}
    > 
      <Link className="menu-link" to={pageUrl(shop,'/')}> <i className="icon material-icons md-home"></i> 
        <span className="text">Dashboard</span> 
      </Link> 
    </li>
    <li className={checkPath('categories')}>
      <Link className="menu-link" to={pageUrl(shop,'/categories')}> <i className="icon material-icons md-category"></i> 
        <span className="text">Categories</span> 
      </Link> 
    </li>
    <li className={checkPath('products')}> 
      <Link className="menu-link" to={pageUrl(shop,'/products')}> <i className="icon material-icons md-shopping_bag"></i> 
          <span className="text">Products</span> 
        </Link> 
    </li>
    <li className={checkPath('drafts')}> 
      <Link className="menu-link" to={pageUrl(shop,'/drafts')}> <i className="icon material-icons md-drafts"></i> 
        <span className="text">Draft</span> 
      </Link> 
    </li>
    <li className={checkPath('transactions')}>
      <Link className="menu-link" to={pageUrl(shop,'/transactions')}> <i className="icon material-icons md-monetization_on"></i> 
        <span className="text">Transactions</span> 
      </Link> 
    </li>
    <li className={checkPath('orders')}>
      <Link className="menu-link" to={pageUrl(shop,'/orders')}> <i className="icon material-icons md-shopping_cart"></i> 
        <span className="text">Orders</span> 
      </Link> 
    </li>
    <li className={checkPath('customers')}>
      <Link className="menu-link" to={pageUrl(shop,'/customers')}> <i className="icon material-icons md-people"></i> 
        <span className="text">Customers</span> 
      </Link> 
    </li>
    <li className={checkPath('reviews')}> 
      <Link className="menu-link" to={pageUrl(shop,'/reviews')}> <i className="icon material-icons md-comment"></i> 
        <span className="text">Reviews</span> 
      </Link> 
    </li>
    <li className={checkPath('settings')}> 
      <Link className="menu-link" to={pageUrl(shop,'/settings')}> <i className="icon material-icons md-settings"></i> 
        <span className="text">Shop Settings</span> 
      </Link> 
    </li>
    <hr/>
    <li className={checkPath('my-shops')}> 
      <Link className="menu-link" disabled to="/my-shops"> <i className="icon material-icons md-pie_chart"></i> 
        <span className="text">My Shops</span> 
      </Link> 
    </li>
  </ul>
  <br/>
  <br/>
</nav>
</aside>

    </>
    )
}

export default Nav

export function Header(){ 
  const {shop} = useParams();
  const navigate = useNavigate()
  const currentUser = useSelector(({currentUser}) => currentUser)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const darkMode = (btn_this) => {
  var body_el = document.body;
  if(body_el.classList.contains('dark')) {
      // Add darkmode 
    localStorage.removeItem("darkmode");
    body_el.classList.toggle("dark");
      btn_this.classList.remove('active');

  } else {
      // remove darkmode 
    localStorage.setItem("darkmode", "active");
    body_el.classList.toggle("dark");
      btn_this.className += ' active'; 
  }
}
const name_initials = (name) =>{
  if (name) {
    var name_upper = name.toUpperCase()
  const split = name_upper.split(" ")
  return split[0][0] + split[1][0]
  }
  
}
const minimizeOnDesktop = function(e){
  e.preventDefault();
  e.stopPropagation();
  var offcanvas_id =  '#offcanvas_aside';
  document.querySelector(offcanvas_id).classList.toggle("show");
  document.querySelector('body').classList.toggle("offcanvas-active");
  document.querySelector(".screen-overlay").classList.toggle("show");
}

    return (
        <>
        <header className="main-header navbar">
		<div className="col-search">
			{/* <form className="searchform">
				<div className="input-group">
				  <input list="search_terms" type="text" className="form-control" placeholder="Search term"/>
				  <button className="btn btn-light bg" type="button"> <i className="material-icons md-search"></i> </button>
				</div>
				<datalist id="search_terms">
				  <option value="Products"/>
				  <option value="New orders"/>
				  <option value="Apple iphone"/>
				  <option value="Ahmed Hassan"/>
				</datalist>
			</form> */}
		</div>
		<div className="col-nav">
     <button className="btn btn-icon btn-mobile me-auto" onClick={e=> {
      minimizeOnDesktop(e)
      }} data-trigger="#offcanvas_aside"> <i className="md-28 material-icons md-menu"></i> </button>
     <ul className="nav">
      {/* <li className="nav-item" onClick={(e)=> darkMode(e.target)}>
          <Link className="nav-link btn-icon" title="Dark mode" to="#"> <i className="material-icons md-nights_stay"></i> </Link>
      </li> */}
      <li className="nav-item d-none">
        <Link className="nav-link btn-icon" to="#"> <i className="material-icons md-notifications_active"></i> </Link>
      </li>
      <li className="nav-item">
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}>
              <span style={{fontSize:15}}>{ currentUser && name_initials(currentUser.name)}</span>
            </Avatar>
          </IconButton>
        </Tooltip>
      </li>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem>
        <Box>
          <h5 style={{fontSize:15}}>{currentUser && currentUser.name}</h5>
          <small style={{fontSize:13}}>{ currentUser && currentUser.email}</small>
        </Box>
        </MenuItem>
        <Divider />
        <MenuItem onClick={()=> {
          navigate(`/profile`)
        }}>
          <ListItemIcon>
          <i className="fa fa-cog" aria-hidden="true"></i>
          </ListItemIcon>
          Profile
        </MenuItem>
        <MenuItem 
        onClick={() => {
          // localStorage.clear();
          Cookie.remove('token',{maxAge:-1,expires:-1});
          setTimeout(() => {
            navigate("/")
          }, 2000)  
        }}
        >
          <ListItemIcon>
          <i className="fa fa-lock" aria-hidden="true"></i>
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </ul> 
  </div>
	</header>
        </>
    )
}