import React from 'react'
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getUserData } from "../../../redux/action/user"
import { Link, useNavigate } from "react-router-dom"
import { Axios } from "../../../api/axios"
import Swal from "sweetalert2"
import ProfileNav from "./ProfileNav"
import { createKey } from '../../../api/api'
import Cookie from 'react-cookies'
import { Spinner } from 'react-bootstrap'
import { CheckedIcon } from '../../../components/Icons'
import { Alert } from '@mui/material'
function Subscriptions(){
    const navigate = useNavigate()
    const {currentUser} = useSelector(state => state)
    const dispatch = useDispatch();
    const [loading,setLoading] = useState(true)
    const [currentPlan,setCurrentPlan] = useState(null)
    const fetchCurrentPlan = async () => {
        try {
            const response = await Axios.get('api?getCurrentPlan')
            const res = response.data
            if (res.success && res.success === true) {
                setCurrentPlan(res.data)
            } else {
                setCurrentPlan(null)
                Swal.fire({
                    text: res.message,
                    icon: 'error',
                    timer: 5000,
                    toast: true,
                    showConfirmButton: false,
                    position: 'top-end'
                });
            }
        } catch (error) {
            Swal.fire({
                text: error.message,
                icon: 'error',
                timer: 5000,
                toast: true,
                showConfirmButton: false,
                position: 'top-end'
            });
        }finally{
            setLoading(false)
        }
    }
        useEffect(()=>{
            !currentUser.data && dispatch(getUserData())
            fetchCurrentPlan()
            return ()=> {
                return null
            }
        },[])
    return (
        loading ?
        <>
        <div className='page-preloader'>
                <Spinner animation="border" >
                </Spinner>
            </div>
        </>
        :
        <>
            <b className="screen-overlay"></b>
            <React.Fragment>
    <div className="">
			<div className="v-body-container">
				<div className="v-h-parent-grid">
					<>
          <ProfileNav page={'SUBSCRIPTION'}/>
          </>
					<div className="p-0 flex-grow-1">
						<div className="v-main-brands-board">
							<div className="v-brand-board-title d-flex align-items-center justify-content-between">
								<h3 className="v-title">Subscription</h3>
								<div className="dropdown" style={{margin:20}}>
                                    <a href="#" data-bs-toggle="dropdown" className="btn btn-light"> <i className="material-icons md-account_circle"></i> </a>
                                    <div className="dropdown-menu" style={{minWidth:200}}>
                                    <div className='w-100 px-2 d-flex justify-content-center align-items-center flex-column'>
                                        <h5 style={{fontSize:15}}>{currentUser && currentUser.name}</h5>
                                        <small style={{fontSize:13}}>{ currentUser && currentUser.email}</small>
                                    </div>
                                    <ul className='mt-2' style={{listStyle:"none"}}>
                                        <li><Link className="dropdown-item" to={'/profile'}>View Profile </Link></li>
                                        <li onClick={()=>{
                                        Cookie.remove('token',{maxAge:-1,expires:-1});
                                        setTimeout(() => {
                                            navigate("/")
                                        }, 2000)  
                                        }}><Link className="dropdown-item text-danger" >Logout</Link></li>
                                    </ul>
                                    </div>
                                            </div>
							</div>
							<div className="v-grid-container mt-3 mt-lg-5">
                                    <section className="content-body p-xl-4">
                                        {
                                            currentUser && currentUser.is_used_free_trial == 0 ?
                                            <>
                                            <Alert style={{width:"100%"}} severity='info' >You have free trial. Create your first store to enjoy this option!
                                            </Alert>
                                            </>:
                                            <>
                                            <React.Fragment>
                                      <div>
                                          <p>View subscription details, features and manage your subscription plans</p>
                                      </div>
                                      {
                                          currentPlan &&
                                          <div className='mt-2' style={{marginLeft:0}}>
                                          <div className='card-title'>Subscription Plan - {currentPlan.plan}</div>
                                          <div >
                                              <ul>
                                              <li className="d-flex mt-4 justify-content-between align-items-center">
                                                  <span>Status</span>
                                                  <strong className="text-bold text-success">Active</strong>
                                              </li>
                                              
                                              <li className="d-flex mt-4 justify-content-between align-items-center">
                                                  <span>Price</span>
                                                  <strong className="text-bold "> {currentPlan.price ? 
                                                  <>NGN {new Number(currentPlan.price).toLocaleString()}</>:
                                                  <>FREE</>
                                                  } </strong>
                                              </li>
                                              <li className="d-flex mt-4 justify-content-between align-items-center">
                                                  <span>Plan Reference</span>
                                                  <strong className="text-bold">{currentPlan.current_plan_ref ?? 'Not Available'} </strong>
                                              </li>
                                              <li className="d-flex mt-4 justify-content-between align-items-center">
                                                  <span>Date Started</span>
                                                  <strong className="text-bold">{currentPlan.current_plan_start} </strong>
                                              </li>
                                              <li className="d-flex mt-4 justify-content-between align-items-center">
                                                  <span>Period</span>
                                                  <strong className="text-bold"> 1 month </strong>
                                              </li>
                                              <li className="d-flex mt-4 justify-content-between align-items-center">
                                                  <span>Expires on</span>
                                                  <strong className="text-bold">{currentPlan.current_plan_expire} </strong>
                                              </li>
                                              </ul>
                                              <hr />
                                              <li className='card-title text-bold'><strong>Features</strong></li>
                                              <div className="v-card-body">
                                                  <ul className="v-listed-goodies">
                                                      <li className="v-each-goodie mt-2">
                                                          <span className="v-icon">
                                                                <CheckedIcon />
                                                          </span>
                          
                                                          <span className="v-goodie"> Max store count - {currentPlan.max_store_count}</span>
                                                      </li>
                                                      <li className="v-each-goodie mt-2">
                                                          <span className="v-icon">
                                                                 <CheckedIcon />
                                                          </span>
                          
                                                          <span className="v-goodie">{currentPlan.messaging_credit} messaging credits</span>
                                                      </li>
                                                      <li className="v-each-goodie mt-2">
                                                          <span className="v-icon">
                                                                <CheckedIcon />
                                                          </span>
                          
                                                          <span className="v-goodie">Marketing campaigns</span>
                                                      </li>
                                                      <li className="v-each-goodie mt-2">
                                                          <span className="v-icon">
                                                                <CheckedIcon />
                                                          </span>
                          
                                                          <span className="v-goodie"> {currentPlan.max_product} products listing</span>
                                                      </li>
                                                      <li className="v-each-goodie mt-2">
                                                          <span className="v-icon">
                                                               <CheckedIcon />
                                                          </span>
                                                          
                                                          <span className="v-goodie">&#8358;{currentPlan.limit_exceed_service_charge} service charge</span>
                                                      </li>
                                                  </ul>
                                              </div>
                                          </div>
                                          <div className='card-footer mt-2'>
                                              <Link style={{textDecoration:'underline'}} to={`/user/plans`}>Renew Plan</Link>
                                          </div>
                                          </div>
                                      }
                                          </React.Fragment>
                                            </>
                                        }
                                          
                                    </section>
                            </div>
						</div>
					</div>
				</div>
			</div>
		</div>

            </React.Fragment>
        </>
    )
}
export default Subscriptions
