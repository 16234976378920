import React, { useEffect, useState } from 'react'
import Nav, { Header } from '../../../components/dashboard/Nav'
import '../../../assets/css/products.css'
import { useDispatch, useSelector } from 'react-redux'
import { getUserData } from '../../../redux/action/user'
import { addProduct } from '../../../redux/action/products'
import { userShopData } from '../../../redux/action/shop'
import { setCurrentStore } from '../../../redux/action/shop'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Spinner } from 'react-bootstrap';
import { Axios, token } from '../../../api/axios'
import { pageUrl } from '../../../api/api'
import { Alert } from '@mui/material';
import { initProductData } from '../../../redux/reducer/productReducer'
function AddCollections(){
    const {shop} = useParams()
    const navigate = useNavigate()
    const {product,currentUser,shops,currentShop} = useSelector((state) => state)
    const [createState,setCreateState] = useState(null)
    const [isUploading,setIsUploading] = useState(false)
    const dispatch = useDispatch()
  

    const publishProduct = async () => {
        setCreateState(null)
        const newCollection = new FormData(document.getElementById('createCollectionForm'))
        setIsUploading(true)
        try {
            const res = await Axios({
                url:"api?addCollection",
                method:"POST",
                data:newCollection,
                headers:{
                    'authorization':'Bearer '+token()
                }
            })
            setCreateState({success:res.data.success,message:res.data.message,data:res.data})
            setIsUploading(false)
            res.data.success && dispatch(addProduct(initProductData))
        } catch (error) {
            setCreateState({success:false,message:error.message})
            setIsUploading(false)
        }
    }
    
    useEffect(()=>{
      !currentUser.data && dispatch(getUserData())
      !currentShop.data && dispatch(setCurrentStore(shop))
      !shops.data && dispatch(userShopData());
      dispatch(addProduct({shopId:currentShop.id}));

      return () =>{
        return setCreateState(null);
      }

    },[shops.loading,])
    return (
        shops.loading || currentShop.loading || currentUser.loading ?
    <>
    <div className='page-preloader'>
    <Spinner animation="border" >
    <>{shops.err && "Network failure,Please refresh"}</></Spinner>
</div></> :
        <>
            <Nav />
            <>
            <main className='main-wrap'>
                <Header />
                <React.Fragment>
                <section className="content-main" style={{maxWidth:1200}}>
<form method='POST' id='createCollectionForm' encType='multipart/form-data' onSubmit={(e) =>{
    e.preventDefault()
    publishProduct()
}}>
<div className="content-header">
    <h2 className="content-title">Create Collections</h2>
    <div>
        <button type='submit' disabled={isUploading} className="btn btn-primary">{isUploading? "Publishing...":"Publish now"}</button>
    </div>
</div>

<div className="row mb-4">
    <div className="col-xl-8 col-lg-8">
        <div className="card mb-2">
            <div className="card-body">
             
                {
          createState != null ?
          <>
          <Alert severity={createState.success ? 'success' :'error'}>
            {createState.message}
        </Alert>
          </>
          :""
    }
                    <div className="mb-4">
                        <label htmlFor="product_title" className="form-label">Title</label>
                        <input required type="text" placeholder="Type here" name='title' className="form-control" id="product_title"/>
                    </div>
            </div>
        </div>
        <div className="card mb-2">
            <div className="card-body">
                <div>
                    <label className="form-label">Description</label>
                    <textarea required placeholder="Type here" 
                    name='description' className="form-control" rows="4"></textarea>
                </div>
            </div>
        </div>
        <div className="card mb-4">
        <div className="card-body">
                <div>
                    <label className="form-label">Collection type</label>
                    <div className='container'>
                    <div className='d-flex'>
                        <input type='radio' name='type' id='type1' className='form-check' />
                        <label htmlFor='type1' className='m-1' >Manual</label>
                    </div>
                    <p>Add products to this collection one by one. Learn more about manual collections.</p>
                    </div>
                    <div className='container'>
                    <div className='d-flex'>
                        <input type='radio' name='type' id='type2' className='form-check' />
                        <label htmlFor='type2' className='m-1' >Automatic</label>
                    </div>
                    <p>Existing and future products that match the conditions you set will automatically be added to this collection. Learn more about automated collections.</p>
                    </div>
                </div>
            </div>
            <hr/>
            <div className="card-body">
                <div>
                    <label className="form-label">Conditions</label>
                </div>
                <div className='row'>
                    <div className='form-group col-lg-4'>
                    <select className='form-control' name='product_prop'>
                        <option value={'PRODUCTTITLE'}>Product title</option>
                        <option value={'TAG'}>Product tag</option>
                        <option value={'CATEGORY'}>Product category</option>
                        <option value={'PRICE'}>Product Price</option>
                    </select>
                    </div>
                    <div className='form-group col-lg-4'>
                        <select className='form-control' name='condition'>
                            <option value={'equal'}>Is equal to</option>
                            <option value={'less'}>Is less than</option>
                            <option value={'greater'}>Is greater than</option>
                        </select>
                    </div>
                    <div className='form-group col-lg-4'>
                    <input  className="form-control" type="text" name='value' placeholder='Enter value' />
                    </div>
                </div>
                <button type='button' className='btn btn-sm btn-primary mt-2'>Add more conditions</button>
                <hr />

            </div>
        </div>
    </div>
    <aside className="col-xl-4 col-lg-4">
        <div className="card mb-4">
            <div className="card-body">
                    <div className="mt-4">
                        <label className="form-label">Image</label>
                        <label htmlFor='collection-image' className=' w-sm-50 text-muted 
                         collection-image d-flex justify-content-center align-items-center' style={{
                            width: "100%",
                            border: "1px dotted grey",
                            padding: "50px 20px",
                            textAlign: "center",
                            borderRadius: 5,
                            cursor: "pointer",
                            fontSize:14
        
                        }}>
                            Click to select collections logo
                        </label>
                        <input type='file'  className='d-none' id='collection-image' name='file' />
                    </div>
                   
            </div>
        </div>
    </aside> 
</div> 
</form>

</section>


                </React.Fragment>
            </main>
            </>

        </>
    )
};

export default AddCollections
//You must be really trying hard enough to get everything stable - your studies, tech journey and other things