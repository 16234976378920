import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate,  useParams,useLocation } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton'
import { pageUrl } from '../../api/api'
import '../../assets/css/nav.css'
import { Menu,MenuItem,IconButton,Tooltip,Box,ListItemIcon } from '@mui/material';
import logo from '../../assets/images/logo.png'
import { changePreviewState, setThemePage } from '../../redux/action/themes-actions';
import { defaultThemePages } from '../shop/allthemes';
function CNav(){
  const {shop} = useParams();
  const currentShop = useSelector(state => state.currentShop)
  const minimizeOnDesktop = function(){
    if( window.innerWidth < 768) {
      document.querySelector('body').classList.remove('aside-mini');
      document.querySelector(".screen-overlay").classList.remove("show");
      document.querySelector(".navbar-aside").classList.remove("show");
        document.body.classList.remove("offcanvas-active");
      } 
      else {
        // minimize sideber on desktop
      document.querySelector('body').classList.toggle('aside-mini');
    }
  }

  useEffect(()=>{

  },[currentShop])

    return (
        <>
    <b className="screen-overlay"></b>
<aside className="navbar-aside" id="offcanvas_aside">
<div className="aside-top">
  <Link to={pageUrl(shop,'/')} className="brand-wrap">
    <img src={logo} height="46" className="logo" alt="Textng Shop" style={{width:150}} />
  </Link>
  <div>
    <button className="btn btn-icon btn-aside-minimize" onClick={minimizeOnDesktop}> <i className="text-muted material-icons md-menu_open"></i> </button>
  </div>
  </div>
<nav>
  <ul className="menu-aside cusTom" style={{width:"100%"}}>
    <li className="menu-item active"> 
      <Link className="menu-link" to="#"> <i className="icon material-icons md-home"></i> 
        <span className="text">Sections</span> 
      </Link> 
    </li>
    <li className="menu-item"> 
      <Link className="menu-link" to={"#"}> <i className="icon material-icons md-shopping_bag"></i>  
        <span className="text" >Theme Settings</span> 
      </Link> 
    </li>
  
    <hr/>
    <li className="menu-item"> 
      <Link className="menu-link" disabled to="/my-shops"> <i className="icon material-icons md-pie_chart"></i> 
        <span className="text">My Shops</span> 
      </Link> 
    </li>
  </ul>
  <br/>
  <br/>
</nav>
</aside>

    </>
    )
}

export default CNav

export function CHeader(){ 
  const {currentUser,themePage} = useSelector((state) => state)
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch()
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const darkMode = (btn_this) => {
  var body_el = document.body;
  if(body_el.classList.contains('dark')) {
      // Add darkmode 
    localStorage.removeItem("darkmode");
    body_el.classList.toggle("dark");
      btn_this.classList.remove('active');

  } else {
      // remove darkmode 
    localStorage.setItem("darkmode", "active");
    body_el.classList.toggle("dark");
      btn_this.className += ' active'; 
  }
}
const minimizeOnDesktop = function(e){
  e.preventDefault();
  e.stopPropagation();
  var offcanvas_id =  '#offcanvas_aside';
  document.querySelector(offcanvas_id).classList.toggle("show");
  document.querySelector('body').classList.toggle("offcanvas-active");
  document.querySelector(".screen-overlay").classList.toggle("show");
}
    return (
        <>
        <header className="main-header navbar">
		<div className="col-search">
            <select className='form-control col-8 align-self-center m-auto' onChange={(e)=>{
              dispatch(setThemePage('DEFAULT',e.target.value))
              console.log(themePage);
            }}>
                <option value={defaultThemePages.LANDING} >Home Page</option>
                <option value={defaultThemePages.SHOP} >Products</option>
                <option >Checkout</option>
                <option >Collections</option>
            </select>
            
		</div>
		<div className="col-nav">
     <button className="btn btn-icon btn-mobile me-auto" onClick={e=> {
      minimizeOnDesktop(e)
      }} data-trigger="#offcanvas_aside"> <i className="md-28 material-icons md-menu"></i> </button>
     <ul className="nav">
     <li className="nav-item">
        <Tooltip title="Preview mode">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <i className='fa fa-eye'></i>
          </IconButton>
        </Tooltip>
      </li>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem style={{fontWeight:400}} onClick={()=>{
          dispatch(changePreviewState(1))
        }}> 
          <ListItemIcon >
          <i className="fa fa-desktop"></i>
          </ListItemIcon>
          Desktop
        </MenuItem>
        <MenuItem style={{fontWeight:400}} onClick={()=>{
          dispatch(changePreviewState(2))
        }} >
          <ListItemIcon>
          <i className="fa fa-tablet"></i>
          </ListItemIcon>
          Tablets
        </MenuItem>
        <MenuItem style={{fontWeight:400}} onClick={()=>{
          dispatch(changePreviewState(3))
        }}>
          <ListItemIcon>
          <i className="fa fa-mobile"></i>
          </ListItemIcon>
          Mobile
        </MenuItem>
      </Menu>
      <li className="nav-item mx-2" onClick={(e)=> darkMode(e.target)}>
          <Link className="nav-link btn-icon" title="Dark mode" to="#"> <i className="material-icons md-nights_stay"></i> </Link>
      </li>
      <li className="nav-item mx-2">
        <Link className="nav-link btn btn-secondary" to="#"> Save</Link>
      </li>
    </ul> 
  </div>
	</header>
        </>
    )
}

