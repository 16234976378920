import React, { useEffect } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom';
import '../../../assets/css/shop.css'
import '../../../assets/css/stores.css'
import { connect, useDispatch, useSelector } from 'react-redux';
import { setCurrentStore, userShopData } from '../../../redux/action/shop';
import { fetchShopProducts } from '../../../redux/action/products';
import { Spinner } from 'react-bootstrap';
import { Alert } from '@mui/material';
import { getUserData } from '../../../redux/action/user';
import { deactivateShop, activateShop, getTransactions } from '../../../api/api';
import Swal from 'sweetalert2';
import { transactions as setUserTransactions } from '../../../redux/slice/transactionSlice'
import { orders } from '../../../redux/slice/orderSlice';
import Cookie from 'react-cookies'
import VerificationMessage from '../../auth/VerificationMessage';
import ProfileNav from '../profile/ProfileNav';
function MyShops({shops,userShopData}){
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const currentUser = useSelector(({currentUser}) => currentUser)
    useEffect(()=>{
       userShopData();
       !currentUser && dispatch(getUserData())
       
    },[])
    const darkMode = (btn_this) => {
        var body_el = document.body;
        if(body_el.classList.contains('dark')) {
            // Add darkmode 
          localStorage.removeItem("darkmode");
          body_el.classList.toggle("dark");
            btn_this.classList.remove('active');
      
        } else {
            // remove darkmode 
          localStorage.setItem("darkmode", "active");
          body_el.classList.toggle("dark");
            btn_this.className += ' active'; 
        }
      }
      const fetchTransactions = async (shopId) => {
        const response = await getTransactions({shopId})
        // console.log(response);
        if(response.success){
            dispatch(setUserTransactions(response.data));
        }else{
          dispatch(setUserTransactions(''));
        }

    }
      const handleDeactivateeShop = async (shopName, shopId) => {
        Swal.fire({
          html: `<h5 className="text-danger">Deactivate ${shopName}?</h5>`,
          showCancelButton: true,
          confirmButtonText: 'Deactivate',
          confirmButtonColor: 'red',
          position: 'center',
          icon: 'question'
        }).then( async (response) => {
          if (response.isConfirmed) {
              
                  const res = await deactivateShop({shopId});
                  if(res.success){
                    Swal.fire({
                      text: res.message,
                      icon: 'success',
                      timer: 5000,
                      toast: true,
                      showConfirmButton: false,
                      position: 'top-end'
                  });
                  userShopData();
                  }else{
                    Swal.fire({
                      text: res.message,
                      icon: 'error',
                      timer: 5000,
                      toast: true,
                      showConfirmButton: false,
                      position: 'top-end'
                  });
                  }
                  
          } 
        })

      }
      const handleActivateeShop = async (shopName, shopId) => {
        Swal.fire({
          html: `<h5> Activate ${shopName}?</h5>`,
          showCancelButton: true,
          confirmButtonText: 'Activate',
          confirmButtonColor: '#1BBC9B',
          position: 'center',
          icon: 'question'
        }).then( async (response) => {
          if (response.isConfirmed) {
              
                  const res = await activateShop({shopId});
                  if(res.success){
                    Swal.fire({
                      text: res.message,
                      icon: 'success',
                      timer: 5000,
                      toast: true,
                      showConfirmButton: false,
                      position: 'top-end'
                  });
                  userShopData();
                  }else{
                    Swal.fire({
                      text: res.message,
                      icon: 'error',
                      timer: 5000,
                      toast: true,
                      showConfirmButton: false,
                      position: 'top-end'
                  });
                  }
                  
          } 
        })

      }
return (
    shops.loading || currentUser.loading ?
    <>
    <div className='page-preloader'>
    <Spinner animation="border" >
    <>{shops.err && "Network failure,Please refresh"}</></Spinner>
</div>
</> :
    <>
      {
        currentUser && currentUser.email_verified && currentUser.email_verified == 1 ? 
        <>
          {
            <>
                      <>
    <b className="screen-overlay"></b>
    <React.Fragment>
    <div className="">
			<div className="v-body-container">
				<div className="v-h-parent-grid">
					<>
          <ProfileNav page={"STORES"}/>
          </>
					<div className="p-0 flex-grow-1">
						<div className="v-main-brands-board">
							<div className="v-brand-board-title d-flex align-items-center justify-content-between">
								<h3 className="v-title">My Stores</h3>
                <div className="dropdown" style={{margin:20}}>
        <a href="#" data-bs-toggle="dropdown" className="btn btn-light"> <i className="material-icons md-account_circle"></i> </a>
        <div className="dropdown-menu" style={{minWidth:200}}>
          <div className='w-100 px-2 d-flex justify-content-center align-items-center flex-column'>
            <h5 style={{fontSize:15}}>{currentUser && currentUser.name}</h5>
            <small style={{fontSize:13}}>{ currentUser && currentUser.email}</small>
          </div>
          <ul className='mt-2' style={{listStyle:"none"}}>
            <li><Link className="dropdown-item" to={'/profile'}>View Profile </Link></li>
            <li onClick={()=>{
              Cookie.remove('token',{maxAge:-1,expires:-1});
              setTimeout(() => {
                navigate("/")
              }, 2000)  
            }}><Link className="dropdown-item text-danger" >Logout</Link></li>
          </ul>
        </div>
                </div>
							</div>
              <Link to='/create-shop' className="v-create-new-store"> Create new store </Link>
							<div className="v-grid-container mt-3 mt-lg-5">
              {
                  shops.shops.length > 0?
                  <>
                    {
                      shops.shops.sort(function(a,b){return a - b}).map((shop,i)=>{
                        return (
                          <div className="v-brand" key={shop.id} >
									<div className="v-brand-img-container" >
										<img src={shop.image} alt="" className="img-fluid" />
									</div>
									<div className="v-brand-detail" style={{cursor:"pointer"}} >
										<div onClick={(e) =>{
                              if(shop.status === '1'){
                                e.preventDefault()
                                localStorage.removeItem("shopId")
                                localStorage.setItem('shopId', shop.id);
                                dispatch(setCurrentStore(shop.name))
                                && dispatch(fetchShopProducts(shop.id))
                                dispatch(orders(null))
                                // && setShopProducts(shop.id)
                                fetchTransactions(shop.id);
                                navigate(`/shop/${shop.name}/dashboard/`)
                             }
                             if (shop.status == 2) {
                              Swal.fire({
                                toast:true,
                                text:"Your store has been disabled by ShopAfrica Admin. Reach out on how to deactivate your store",
                                icon:'warning',
                                position:"top-right",
                                showConfirmButton:false,
                                timer:5000
                              });
                             }
                          }}>
                    <h5 className="v-brand-name">{shop.name}</h5>
										<span className="v-brand-description">{shop.description}</span>
                    </div>
										<div className="v-brand-detail-footer justify-content-end mt-4">
                    <>
                            {//active
                              shop.status == 1 &&
                              <button className={`btn btn-sm ${'btn-outline-danger'}`} onClick={() => handleDeactivateeShop(shop.name, shop.id)}> 
                                        {<i className="material-icons md-delete_forever"></i>}
                                        Deactivate 
                            </button>
                            }
                            {//user deactivate
                              shop.status == 0 &&
                              <button className={`btn btn-sm ${'btn-outline-secondary'}`} onClick={() => handleActivateeShop(shop.name, shop.id)}> 
                                        Activate 
                            </button>
                            }
                            {//admin deactivate
                              shop.status == 2 &&
                        <span className="badge rounded-pill alert-secondary">Admin Disabled</span>
                            }
                            {//admin deactivate
                              shop.status == 3 &&
                              <span className="badge rounded-pill alert-secondary">Subscription Expired</span>
                            }
                          </>
										</div>
									</div>
								          </div>

                         
                        )
                      })
                    }
                  </>:
                  <Alert severity='warning'>You do not have any store yet!</Alert>
                }
								
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

    </React.Fragment>
        </>
            </>
          }
        </>
        :
        <>
        <VerificationMessage needverification = {true} message={"Account Verification required"} data={{message:`Your email address, ${currentUser.email} needs to be verified. Kindly click on the link sent to your email to verify your account.`}} />
        </>
      }
    </>
)
}

const mapStateToProps = ({shops,currentUser}) => ({
  shops,currentUser
})

export default connect(mapStateToProps,{userShopData})(MyShops)
// connect()