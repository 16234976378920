import React, {  } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Alert } from '@mui/material';
import sampleProducts from '../../assets/file/sample_products.csv'
export default function ImportProducts({show,handleClose}){

  return (
    <>
 
      <Modal show={show} onHide={handleClose} 
      
      aria-labelledby="contained-modal-title-vcenter"
      centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Import products</Modal.Title>
        </Modal.Header>
        <Modal.Body>
              <Alert severity='info'>
                File must be in csv/excel format
              </Alert>
            
          <Form>
            <a type='button' href={sampleProducts} download className='mt-2 btn btn-sm btn-primary text-white'>
                Click to download file sample
            </a>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Products file</Form.Label>
              <Form.Control
                type="file"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" >
            Upload
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}