import { useEffect } from "react";
import { Navigate,Outlet } from "react-router-dom";
import { token } from "../api/axios";
import { useDispatch, useSelector } from "react-redux";
import VerificationMessage from "../pages/auth/VerificationMessage";
import { getUserData } from "../redux/action/user";


const Protected = (props) =>{
    const currentUser = useSelector(({currentUser}) => currentUser)
    const userToken = token()
    const dispatch = useDispatch()
    useEffect(()=>{
        !currentUser.data && dispatch(getUserData())
    },[userToken])
    return (
       token() ?  
       <>
            <Outlet />
       </>
       : <Navigate to={'/login'} replace={true} />
    )
}
export default Protected;
