import React, { useEffect } from 'react'
import '../../assets/css/style.css';
import Whatweoffer from '../../components/landing/Whatweoffer';
import MainFooter from '../../components/MainFooter';
import MainNav from '../../components/MainNav';
function Index(){
    return(
        <>
        <MainNav />
        <>
        <section className="about py-md-5 py-5">
        <div className="container-fluid">
            <div className="feature-grids row px-3">
                <div className="col-lg-3 gd-bottom">
                    <div className="bottom-gd row bg-white">
                        <div className="icon-gd col-md-3 text-center"><span className="fa fa-gift" aria-hidden="true"></span></div>
                        <div className="icon-gd-info col-md-9">
                            <h3 className="mb-2">SELL PHYSICAL PRODUCTS</h3>
                            <p>From Handcrafted goods to tech gadgets, sell anything with ease.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 gd-bottom">
                    <div className="bottom-gd row bg-white">
                        <div className="icon-gd col-md-3 text-center"><span className="fa fa-handshake-o" aria-hidden="true"></span></div>
                        <div className="icon-gd-info col-md-9">
                            <h3 className="mb-2">FREE Trial</h3>
                            <p>Enjoy a risk-free 14 days subscription to kickstart your journey</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 gd-bottom">
                    <div className="bottom-gd row bg-white">
                        <div className="icon-gd col-md-3 text-center"> <span className="fa fa-truck" aria-hidden="true"></span></div>

                        <div className="icon-gd-info col-md-9">
                            <h3 className="mb-2">DELIVERY CONFIGURATIONS</h3>
                            <p> We've got your back with useful shipping configurations.</p>
                        </div>

                    </div>
                </div>
                <div className="col-lg-3 gd-bottom">
                    <div className="bottom-gd row bg-white">
                        <div className="icon-gd col-md-3 text-center"> <span className="fa fa-headphones" aria-hidden="true"></span></div>
                        <div className="icon-gd-info col-md-9">
                            <h3 className="mb-2">24/7 CUSTOMER SERVICE</h3>
                            <p>Our 24/7 customer support team ensures you never face a hurdle alone</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
        </>
        <Whatweoffer />
        <MainFooter />
        </>
    )
}

export default Index