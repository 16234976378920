import { Axios, token } from "../../api/axios"
import { ADD_PRODUCT,
    FETCH_SHOP_PRODUCTS_DATA
,FETCH_SHOP_PRODUCTS_LOADING,FETCH_SHOP_PRODUCTS_FAILED, LOADING_SINGLE_PRODUCT, SINGLE_PRODUCT_LOADED_SUCCESS, SINGLE_PRODUCT_LOADED_FAILED, GET_PRODUCT_CATEGORIES
} from "./types"


export const addProduct = (payload) => {
    return {
        type:ADD_PRODUCT,
        payload
    }
}

export const fetchShopProducts = (shopId) => dispatch => {
    dispatch({type:FETCH_SHOP_PRODUCTS_LOADING})
    return Axios({
        method:"POST",
        url:"api?getProducts",
        data:{shopId},
    }).then(products => {
        dispatch({
        type:FETCH_SHOP_PRODUCTS_DATA,
        payload:{...products.data,products:products.data.data,data:true}
    })
    })
    .catch(err=> {
        return dispatch({type:FETCH_SHOP_PRODUCTS_FAILED,payload:{err:err.response.status,data:false}})
    })
}

export const getProductById = (productId,shopId) => dispatch =>{
     dispatch({type:LOADING_SINGLE_PRODUCT})

        return Axios({
            method:"POST",
            url:"api?getProduct",
            data:{productId,shopId},
            headers:{
                "Authorization":"Bearer "+ token()
            },
        }).then(product=>{
            dispatch({
                type:SINGLE_PRODUCT_LOADED_SUCCESS,
                payload:{product:product.data.data,
                    images:product.data.data.images,data:true}
            })
        }).catch(err=>{
            dispatch({
                type:SINGLE_PRODUCT_LOADED_FAILED,
                payload:{err:err.response,data:false}
            })
        })
        

}

export const getProductCategories = () => dispatch => {
    return Axios({
        method:"GET",
        url:"api?getCategories",
        headers:{
            "Authorization":"Bearer "+ token()
        },
    }).then(category=>{
        dispatch({
            type:GET_PRODUCT_CATEGORIES,
            payload:{...category.data,data:true}
        })
    }).catch(err=>{
        
    })
}

export const getFashions =  () => async dispatch => {
    return await Axios({
        method:"GET",
        url:"api?getFashions",
    }).then(fashions=>{
        dispatch({
            
        })
    }).catch(err=>{
        
    })
}