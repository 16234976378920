import React from 'react'
import { Spinner, Table } from 'react-bootstrap';
import Nav, { Header } from '../../../components/dashboard/Nav'
import { updateCategory as updateCategoryDetails, pageUrl } from '../../../api/api';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
const EditCategory = () => {
    const {shop} = useParams();
    const navigate = useNavigate();
    const [ready, setReady] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState(null);
    const [shopId, setShopId] = React.useState(localStorage.getItem('shopId'));
    const updateData = (e) => {
        let key = e.target.name;
        let value = e.target.value;
        setData({
            ...data, [key]: value
        });
        console.log(data);
    }

    const updateCategory = async (e) => {
     
        delete data.date_created;
        delete data.date_update;
        setLoading(true);
        const response = await updateCategoryDetails(data);
        console.log(response);
        if(response.success){
            Swal.fire({
                text: response.message,
                icon: 'success',
                timer: 3000,
                toast: true,
                showConfirmButton: false,
                position: 'top-end'
            });
            navigate(pageUrl(shop, '/categories'));
        }else{
            Swal.fire({
                text: response.message,
                icon: 'error',
                timer: 3000,
                toast: true,
                showConfirmButton: false,
                position: 'top-end'
            });
        }
        setLoading(false);
    }
    
    React.useEffect(() => {
        console.log(data)
       
    }, [data])
    React.useEffect(() => {
        setData(JSON.parse(localStorage.getItem('category')));
    }, [])
    return (
                !ready ?
            <>
            <div className='page-preloader'>
            <Spinner animation="border" >
            {/* <>{shops.err && "Network failure,Please refresh"}</> */}
            </Spinner>
        </div></> 
        : data &&
                <>
        <Nav activeState={''}/>
        <>
        <main className='main-wrap'>
        <Header />
        <React.Fragment>
        <section className="content-main" style={{maxWidth:1200}}>
        <div className="content-header">
            <h2 className="content-title"> Categories</h2>
            <div>
               {/* <h3>
                Existing Categories
               </h3> */}
            </div>
        </div>

        <div className="row mb-4">
            <div className="col-xl-6 col-lg-6">
                <div className="card mb-4">
                    <h5 className='ms-4 mt-2'> Add New Category </h5>
                    <div className="card-body">
                        <label className='t-label' for='title'> Category </label>
                        <input
                        onChange={updateData}
                        name='title'
                        value={data.title}
                        className='form-control'
                        type={'text'}
                        placeholder="Enter Category name"
                        />
                        <div className='row'>
                            <div className='col-xl-6 col-lg-6'>
                                <label className='t-label' for='main'> Main </label>
                                <select className='form-control'
                                // onChange={updateData}
                                >
                                    <option>
                                        Default
                                    </option>
                                </select>
                            </div>
                            <div className='col-xl-6 col-lg-6'>
                                <label className='t-label' for='iconName'> Category Icon </label>
                                <input
                                value={data.icon}
                                name='icon'
                                onChange={updateData}
                                className='form-control'
                                type={'text'}
                                placeholder="Enter Icon name"
                                />
                            </div>
                        </div>
                        <label className='t-label' for='description'> Description </label>
                        <textarea
                        onChange={updateData}
                        value={data.description}
                        name='description'
                        className='form-control' 
                        id="" 
                        cols="30" 
                        rows="5" />
                        
                        <label className='t-label' for='title'> Display Type </label>
                        <select
                        value={data.displayType}
                        name='displayType'
                        onChange={updateData}
                        className='form-control'>
                                <option>
                                    Default
                                </option>
                                <option>
                                    Product
                                </option>
                                <option>
                                    Sub-category
                                </option>
                                <option>
                                    Both
                                </option>
                        </select>

                        <button
                        className='form-control t-btn mt-4'
                        onClick={(e) =>updateCategory(e)}
                        disabled={loading}
                        > 
                            {loading? <Spinner /> : 'Update Category'}
                        </button>
                    </div>
                </div>
            </div>
        </div> 
        </section>


        </React.Fragment>
    </main>
    </>

</>
    )
}

export default EditCategory