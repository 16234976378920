import React, { useEffect } from 'react'
import Nav, { Header } from '../../../components/dashboard/Nav'
import ProductsContent from './ProductsContent'
import '../../../assets/css/products.css'
import { useSelector,useDispatch } from 'react-redux'
import { getUserData } from '../../../redux/action/user'
import { setCurrentStore } from '../../../redux/action/shop'
import { useParams } from 'react-router-dom'
import { fetchShopProducts } from '../../../redux/action/products'
import NeedHelp from '../../../components/dashboard/NeedHelp'
function Products(){
    const {shop} = useParams()
    const {currentShop,currentUser,currentShopProducts} = useSelector(state => state)
    const dispatch = useDispatch()
    useEffect(()=>{
        !currentUser.data && dispatch(getUserData())
        !currentShop.data && dispatch(setCurrentStore(shop))

        return ()=> {
            return null
        }
    },[currentUser.loading]);
    return (
        <>
            <Nav activeState={'products'}/>
            <>
            <main className='main-wrap'>
                <Header />
                <React.Fragment>
                    <ProductsContent />
                </React.Fragment>
            </main>
            </>
<NeedHelp />
        </>
    )
}

export default Products