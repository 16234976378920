import { useState } from "react"
import { Toast } from "react-bootstrap"


export function ToastAlert({show,setShow,position,status,message}) {
    
    return (
        <>
            <Toast onClose={setShow} position={position} show={show} delay={10000}
            bg={status ===true? 'success':'danger'}
            autohide>
          <Toast.Header >
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto"></strong>
          </Toast.Header>
          <Toast.Body className='text-white'>{message}</Toast.Body>
        </Toast>
        </>
    )
}