import React, {useState} from 'react'
import Nav, { Header } from '../../../components/dashboard/Nav';
import { Spinner } from 'react-bootstrap';

const EditOrders = () => {
    const [loading, setLoading] = useState(false);

    const [data, setData] = React.useState();
    const [shopId, setShopId] = React.useState(localStorage.getItem('shopId'));
    const [categories, setCategories] = React.useState(null);
    const updateData = (e) => {
        let key = e.target.name;
        let value = e.target.value;
        setData({
            ...data, [key]: value
        });
        console.log(data);
    }
  return (
    <div>
        <Nav activeState={''}/>
            <>
            <main className='main-wrap'>
                <Header />
                <>
                <section className="content-main" style={{maxWidth:1200}}>
                    <div className="content-header">
                        <h2 className="content-title"> Update Order </h2>
                        
                    </div>

                    <div className="row mb-4">
                        <div className='card'>
                            <div className="card mb-4">
                                <h5 className='ms-4 mt-2'> Update Order  </h5>
                                <div className="card-body">
                                    <label className='t-label' for='title'> Category </label>
                                    <input
                                    onChange={updateData}
                                    name='title'
                                    // value={data.title}
                                    className='form-control'
                                    type={'text'}
                                    placeholder="Enter Category name"
                                    />
                                    <div className='row'>
                                        <div className='col-xl-6 col-lg-6'>
                                            <label className='t-label' for='main'> Main </label>
                                            <select className='form-control'
                                            // onChange={updateData}
                                            >
                                                <option>
                                                    Default
                                                </option>
                                            </select>
                                        </div>
                                        <div className='col-xl-6 col-lg-6'>
                                            <label className='t-label' for='iconName'> Category Icon </label>
                                            <input
                                            // value={data.icon}
                                            name='icon'
                                            onChange={updateData}
                                            className='form-control'
                                            type={'text'}
                                            placeholder="Enter Icon name"
                                            />
                                        </div>
                                    </div>
                                    <label className='t-label' for='description'> Description </label>
                                    <textarea
                                    onChange={updateData}
                                    // value={data.description}
                                    name='description'
                                    className='form-control' 
                                    id="" 
                                    cols="30" 
                                    rows="5" />
                                    
                                    <label className='t-label' for='title'> Display Type </label>
                                    <select
                                    // value={data.displayType}
                                    name='displayType'
                                    onChange={updateData}
                                    className='form-control'>
                                            <option>
                                                Default
                                            </option>
                                            <option>
                                                Product
                                            </option>
                                            <option>
                                                Sub-category
                                            </option>
                                            <option>
                                                Both
                                            </option>
                                    </select>

                                    <button
                                    className='form-control t-btn mt-4'
                                    // onClick={(e) =>updateCategory(e)}
                                    // disabled={loading}
                                    > 
                                        {loading? <Spinner /> : 'Update Order'}
                                    </button>
                                </div>
                            </div>
                        </div>
                        
                    </div> 
                    </section> 
                </>
            </main>
            </>
    </div>
  )
}

export default EditOrders
