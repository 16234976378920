import React, { useEffect,useState } from 'react'
import Nav, { Header } from '../../../components/dashboard/Nav'
import '../../../assets/css/products.css'
import { useSelector,useDispatch } from 'react-redux'
import { getUserData } from '../../../redux/action/user'
import { setCurrentStore } from '../../../redux/action/shop'
import { useNavigate, useParams, useSearchParams,Link } from 'react-router-dom'
import { pageUrl } from '../../../api/api'
import { Axios } from '../../../api/axios'
import { Alert } from '@mui/material';
import { fetchShopProducts, getProductById } from '../../../redux/action/products'
function  AddMoreProductDetails(){
    const {shop} = useParams()
    const [searchParam,_] = useSearchParams()
    const navigate  = useNavigate()
    const [createState,setCreateState] = useState(null)
    const productId = searchParam.get('productId')
    const {currentShop,currentUser} = useSelector(state => state)
    const [isUploading,setIsUploading] = useState(false)
    const dispatch = useDispatch()
    const [uploadedImages,setUploadedImages] = useState([])
    const submitProductImages = async e => {
        e.preventDefault()
        setCreateState(null)
        setIsUploading(true)
        const data = new FormData(e.currentTarget)
        // console.log(data);
        try {
            const res = await Axios({
                url:"api?productImages",
                method:"POST",
                data
            })
            setCreateState({success:res.data.success,message:res.data.message})
            setUploadedImages(res.data.urls)
        setIsUploading(false)
        res.data.success && dispatch(fetchShopProducts(currentShop.id))
        document.querySelectorAll("input").forEach(input=>{
            input.value=""
        })
        } catch (error) {
            setCreateState({success:false,message:error.message})
            setIsUploading(false)
        }
    }
    useEffect(()=>{
        !productId && navigate(pageUrl(shop,'/add-product'));
        !currentUser.data && dispatch(getUserData())
        !currentShop.data && dispatch(setCurrentStore(shop))
        return ()=> {
            return null
        }
    },[currentUser.loading,currentShop.loading]);
    return (
        <>
            {productId && <Nav activeState={''}/>}
            <>
            <main className='main-wrap'>
                {productId && <Header />}
                <React.Fragment>
            <section className="content-main">
            <div className="content-header">
                <h2 className="content-title">Add Product Images</h2>
            </div>

            <div className="row mb-4">
                <div className="col-xl-8 col-lg-8"><div>
        
      </div>
        <div className="card mb-4 mx-auto">
            <div className="card-body">
            {
          createState != null ?
          <>
          <Alert severity={createState.success ? 'success' :'error'}>{createState.message}! <>
            {
                createState.success && <Link className='text-primary' to={pageUrl(shop,'/products')} style={{cursor:'pointer'}} onClick={()=>{
                    dispatch(getProductById(productId))
            }}>View</Link>
            }
          </></Alert>
          </>
          :""
            }
            <form 
            method='POST' 
            onSubmit={(e)=> {
                submitProductImages(e)
            }}
            encType="multipart/form-data"
            id='productImages'
            >
                    <div className="mb-4">
                        <label htmlFor="product_title" className="form-label">Product images</label>
                        <input type={'hidden'} name='productId' value={productId || localStorage.getItem('productId')} />
                        <input type="file" placeholder="Type here" name='file []' multiple className="form-control" id="product_title"/>
                    </div>
                    <div className="mb-4">
                        <button type='submit' className='btn t-btn' disabled={isUploading}>{isUploading ? "Uploading..." :"Add images"}</button>
                    </div>
            </form>
            </div>

        </div>
                </div>
    
            </div>
            </section>
            
                </React.Fragment>
            </main>
            </>

        </>
    )
}

export default AddMoreProductDetails