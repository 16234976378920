import React, { useEffect } from 'react'
import Nav, { Header } from '../../../components/dashboard/Nav'
import DraftContents from './DraftContents'
import '../../../assets/css/products.css'
import { useSelector,useDispatch } from 'react-redux'
import { getUserData } from '../../../redux/action/user'
import { setCurrentStore } from '../../../redux/action/shop'
import { useParams } from 'react-router-dom'
import NeedHelp from '../../../components/dashboard/NeedHelp'
function Drafts(){
    const {shop} = useParams()
    const {currentShop,currentUser,currentShopProducts} = useSelector(state => state)
    const dispatch = useDispatch()
    useEffect(()=>{
        !currentUser.data && dispatch(getUserData())
        !currentShop.data && dispatch(setCurrentStore(shop))
        return ()=> {
            return null
        }
    },[currentUser.loading,currentShop.id]);
    return (
        <>
            <Nav activeState={'drafts'}/>
            <>
            <main className='main-wrap'>
                <Header />
                <React.Fragment>
                        <DraftContents />
                </React.Fragment>
            </main>
            </>
        <>
        <NeedHelp />    
        </>
        </>
    )
}

export default Drafts