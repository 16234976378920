import { token,Axios } from "../../api/axios";
import { themes } from "../../components/shop/allthemes";
import { defaultThemeComponentDOMSetup } from "../../components/shop/theme-constants";
import { CHANGE_THEME_PREVIEW_STATE, FETCH_AVAILABLE_THEMES, FETCH_AVAILABLE_THEMES_FAILED, FETCH_AVAILABLE_THEMES_SUCESS, SET_DEFAULT_THEME_COMPONENT, SET_THEME_PAGE } from "./types";

export const getAvailableThemes = () => dispatch =>{
    dispatch({
        type:FETCH_AVAILABLE_THEMES,
    })
    return Axios.get("api?getThemes",{
        headers: {
            'Authorization': 'Bearer ' + token()
        }
    }).then(res => {
        const {themes} = res.data
        dispatch({
        type:FETCH_AVAILABLE_THEMES_SUCESS,
        payload: {themes:themes,data:true},
    })
    })
    .catch(err=> {
        return dispatch({type:FETCH_AVAILABLE_THEMES_FAILED,payload:{err:err.response.status,data:false,shops:[]}})
    })
}

//THEME 1
export const setDefaultThemeChanges = (component,section,key,value) =>{
    return {
        type:SET_DEFAULT_THEME_COMPONENT,
        payload:{
            component,section,key,value
        }
    }
}
export const changePreviewState = (state) => {
    return {
        type:CHANGE_THEME_PREVIEW_STATE,
        payload:state
    }
}

export const setThemePage = (theme,page) => {
    //coming back to this function...
    
    return {
        type:SET_THEME_PAGE,
        payload:page
    }
}