import { Alert } from '@mui/material'
import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link ,useNavigate,useParams} from 'react-router-dom'
import { pageUrl, getTransactions, createKey } from '../../../api/api'
import  item1 from "../../../assets/images/items/1.jpg"
import { addProduct, fetchShopProducts, getProductById } from '../../../redux/action/products'
import  Carousel  from 'react-bootstrap/Carousel'
import { Axios } from '../../../api/axios'
import { Spinner, Button } from 'react-bootstrap';
import ImportProducts from '../../../components/dashboard/ImportProducts'
import { transactions as setUserTransactions } from '../../../redux/slice/transactionSlice'
import { Receipt } from '@mui/icons-material'
import MyTable from '../../../components/Table'
import { fetchTransactionRecords } from '../../../api/api'
function Transactions(){
    const {shop} = useParams()
    const shopId = localStorage.getItem('shopId');
    const navigate = useNavigate()
    const {currentShop,currentUser,currentShopProducts, transactionData} = useSelector(state => state)
    const [deleteState,setDelState] = useState(null)
    const [showImportModal,handleImportModal] = useState(false)
    const dispatch = useDispatch()
    const [transactions, setTransactions] = useState(transactionData.transactions);
    const receipt = useMemo(() => transactionData.transaction, [transactionData.transaction]);
    
    const fetchTransactions = async () => {
        const response = await fetchTransactionRecords({shopId})
        if(response.success){
            dispatch(setUserTransactions(response.data));
        }
    }

    const columns = [
        // {
        // accessor: `transaction_id`,
        // Header: 'Ref',
        // },
        {
          accessor: `id`,
          Header: '#ID',
          },
          {
            accessor: `name`,
            Header: 'Customer Name',
            },
         {
        accessor: 'email',
        Header: 'Email',
        },
        {
        accessor: 'total_quantity',
        Header: 'Quantity',
        },
        {
            accessor: `amount`,
            Header: 'Amount',
            },
        {
          accessor: 'created_at',
          Header: 'Date',
          },
        {
        Header: "Action",
        accessor: () => <Button  className=" btn btn-sm btn-light"> Details </Button>,
        }
    
      ]
        const data = useMemo(() => transactions, [transactions]);
    
    useEffect(()=>{
        setTransactions(transactionData.transactions);
        // setReceipt(transactionData.transaction);
        // setReceipt(transactionData.transactions[0]);
        return ()=>{
            return null
        }
    },[currentShop.id, transactionData.transactions])
    
    useEffect(() => {
        !transactionData.transactions && fetchTransactions();
        
    }, [])

    return (
        currentShopProducts.loading ?
        <>
            <div className='page-preloader' style={{width:"max-content"}}>
    <Spinner animation="border" >
    <>{currentUser.err && "Network failure,Please refresh"}</></Spinner>
        </div>
        </>
        :
        <React.Fragment>
            <>
                <ImportProducts
                    show={showImportModal}
                    handleClose={()=>handleImportModal(false)}
                />
            </>
            <section className="content-main">
	
    <div className="content-header">
        <h2 className="content-title">Transactions</h2>
        
    </div>
    
    <div className="card mb-4">
    <div className="card-body">
    <div className="row">
                <div className="col-lg-8">
                    
                        <div className="container">
                        {
                           transactions ? 
                           <MyTable columns={columns} body={data} path="transactions" />
                           : 
                           <Alert className='mt-1 mb-1' severity='warning' style={{
                            display:'flex',justifyContent:"center",alignItems:"center",
                        }}><h5 className='text-center text-muted'> You do not have any transaction record </h5> </Alert>
                           
                        }
                        </div>
                </div>
                {
                    data && 
                    <aside className="col-lg-4">
                    <div className="box bg-light" style={{minHeight:"80%"}}>
                        <h6>Transaction Details</h6>
                        <hr/>
                        {
                        receipt ? 
                        <>
                        <h6>Customer Name:</h6><p>{`${receipt.firstname} ${receipt.lastname}`}</p>
                        <h6 className="mb-0">Email:</h6> <p>{receipt.email}</p>
                        <h6 className="mb-0">Date:</h6> <p>{receipt.created_at}</p>
                        <h6 className="mb-0">Billing address</h6> <p>{`${receipt.address}. ${receipt.town}`}</p>
                        <h6 className="mb-0">Reference: </h6> <p>{receipt.reference}</p>
                        <h6 className="mb-0"> Total quantity:</h6><p>{receipt.total_quantity} items</p>
                        <h6 className="mb-0">Amount Payed: <p>{receipt.amount}</p></h6>
                        <h6 className='mb-0'>Items Purchased: </h6>
                        <>
                        <p>
                        {
                            receipt.products && receipt.products.length > 0 &&
                            receipt.products.map(function(product){
                                return (
                                    <div key={createKey()}>
                                <Link to={`/shop/${shop}/dashboard/products/${product.id}`}
                                onClick={(e)=>{
                                    dispatch(getProductById(product.id,shopId))
                                    //fetchProduct({shopId, productId: product.id});]
                                }}
                                >{product.title} <i class="icons material-icons md-launch"></i></Link> <i class="fa fa-times"></i><b>{product.quantity}</b><br/>
                                </div>
                                )
                                
                            })
                        }
                        </p>
                        </>
                        <hr/>
                        <a className="btn btn-light d-none" href="#"> Download receipt </a>
                        </> :
                        <><p className="text-center text-muted my-5">Please select transaction <br/> to see details</p></>
                        }
                     
                    </div>
                </aside>
                }
            </div>
    
    </div>
    </div> 
    
    
    
    </section>
        </React.Fragment>
    )
}

export default Transactions