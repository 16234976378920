import {LOADING_USER_DATA,FETCH_CURRENT_USER_FAILED,FETCH_USER_DATA} from '../action/types'

const initialUserData = {}

export const userDataReducer = (state = initialUserData,action) => {
    switch(action.type){
        case LOADING_USER_DATA:
            return {loading:true}
            break;
        case FETCH_USER_DATA:
            return {loading:false,...action.payload}
            break;
        case FETCH_CURRENT_USER_FAILED:
            return {loading:false,...action.payload}
            break;
        default:
            return state;
    }
}