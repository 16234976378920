import React,{useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cookie from 'react-cookies';
import { userData } from '../../redux/slice/userSlice';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
//import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Copyright from '../../components/Copyright';
import { login } from '../../api/api';
import { useEffect } from 'react';
import { Alert } from '@mui/material';
import Swal from 'sweetalert2';
import { Axios } from '../../api/axios';

const theme = createTheme();

export default function Reset() {
    const {token } = useParams()
const navigate = useNavigate();
const [loginStatus,setLoginStatus] = useState(null)
const user = useSelector(state => state.user);
const dispatch = useDispatch();
const navigator = useNavigate()
const [loading, setLoading] = React.useState(false)
const handleSubmit = async (e) =>{
    e.preventDefault()
    const form = new FormData(e.currentTarget)
    if (form.get("new_password") === form.get('confirm_password')) {
        form.append('token',token)
        try {
            const res = await Axios.post("api?resetPassword",form)
            const data = res.data
            if(data.success){
                Swal.fire({
                    text:data.message,
                    icon:"success",
                    toast:true,
                    timer:5000,
                    timerProgressBar:true
                }).then(()=>{
                    navigator('/login')
                })
            }else{
                Swal.fire({
                    text:data.message,
                    icon:"error",
                    toast:true,
                    timer:5000,
                    timerProgressBar:true
                })
            }
        } catch (error) {
            Swal.fire({
                text:error.message,
                icon:"error",
                toast:true,
                timer:5000,
                timerProgressBar:true
            })
        }
    } else {
        Swal.fire({
            text:"Password mismatched!",
            icon:"error",
            toast:true,
            timer:5000,
            timerProgressBar:true
        })
    }
}
  useEffect(() => {
    return ()=>{
      return null
    }
  })
  return (
    <>
      <ThemeProvider theme={theme}>
      {
        token ?
        <>
        <Container component="main" maxWidth="xs">
        <CssBaseline />

        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <i className="fa fa-user" aria-hidden="true"></i>
          </Avatar>
          <Typography component="h1" variant="h5">
          Reset Password
          </Typography>
          <Box component="form" onSubmit={handleSubmit}  sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="password"
              label="New password"
              type="password"
              name="new_password"
              autoComplete="password"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="confirm_password"
              label="Confirm Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }} 
              style={{paddingTop:10,paddingBottom:10,backgroundColor:'#1bbc9b'}}
              disabled = {loading}
            >
              {loading ? 'Loading...' : 'Reset' }
            </Button>
            <Grid container>
              <Grid item>
                <Link to="/login" variant="body2">
                  {"Remember your password? Login"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
        </>:
        <>
        <Alert severity='error'>No reset password token!</Alert>
        </>
      }
    </ThemeProvider>
    </>
  );
}