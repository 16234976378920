import React, { useState } from 'react'
import '../../../assets/css/reg.css'
import { Axios } from '../../../api/axios'
import Swal from 'sweetalert2'
import { getUserData } from '../../../redux/action/user'
import { useNavigate, useNavigation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
 function BusinessRegistration () {
  const [options,setOption] = useState({type:"",})
  const [current,setCurrrent] = useState("TYPE")
  const [loading,setLoading] = useState(false)
   const dispatch = useDispatch()
  const navigator = useNavigate()
  const uploaddocuments = async () => {
    setLoading(true);
    const formdata = new FormData()
    for (const key in options) {
      formdata.append(key,options[key])
    }
    try {
        const response = await Axios.post('api?uploadbusiness',formdata,{
          headers:{
            'Content-Type':"multi-part/formdata"
          }
        });
        const res = response.data
        if (res.success && res.success === true) {
            Swal.fire({
                text: res.message,
                icon: 'success',
                timer: 5000,
                toast: true,
                showConfirmButton: false,
                position: 'top-end'
            });
            dispatch(getUserData());
            navigator(-1)
        } else {
            Swal.fire({
                text: res.message,
                icon: 'error',
                timer: 5000,
                toast: true,
                showConfirmButton: false,
                position: 'top-end'
            });
        }
    } catch (error) {
        Swal.fire({
            text: error.message,
            icon: 'error',
            timer: 5000,
            toast: true,
            showConfirmButton: false,
            position: 'top-end'
        });
    }finally{
        setLoading(false)
    }
}
  return (
    <div>
        <div className="container-fluid">
	<div className="row justify-content-center">
		<div className="col-11 col-sm-9 col-md-7 col-lg-6 col-xl-5 text-center p-0 mt-3 mb-2">
            <div className="card px-0 pt-4 pb-0 mt-3 mb-3">
                <h2 id="heading">Business Credential Registration</h2>
                <p>Fill all form field to go to next step</p>
                   <br/> 
                    <ul id="progressbar">
                        <li className="active w-50" id="account"><strong>Business Type</strong></li>
                        <li id="personal" className={`w-50 ${current == "UPLOAD" && 'active'}`}><strong>Document</strong></li>
                    </ul>
                    <br/>
                    <div className='container'>
                      {
                        current == 'TYPE' ?
                        <>
                        <div className='business-type'>
                        <div className='form-group'>
                          <select className='form-control' value={options.type} onChange={(e)=>{
                            if (e.target.value !="") {
                              setOption({...options,type:e.target.value})
                            }
                          }}>
                            <option value={""}>Select option</option>
                            <option value={"individual"}>Individual</option>
                            <option value={"Business"}>Business</option>
                          </select>
                          <div className='toggle d-flex justify-content-end mt-3 mb-2'>
                          <button className='btn ban-buttons' onClick={()=>{
                            if (options.type != "") {
                              setCurrrent("UPLOAD")
                            }
                          }}>Next</button>
                        </div>
                        </div>
                      </div>
                        </>:
                        <>
                          <div className='upload'>
                          {
                            options.type == "individual" ?
                            <>
                            <div className='form-group mb-2'>
                        <label>Enter your BVN </label>
                          <input type='text' name='bvn' onChange={(e)=>{
                            setOption({...options,bvn:e.target.value})
                          }} className='form-control'  />
                        </div>
                            <div className='form-group'>
                        <label>Upload your NIN</label>
                          <input type='file' name='nin' onChange={(e)=>{
                            setOption({...options,[e.target.name]:e.target.files[0]})
                          }} className='form-control'  />
                        </div>
                            </>:
                            <>
                              <div className='form-group mb-2'>
                        <label>Upload your CAC</label>
                          <input type='file' name='cac' onChange={(e)=>{
                            setOption({...options,[e.target.name]:e.target.files[0]})
                          }} className='form-control'  />
                        </div>
                        <div className='form-group mb-2'>
                        <label>Upload BVN of Board of Directors</label>
                          <input type='file' name='bod_bvn' onChange={(e)=>{
                            setOption({...options,[e.target.name]:e.target.files[0]})
                          }} className='form-control'  />
                        </div>
                        <div className='form-group'>
                        <label>ID card of Board of Directors</label>
                          <input type='file' name='idcard' onChange={(e)=>{
                            setOption({...options,[e.target.name]:e.target.files[0]})
                          }} className='form-control'  />
                        </div>
                            </>
                          }
                        
                          <div className='toggle d-flex justify-content-end mt-3 mb-2'>
                          <div className="ban-buttons" onClick={()=> setCurrrent('TYPE')}>
                        <a type='button' className="btn btn-sm mt-2 mx-2">Prev</a>
                        </div>
                          <div className="ban-buttons">
                        <a type='button' className="btn mt-2"  onClick={()=>{
                          if(!loading){
                            uploaddocuments()
                          }
                        }}>Submit</a>
                      </div>
                        </div>
                      </div>
                        </>
                      }
                    </div>
                 
            </div>
        </div>
	</div>
</div>
    </div>
  )
}

export default BusinessRegistration
