//user
export const LOADING_USER_DATA = 'LOADING_USER_DATA'
export const FETCH_USER_DATA = 'FETCH_USER_DATA'
export const FETCH_CURRENT_USER_FAILED = 'FETCH_CURRENT_USER_FAILED'
//shop
export const LOADING_SHOP_DATA = 'LOADING_SHOP_DATA'
export const FETCH_SHOP_DATA ='FETCH_SHOP_DATA'
export const FETCH_SHOP_FAILED = 'FETCH_SHOP_FAILED'
export const SET_CURRENT_STORE = 'SET_CURRENT_STORE';
export const SET_CURRENT_STORE_ERROR = "SET_CURRENT_STORE_ERROR"
export const GET_CURRENT_STORE_REVIEWS = "GET_CURRENT_STORE_REVIEWS"
export const GET_CURRENT_STORE_REVIEWS_SUCCESS = "GET_CURRENT_STORE_REVIEWS_SUCCESS"
export const GET_CURRENT_STORE_REVIEWS_FAILED = "GET_CURRENT_STORE_REVIEWS_FAILED"
export const SET_STORE_DELIVERY_DATA  = "SET_STORE_DELIVERY_DATA"
//product
export const ADD_PRODUCT = 'ADD_PRODUCT'
export const FETCH_SHOP_PRODUCTS_DATA = 'FETCH_SHOP_PRODUCTS_DATA'
export const FETCH_SHOP_PRODUCTS_LOADING = "FETCH_SHOP_PRODUCTS_LOADING"
export const FETCH_SHOP_PRODUCTS_FAILED = "FETCH_SHOP_PRODUCTS_LOADING_FAILED"
export const LOADING_SINGLE_PRODUCT = 'LOADING_SINGLE_PRODUCT'
export const SINGLE_PRODUCT_LOADED_SUCCESS = 'SINGLE_PRODUCT_LOADED_SUCCESS'
export const SINGLE_PRODUCT_LOADED_FAILED = 'SINGLE_PRODUCT_LOADED_FAILED'
export const GET_PRODUCT_CATEGORIES = 'GET_PRODUCT_CATEGORIES'
/** THEME CUSTOMIZATION */
export const FETCH_AVAILABLE_THEMES = 'FETCH_AVAILABLE_THEMES'
export const FETCH_AVAILABLE_THEMES_SUCESS = 'FETCH_AVAILABLE_THEMES_SUCCESS'
export const FETCH_AVAILABLE_THEMES_FAILED = 'FETCH_AVAILABLE_THEMES_FAILED'
//Default theme
export const CHANGE_THEME_PREVIEW_STATE = 'CHANGE_THEME_PREVIEW_STATE'
export const SET_DEFAULT_THEME_COMPONENT = 'SET_DEFAULT_THEME_COMPONENT'
export const SET_THEME_PAGE = 'SET_THEME_PAGE'
