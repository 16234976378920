import React from 'react'
import { Link } from 'react-router-dom'


function MainFooter(){
    return(
        <>
            <footer>
        <div className="container">
            <div className="row footer-top">
                <div className="col-lg-4 footer-grid_section_w3layouts">
                    <h2 className="logo-2 mb-lg-4 mb-3">
                        <Link to={"/"} replace={true}>SHOPAFRICA</Link>
                    </h2>
                    <p>We’re more than just an e-commerce platform; we are a force for positive change, championing the spirit of entrepreneurship and innovation in Africa and beyond.</p>
                    <h4 className="sub-con-fo ad-info my-4">Catch on Social</h4>
                    <ul className="w3layouts_social_list list-unstyled">
                        <li className='d-none'>
                            <a href="#" className="w3pvt_facebook">
                                <span className="fa fa-facebook-f"></span>
                            </a>
                        </li>
                        <li className="mx-2">
                            <a target='_blank' href="https://x.com/shopafricastore?s=11" className="w3pvt_twitter">
                                <span className="fa fa-twitter"></span>
                            </a>
                        </li>
                        <li>
                            <a target='_blank' href="https://instagram.com/shopafricastores?igshid=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr" className="w3pvt_dribble">
                                <span className="fa fa-instagram"></span>
                            </a>
                        </li>
                        <li className="mx-2">
                            <a target='_blank' href="https://www.linkedin.com/company/shopafrica/" className="w3pvt_facebook">
                                <span className="fa fa-linkedin"></span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="col-lg-8 footer-right">
                    <div className="w3layouts-news-letter d-none">
                        <h3 className="footer-title text-uppercase text-wh mb-lg-4 mb-3">Newsletter</h3>

                        <p>By subscribing to our mailing list you will always get latest news and updates from us.</p>
                        <form action="#" method="post" className="w3layouts-newsletter">
                            <input type="email" name="Email" placeholder="Enter your email..." required=""/>
                            <button className="btn1"><span className="fa fa-paper-plane-o" aria-hidden="true"></span></button>

                        </form>
                    </div>
                    <div className="row mt-lg-4 bottom-w3layouts-sec-nav mx-0">
                        <div className="col-md-6 footer-grid_section_w3layouts">
                            <h3 className="footer-title text-uppercase text-wh mb-lg-4 mb-3">Information</h3>
                            <ul className="list-unstyled w3layouts-icons">
                                <li>
                                    <Link to="/" replace>Home</Link>
                                </li>
                                <li className="mt-3">
                                <Link to="/about">About Us</Link>
                                </li>
                                <li className="mt-3">
                                <Link to="/faq">Frequently asked questions</Link>
                                </li>
                                <li className="mt-3">
                                <Link to="/terms">Terms and Conditions</Link>
                                </li>
                                <li className="mt-3">
                                <Link to="/privacy-policy">Privacy Policy</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6 footer-grid_section_w3layouts my-md-0 my-5">
                            <h3 className="footer-title text-uppercase text-wh mb-lg-4 mb-3">Contact Info</h3>
                            <div className="contact-info">
                                <div className="footer-address-inf d-none">
                                    <h4 className="ad-info mb-2">Phone</h4>
                                    <p>+234 12345678</p>
                                </div>
                                <div className="footer-address-inf my-4">
                                    <h4 className="ad-info mb-2">Email </h4>
                                    <p><a href="mailto:team@shopafrica.store">team@shopafrica.store</a></p>
                                </div>
                                <div className="footer-address-inf">
                                    <h4 className="ad-info mb-2">Location</h4>
                                    <p>Alternative Route, Cheveron Drive, Lekki, Lagos State.</p>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className="cpy-right text-left row">
                        <p className="col-md-10">© 2023 SHOPAFRICA. All rights reserved</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>

        </>
    )
}
export default MainFooter