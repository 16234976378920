import React,{ useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getUserData } from "../../../redux/action/user"
import { Link, useNavigate } from "react-router-dom"
import { Axios } from "../../../api/axios"
import Swal from "sweetalert2"
import ProfileNav from "./ProfileNav"
import Cookie from 'react-cookies'
function Profile(){
    const {currentUser} = useSelector(state => state)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [user,setUserDetail] = useState({})
    const [submiting,setSubmitStatus] = useState(false)
    const updateUser = async (user) => {
        if (Object.keys(user).length != 0) {
            try {
                const response = await Axios.post("api?updateUser",user)
                const res = response.data
                if (res.status) {
                    dispatch(getUserData())
                    Swal.fire({
                      text: res.message,
                      icon: 'success',
                      timer: 5000,
                      toast: true,
                      showConfirmButton: false,
                      position: 'top-end'
                  });
                  //const close = document.getElementsByClassName("btn-close")
                    //     close.item(0).click()
                  }else{
                    Swal.fire({
                      text: res.message,
                      icon: 'error',
                      timer: 5000,
                      toast: true,
                      showConfirmButton: false,
                      position: 'top-end'
                  });
                  }
            } catch (error) {
                Swal.fire({
                    text: error.message,
                    icon: 'error',
                    timer: 5000,
                    toast: true,
                    showConfirmButton: false,
                    position: 'top-end'
                });
            }
        }else{
            Swal.fire({
                text: 'You need to update user data',
                icon: 'info',
                timer: 5000,
                toast: true,
                showConfirmButton: false,
                position: 'top-end'
            });
        }
    }
    const updatePassword = async (e) => {
        const data = new FormData(e.currentTarget)
        setSubmitStatus(true)
        if (data.get('new_password') == data.get('confirm_password')) {
            try {
                const res = await Axios.post("api?updatePassword",data)
                const response = res.data
                if(response.success){
                    Swal.fire({
                      text: response.message,
                      icon: 'success',
                      timer: 5000,
                      toast: true,
                      showConfirmButton: false,
                      position: 'top-end'
                  });
                  const inputs = document.getElementsByClassName("modal-body form-control")
                       for (let index = 0; index < inputs.length; index++) {
                        const element = inputs.item(index);
                        element.setAttribute("value",'')
                       }
                       const close = document.getElementsByClassName("btn-close")
                       close.item(0).click()
                  }else{
                    Swal.fire({
                      text: response.message,
                      icon: 'error',
                      timer: 5000,
                      toast: true,
                      showConfirmButton: false,
                      position: 'top-end'
                  });
                  }
            } catch (error) {
                Swal.fire({
                    text: error.message,
                    icon: 'error',
                    timer: 5000,
                    toast: true,
                    showConfirmButton: false,
                    position: 'top-end'
                });
            }
            finally{
                setSubmitStatus(false)
            }
        } else {
            setSubmitStatus(false)
            Swal.fire({
                text: 'Password mismatched!',
                icon: 'error',
                timer: 5000,
                toast: true,
                showConfirmButton: false,
                position: 'top-end'
            });
        }
      }
        useEffect(()=>{
            !currentUser.data && dispatch(getUserData())
            return ()=> {
                return null
            }
        },[])
    return (
        <>
            <b className="screen-overlay"></b>
            <React.Fragment>
    <div className="">
			<div className="v-body-container">
				<div className="v-h-parent-grid">
					<>
          <ProfileNav page={'PROFILE'}/>
          </>
					<div className="p-0 flex-grow-1">
						<div className="v-main-brands-board">
							<div className="v-brand-board-title d-flex align-items-center justify-content-between">
								<h3 className="v-title">Profile</h3>
								<div className="dropdown" style={{margin:20}}>
        <a href="#" data-bs-toggle="dropdown" className="btn btn-light"> <i className="material-icons md-account_circle"></i> </a>
        <div className="dropdown-menu" style={{minWidth:200}}>
          <div className='w-100 px-2 d-flex justify-content-center align-items-center flex-column'>
            <h5 style={{fontSize:15}}>{currentUser && currentUser.name}</h5>
            <small style={{fontSize:13}}>{ currentUser && currentUser.email}</small>
          </div>
          <ul className='mt-2' style={{listStyle:"none"}}>
            <li><Link className="dropdown-item" to={'/profile'}>View Profile </Link></li>
            <li onClick={()=>{
              Cookie.remove('token',{maxAge:-1,expires:-1});
              setTimeout(() => {
                navigate("/")
              }, 2000)  
            }}><Link className="dropdown-item text-danger" >Logout</Link></li>
          </ul>
        </div>
                </div>
							</div>
							<div className="v-grid-container mt-3 mt-lg-5">
              <section className="content-body p-xl-4">
            <form>
              <div className="row">
                <div className="col-lg-12">
                  <div className="row gx-3">
                    <div className="col-6  mb-3">
                      <label className="form-label">First name</label>
                      <input className="form-control" onChange={(e)=>{
                        setUserDetail({...user,[e.target.name]:e.target.value})
                      }} name="firstname" type="text" defaultValue={currentUser && currentUser.firstname} placeholder="Type here"/>
                    </div> 
                    <div className="col-6  mb-3">
                      <label className="form-label">Last name</label>
                      <input className="form-control" onChange={(e)=>{
                        setUserDetail({...user,[e.target.name]:e.target.value})
                      }} name="lastname" type="text" defaultValue={currentUser && currentUser.lastname}  placeholder="Type here"/>
                    </div> 
                    <div className="col-lg-6  mb-3">
                      <label className="form-label">Email</label>
                      <input className="form-control" type="email" disabled defaultValue={currentUser && currentUser.email}  placeholder="example@mail.com"/>
                    </div>
                    <div className="col-lg-6  mb-3">
                      <label className="form-label">Phone</label>
                      <input className="form-control" onChange={(e)=>{
                        setUserDetail({...user,[e.target.name]:e.target.value})
                      }} name="phone" type="tel" defaultValue={currentUser && currentUser.phone} placeholder="+1234567890"/>
                    </div> 
                    <div className="col-lg-12  mb-3">
                      <label className="form-label">Address</label>
                      <input className="form-control" onChange={(e)=>{
                        setUserDetail({...user,[e.target.name]:e.target.value})
                      }} name="address" type="text" defaultValue={currentUser && currentUser.address}  placeholder="Type here"/>
                    </div> 
                  </div>
                </div>
              </div>
              <br/>
              <button className="btn t-btn" type="button" onClick={()=>{
                updateUser(user);
              }}>Save changes</button>
            </form>

            <hr className="my-5" />

            <div className="row" style={{maxWidth:920}}>
              <div className="col-md">
                <article className="box mb-3 bg-light">
                  <a className="btn float-end btn-light btn-sm" href="#" data-bs-toggle="modal" data-bs-target="#change_password">Change</a>
                  <h6>Password</h6>
                  <small className="text-muted d-block" style={{width:'70%'}}>You can reset or change your password by clicking here</small>
                </article>
              </div>
              <div className="col-md d-none">
                <article className="box mb-3 bg-light">
                  <a className="btn float-end btn-outline-danger btn-sm" href="#">Deactivate</a>
                  <h6>Remove account</h6>
                  <small className="text-muted d-block w-70" >Once you delete your account, there is no going back.</small>
                </article>
              </div> 
            </div>


          </section>
              </div>
						</div>
					</div>
				</div>
			</div>
		</div>

    </React.Fragment>
<div className="modal fade" id="change_password" tabindex="-1" aria-labelledby="addNewProductLabel" data-bs-backdrop="static" data-bs-keyboard="false"   aria-hidden="true">
			<div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
				<div className="modal-content">
                    <div className="modal-header border-0">
						<h1 className="modal-title fs-5" id="addNewProductLabel">Update Password</h1>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
                    <>
                    <div className="modal-body">
                    <form method="POST" onSubmit={(e)=>{
                        e.preventDefault()
                        updatePassword(e)
                    }}>
                        <div className="mb-4">
                              <label className="form-label">Current Password</label>
                              <input type="password" name='current_password' required className="form-control"   />
                         </div>
                         <div className="mb-4">
                              <label className="form-label">New Password</label>
                              <input type="password" name='new_password' required className="form-control"   />
                         </div>
                         <div className="mb-4">
                              <label className="form-label">Confirm Password</label>
                              <input type="password" name='confirm_password' required className="form-control"   />
                         </div>
                         <div className="mb-4">
                             <button className='btn t-btn'  type='submit' >
                                {submiting ? 'Processing...' : ' Update Password'}
                             </button>
                         </div>
                    </form>
                    </div>
                    </>
				</div>
			</div>
		        </div>
        </>
    )
}
export default Profile