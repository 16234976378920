import React, { useEffect } from 'react'
import Nav, { Header } from '../../../components/dashboard/Nav'
import Customers from './Customers'
import NeedHelp from '../../../components/dashboard/NeedHelp'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getUserData } from '../../../redux/action/user'
import { setCurrentStore } from '../../../redux/action/shop'

export default function CustomerWrapp() {
    const {shop} = useParams()
    const {currentShop,currentUser,currentShopProducts} = useSelector(state => state)
    const dispatch = useDispatch()
    useEffect(()=>{
        !currentUser.data && dispatch(getUserData())
        !currentShop.data && dispatch(setCurrentStore(shop))
        return ()=> {
            return null
        }
    },[currentUser.loading]);
  return (
    <>
            <Nav activeState={'customers'}/>
            <>
            <main className='main-wrap'>
                <Header />
                <React.Fragment>
                    <Customers />
                </React.Fragment>
            </main>
            </>
        <NeedHelp />
        </>
  )
}
