import React, { useEffect, useMemo, useState } from 'react';
import {Button, Table, Form, FormGroup} from 'react-bootstrap';
import { useTable, usePagination, useGlobalFilter } from 'react-table';
import { transaction } from '../redux/slice/transactionSlice';
import { useDispatch, useSelector,  } from 'react-redux';
import { order, orderDetail } from '../redux/slice/orderSlice';
import {review, reviewDetail} from '../redux/slice/reviewSlice';
import { useNavigate, useParams } from 'react-router-dom';






const MyTable = ({ body, columns, path}) => {
    const {shop} = useParams()
    const navigate = useNavigate();
    const {userOrders} = useSelector((state) => state);
    const [oDetail, setoDetail] = useState(userOrders.detail);
const [show, setShow] = useState(false);
const dispatch = useDispatch();
const {getTableProps, getTableBodyProps, headerGroups, page, 
    previousPage, nextPage, canNextPage, canPreviousPage, 
    pageOptions,
    prepareRow,
    state, 
    setGlobalFilter,
    setPageSize
} = useTable({
    columns,
    data:body
},
 useGlobalFilter, usePagination,
);

const {pageIndex, globalFilter} = state
const { pageSize} = state

  return (
    <>
        <div className="row">
            <div className="col-lg-4 col-md-4 me-auto mt-1">
                <input type="text" placeHolder="Search..." className="form-control mb-sm-1"
                onChange={e => setGlobalFilter(e.target.value)}
                />
            </div>
            <div className="col-lg-3 col-6 col-md-3 mt-1">
                {path === 'orders' && 
                <select 
                onChange={(e) => setGlobalFilter(e.target.value)}
                className="form-select">
                    <option value={''}>Status</option>
                    <option value={'Shipped'}>Shipped</option>
                    <option value='Confirmed'>Confirmed</option>
                    <option value='Pending' >Pending</option>
                    <option value='Canceled' >Canceled</option>
                </select>}
            </div>
            <div className="col-lg-3 col-6 col-md-3 mt-1">
                <select 
                onChange={(e)=> setPageSize(Number(e.target.value))}
                className="form-select">
                    <option value={10}>Show 10</option>
                    <option value={20}>Show 20</option>
                    <option value={30}>Show 30</option>
                    <option value={40}>Show 40</option>
                </select>
            </div>
        </div>
        <hr />
    <div className='' >
      <Table  hover size="lg" responsive {...getTableProps()} >
                    <thead>
                         {headerGroups.map((headerGroup,index) => (
                        <tr key={index + Math.random()} {...headerGroup.getHeaderGroupProps()}>
                            {
                                headerGroup.headers.map( (column) => (
                                    <th {...column.getHeaderProps()}>
                                        {column.render('Header')}
                                    </th>
                                ))
                            }
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row,index) => {
                            prepareRow(row)
                            return (
                                <tr  key={index + Math.random()}  
                                onClick={() =>{
                                    if(path === 'transactions'){
                                        dispatch(transaction(row.original));
                                    }
                                    if(path === 'orders' && localStorage.getItem('oDetail') === 'true'){
                                        navigate(`/shop/${shop}/dashboard/orders/${row.original.cartId}`)
                                    }
                                    if(path === 'reviews'){
                                        dispatch(review(row.original));
                                        dispatch(reviewDetail(true));
                                    }
                                }}
                                {...row.getRowProps()} >
                                  {
                                    row.cells.map((cell, index) =>{
                                        return (
                                            <td {...cell.getCellProps() } 
                                            // className='text-muted'
                                            style={{ 
                                                color: 'GrayText'
                                             }}
                                            
                                            >
                                                {cell.render("Cell")}
                                            </td>
                                        )
                                    })
                                  }
                                </tr>
                            )
                        })}
                            
                            
                        
                    </tbody>
                </Table>
                {path !== 'dashboard' && 
                <div className='mt-3 mb-4 d-flex justify-content-end'>
                <center> 
                <Button 
                variant='tertiary'
                disabled={!canPreviousPage}onClick={() => previousPage()} style={{ width: '100px' }} className=' btn btn-md t-btn'> Previous </Button>
                <span>
                         {pageIndex.length}
                       <span className='text-muted'> {pageIndex + 1} of {pageOptions.length} </span>
                    </span>
                <Button
                variant='tertiary'
                disabled={!canNextPage} onClick={() => nextPage()} style={{ width: '100px' }} className='btn btn-md t-btn'> Next </Button>
                </center>
                </div>}
    </div>
    </>
  )
}

export default MyTable
