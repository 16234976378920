import { ADD_PRODUCT, FETCH_SHOP_PRODUCTS_DATA, 
    FETCH_SHOP_PRODUCTS_FAILED, FETCH_SHOP_PRODUCTS_LOADING, 
    GET_PRODUCT_CATEGORIES, 
    LOADING_SINGLE_PRODUCT, SINGLE_PRODUCT_LOADED_FAILED,
     SINGLE_PRODUCT_LOADED_SUCCESS } 
     from "../action/types";



export const initProductData = {
    title:"",
    sku:"",
    product_size:[],
    barcode:"",
    quantity:"",
    brand:"",
    description:"",
    pricing:{
        sellingPrice:0,
        comparePrice:0,
        costPerItem:0,
        profit:0,
        margin:0,
    },
    media:[],
    media:[],
    status:"",
    tags:[],
    categories:[],
    product_color:[],
    variants:[
        {
            size:"",
            color:"",
            details:{
                price:0,
                quantity:0,
                sku:"",
                barcode:""
            }
        }
    ],
    meta:{
        dimensions:{width:"",length:"",weight:"",depth:""},
        features:[''],
        specifications:[{property:"",value:""}]
    }
}

export const addProductReducer = (state = initProductData,action) =>{
    switch (action.type) {
        case ADD_PRODUCT:
            return {...state,
                ...action.payload
                    }
            break;
        default:
            return state
            break;
    }
}
const initialStoreProductsData = {}
export const storeProductsReducer = (state = initialStoreProductsData,action) => {
    switch(action.type){
        case FETCH_SHOP_PRODUCTS_LOADING:
            return {loading:true}
            break;
        case FETCH_SHOP_PRODUCTS_DATA:
            return {loading:false,...action.payload}
            break;
        case FETCH_SHOP_PRODUCTS_FAILED:
            return {loading:false,...action.payload}
            break;
        default:
            return state;
    }
}
const singleProductData = {}
export const singleProductReducer = (state=singleProductData,action) => {
    switch(action.type){
        case LOADING_SINGLE_PRODUCT:
            return {loading:true}
            break;
        case SINGLE_PRODUCT_LOADED_SUCCESS:
            return {loading:false,...action.payload}
            break;
        case SINGLE_PRODUCT_LOADED_FAILED:
            return {loading:false,...action.payload}
            break;
        default:
            return state;
    }
}


export const productStatus = [
    'Available','Limited','Out of stock'
]

const categories = {}
export const categoriesReducer = (state = categories,action) => {
    switch (action.type) {
        case GET_PRODUCT_CATEGORIES:
            return action.payload
            break;
        default:
            return state
            break;
    }
}