import React, { useEffect, useState } from 'react'
import '../../assets/css/verif.css'
import { SHOP_AFRICA_LOGO } from '../../components/contants'
import { Link } from 'react-router-dom'
import { Axios } from '../../api/axios'
import Swal from 'sweetalert2'
import { getUserData } from '../../redux/action/user'
import { useDispatch, useSelector } from 'react-redux'
import { Alert } from '@mui/material'
export default function VerificationMessage({data,message,needverification}) {
	const [loading,setLoading]= useState(false)
	const currentUser = useSelector(({currentUser}) => currentUser);
	const dispatch = useDispatch()
	const verify_email = async function (){
		const email = currentUser.email
		setLoading(true)
        try {
            const res = await Axios.post("api?send_verification_mail",{email})
            const response = res.data;
			if(response.success){
				Swal.fire({
				  text: response.message,
				  icon: 'success',
				  timer: 5000,
				  toast: true,
				  showConfirmButton: false,
				  position: 'top-end'
			  });
			  }else{
				Swal.fire({
				  text: response.message,
				  icon: 'error',
				  timer: 5000,
				  toast: true,
				  showConfirmButton: false,
				  position: 'top-end'
			  });
			  }
        } catch (error) {
            Swal.fire({
                text: error.message,
                icon: 'error',
                timer: 5000,
                toast: true,
                showConfirmButton: false,
                position: 'top-end'
            })
        }
        finally {
            setLoading(false)
        }
    }
	useEffect(()=>{
		!currentUser && dispatch(getUserData())
		
	 },[])
  return (
    <>
    <>
    <>
                <div class="container my-3 col-md-8 col-lg-7 mx-auto mt-5">
					<div class="v-container v-success">
						<div class="v-container-inner">
							<div>
								<div class="v-logo-container">
									<img src={SHOP_AFRICA_LOGO} alt="shop africa" class="img-fluid" />
								</div>
							</div>
							<div class="v-icon-container">
								<svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
									<path
										d="M128 447.1V223.1c0-17.67-14.33-31.1-32-31.1H32c-17.67 0-32 14.33-32 31.1v223.1c0 17.67 14.33 31.1 32 31.1h64C113.7 479.1 128 465.6 128 447.1zM512 224.1c0-26.5-21.48-47.98-48-47.98h-146.5c22.77-37.91 34.52-80.88 34.52-96.02C352 56.52 333.5 32 302.5 32c-63.13 0-26.36 76.15-108.2 141.6L178 186.6C166.2 196.1 160.2 210 160.1 224c-.0234 .0234 0 0 0 0L160 384c0 15.1 7.113 29.33 19.2 38.39l34.14 25.59C241 468.8 274.7 480 309.3 480H368c26.52 0 48-21.47 48-47.98c0-3.635-.4805-7.143-1.246-10.55C434 415.2 448 397.4 448 376c0-9.148-2.697-17.61-7.139-24.88C463.1 347 480 327.5 480 304.1c0-12.5-4.893-23.78-12.72-32.32C492.2 270.1 512 249.5 512 224.1z" />
								</svg>
							</div>
							<div class="v-text-container col-sm-11 col-md-10 mx-auto col-lg-8">
								<h5 class="v-close-to-icon">{message ?? "Account successfully created!"}</h5>
								<span class="v-subtext">{data && data.message}</span>
								{
									needverification &&
								<>
									<p className='mt-2'>Didn't get verification mail? </p>
								<>
									<div className="ban-buttons">
										<Link onClick={(e)=>{
											e.preventDefault();
											verify_email()
										}} className="btn btn-sm mt-2">{loading ?"Processing...":"Resend Mail"}</Link>
									</div>
								</>
								</>
								}
							</div>
							{data && <div className='container'>
								<Alert severity='info'>
									Please note that your email could end up as spam. You can mark as "Not spam" to avoid this for subsequent mails
								</Alert>
							</div>}
						</div>
					</div>
				</div>
                </>
    </>
    </>
  )
}
