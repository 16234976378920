import { BrowserRouter,Routes, Route } from 'react-router-dom';
import {Provider} from 'react-redux';
import store from './redux/store';
import React from 'react';
import './assets/css/general.css'
import Index from './pages/landing/Index'
import Login from './pages/auth/Login';
import Signup from './pages/auth/Signup';
import Dashboard from './pages/dashboard/dashboard/Dashboard';
import Products from './pages/dashboard/products/Products';
import AddProducts from './pages/dashboard/products/AddProduct';
import Protected from './route/protected';
import UnProtected from './route/unProtected'
import MyShops from './pages/dashboard/shop/MyShops';
import CreateShop from './pages/dashboard/shop/CreateShop';
import AddMoreProductDetails from './pages/dashboard/products/AddMoreProductDetails';
import Drafts from './pages/dashboard/products/Drafts';
import EditProduct from './pages/dashboard/products/EditProduct';
import SettingWrap from './pages/dashboard/shop/SettingWrap';
import CIndex from './pages/shop/customize/CIndex';
import T1_Landing from './pages/shop/themes/default/pages/landing/T1_Landing';
import Collections from './pages/dashboard/collections/Collections';
import AddCollections from './pages/dashboard/collections/AddCollections';
import TransactionWrapper from './pages/dashboard/transactions/TransactionWrapper';
import Orders from './pages/dashboard/orders/Orders';
import Reviews from './pages/dashboard/reviews/ReviewWrapper';
import AddCategory from './pages/dashboard/categories/addCategory';
import EditCategory from './pages/dashboard/categories/EditCategory';
import EditOrders from './pages/dashboard/orders/editOrders';
import OrderDetails from './pages/dashboard/orders/OrderDetail';
import Contact from './pages/landing/contact/Contact';
import About from './pages/landing/about/About';
import ForgotPassword from './pages/auth/ForgotPassword';
import Profile from './pages/dashboard/profile/Profile';
import Reset from './pages/auth/Reset';
import SubscriptionIndex from './pages/dashboard/plans/Index';
import Plans from './pages/dashboard/plans/Plans';
import Checkout from './pages/dashboard/plans/Checkout';
import PaymentStatus from './pages/dashboard/plans/PaymentStatus';
import CustomerWrapp from './pages/dashboard/customers/CustomerWrapp';
import VerificationMessage from './pages/auth/VerificationMessage';
import Verification from './pages/auth/Verification';
import SMS from './pages/dashboard/profile/SMS';
import Faq from './pages/landing/Faq';
import Pricing from './pages/landing/Pricing';
import Subscriptions from './pages/dashboard/profile/Subscriptions';
import PrivacyPolicy from './pages/landing/PrivacyPolicy';
import Terms from './pages/landing/Terms';
import BusinessRegistration from './pages/dashboard/profile/BusinessRegistration';
import LoginFromMarasoft from './pages/auth/LoginFromMarasoft';

function App() {
  return (
   <>
    <Provider store={store}>
    <BrowserRouter >
      <Routes>
        <Route element={<Protected />}>
              <Route path='/shop/:shop/dashboard/product-otherdetails/' element={<AddMoreProductDetails />} />
              <Route path='/shop/:shop/dashboard/add-product' element={<AddProducts />} />
              <Route path='/shop/:shop/dashboard/products' element={<Products />} />
              <Route path='/shop/:shop/dashboard/transactions' element={<TransactionWrapper />} />
              <Route path='/shop/:shop/dashboard/orders' element={<Orders />} />
              <Route path='/shop/:shop/dashboard/collections' element={<Collections />} />
              <Route path='/shop/:shop/dashboard/collections/new' element={<AddCollections />} />
              <Route path='/shop/:shop/dashboard/settings' element={<SettingWrap />} />
              <Route path='/subscription' element={<Subscriptions />} />
              <Route path='/shop/:shop/dashboard/products/:productId' element={<EditProduct />} />
              <Route path='/shop/:shop/dashboard/orders/:cartId' element={<OrderDetails />} />
              <Route path='/shop/:shop/dashboard/drafts' element={<Drafts />} />
              <Route path='/shop/:shop/dashboard/' element={<Dashboard />} />
              <Route path='/shop/:shop/dashboard/reviews' element={<Reviews />} />
              <Route path='/shop/:shop/dashboard/customers' element={<CustomerWrapp />} />
              <Route path='/shop/:shop/dashboard/categories' element={<AddCategory />} />
              <Route path='/shop/:shop/dashboard/edit-category' element={<EditCategory />} />
              <Route path='/shop/:shop/dashboard/edit-order' element={<EditOrders />} />
              <Route path='/my-shops' element={<MyShops />} />
              <Route path='/user/plans' element={< Plans />} />
              <Route path='/user/:planId/checkout' element={< Checkout />} />
              <Route path='/user/:planId/payment_status' element={< PaymentStatus />} />
              <Route path='/profile' element={<Profile />} />
              <Route path='/business-registration' element={<BusinessRegistration />} />
              <Route path='/sms' element={<SMS />} />
              <Route path='/create-shop' element={<CreateShop />} />
              <>
              {
                //themes urls
                <Route path='/shop/:shop/dashboard/themes/:themeId/:page' element={<CIndex />} />
              }
              </>
        </Route>
        <Route path="/shop/:shop/dashboard/themes/1/customize" element={<T1_Landing />} />
        <Route path='/signup-verification' element={<VerificationMessage />} />
        <Route path='/verification/:token' element={<Verification />} />
        <Route path='/login_from_marasoft/:token' element={<LoginFromMarasoft />} />
        <Route element={<UnProtected/>}>
            <Route path='/login' element={<Login />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/signup' element={<Signup />} />
            <Route path='/faq' element={<Faq />} />
            <Route path='/reset/:token' element={<Reset />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/about' element={<About />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='/terms' element={<Terms />} />
            <Route path='/pricing' element={<Pricing />} />
            <Route path='/' Component={Index} element={<Index />} />
        </Route>
      </Routes>
    </BrowserRouter>
    </Provider>

   </>
  )
}

export default App;
