import aboutImg from "../../assets/images/icons/80246.jpg"
import Features from "./Features"

function Whatweoffer(){
    return (
        <>
        <section className="hand-crafted py-5 bg-white">
        <div className="container py-md-3">
            <div className="row banner-grids">
                <div className="col-md-6 banner-image">
                    <div className="effect-w3">
                        <img src={aboutImg} alt="" className="img-fluid image1"/>
                    </div>

                </div>
                <div className="col-md-6 last-img pl-lg-5 p-3">
                    <h3 className="tittle mb-lg-5 mb-3"><span className="sub-tittle"> </span>BUSINESS INSIGHTS</h3>
                    <p className="mb-4">Track every transaction and activity with robust statistics tools. Our intuitive chart transforms your transaction data into visual representations, making it easy to compare performance across different timeframes and track trends at a glance. Customize the date and time range to view transactions within a specific period. Whether you want to analyze daily, weekly, or monthly data, the choice is yours.</p>
                    <ul className="tic-info list-unstyled">
                        <li>

                            <span className="fa fa-check mr-2"></span> Visual Charts

                        </li>
                        <li>

                            <span className="fa fa-check mr-2"></span> Customizable time stamp

                        </li>
                        <li>

                            <span className="fa fa-check mr-2"></span> Order Details

                        </li>
                        <li>

                            <span className="fa fa-check mr-2"></span> Data-Driven Reports

                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="row banner-grids">
            <div className="col-md-6 last-img pl-lg-5 d-flex justify-content-center align-items-center flex-column">
                    <h3 className="tittle mb-lg- 3mb-2"><span className="sub-tittle"> </span>ZERO WITHDRAWAL FEE</h3>
                    <p>
                    Keep every cent you earn - no added or hidden withdrawal fees. We’ve designed our service with cost-efficiency in mind, so you can enjoy all these benefits without breaking the bank.
                    </p>
                    <h3 className="tittle mb-lg-3 mb-2 mt-5"><span className="sub-tittle"> </span>SMS MARKETING</h3>
                    <p>
                    Our SMS marketing feature enables personalized and targeted text messages, allowing you to engage customers effectively and boost sales for your online store(s) effortlessly. 
                    </p>
            </div>
                <div className="col-md-6 banner-image">
                    <div className="effect-w3">
                        <img src={require("../../assets/images/icons/95605.jpg")} alt="" className="img-fluid image1"/>
                    </div>

                </div>
            </div>
        </div>
    </section>
        <>
        <Features />
        </>
        </>
    )

}
export default Whatweoffer