import { useState } from "react";
import { Axios } from "../../api/axios";
import Swal from "sweetalert2";
import { request_catgories } from "../contants";

function NeedHelp(){
    const [imagePreview,setImagePreview] = useState(null)
    const shopId = localStorage.getItem("shopId")
  const toBase64Image = event =>{ 
    const fileInput = event.target;
            if (fileInput.files.length > 0) {
                const selectedImage = fileInput.files[0];
                const reader = new FileReader();
                reader.onload = function (e) {
                    const base64URL = e.target.result;
                    setImagePreview(base64URL)
                };

                reader.readAsDataURL(selectedImage);
            } else {
                setImagePreview(null)
            }
  }
  const [submiting,setSubmitStatus] = useState(false)
  const [attachment,setAttachment] = useState(null)
  const submitRequest = async (e) => {
    const data = new FormData(e.currentTarget)
    data.append("shopId",shopId)
    setSubmitStatus(true)
    try {
        const res = await Axios.post("api?submitRequest",data)
        const response = res.data
        if(response.success){
            Swal.fire({
              text: response.message,
              icon: 'success',
              timer: 5000,
              toast: true,
              showConfirmButton: false,
              position: 'top-end'
          });
          const inputs = document.getElementsByClassName("modal-body form-control")
               for (let index = 0; index < inputs.length; index++) {
                const element = inputs.item(index);
                element.setAttribute("value",'')
               }
               const close = document.getElementsByClassName("btn-close")
               close.item(0).click()
          }else{
            Swal.fire({
              text: response.message,
              icon: 'error',
              timer: 5000,
              toast: true,
              showConfirmButton: false,
              position: 'top-end'
          });
          }
    } catch (error) {
        Swal.fire({
            text: error.message,
            icon: 'error',
            timer: 5000,
            toast: true,
            showConfirmButton: false,
            position: 'top-end'
        });
    }
    finally{
        setSubmitStatus(false)
    }
  }
    return (
        <>
            <div className=''>
                <button type='button' data-bs-toggle="modal" data-bs-target="#addRequest" className='btn t-btn' style={{position:'fixed',right:20,bottom:30}}>Need Help?</button>
              </div>
              <div className="modal fade" id="addRequest" tabindex="-1" aria-labelledby="addNewProductLabel" data-bs-backdrop="static" data-bs-keyboard="false"   aria-hidden="true">
			<div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
				<div className="modal-content">
                    <div className="modal-header border-0">
						<h1 className="modal-title fs-5" id="addNewProductLabel">Submit A Request</h1>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
                    <>
                    <div className="modal-body">
                    <form method="POST" onSubmit={(e)=>{
                        e.preventDefault()
                        submitRequest(e)
                    }}>
                    <div className="mb-4">
                              <label for="product_name" className="form-label">Email Address</label>
                              <input type="email" name='email' required className="form-control"   />
                         </div>
                         <div className="mb-4">
                              <label for="product_name" className="form-label">Subject</label>
                              <input type="text" name='subject' required className="form-control"   />
                         </div>
                         <div className="mb-4">
                              <label for="product_name" className="form-label">Category</label>
                              <select className="form-control" name="category">
                                <option>Select a category for your request</option>
                                {
                                    request_catgories.map((req,ind)=>{
                                        return <option key={ind} value={req}>{req}</option>
                                    })
                                }
                                
                                
                              </select>
                         </div>
                         <div className="mb-4">
                              <label for="product_name" className="form-label">Description</label>
                              <textarea type="text" name='description' required className="form-control"  />
                         </div>
                         <div className="mb-4">
                         <label className='form-label'>Attachment</label>
                         <div className='d-flex justify-content-center align-items-center'>
                        <input type="file" accept="image/*" name="file" onChange={(e)=>{
                            toBase64Image(e)
                        }} hidden placeholder="Type here"  className="form-control" id="uploadImages"/>
                            <label  htmlFor='uploadImages' className='upload-images-label d-flex justify-content-center align-items-center' style={{
                                    display: "block",
                                    width: "90%",
                                    height:140,
                                    borderStyle:'dashed',
                                    borderColor:'GrayText',
                                    borderWidth:0.5,
                                    borderRadius:5,
                                }
                            }>
                                <span style={{fontSize:14}}>Click to add an Attachment </span>
                            </label>
                            </div>
                            {
                                imagePreview &&
                                <img src={imagePreview} height={100} width={200} alt='Preview image' className='mt-2' />
                            }
                         </div>
                         <div className="mb-4">
                             <button className='btn t-btn' disabled={submiting} type='submit' >
                                {submiting ? "Processing..." :"Submit Request"}
                             </button>
                         </div>
                    </form>
                    </div>
                    </>
				</div>
			</div>
		        </div>
        </>
    )
}
export default NeedHelp