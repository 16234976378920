import React, { useEffect } from 'react'
import Nav from '../../components/landing/Nav'
import MainFooter from '../../components/MainFooter'
import '../../assets/css/faq.css'
import { useLocation } from 'react-router-dom';
export default function Faq() {
   return (
    <>
        <Nav />
        <>
        <div className="container">
			<div className="col-lg-10 mx-auto v-general-inner-container">
				<div className="row col-12 m-0 align-items-center row-gap-4 justify-content-around mt-5" >
					<div className="col-md-6 mt-3">
						<div className="v-text-container">
							<div className="d-flex flex-column row-gap-4">
								<h2 className="v-text-title">FAQs</h2>
								<span className="v-subtext-content">
									Have questions? Here, you'll find the answers most valued by our partners, along with access to step-by-step instructions and
									support. Discover the most sought-after solutions our partners value, complete with easy-to-follow steps and dedicated support
									whenever you need guidance.
								</span>
							</div>
						</div>
					</div>
					<div className="col-md-5 mt-3">
						<div className="v-image-container">
							<img src={require(".././../assets/images/faq-3.png")} alt="Image" className="img-fluid" style={{
                                minWidth:400,minHeight:400
                            }} />
						</div>
					</div>
				</div>
				<div>
					<div className="v-accordion-container">
						<div className="accordion accordion-flush" id="accordionFlushExample">
							<div className="accordion-item">
								<h2 className="accordion-header">
									<button
										className="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#flush-collapseOne"
										aria-expanded="false"
										aria-controls="flush-collapseOne">
										<span className="v-quest-text-container"> What is ShopAfrica? </span>
									</button>
								</h2>
								<div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
									<div className="accordion-body">
										<span className="v-ans-text-container">
											ShopAfrica is an e-commerce platform that empowers vendors to establish online stores for selling products to their customers.
										</span>
									</div>
								</div>
							</div>
							<div className="accordion-item">
								<h2 className="accordion-header">
									<button
										className="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#flush-collapseTwo"
										aria-expanded="false"
										aria-controls="flush-collapseTwo">
										<span className="v-quest-text-container"> Does ShopAfrica offer delivery services ? </span>
									</button>
								</h2>
								<div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
									<div className="accordion-body">
										<span className="v-ans-text-container">
											Currently, we don't provide delivery services for product sold on the platform. Instead, merchants are responsible for
											delivering sold products. To facilitate this, we offer delivery settings, enabling merchants to set the custom prices for
											various locations. This allows their customers to pay the delivery fee along with their purchase.
										</span>
									</div>
								</div>
							</div>
							<div className="accordion-item">
								<h2 className="accordion-header">
									<button
										className="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#flush-collapseThree"
										aria-expanded="false"
										aria-controls="flush-collapseThree">
										<span className="v-quest-text-container">What currencies can I sell in?</span>
									</button>
								</h2>
								<div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
									<div className="accordion-body">
										<span className="v-ans-text-container">
											Currently, ShopAfrica exclusively supports transactions in Naira (NGN) for purchases made on the platform
										</span>
									</div>
								</div>
							</div>
							<div className="accordion-item">
								<h2 className="accordion-header">
									<button
										className="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#flush-collapseFour"
										aria-expanded="false"
										aria-controls="flush-collapseFour">
										<span className="v-quest-text-container">How do I get paid?</span>
									</button>
								</h2>
								<div id="flush-collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
									<div className="accordion-body">
										<span className="v-ans-text-container">
											In each ShopAfrica account, there's an integrated wallet where customer payments are desposited directly (first as a ledger
											balance). Once these funds become available for withdrawal, you can manually withdraw the entire or a portion of the money to your
											bank account. To do so, navigate to the settings page, click on the withdrawal option, input your bank details, and proceed to
											withdraw.
										</span>
									</div>
								</div>
							</div>
							<div className="accordion-item">
								<h2 className="accordion-header">
									<button
										className="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#flush-collapseFive"
										aria-expanded="false"
										aria-controls="flush-collapseFive">
										<span className="v-quest-text-container">Can I sell on ShopAfrica if I sell on other platforms ?</span>
									</button>
								</h2>
								<div id="flush-collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
									<div className="accordion-body">
										<span className="v-ans-text-container">
											Absolutely! You can sell your products on ShopAfrica even if you are selling them to elsewhere. ShopAfrica is here to assist you
											make your products and sales better.
										</span>
									</div>
								</div>
							</div>
							<div className="accordion-item">
								<h2 className="accordion-header">
									<button
										className="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#flush-collapseSix"
										aria-expanded="false"
										aria-controls="flush-collapseSix">
										<span className="v-quest-text-container">Are there any withdrawal fees for wallet funds ?</span>
									</button>
								</h2>
								<div id="flush-collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
									<div className="accordion-body">
										<span className="v-ans-text-container">
											No, there is no withdrawal fee for funds in the wallet. You can withdraw all your funds from the wallet without any withdrawal
											charges. However, please note that your accumulated service charges will be deducted from the total amount in your wallet when
											you initiate a withdrawal.
										</span>
									</div>
								</div>
							</div>
							<div className="accordion-item">
								<h2 className="accordion-header">
									<button
										className="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#flush-collapseSeven"
										aria-expanded="false"
										aria-controls="flush-collapseSeven">
										<span className="v-quest-text-container">How can I track the delivery and ensure my customer receives the shipped product?</span>
									</button>
								</h2>
								<div id="flush-collapseSeven" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
									<div className="accordion-body">
										<span className="v-ans-text-container">
											Your customers will receive multiple emails regarding the delivery status from the moment the order is placed until they confirm
											 receipt. Additionally, you can personally contact them to contact if they have received the order, as their contact details
											will be accessible upon order placement.
										</span>
									</div>
								</div>
							</div>
							<div className="accordion-item">
								<h2 className="accordion-header">
									<button
										className="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#flush-collapseEight"
										aria-expanded="false"
										aria-controls="flush-collapseEight">
										<span className="v-quest-text-container">Can I deactivate my store at anytime?</span>
									</button>
								</h2>
								<div id="flush-collapseEight" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
									<div className="accordion-body">
										<span className="v-ans-text-container"> Certainly, you have the freedom to deactivate your store whenever you choose. </span>
									</div>
								</div>
							</div>
							<div className="accordion-item">
								<h2 className="accordion-header">
									<button
										className="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#flush-collapseNine"
										aria-expanded="false"
										aria-controls="flush-collapseNine">
										<span className="v-quest-text-container">What charges apply to using ShopAfrica ?</span>
									</button>
								</h2>
								<div id="flush-collapseNine" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
									<div className="accordion-body">
										<span className="v-ans-text-container">
											Your can see the detailed description of our charges on the <a href="" className="v-link-out">pricing page</a>
										</span>
									</div>
								</div>
							</div>
							<div className="accordion-item">
								<h2 className="accordion-header">
									<button
									onClick={(e)=>{
									
									}}
										className="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#flush-collapseTen"
										aria-expanded="false"
										aria-controls="flush-collapseTen">
										<span className="v-quest-text-container">How can I contact you?</span>
									</button>
								</h2>
								<div id="flush-collapseTen" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
									<div className="accordion-body">
										<span className="v-ans-text-container">
											Shoot us an email <a href="mailto:shopafricastores@gmail.com" className="v-link-out">@ shopafricastores@gmail.com</a> or you can reach out to us on
											<a href="https://x.com/shopafricastore?s=11" className="v-link-out">Twitter</a> and <a target='_blank' href="https://instagram.com/shopafricastores?igshid=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr" className="v-link-out">Instagram</a>
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
        </>
        <div className="container">
            <MainFooter />
        </div>
    </>
  )
}
