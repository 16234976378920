import { Navigate,Outlet, useLocation, useNavigate } from "react-router-dom";
import { token } from "../api/axios";
import { useEffect } from "react";


const UnProtected = () =>{
    const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    //return null;
  }, [pathname]);

    return (
        token() ?
        <Navigate  to={'/my-shops'} />
        :
        <Outlet /> 
    )
}
export default UnProtected;
