import React from 'react'
import { Link } from 'react-router-dom'
import { pageUrl } from '../../../api/api'
import logo from "../../../assets/images/shopafrica-logo-2.png"
export default function ProfileNav({page}) {
  return (
    <aside className="p-0 v-aside">
      <div className="aside-top"
style={{ 
  backgroundColor: '#2c3e50'
 }}
>
  <Link to={'#'} className="brand-wrap "
  >
    <img src={logo} height="60" className="logo" alt="Textng Shop" style={{width:190}} />
  </Link>
  <div>
  </div>
  </div>
    <div className="v-aside-inner">
      <ul>
      <li className={page == 'STORES' && 'active'}>
          <Link to={"/my-shops"} className={page == 'STORES' ? 'active':""} > 
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M4 13h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1m-1 7a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1zm10 0a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-7a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1zm1-10h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1" />
            </svg>
            <span className="v-link-text"> Stores </span>
          </Link>
        </li>
        <li>
          <Link to={"/profile"} className={page == 'PROFILE' ? 'active':""}>
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M4 13h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1m-1 7a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1zm10 0a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-7a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1zm1-10h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1" />
            </svg>
            <span className="v-link-text"> General </span>
          </Link>
        </li>
        <li>
          <Link to={"/sms"} className={page == 'SMS' ? 'active':""}>
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2M9 11H7V9h2zm4 0h-2V9h2zm4 0h-2V9h2z" />
            </svg>
            <span className="v-link-text"> SMS Broadcast </span>
          </Link>
        </li>
        <li>
          <Link to={"/subscription"} className={page == 'SUBSCRIPTION' ? 'active':""}>
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M4 6h16v2H4zm2-4h12v2H6zm14 8H4a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-8a2 2 0 0 0-2-2m-5 7h-2v2h-2v-2H9v-2h2v-2h2v2h2Z" />
            </svg>
            <span className="v-link-text"> Subscription </span>
          </Link>
        </li>
      </ul>
    </div>
  </aside>
  )
}

//GENERAL ,SMS, SUBSCRIPTION