import { defaultThemePages } from "../../components/shop/allthemes";
import { defaultThemeComponentDOMSetup } from "../../components/shop/theme-constants";
import { CHANGE_THEME_PREVIEW_STATE, FETCH_AVAILABLE_THEMES, FETCH_AVAILABLE_THEMES_SUCESS, SET_DEFAULT_THEME_COMPONENT, SET_THEME_PAGE } from "../action/types";

const initThemes = {}
export const availableThemesReducer = (state =  initThemes, action) =>{
    switch (action.type) {
        case FETCH_AVAILABLE_THEMES:
            return {loading:true}
            break;
        case FETCH_AVAILABLE_THEMES_SUCESS:
            var newState = {loading:false,...action.payload}
            return newState;
            break;;
        default:
            return state
            break;
    }
}

const initialDefaultThemePageData = defaultThemeComponentDOMSetup
export const defaultThemeReducer = (state = initialDefaultThemePageData,action) =>{
    switch (action.type) {
        case SET_DEFAULT_THEME_COMPONENT:
            const {component,section,key,value} = action.payload
            //state[component][section][key] = value
            console.log(state);
            return {
                ...state,
                resetId:Math.random(),
                [component]:{
                    ...state[component],
                    [section]:{
                        ...state[component][section],
                        [key]:value
                    }
                }
            }
            break;
        default:
            return state
            break;
    }
}
//1-desktop,2-tablet, 3-mobile
const initPreviewState = {state:2}
export const changePreviewStateReducer = (state=initPreviewState,action)=>{
    switch (action.type) {
        case CHANGE_THEME_PREVIEW_STATE:
            return {
                state:action.payload
            }
            break;
        default:
            return state;
            break;
    }
}

const initialThemePage = defaultThemePages.LANDING
export const themePageReducer = (state = initialThemePage,action) => {
    switch (action.type) {
        case SET_THEME_PAGE:
            return action.payload
            break;
        default:
            return state
            break;
    }
}