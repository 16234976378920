import React, { useEffect, useState } from 'react'
import Nav, { Header } from '../../../components/dashboard/Nav'
import ProductOrders from './ProductOrders'
import '../../../assets/css/products.css'
import { useSelector,useDispatch } from 'react-redux'
import { getUserData } from '../../../redux/action/user'
import { setCurrentStore } from '../../../redux/action/shop'
import { useParams } from 'react-router-dom'
import NeedHelp from '../../../components/dashboard/NeedHelp'

function Orders(){
    const {shop} = useParams()
    const {currentUser, userOrders} = useSelector(state => state)
    const dispatch = useDispatch()

    useEffect(()=>{
        return ()=> {
            return null
        }
    },[currentUser.loading]);

    return (
        <>
            <Nav activeState={'orders'}/>
            <>
            <main className='main-wrap'>
                <Header />
                <React.Fragment>
                    <ProductOrders />
                </React.Fragment>
            </main>
            </>
        <NeedHelp />
        </>
    )
}

export default Orders