export const themes = {
    default: {
        pages: {
            Landing:'LANDING',
            shop:'SHOP'
        }
    }
}

export const defaultThemePages = {
    LANDING:'LANDING',
    SHOP:"SHOP"
}

