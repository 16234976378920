import React, { useState,useRef,useEffect } from 'react'
import { Tab,Tabs, Toast } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { createKey, sanitizeStoreName, store_link, updateShopImage } from '../../../api/api';
import { Axios,} from '../../../api/axios';
import { useDispatch } from 'react-redux';
import { updateShop } from '../../../api/api';
import Swal from 'sweetalert2';
import { setCurrentStore, setStoreDeliveryState, } from '../../../redux/action/shop';
import axios from 'axios';
import { getUserData } from '../../../redux/action/user';
import { Alert } from '@mui/material';

function Settings(){
    const [isShopUpdated,setIsShopUpdated] = useState(false);
    const dispatch = useDispatch()
    const [toastShow,setToastShow] = useState(false)
    const [updateStatus,handleUpdateStatus] = useState(null)
    const {shop} = useParams()
    const {currentShop,delivery_data} = useSelector(state => state)
    const [shopDetails, setShopDetails] = useState({name:shop});
    const [loading, setLoading] = useState(false);
    const [allbanks,setBanks] = useState([])
    const shopId = localStorage.getItem("shopId")
    const [chargeHolder,setChargeHolder] = useState([""])
    const currentUser = useSelector(({currentUser}) => currentUser)
    const [chargeToUpdate,setChargeToUpdate] = useState({})
    const [withdrawState,setWithdrawState] = useState('COLLECTION')
    const [defaultDelivery,setDefaultDelivery] = useState(0);
    const [delivery,setDeliveryData] = useState({
      shopId,
      type:"PICKUP_ONLY",pickup_location:""
    });
    const addDefaultCharge = async() => {
      setUpdatingDelivery(true);
const data = {amount:defaultDelivery,storeId:shopId}
      try {
        const res = await Axios.post("api?addDefaultDelivery",data)
        const response = res.data
        if (response.success) {
          Swal.fire({
            text: response.message,
            icon: 'success',
            timer: 5000,
            toast: true,
            showConfirmButton: false,
            position: 'top-end'
        });
        }else{
          Swal.fire({
            text: response.message,
            icon: 'error',
            timer: 5000,
            toast: true,
            showConfirmButton: false,
            position: 'top-end'
        });
        }
      } catch (error) {
        Swal.fire({
          text: error.message,
          icon: 'error',
          timer: 5000,
          toast: true,
          showConfirmButton: false,
          position: 'top-end'
      });
      }finally{
          setUpdatingDelivery(false)
      }
    }
    const initbankdetail = {
      account_name:"",
      account_number:"",
      amount:"",
      bank_code:"",
      isverified:null,
      savefornext:false
  }
  const [updatingDelivery,setUpdatingDelivery] = useState(false)
  const update_delivery_mode = async (data) => {
    setUpdatingDelivery(true)
    try {
      const res = await Axios.post("api?updateDelivery",data)
      const response = res.data
      if (response.status) {
        setChargeHolder([""])
        Swal.fire({
          text: response.message,
          icon: 'success',
          timer: 5000,
          toast: true,
          showConfirmButton: false,
          position: 'top-end'
      });
      }else{
        Swal.fire({
          text: response.message,
          icon: 'error',
          timer: 5000,
          toast: true,
          showConfirmButton: false,
          position: 'top-end'
      });
      }
    } catch (error) {
      Swal.fire({
        text: error.message,
        icon: 'error',
        timer: 5000,
        toast: true,
        showConfirmButton: false,
        position: 'top-end'
    });
    }finally{
        setUpdatingDelivery(false)
    }
  }
    const [bank_detail,setBankDetail] = useState(initbankdetail)
    const fetchBanks = async () => {
      try {
        const res = await axios.get("https://cloudtech.striderng.xyz/marasoftbank/api/user/getbanks.php")
        const data = res.data
        setBanks(data)
      } catch (error) {
        console.log(error)
        setBanks([])
      }
    }
    const [deliveryTab,setDeliveryTab] = useState("MANAGE")
    const [deliveryCountryStates,setDeliveryCountryStates]  = useState(null)
    const fetchDeliveryCountryStates =  async countryId => {
      try {
        const data = await Axios.get("api?getCountryState",{
          params:{countryId,shopId}
        })
        const res = data.data
        if (res.status) {
          setDeliveryCountryStates(res.data)
        }else{
          setDeliveryCountryStates(null)
          Swal.fire({
            text: res.message,
            icon: 'error',
            timer: 5000,
            toast: true,
            showConfirmButton: false,
            position: 'top-end'
        });
        }
        
      } catch (error) {
        setDeliveryCountryStates(null)
        Swal.fire({
          text: error.message,
          icon: 'error',
          timer: 5000,
          toast: true,
          showConfirmButton: false,
          position: 'top-end'
      });
      }
    }
    const [storeDeliveryData,setStoreDeliveryData] = useState(null)
     const fetchStoreDeliveryStates =  async (countryId,storeId) => {
      try {
        const data = await Axios.get("api?getStoreDelivery",{
          params:{countryId,storeId}
        })
        const res = data.data
        if (res.success) {
          setStoreDeliveryData(res.data)
        }else{
          setStoreDeliveryData(null)
          Swal.fire({
            text: res.message,
            icon: 'error',
            timer: 5000,
            toast: true,
            showConfirmButton: false,
            position: 'top-end'
        });
        }
        
      } catch (error) {
        setStoreDeliveryData(null)
        Swal.fire({
          text: error.message,
          icon: 'error',
          timer: 5000,
          toast: true,
          showConfirmButton: false,
          position: 'top-end'
      });
      }
    }
    //$updateChargeData
    const handleChange = (e) => {
      if (e.target.name == 'name') {
        const storename= sanitizeStoreName(e.target.value);
        setShopDetails({...shopDetails, [e.target.name]: storename});
      }else{
        setShopDetails({...shopDetails, [e.target.name]: e.target.value});
      }
      
    }
    const updateNow= async (e) => {
      e.preventDefault();
      setLoading(true);
      const response = await updateShop(shopDetails);
      if(response.success){
        dispatch(setCurrentStore(shopDetails.shopId))
        Swal.fire({
          text: response.message,
          icon: 'success',
          timer: 5000,
          toast: true,
          showConfirmButton: false,
          position: 'top-end'
      });
      if (shopDetails.name != shop) {
        navigate('/my-shops');
      }
        // dispatch(setCurrentStore(response.data)) && dispatch(userShopData())

      }else{
        Swal.fire({
          text: response.message,
          icon: 'error',
          timer: 5000,
          toast: true,
          showConfirmButton: false,
          position: 'top-end'
      });
      }

      setLoading(false);
    }
    const uploadImage = async () => {
      setLoading(true);
      const data = new FormData(document.getElementById("changeImageForm"))
      data.append('oldImage', currentShop.image.split("/")[6]);
      data.append('shopId', shopDetails.shopId);
      const response = await updateShopImage(data);
      if(response.success){
        dispatch(setCurrentStore(shopDetails.shopId))
        Swal.fire({
          text: response.message,
          icon: 'success',
          timer: 5000,
          toast: true,
          showConfirmButton: false,
          position: 'top-end'
      });
      }else{
        Swal.fire({
          text: response.message,
          icon: 'error',
          timer: 5000,
          toast: true,
          showConfirmButton: false,
          position: 'top-end'
      });
      }
      setLoading(false);
    }
    const updateShopForUser =async e => {
      e.preventDefault()
      setIsShopUpdated(true)
      const data = new FormData(e.currentTarget)
           try {
          const res = await Axios({
            url :'api?updateShop',
            method:"POST",
            data
          })
          //setUpdateState({success:res.data.success,message:res.data.message})
          //res.data.success && dispatch(setCurrentStore(res.data.name)) && dispatch(userShopData())
          handleUpdateStatus(res.data)
          setToastShow(true)
          setIsShopUpdated(false);
          setTimeout(() => {
            setToastShow(false)
          }, 5000);
        } catch (error) {
          handleUpdateStatus({success:false,message:error.message})
          setIsShopUpdated(false)
        }
    }
    const verify_account = async (data) => {
      setVerifyingStatus(true)
      try {
        const response = await Axios.post("api?verifyAccount",data)
        const res = response.data
        if(res.status == true){
          setBankDetail({...bank_detail,account_name:res.data.account_name,isverified:true,account_number:res.data.account_number})
        }else{
          setBankDetail({...bank_detail,account_name:"",isverified:false})
        }
      } catch (error) {
        
      }
      setVerifyingStatus(false)
    }
    const [isverifying,setVerifyingStatus] = useState(false)
    const [isWithdrawing,setWithdrawingStatus] = useState(false)
    const initiateWithdrawal = async (data) => {
      setWithdrawingStatus(true)
      try {
        const response = await Axios.post("api?initiateWithdrawal",data);
        const res = response.data
        if(res.status == true){
         new Swal("Withdrawal Successful",res.message,'success')
        setBankDetail(initbankdetail)
        dispatch(setCurrentStore(shopId))
        }else{
          Swal.fire({
            text: res.message,
            icon: 'warning',
            timer: 5000,
            toast: true,
            showConfirmButton: false,
            position: 'top-end'
        })
        dispatch(setCurrentStore(shopId))
        }
      } catch (error) {
        Swal.fire({
          text: error.message,
          icon: 'error',
          timer: 5000,
          toast: true,
          showConfirmButton: false,
          position: 'top-end'
      })
      }
      setWithdrawingStatus(false)
    }
    const updateSelfDeliveryState = async () => {
      try {
          const data = await Axios.post("api?updateDeliveryState",
          {delivery_data,shopId,countryId:1}
          )
          const res = data.data
          if (res.success) {
            dispatch(setStoreDeliveryState([{stateId:"",charge:""}]))
            fetchStoreDeliveryStates(1,shopId)
            Swal.fire({
              text: res.message,
              icon: 'success',
              timer: 5000,
              toast: true,
              showConfirmButton: false,
              position: 'top-end'
          });
          }else{
            Swal.fire({
              text: res.message,
              icon: 'error',
              timer: 5000,
              toast: true,
              showConfirmButton: false,
              position: 'top-end'
          });
          }
          
      } catch (error) {
        Swal.fire({
          text: error.message,
          icon: 'error',
          timer: 5000,
          toast: true,
          showConfirmButton: false,
          position: 'top-end'
      });
      }
    }
  const updateStateDeliveryCharge = async () => {
    try {
        const data = await Axios.post("api?updateChargeData",chargeToUpdate)
        const res = data.data
        if (res.status) {
          fetchStoreDeliveryStates(1,shopId)
          Swal.fire({
            text: res.message,
            icon: 'success',
            timer: 5000,
            toast: true,
            showConfirmButton: false,
            position: 'top-end'
        });
        const close = document.getElementsByClassName("btn-close")
               close.item(0).click()
        }else{
          Swal.fire({
            text: res.message,
            icon: 'error',
            timer: 5000,
            toast: true,
            showConfirmButton: false,
            position: 'top-end'
        });
        }
        
    } catch (error) {
      Swal.fire({
        text: error.message,
        icon: 'error',
        timer: 5000,
        toast: true,
        showConfirmButton: false,
        position: 'top-end'
    });
    }
  }
  //<Navigate to={"/business-registration"} replace={true} />
  const deleteStateDeliveryCharge = async (detail) => {
    try {
        const data = await Axios.post("api?deleteChargeData",detail)
        const res = data.data
        if (res.status) {
          fetchStoreDeliveryStates(1,shopId)
          Swal.fire({
            text: res.message,
            icon: 'success',
            timer: 5000,
            toast: true,
            showConfirmButton: false,
            position: 'top-end'
        });
        }else{
          Swal.fire({
            text: res.message,
            icon: 'error',
            timer: 5000,
            toast: true,
            showConfirmButton: false,
            position: 'top-end'
        });
        }
        
    } catch (error) {
      Swal.fire({
        text: error.message,
        icon: 'error',
        timer: 5000,
        toast: true,
        showConfirmButton: false,
        position: 'top-end'
    });
    }
  }
  const handleChargeChange = (dataIndex, newValue) => {
    setChargeHolder((prevChargeHolder) => {
      let updatedChargeHolder = [...prevChargeHolder];  // Create a new array
      updatedChargeHolder[dataIndex] = newValue;
      return updatedChargeHolder;
    }); 
  
    const newDeliveryData = [...delivery_data];
    newDeliveryData[dataIndex] = { ...newDeliveryData[dataIndex], charge: newValue };
    dispatch(setStoreDeliveryState(newDeliveryData));
  };
  const delete_banner = async () =>{
    const form =  new FormData();
    form.append("storeId",shopId);
    try {
        const data = await Axios.post("api?deleteBannerImage",form)
        const res = data.data
        if (res.success) {
          dispatch(setCurrentStore(shop))
          Swal.fire({
            text: res.message,
            icon: 'success',
            timer: 5000,
            toast: true,
            showConfirmButton: false,
            position: 'top-end'
        });
        }else{
          Swal.fire({
            text: res.message,
            icon: 'error',
            timer: 5000,
            toast: true,
            showConfirmButton: false,
            position: 'top-end'
        });
        }
        
    } catch (error) {
      Swal.fire({
        text: error.message,
        icon: 'error',
        timer: 5000,
        toast: true,
        showConfirmButton: false,
        position: 'top-end'
    });
    }
  }
  const navigate = useNavigate()
    useEffect(()=>{
      fetchDeliveryCountryStates(1)
      !currentUser && dispatch(getUserData())
      fetchStoreDeliveryStates(1,shopId)
      allbanks.length <=0 && fetchBanks();

      setShopDetails({
        ...shopDetails,name:currentShop && (currentShop.name ??  currentShop.title) ,
        shopId: localStorage.getItem('shopId')
      })
     
    },[])

    const [isCopied, setIsCopied] = useState(false);
  const handleCopyClick = (textToCopy) => {
    const tempTextArea = document.createElement('textarea');
    tempTextArea.value = textToCopy;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextArea);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const updateStoreBanner = async (file) => {
    const form =  new FormData();
    form.append("banner",file);
    form.append("storeId",shopId);
    try {
        const data = await Axios.post("api?addBannerImage",form)
        const res = data.data
        if (res.success) {
          dispatch(setCurrentStore(shop))
          Swal.fire({
            text: res.message,
            icon: 'success',
            timer: 5000,
            toast: true,
            showConfirmButton: false,
            position: 'top-end'
        });
        }else{
          Swal.fire({
            text: res.message,
            icon: 'error',
            timer: 5000,
            toast: true,
            showConfirmButton: false,
            position: 'top-end'
        });
        }
        
    } catch (error) {
      Swal.fire({
        text: error.message,
        icon: 'error',
        timer: 5000,
        toast: true,
        showConfirmButton: false,
        position: 'top-end'
    });
    }
  }


    return (
        <main>
            <section className="content-main" >
	
    <div className="content-header">
        <h2 className="content-title">Settings</h2>
    </div>
    
    <div className="card mb-4">
        <div className="card-body">
          <>
          <Tabs
      defaultActiveKey="details"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="details" title={<p className='t-label'>Shop Details</p>}>
      <section className="content-body p-xl-4">
      <div className='col-12'>
      <div class="card mb-4" style={{boxShadow:"none"}}>
          <h6>Store Banner</h6>
          <div class="card-header d-flex justify-content-center align-items-center"  style={{height:150}}>
            {
              currentShop && currentShop.banner_url ?
              <img src={currentShop && currentShop.banner_url} style={{width:'100%',height:"100%"}} className='img-fluid' alt='Banner' />:
              <>
              <Alert severity='info'><label style={{cursor:"pointer"}} htmlFor='uploadstorebanner'>Upload store banner to be displayed in your store.</label></Alert>
              </>
            }
          </div> 
          <div className='d-flex justify-content-end mt-2'>
          <label htmlFor='uploadstorebanner' className={`btn btn-sm t-btn`} title='Upload Banner'>{<i className="fa fa-upload"></i> }</label>
         
          {currentShop && currentShop.banner_url && <label onClick={delete_banner}  className={`btn btn-sm bg-danger mx-1 text-white`} title='Delete Banner'>{<i className="fa fa-trash"></i> }</label>} 
          </div>
          <input type='file' accept='image/*' hidden id='uploadstorebanner'  onChange={e=>{
            updateStoreBanner(e.target.files[0])
          }}/>
    </div>  
      </div>
      <div className="row">
      <div className="col-xl col-lg flex-grow-0" style={{flexBasis:230}}>
        <div className=" d-flex justify-content-center  w-100 bg-white position-relative text-center" 
        style={{width:100,height:100}}
        >
          {
            currentShop && <img  src={currentShop.image} style={{maxHeight:"95%"}} className="center-xy img-fluid" alt="Logo Brand"/>
          }
        </div>
      </div> 
      <div className="col-xl col-lg">
        <h3>{currentShop.title || currentShop.name}</h3>
        <p>{currentShop.address || ""}</p>
      </div> 
      <div className="col-xl-4 text-md-end">
          <a href={store_link(currentShop && currentShop.name && currentShop.name.toLocaleLowerCase())} className="btn btn-sm btn-outline-primary mx-2" target='_blank'> View live <i className="material-icons md-launch"></i> </a>
          <button onClick={()=>{
            handleCopyClick(store_link(currentShop && currentShop.name && currentShop.name.toLocaleLowerCase()))
          }} className={`btn btn-sm btn-outline-primary ${isCopied && 'bg-primary text-white'}`} title='Copy store link'>{isCopied ? 'Copied':<i className="fa fa-copy"></i> }</button>
      </div> 
    </div> 
    <hr className="my-4"/>
    <div className="row border-bottom mb-4 pb-4">
            <div className="col-md-5">
              <h5>Logo</h5>
              <p className="text-muted" style={{maxWidth:"90%"}}>
              This is the logo of the shop. Once changed, you might not be redirected to reopen your store to reflect the changes. </p>
            </div>
            <div className="col-md-7">
            <form method='POST' id='changeImageForm' >
            <aside className="col-lg-4" style={{float:"right"}}>
                  <figure className="text-lg-center">
                    <img className="img-lg mb-3 img-avatar" src={currentShop.image} alt="User Photo"/>
                    <input type='file' name='file' onChange={uploadImage} id='shopImage' style={{display:"none"}}  />
                    <figcaption>
                      <label htmlFor='shopImage' className="btn t-btn" >
                         {loading? `Uploading...`:<><i className="icons material-icons md-backup"></i> Upload</> }
                      </label>
                    </figcaption>
                  </figure>
                </aside>
            </form>
                
            </div> 
          </div>
          <form method='POST' encType='multipart/form-data' onSubmit={updateNow} >

            
          <div className="row border-bottom mb-4 pb-4">
            <div className="col-md-5">
              <h5>Shop name</h5>
              <p className="text-muted" style={{maxWidth:"90%"}}>
              This is the name of the shop. Once changed, you will be redirected to reopen your store to reflect the changes. </p>
            </div>
            <div className="col-md-7">
                
                <div className="mb-3">
                  <label className="form-label">Shop Name</label>
                  <input 
                  onChange={handleChange}
                  className="form-control" value={shopDetails.name} type="text" name="name" defaultValue={currentShop.title || currentShop.name} placeholder="Type here"/>
                </div>
              
                    <input type='hidden' name='shopId' value={currentShop.id || null} style={{display:"none"}}  />
                <div className="mb-3">
                  <label className="form-label">Description</label>
                  <textarea
                  onChange={handleChange}
                  style={{resize:'none',height:120}} name='description' type="text" defaultValue={currentShop.description} className="form-control"></textarea>
                </div>
                <div className="mb-3">
          	<label className="form-label">Type</label>
                <select 
                onChange={handleChange}
                name='type' required className='form-control' defaultValue={currentShop.type}>
                  <option value='E-Commerce' >E-Commerce</option>
                  <option value='Online-Store' >Online store</option>
                  <option value='Advert Center'>Advert Center</option>
                </select>
          </div> 

            </div> 
          </div>
          <div className="row border-bottom mb-4 pb-4">
            <div className="col-md-5">
              <h5>Location</h5>
              <p className="text-muted" style={{maxWidth:"90%"}}>You can as well change the location of your store. All changes will be reflected imediately.</p>
            </div>
            <div className="col-md-7">
            <div className="mb-3">
          	<label className="form-label">Shop Address</label>
			    <input 
          onChange={handleChange}
          required className="form-control" name='address' defaultValue={currentShop.address || ""} placeholder="Enter shop address" type="text"/>
          </div> 
          <div className="mb-3">
          	<label className="form-label">City</label>
			  <input 
        onChange={handleChange}
        required className="form-control" name='city' defaultValue={currentShop.city || ""} placeholder="City" type="text"/>
          </div>
          <div className="mb-3">
          	<label className="form-label">State</label>
			<input 
      onChange={handleChange}
      required className="form-control" name='state' defaultValue={currentShop.state || ""} placeholder="State" type="text"/>
          </div>

            </div>
          </div> 
          <div className="row border-bottom mb-4 pb-4">
            <div className="col-md-5">
              <h5>Contact </h5>
              <p className="text-muted" style={{maxWidth:"90%"}}>You can contact and follow our social handles .</p>
            </div>
            <div className="col-md-7">
            <div className="mb-3">
          	<label className="form-label">Contact Phone No.</label>
			    <input 
          onChange={handleChange}
           className="form-control" name='phone' defaultValue={currentShop.phone || ""} placeholder="Enter Phone" type="text"/>
          </div> 
          <div className="mb-3">
          	<label className="form-label">Whatsapp </label>
			    <input 
          onChange={handleChange}
           className="form-control" name='whatsapp' defaultValue={currentShop.whatsapp || ""} placeholder="Enter Whatsapp Link/Phone No." type="text"/>
          </div> 
          <div className="mb-3">
          	<label className="form-label"> Email </label>
			    <input 
          onChange={handleChange}
           className="form-control" name='email' defaultValue={currentShop.email || ""} placeholder="Enter Email" type="text"/>
          </div> 
          <div className="mb-3">
          	<label className="form-label">Facebook</label>
			  <input 
        onChange={handleChange}
         className="form-control" name='facebook' defaultValue={currentShop.facebook || ""} placeholder="E.g https://www.facebook.com/example" type='url'/>
          </div>
          <div className="mb-3">
          	<label className="form-label">Instagram</label>
			<input 
      onChange={handleChange}
       className="form-control" name='instagram' defaultValue={currentShop.instagram || ""} placeholder="E.g https://www.instagram.com/example" type="url"/>
          </div>
          <div className="mb-3">
          	<label className="form-label">X(Twitter)</label>
			<input 
      onChange={handleChange}
       className="form-control" name='twitter' defaultValue={currentShop.twitter || ""} placeholder="E.g https://www.x.com/example" type="url"/>
          </div>

            </div>
          </div> 


          <button className="btn t-btn" disabled={loading} type="submit">{loading?'Updating...':'Save all changes'}</button>  &nbsp;
       
          </form>
          
           </section>
      </Tab>
      <Tab
      eventKey={'delivery'} title = {<p className='t-label'>Delivery</p>}
      >
          <section className="content-body p-xl-4">
          

          <form method='POST' onSubmit={async (e)=>{
            e.preventDefault()
           await update_delivery_mode(delivery)
           dispatch(setCurrentStore(shop))
          }}>
          <div className="row mb-4 pb-4">
            <div className="col-md-5">
              <h5>Need delivery Service ?</h5>
              <p className="text-muted" style={{maxWidth:"90%"}}>
              At ShopAfrica, we can help you handle your delivery at convenient cost. </p>
            </div>
            <div className="col-md-7">
                <div className="mb-3">
          	<label className="form-label">Select Delivery Preference</label>
                <select 
                onChange={async (e)=>{
                  if (e.target.value == 'SHOPAFRICA_DELIVERY') {
                    Swal.fire({
                      text: "Coming soon!",
                      icon: 'info',
                      timer: 5000,
                      toast: true,
                      showConfirmButton: false,
                      position: 'top-end'
                  });
                  return
                  }
                  else{
                    setDeliveryData({...delivery,type:e.target.value})
                  }
                }}
                name='delivery_type' defaultValue={currentShop.delivery_type} required className='form-control' >
                  <option value={''}>Select delivery service</option>
                  <option  value="PERSONAL" selected={currentShop.delivery_type == 'PERSONAL'}>I will manage delivery myself</option>
                  <option value={"SHOPAFRICA_DELIVERY"} selected={currentShop.delivery_type == 'SHOPAFRICA_DELIVERY'}>Use ShopAfrica delivery partners</option>
                </select>
                </div> 
                  <div className="mb-3">
          	    <label className="form-label">Store Pickup Location </label>
                <input type="text" defaultValue={currentShop && currentShop.pickup_location}   onChange={(e)=> setDeliveryData({...delivery,pickup_location:e.target.value})} className="form-control"  name='pickup_location'/>
                </div> 
                
                <div className='form-group'>
                    <button className='btn t-btn'>{updatingDelivery ? "Saving...":"Save change"}</button>
                </div>
            </div> 
          </div>
          </form>
                {
                  currentShop.delivery_type == "PERSONAL" &&
                  <>
                  <div className="bg-white pt-4 pl-2 pr-2 pb-2">
                        <ul role="tablist" className="nav bg-light nav-pills rounded nav-fill mb-3">
                            <li style={{cursor:"pointer"}} className="nav-item" onClick={()=> setDeliveryTab('MANAGE')}> <a data-toggle="pill" className={`nav-link ${deliveryTab == 'MANAGE' && 'active'} `}> <i className="fa  mr-2"></i> Manage Delivery </a> </li>
                            <li style={{cursor:"pointer"}}  className="nav-item" onClick={()=> setDeliveryTab('CHARGES')}> <a data-toggle="pill"  className={`nav-link ${deliveryTab == 'CHARGES' && 'active'} `}> <i className="fa  mr-2"></i> Delivery Charges </a> </li>
                        </ul>
                  </div>
                    {
                      deliveryTab == "MANAGE" ?
                      <>
                  <div className="row mb-4 pb-4 mt-2">
                      <div className="col-md-5">
                        <span>Select Delivery Country</span>
                        <div className="mb-3 mt-1">
                          <select 
                          className='form-control' >
                            <option selected value={"1"}>Nigeria</option>
                          </select>
                          </div>
                      </div>
                      <Alert severity='info'>
                        It is advisable that you add a default delivery charge. This amount represent the minimum delivery charge for any state you have not registered it's delivery charge.
                      </Alert>
                      <div className="col-md-5">
                        <span>Default Delivery Charge (NGN)</span>
                        <div className="mb-3 mt-1">
                          <input type='number' placeholder='0'
                          defaultValue={currentShop && new Number(currentShop.default_charge_amount)}
                          onChange={(e)=>{
                            setDefaultDelivery(e.target.value)
                          }} className='form-control' />
                          <button type='button' className='btn btn-sm t-btn mt-2' onClick={()=>{
                            addDefaultCharge();
                          }}>{updatingDelivery ? 'Updating':"Update"}</button>
                          </div>
                      </div>
                    <div className='col-12'>
                      <span>Add State Delivery charge</span>
                        <div className='mt-2'>
                        {
                          delivery_data && delivery_data.map(function(data,dataIndex) {
                            return (
                              <div className='delivery_charge' key={dataIndex}>
                          <div className='row'>
                              <div className='col-lg-4 col-md-6 col-sm-12 mt-1 mb-1'>
                                <select className='form-control' onChange={(e)=>{
                                  const stateId = e.target.value
                                  const currentDeliveryData = delivery_data.slice()
                                  const findstate = currentDeliveryData.filter((d) => d.stateId == stateId)
                                  if(findstate && findstate.length > 0){
                                    Swal.fire({
                                      text: "You have already added this state.",
                                      icon: 'warning',
                                      timer: 5000,
                                      toast: true,
                                      showConfirmButton: false,
                                      position: 'top-end'
                                  })
                                  return
                                  }
                                  if (storeDeliveryData && (storeDeliveryData.filter(state => state.stateId == e.target.value)).length > 0) {
                                    Swal.fire({
                                      text: "This state already exist in your store.",
                                      icon: 'warning',
                                      timer: 5000,
                                      toast: true,
                                      showConfirmButton: false,
                                      position: 'top-end'
                                  })
                                  return
                                  }
                                  else{
                                    var stateData = currentDeliveryData[dataIndex]
                                    const newStateData = { ...stateData, stateId: stateId };
                                    const newDeliveryData = [...currentDeliveryData];
                                    newDeliveryData[dataIndex] = newStateData;
                                  dispatch(setStoreDeliveryState(newDeliveryData))
                                  }
                                }} value={data.stateId}>
                                  <option value={""}>Select state</option>
                                    {
                                      deliveryCountryStates && deliveryCountryStates.map(function(state){
                                        return <option value={state.id} key={createKey()}>{state.name}</option>
                                      })
                                    }
                                </select>
                              </div>
                              <div className='col-lg-4 col-md-6 col-sm-12 mt-1 mb-1'>
                                <input className='form-control' 
                                value={data.charge}
                                onChange={(e) => handleChargeChange(dataIndex, e.target.value)}
                                placeholder='Enter charge for state delivery' type='number' min={0}  />
                              </div>
                              <div className='col-lg-4 col-md-12 col-sm-12 mt-1 mb-1 d-flex'>
                                <div className='d-flex  align-items-center'>
                                    <button className='btn btn-sm btn-danger' onClick={()=>{
                                      const currentDeliveryData = delivery_data.slice()
                                      const newdata = currentDeliveryData.filter((_,index)=> index != dataIndex)
                                      dispatch(setStoreDeliveryState(newdata))
                                      setChargeHolder([...chargeHolder.filter((_,index)=> index != dataIndex).slice()])
                                    }} ><i className="material-icons md-delete_forever"></i></button>
                                </div>
                              </div>
                            </div>  
                          </div>
                            )
                          })
                        }
                          <div className='col-12 mt-2'>
                            <button className="btn btn-sm t-btn" 
                            onClick={()=>{
                              const data = delivery_data.slice()
                              const newdata = [...data,{
                                stateId:"",
                                charge:"",
                            }]
                              dispatch(setStoreDeliveryState(newdata))
                              setChargeHolder([...chargeHolder,""])
                            }}
                            >Add More</button>
                          </div>
                          <button className='btn t-btn mt-2' type='button' onClick={()=>{
                            updateSelfDeliveryState()
                          }}>Update</button>
                        </div>
                    </div>
                  </div>
                      </>:
                      <>
                        <div className='table-responsive mt-2'>
                            <table className="table">
                            <thead>
                                <tr>
                                    <th className='w-25'>S/N</th>
                                    <th className='w-25'>State</th>
                                    <th className='w-25'>Charge per delivery</th>
                                    <th className='w-25'></th>
                                </tr>
                            </thead>
                            <tbody>
                          {
                            storeDeliveryData && storeDeliveryData.length > 0 &&
                            storeDeliveryData.map(function(data,index){
                              return (
                                <tr key={createKey()}>
                                  <td>{index + 1}</td>
                                  <td>{data.name}</td>
                                  <td>
                                    <input className='form-control' defaultValue={data.charge}  readOnly  />
                                  </td>
                                  <td>
                                    <div className='d-flex gap-2 align-items-center'>
                                      <button className='btn btn-primary btn-sm' onClick={()=>setChargeToUpdate({
                                        stateId:data.stateId,charge:data.charge,countryId:data.countryId,name:data.name,storeId:shopId
                                      })}  data-bs-toggle="modal" data-bs-target="#editdeliverydata" ><i className="material-icons md-edit"></i></button>
                                      <button className='btn btn-danger btn-sm' onClick={()=>{
                                        deleteStateDeliveryCharge({
                                          stateId:data.stateId,countryId:data.countryId,storeId:data.storeId
                                        })
                                      }}><i className="material-icons md-delete_forever"></i></button>
                                    </div>
                                  </td>
                                </tr>
                              )
                            })
                          }
                            </tbody>
                            </table>
                          </div>
                      </>
                    }
                  </>
                }
           </section>
      </Tab>
      <Tab eventKey="theme" title={<p className='t-label'> Withdrawal </p>}>
                             
      {currentUser.is_business_registered == 0 &&
            <>
              <Alert severity='warning' >
                <strong>You need to provide your business details and credentials to be able to Withdraw</strong>
              </Alert>
              <div className='d-flex align-items-center'>
                <button className='btn t-btn mt-3 btn-sm' onClick={()=>{
                navigate("/business-registration")
              }}>Proceed</button></div>
            </>}
            {
              currentUser.is_business_registered == 1 &&
              <div className="row">
            <div className="col-lg-6 mx-auto">
                    <div className="bg-white pt-4 pl-2 pr-2 pb-2">
                        <ul role="tablist" className="nav bg-light nav-pills rounded nav-fill mb-3">
                            <li className="nav-item"> <a data-toggle="pill" href="#" className={`nav-link ${withdrawState == 'COLLECTION' && 'active'} `}> <i className="fa fa-credit-card mr-2"></i> Account details </a> </li>
                            <li className="nav-item"> <a data-toggle="pill" href="#" className={`nav-link ${withdrawState == 'VERIFICATION' && 'active'} `}> <i className="fa fa-paypal mr-2"></i> Verification </a> </li>
                        </ul>
                    </div>
              {
                withdrawState == 'COLLECTION' && 
                <>
                <div className="mx-auto" style={{maxWidth:500}} >
                <div className="card-body">
                <form>
                <div className="mb-3">
                      <label className="form-label">Select Bank</label>
                          <select className='form-control' value={bank_detail.bank_code} onChange={(e)=>{
                              setBankDetail({...bank_detail,bank_code:e.target.value})
                          }}>
                            <option value={''}>Select option</option>
                            {
                              allbanks && allbanks.length > 0 && allbanks.map((bank,index)=>{
                                return (
                                  <option key={index} value={bank.code}>{bank.name}</option>
                                )
                              })
                            }
                          </select>
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Account Number</label>
                          <input className="form-control"  onChange={async (e)=>{
                              setBankDetail({...bank_detail,account_number:e.target.value})
                              if (e.target.value.length == 10 && bank_detail.bank_code != "" ) {
                               await verify_account({account_number:e.target.value,bank_code:bank_detail.bank_code})
                              }
                          }} value={bank_detail.account_number} placeholder="Enter your account number" type="number" maxLength={10} min={0}  />
                    </div>
                    
                    {
                      bank_detail.isverified != null &&
                      <>
                      <div className="mb-3">
                      <label className="form-label">Account Name</label>
                          {
                            bank_detail.isverified == true ?
                            <div className='alert alert-success'>{bank_detail.account_name}</div>:
                            <>
                            <div className='alert alert-danger'>Can't verified this account.Try again!</div>
                            </>
                          }
                    </div>
                      </>
                    }
                    <div className="mb-3">
                      <label className="form-label" >Amount To Withdrawal</label>
                      <input onChange={(e)=>{
                              setBankDetail({...bank_detail,amount:e.target.value})
                          }} className="form-control" placeholder="Enter amount" value={bank_detail.amount} type="number" min={100}  />
                    </div>
                    <input type='checkbox' onChange={(e)=>{
                              setBankDetail({...bank_detail,savefornext:e.target.checked})
                          }} /> Save details for another withdrawal.
                    <div className="form-group mt-2">
                            <p> <button onClick={()=>{
                             if (bank_detail.isverified) {
                              setWithdrawState("VERIFICATION")
                             }
                            }} disabled={isverifying} type="button" className="btn t-btn ">
                              {isverifying ? "Verifying details ..." : <><i className="fa fa-mobile-alt mr-2"></i> Proceed Withdraw</>}
                              </button> </p>
                    </div>
                </form>
              

                </div> 
              </div>
                </>
              }
              {
                withdrawState == 'VERIFICATION' && 
                <>
                <div className="mx-auto" style={{maxWidth:500}} >
              <div className="card-body">
                  <div className='alert d-flex flex-column justify-content-center align-items-center'>
                      <h4 style={{textAlign:"center"}}>{bank_detail.account_name}</h4>
                      <span>{bank_detail.account_number}</span>
                      <span>{allbanks.filter((bank) => bank.code == bank_detail.bank_code )[0].name}</span>
                      <span style={{textAlign:'center'}}>Are you sure you want to Withdraw an amount of <b>₦ {bank_detail.amount.toLocaleString('en-US', {
									minimumFractionDigits: 2,
									maximumFractionDigits: 2,
								  })}</b> to your account ?</span>
                      <div className='d-flex mt-2'>
                        <button className='btn btn-sm btn-danger mx-1' disabled={isWithdrawing} onClick={()=>{
                          setBankDetail(initbankdetail)
                          setWithdrawState('COLLECTION')
                        }}>Cancel</button>
                        <button onClick={()=>{
                          initiateWithdrawal({...bank_detail,shopId})
                        }} className='btn btn-sm t-btn mx-1' disabled={isWithdrawing}>{isWithdrawing?"Processing...":"Proceed"}</button>
                      </div>
                  </div>
              </div> 
              </div>
                </>
              }

            </div>
            </div>
            }
            {currentUser.is_business_registered == 2 &&
            <>
              <Alert severity='info' >
                <strong>Your business details are being processed! You will be notified once it has been processed.</strong>
              </Alert>
            </>}
            {currentUser.is_business_registered == 3 &&
            <>
              <Alert severity='warning' >
                <strong>You business details failed verification. Ensure you upload the correct credentials required.</strong>
              </Alert>
              <div className='d-flex align-items-center'>
                <button className='btn t-btn mt-3 btn-sm' onClick={()=>{
                navigate("/business-registration")
              }}>Proceed</button></div>
            </>}
      </Tab>

    </Tabs>
          </>
        
        </div>
    </div> 
        </section>
    
      <Toast
              show={toastShow} delay={4000}
              className="d-inline-block m-1"
              bg={updateStatus && `${updateStatus.success ? 'success' :'danger'}`}
              style={{
                  position: "fixed",
                  right: 0,
                  bottom: 30
              }}
              >
              <Toast.Body className={'text-white'}>
              {updateStatus && updateStatus.message}
              </Toast.Body>
      </Toast>
      <div className="modal fade" id='editdeliverydata'  tabindex="-1" aria-labelledby="editdeliverydata" data-bs-backdrop="static" data-bs-keyboard="false"   aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                  <div className="modal-header border-0">
                    <h1 className="modal-title fs-5" id="editdeliverydata">Edit State Charge</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                    <>
                    <div className="modal-body">
                    <div className="mb-4">
                              <label for="product_name" className="form-label">Name</label>
                              <input type="text" name='name' required value={chargeToUpdate && chargeToUpdate.name} className="form-control"  readOnly />
                        </div>
                        <div className="mb-4">
                              <label for="product_name" className="form-label">Charge</label>
                              <input type="text" name='name' required value={chargeToUpdate && chargeToUpdate.charge} onChange={(e)=>{
                                setChargeToUpdate({...chargeToUpdate,charge:e.target.value})
                              }} className="form-control"   />
                        </div>
                        <div className="mb-4">
                            <button className='btn t-btn' type='button' onClick={()=>{
                              updateStateDeliveryCharge()
                            }} >Update</button>
                        </div>
                    </div>
                    </>
                </div>
              </div>
		</div>
        </main>
      );
}

export default Settings