import React from 'react'
import Nav from '../../components/landing/Nav'
import MainFooter from '../../components/MainFooter'

export default function PrivacyPolicy() {
  return (
    <React.Fragment>
        <>
            <Nav />
        </>
        <div className="container my-5">
      <h1 className="text-center mb-4">ShopAfrica Privacy Policy</h1>
      <p className="mb-4 text-center px-lg-4">Published date November 1, 2023</p>
      {/* Introduction Section */}
      <section>
        <h2>Introduction</h2>
        <p>By using this Site, you indicate your agreement with this policy. If you do not consent to this policy, please refrain from using our Site. Your continued usage of the Site after any modifications to this policy are posted will be considered as your acceptance of those changes.</p>
        <br/>
        <p>ShopAfrica (hereinafter referred to as "we" or the "data controller") operates an e-commerce system for online product sales. This Privacy Policy dictates how shopafrica.com collects, utilizes, manages, and discloses information obtained from users and clients of the shopafrica.com website ("Site"). This privacy policy is applicable to the Site and all products and services provided on shopafrica.com. For the purposes of this Site, a "User" is any individual who accesses the Site, including newsletter subscribers, and a "Client" is any person registered with the Site. These privacy policies are binding whenever you access the content and services we offer, regardless of the device you use, such as a computer, cell phone, tablet, TV, and more.</p>
      </section>

      {/* Information Collection Section */}
      <section className='mt-3'>
        <h2>Information Collection</h2>
        <br />
        <p><strong>Account Information:</strong> When you create an account, we may collect your name, email address, and contact details.</p>
        <p><strong>Customer Support:</strong> To respond to your inquiries and provide assistance.</p>
        <p><strong> Improving Our Services:</strong> To enhance our platform, products, and services.</p>
        <p><strong>Marketing:</strong> To inform you about promotions, products, and updates, but only with your consent.</p>
        <p><strong>Legal Compliance:</strong> To meet legal and regulatory requirements.</p>
      </section>

      {/* Use of Information Section */}
      <section className='mt-2'>
        <h2>Use of Information</h2>
        <p className='mt-1'>We use your data for the following purposes:</p>
        <p><strong> Account Management:</strong> To create and manage your account, including order processing, payment, and communications.</p>
        <p><strong>Customer Support:</strong> To respond to your inquiries and provide assistance.</p>
        <p><strong>Improving Our Services:</strong> To enhance our platform, products, and services.</p>
        <p><strong>Marketing:</strong> To inform you about promotions, products, and updates, but only with your consent.</p>
        <p><strong>Legal Compliance:</strong> To meet legal and regulatory requirements.</p>
      </section>
      <p><strong></strong></p>
      {/* Information Sharing and Disclosure Section */}
      <section className='mt-3'>
        <h2>Information Sharing and Disclosure</h2>
        <p className='mt-1'>We may share your information with:</p>
        <p><strong>Service Providers:</strong> Third-party service providers who assist us in delivering our services, such as payment processors or delivery partners.</p>
        <p><strong>Legal Obligations:</strong> When required to comply with legal requests or to protect our rights, privacy, safety, or property.</p>
      </section>

      {/* User Rights and Choices Section */}
      <section className='mt-3'>
        <h2>User Rights and Choices</h2>
        <p className='mt-1'>You have the right to: </p>
        <p><strong> Access, Correct, or Delete Your Data:</strong> You can update or delete your personal information in your account settings.</p>
        <p><strong>Marketing Preferences:</strong> You can opt-out of marketing communications.</p>
      </section>

      {/* Data Security Section */}
      <section className='mt-3'>
        <h2>Data Security</h2>
        <p>We use cookies and similar tracking technologies. You can manage cookie preferences in your browser settings. We implement reasonable security measures to safeguard your data. However, no method of transmission or storage is completely secure. We cannot guarantee the security of your information.
        Your data may be transferred to and stored on servers located outside your country. By using ShopAfrica, you consent to this transfer.
        </p>
      </section>

      {/* Changes to the Privacy Policy Section */}
      <section className='mt-3'>
        <h2>Changes to the Privacy Policy</h2>
        <p>We may update this Privacy Policy. Check the "Last Updated" date at the top of the page for the latest version.</p>
      </section>

      {/* Contact Information Section */}
      <section className='mt-3'>
        <h2>Contact Information</h2>
        <p>If you have questions or concerns about this Privacy Policy or your data, please contact us.</p>
      </section>
      
    </div>
    <div className="container">
            <MainFooter />
        </div>
    </React.Fragment>
  )
}
