import { FETCH_SHOP_DATA, FETCH_SHOP_FAILED, GET_CURRENT_STORE_REVIEWS_SUCCESS, LOADING_SHOP_DATA,SET_CURRENT_STORE, SET_STORE_DELIVERY_DATA } from "../action/types";

const initialShopData = {shops:[]};

export const shopDataReducer = (state = initialShopData,action) => {
  switch(action.type){
      case LOADING_SHOP_DATA:
          return {loading:true}
          break;
      case FETCH_SHOP_DATA:
          return {loading:false,shops:action.payload.shops,data:action.payload.data}
          break;
      case FETCH_SHOP_FAILED:
          return {loading:true,...action.payload}
          break;
      default:
          return state;
          break
  }
}
const initialStoreData = {}
export const setStoreReducer = (state = initialStoreData,action) => {
    switch (action.type) {
        case SET_CURRENT_STORE:
            return action.payload
            break;
        default:
            return state
            break;
    }
}
const initialStoreReviewData = {
    data:false
}
export const setStoreReviewReducer = (state = initialStoreReviewData,action) => {
    switch (action.type) {
        case GET_CURRENT_STORE_REVIEWS_SUCCESS:
            return action.payload
            break;
        default:
            return state
            break;
    }
}

const initialStoreDeliveryData = [{
    stateId:"",
    charge:"",
}];
export const setStoreDeliveryReducer = (state = initialStoreDeliveryData,action) => {
    switch (action.type) {
        case SET_STORE_DELIVERY_DATA:
            return [...action.payload]
            break;
        default:
            return state
            break;
    }
}