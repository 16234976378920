import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Nav, { Header } from '../../../components/dashboard/Nav'
import { setCurrentStore } from '../../../redux/action/shop'
import { getUserData } from '../../../redux/action/user'
import DashboardContent from './DashboardContent'
import { Spinner } from 'react-bootstrap';
import NeedHelp from '../../../components/dashboard/NeedHelp'
function Dashboard(){
    const {shop} = useParams()
    const {currentShop,currentUser} = useSelector(state => state)
    const dispatch = useDispatch()
    useEffect(()=>{
        !currentUser.data && dispatch(getUserData())
        !currentShop.data && dispatch(setCurrentStore(shop))
        return ()=> {
            return null
        }
    },[currentUser.loading,currentShop.loading]);
    return (
        currentShop.loading || currentUser.loading ?
    <>
    <div className='page-preloader'>
    <Spinner animation="border" >
    <>{currentUser.err && "Network failure,Please refresh"}</></Spinner>
</div>
</> :
        <React.Fragment>
            <Nav activeState={'dashboard'}/>
            <>
            <main className='main-wrap'>
                <Header />
                <React.Fragment>
                    <DashboardContent data = {currentShop} />
                </React.Fragment>
            </main>

            </>
            <NeedHelp />
        </React.Fragment>
    )
}


export default Dashboard