import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentStore } from '../../../../../../redux/action/shop'
import { useParams } from 'react-router-dom'
import { useEffect,useState } from 'react'
import { getUserData } from '../../../../../../redux/action/user'
import { dispatchMediaStyleForComponent } from '../../../../../../components/shop/theme-constants'
import { DefaultThemeMobileNav } from '../../../../../../components/shop/themes/defaultThemeComponents'

function T1_Shop() {
    const {shop} = useParams()
    const {currentShop,currentUser,defaultTheme,themePreviewState } = useSelector(state => state)
    const [navBarSettingShow,setNavBarSettingShow] = useState(false)
    const dispatch = useDispatch()
    useEffect(()=>{
        !currentUser.data && dispatch(getUserData())
        !currentShop.data && dispatch(setCurrentStore(shop))
//defaultTheme.landing.header.navStyle
    },[currentShop.data,])
    return (
        <>
            <DefaultThemeMobileNav
            show={navBarSettingShow}
            onHide={()=>setNavBarSettingShow(false)}
            />
                    <section className="main-navbar-jevelin" 
                    style={dispatchMediaStyleForComponent(themePreviewState.state,defaultTheme,'landing','header','navStyle')}>
                        <img
                            src={currentShop.image}
                            height={50}
                            width={100}
                            alt='shop logo'
                            className="sh-header-builder-logo-standard"
                            style={dispatchMediaStyleForComponent(themePreviewState.state,defaultTheme,'landing','header','siteImageStyle')}
                        />

                        <nav className="navbar-jevelin">
                            {
                                defaultTheme.landing['header'].navLinks.map((link,i)=>{
                                    return (
                                        <a href='#'style={{
                                            color:dispatchMediaStyleForComponent(themePreviewState.state,defaultTheme,'landing','header','navStyle')['color']
                                        }} key={i}>{link}</a>
                                    )
                                })
                            }
                        </nav>
                            {
                                
                                <>
                                    <div className="social-media-navbar">
                            {
                                defaultTheme.landing['header'].socialMedia.length > 0 &&
                                defaultTheme.landing['header'].socialMedia.map((med,i)=>{
                                   if(med.link !== ""){
                                    return (
                                        <a key={i} href={med.link} style={{
                                            color:dispatchMediaStyleForComponent(themePreviewState.state,defaultTheme,'landing','header','navStyle')['color']
                                        }}><span className={`fa ${med.icon} social-media`}></span></a>
                                    )
                                   }
                                })
                            }
                                        <span className="fa fa-bars menu-bar" onClick={()=>{
                                                setNavBarSettingShow(true)
                                        }}></span>
                                        <span className="fa fa-shopping-cart"></span>
                                        <strong className="cart-info">0</strong>
                                    </div>
                                </>
                            }
                        
                    </section>
                    <section className="shop-section">
      <div>
        <h2>Shop</h2>
      </div>
      <div className="shop-to-home-link">
        <a href="index.html">Home </a>
        <span className="fa fa-angle-right"></span>
        <span>Product</span>
      </div>
    </section>
    <section className="shop-items">
      <div className="shop-items-main">
        <div className="shop-items-default-sorting">
          <select className="select-order">
            <option value="Default Sorting">Default Sorting</option>
            <option value="Sort by Popularity">Sort by Popularity</option>
            <option value="Sort by Popularity">Sort by Average Rating</option>
            <option value="Sort by Popularity">Sort by latest</option>
            <option value="Sort by Popularity">
              Sort by price : Low to High
            </option>
            <option value="Sort by Popularity">
              Sort by price : High to Low
            </option>
          </select>
          <p>Showing all <strong>9</strong> results</p>
        </div>
        <div className="shopping-items-with-price">
          <div>
            <img
              src="https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-300x300.jpg"
              className=""
              alt=""
              loading="lazy"
              srcset="
                https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-300x300.jpg 300w,
                https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-150x150.jpg 150w,
                https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-100x100.jpg 100w,
                https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-660x660.jpg 660w
              "
            />
            <div className="item-details">
              <div>
                <p>Table succulent seed</p>
                <strong>$5.00</strong>
              </div>
              <p><span className="fa fa-shopping-cart"></span></p>
            </div>
          </div>

          <div>
            <img
              src="https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-300x300.jpg"
              className=""
              alt=""
              loading="lazy"
              srcset="
                https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-300x300.jpg 300w,
                https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-150x150.jpg 150w,
                https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-100x100.jpg 100w,
                https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-660x660.jpg 660w
              "
            />
            <div className="item-details">
              <div>
                <p>Table succulent seed</p>
                <strong>$5.00</strong>
              </div>
              <p><span className="fa fa-shopping-cart"></span></p>
            </div>
          </div>
          <div>
            <img
              src="https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-300x300.jpg"
              className=""
              alt=""
              loading="lazy"
              srcset="
                https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-300x300.jpg 300w,
                https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-150x150.jpg 150w,
                https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-100x100.jpg 100w,
                https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-660x660.jpg 660w
              "
            />
            <div className="item-details">
              <div>
                <p>Table succulent seed</p>
                <strong>$5.00</strong>
              </div>
              <p><span className="fa fa-shopping-cart"></span></p>
            </div>
          </div>
        </div>
        <div className="shopping-items-with-price">
          <div>
            <img
              src="https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-300x300.jpg"
              className=""
              alt=""
              loading="lazy"
              srcset="
                https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-300x300.jpg 300w,
                https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-150x150.jpg 150w,
                https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-100x100.jpg 100w,
                https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-660x660.jpg 660w
              "
            />
            <div className="item-details">
              <div>
                <p>Table succulent seed</p>
                <strong>$5.00</strong>
              </div>
              <p><span className="fa fa-shopping-cart"></span></p>
            </div>
          </div>

          <div>
            <img
              src="https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-300x300.jpg"
              className=""
              alt=""
              loading="lazy"
              srcset="
                https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-300x300.jpg 300w,
                https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-150x150.jpg 150w,
                https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-100x100.jpg 100w,
                https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-660x660.jpg 660w
              "
            />
            <div className="item-details">
              <div>
                <p>Table succulent seed</p>
                <strong>$5.00</strong>
              </div>
              <p><span className="fa fa-shopping-cart"></span></p>
            </div>
          </div>
          <div>
            <img
              src="https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-300x300.jpg"
              className=""
              alt=""
              loading="lazy"
              srcset="
                https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-300x300.jpg 300w,
                https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-150x150.jpg 150w,
                https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-100x100.jpg 100w,
                https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-660x660.jpg 660w
              "
            />
            <div className="item-details">
              <div>
                <p>Table succulent seed</p>
                <strong>$5.00</strong>
              </div>
              <p><span className="fa fa-shopping-cart"></span></p>
            </div>
          </div>
        </div>
        <div className="shopping-items-with-price">
          <div>
            <img
              src="https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-300x300.jpg"
              className=""
              alt=""
              loading="lazy"
              srcset="
                https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-300x300.jpg 300w,
                https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-150x150.jpg 150w,
                https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-100x100.jpg 100w,
                https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-660x660.jpg 660w
              "
            />
            <div className="item-details">
              <div>
                <p>Table succulent seed</p>
                <strong>$5.00</strong>
              </div>
              <p><span className="fa fa-shopping-cart"></span></p>
            </div>
          </div>

          <div>
            <img
              src="https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-300x300.jpg"
              className=""
              alt=""
              loading="lazy"
              srcset="
                https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-300x300.jpg 300w,
                https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-150x150.jpg 150w,
                https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-100x100.jpg 100w,
                https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-660x660.jpg 660w
              "
            />
            <div className="item-details">
              <div>
                <p>Table succulent seed</p>
                <strong>$5.00</strong>
              </div>
              <p><span className="fa fa-shopping-cart"></span></p>
            </div>
          </div>
          <div>
            <img
              src="https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-300x300.jpg"
              className=""
              alt=""
              loading="lazy"
              srcset="
                https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-300x300.jpg 300w,
                https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-150x150.jpg 150w,
                https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-100x100.jpg 100w,
                https://jevelin.shufflehound.com/minimal-furniture-shop/wp-content/uploads/sites/42/2018/03/5559331632_2_1_1_0011_jeremy-lwanga-uUwEAW5jFLE-unsplash-660x660.jpg 660w
              "
            />
            <div className="item-details">
              <div>
                <p>Table succulent seed</p>
                <strong>$5.00</strong>
              </div>
              <p><span className="fa fa-shopping-cart"></span></p>
            </div>
          </div>
        </div>
      </div>
      <div className="shopping-categories">
        <h4>CATEGORIES</h4>
        <div className="shopping-in-stock">
          <p>CHAIRS</p>
          <p className="shopping-in-stock-number"><strong>9</strong></p>
        </div>
        <hr />
        <div className="shopping-in-stock">
          <p>DECOR</p>
          <p className="shopping-in-stock-number"><strong>9</strong></p>
        </div>
        <hr />
        <div className="shopping-in-stock">
          <p>LIGHT</p>
          <p className="shopping-in-stock-number"><strong>9</strong></p>
        </div>
        <hr />
        <div className="shopping-in-stock">
          <p>PLANT</p>
          <p className="shopping-in-stock-number"><strong>9</strong></p>
        </div>
        <hr />
        <div>
          <h4>FILTER BY PRICE</h4>

          <input
            type="range"
            min="1"
            max="100"
            value="50"
            className="slider"
            id="myRange"
          />
        </div>
        <div className="filter-price">
            <p>Price :  <strong>    $5 - $25</strong></p>
            <button>FILTER</button>
        </div>
      </div>
    </section>

            
        </>
        )
}

export default T1_Shop