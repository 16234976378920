import React, { useEffect } from 'react'
import '../../assets/css/pricing.css'
import { CheckedIcon } from '../../components/Icons'
import Nav from '../../components/landing/Nav'
import MainFooter from '../../components/MainFooter'
export default function () {
    useEffect(()=>{
        return () => {
            return null
        }
    })
  return (
    <>
    <Nav />
        <div>
			<div className="container-lg">
				<div className="v-wrap">
					<header className="v-main-header d-flex flex-column align-items-center col-12 col-sm-10 col-md-8 col-lg-6 text-center row-gap-2 pb-4 mx-auto">
						<h3 className="v-header-title">Choose your plan</h3>
						<span className="v-subtext">Publish your passions your way. Whether you'd like to share your knowledge, experiences or latest news</span>
					</header>
					<div className="v-wrap-inner">
						<main>
							<div className="v-parent-plan-container">
								<div className="mt-4 d-flex flex-column align-items-start flex-sm-row column-gap-2">
									<h5 className="v-parent-container-title">Small Businesses</h5>
									<span className="v-price-range">(0 - 5M monthly in sales)</span>
								</div>
								<div className="v-each-4-cards-container">
									<div>
										<div className="v-each-main-card">
											<div className="v-each-main-card-inner">
												<header className="v-card-header">
													<h5 className="v-card-type">Starter</h5>
													<div className="v-card-price">
														<h2 className="v-card-price-value">Free</h2>
													</div>
												</header>
												<div className="v-card-body">
													<ul className="v-listed-goodies">
														<li className="v-each-goodie">
															<span className="v-icon">
																<CheckedIcon />
															</span>
															<span className="v-goodie">1 Store</span>
														</li>
														<li className="v-each-goodie">
                                                            <span className="v-icon">
																<CheckedIcon />
															</span>
															<span className="v-goodie">Unique store URL</span>
														</li>
														<li className="v-each-goodie">
															<span className="v-icon">
                                                            <CheckedIcon />
															</span>
															<span className="v-goodie">50 messaging credits</span>
														</li>
														<li className="v-each-goodie">
															<span className="v-icon">
                                                            <CheckedIcon />
															</span>
															<span className="v-goodie">Marketing campaigns</span>
														</li>
														<li className="v-each-goodie">
															<span className="v-icon">
                                                            <CheckedIcon />
															</span>
															<span className="v-goodie">20 products listing</span>
														</li>
														<li className="v-each-goodie">
															<span className="v-icon">
																<CheckedIcon />
															</span>
															<span className="v-goodie">&#8358;100 service charge</span>
														</li>
														<li className="v-each-goodie">
															<span className="v-icon">
																<CheckedIcon />
															</span>
															<span className="v-goodie">0 - 20 sales monthly</span>
														</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
									<div>
										<div className="v-each-main-card v-best-plan">
											<div className="v-tag">Best Price</div>
											<div className="v-each-main-card-inner">
												<header className="v-card-header">
													<h5 className="v-card-type">Basic</h5>
													<div className="v-card-price">
														<h2 className="v-card-price-value">&#8358; 3000</h2>
														<span className="v-subtext">/mo</span>
													</div>
												</header>
												<div className="v-card-body">
													<ul className="v-listed-goodies">
														<li className="v-each-goodie">
															<span className="v-icon">
																<CheckedIcon />
															</span>
															<span className="v-goodie">1 Store</span>
														</li>
														<li className="v-each-goodie">
															<span className="v-icon">
																<CheckedIcon />
															</span>
															<span className="v-goodie">Unique store URL</span>
														</li>
														<li className="v-each-goodie">
															<span className="v-icon">
																<CheckedIcon />
															</span>
															<span className="v-goodie">100 messaging credits</span>
														</li>
														<li className="v-each-goodie">
															<span className="v-icon">
																<CheckedIcon />
															</span>
															<span className="v-goodie">Marketing campaigns</span>
														</li>
														<li className="v-each-goodie">
															<span className="v-icon">
																<CheckedIcon />
															</span>
															<span className="v-goodie">50 products listing</span>
														</li>
														<li className="v-each-goodie">
															<span className="v-icon">
																<CheckedIcon />
															</span>
															<span className="v-goodie">&#8358;70 service charge</span>
														</li>
														<li className="v-each-goodie">
															<span className="v-icon">
																<CheckedIcon />
															</span>
															<span className="v-goodie">0 - 50 sales monthly</span>
														</li>
													</ul>
													<div className="v-cta-container">
														<a href="" className="v-cta">Choose plan</a>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div>
										<div className="v-each-main-card">
											<div className="v-each-main-card-inner">
												<header className="v-card-header">
													<h5 className="v-card-type">Standard</h5>
													<div className="v-card-price">
														<h2 className="v-card-price-value">&#8358; 5000</h2>
														<span className="v-subtext">/mo</span>
													</div>
												</header>
												<div className="v-card-body">
													<ul className="v-listed-goodies">
														<li className="v-each-goodie">
															<span className="v-icon">
																<CheckedIcon />
															</span>
															<span className="v-goodie">2 Stores</span>
														</li>
														<li className="v-each-goodie">
															<span className="v-icon">
																<CheckedIcon />
															</span>
															<span className="v-goodie">Unique store URL</span>
														</li>
														<li className="v-each-goodie">
															<span className="v-icon">
																<CheckedIcon />
															</span>
															<span className="v-goodie">150 messaging credits</span>
														</li>
														<li className="v-each-goodie">
															<span className="v-icon">
																<CheckedIcon />
															</span>
															<span className="v-goodie">Marketing campaigns</span>
														</li>
														<li className="v-each-goodie">
															<span className="v-icon">
																<CheckedIcon />
															</span>
															<span className="v-goodie">100 products listing</span>
														</li>
														<li className="v-each-goodie">
															<span className="v-icon">
																<CheckedIcon />
															</span>
															<span className="v-goodie">&#8358;50 service charge</span>
														</li>
														<li className="v-each-goodie">
															<span className="v-icon">
																<CheckedIcon />
															</span>
															<span className="v-goodie">0 - 300 sales monthly</span>
														</li>
													</ul>
													<div className="v-cta-container">
														<a href="" className="v-cta">Choose plan</a>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</main>
			
						<main>
							<div className="v-parent-plan-container">
								<div className="mt-4 d-flex flex-column align-items-start flex-sm-row column-gap-2">
									<h5 className="v-parent-container-title">Medium Businesses</h5>
									<span className="v-price-range">(5M - 10M monthly in sales)</span>
								</div>
								<div className="v-each-4-cards-container">
									<div>
										<div className="v-each-main-card">
											<div className="v-each-main-card-inner">
												<header className="v-card-header">
													<h5 className="v-card-type">Pro</h5>
													<div className="v-card-price">
														<h2 className="v-card-price-value">&#8358; 10,000</h2>
														<span className="v-subtext">/mo</span>
													</div>
												</header>
												<div className="v-card-body">
													<ul className="v-listed-goodies">
														<li className="v-each-goodie">
															<span className="v-icon">
																<CheckedIcon />
															</span>
															<span className="v-goodie">3 Stores</span>
														</li>
														<li className="v-each-goodie">
															<span className="v-icon">
																<CheckedIcon />
															</span>
															<span className="v-goodie">Unique store URL</span>
														</li>
														<li className="v-each-goodie">
															<span className="v-icon">
																<CheckedIcon />
															</span>
															<span className="v-goodie">200 messaging credits</span>
														</li>
														<li className="v-each-goodie">
															<span className="v-icon">
																<CheckedIcon />
															</span>
															<span className="v-goodie">Marketing campaigns</span>
														</li>
														<li className="v-each-goodie">
															<span className="v-icon">
																<CheckedIcon />
															</span>
															<span className="v-goodie">200 products listing</span>
														</li>
														<li className="v-each-goodie">
															<span className="v-icon">
																<CheckedIcon />
															</span>
															<span className="v-goodie">&#8358;30 service charge</span>
														</li>
														<li className="v-each-goodie">
															<span className="v-icon">
																<CheckedIcon />
															</span>
															<span className="v-goodie">0 - 500 sales monthly</span>
														</li>
													</ul>
													<div className="v-cta-container">
														<a href="" className="v-cta">Choose plan</a>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div>
										<div className="v-each-main-card">
											<div className="v-each-main-card-inner">
												<header className="v-card-header">
													<h5 className="v-card-type">Basic</h5>
													<div className="v-card-price">
														<h2 className="v-card-price-value">&#8358; 20,000</h2>
														<span className="v-subtext">/mo</span>
													</div>
												</header>
												<div className="v-card-body">
													<ul className="v-listed-goodies">
														<li className="v-each-goodie">
															<span className="v-icon">
																<CheckedIcon />
															</span>
															<span className="v-goodie">5 Stores</span>
														</li>
														<li className="v-each-goodie">
															<span className="v-icon">
																<CheckedIcon />
															</span>
															<span className="v-goodie">Unique store URL</span>
														</li>
														<li className="v-each-goodie">
															<span className="v-icon">
																<CheckedIcon />
															</span>
															<span className="v-goodie">400 messaging credits</span>
														</li>
														<li className="v-each-goodie">
															<span className="v-icon">
																<CheckedIcon />
															</span>
															<span className="v-goodie">Marketing campaigns</span>
														</li>
														<li className="v-each-goodie">
															<span className="v-icon">
																<CheckedIcon />
															</span>
															<span className="v-goodie">500 products listing</span>
														</li>
														<li className="v-each-goodie">
															<span className="v-icon">
																<CheckedIcon />
															</span>
															<span className="v-goodie">&#8358;20 service charge</span>
														</li>
														<li className="v-each-goodie">
															<span className="v-icon">
																<CheckedIcon />
															</span>
															<span className="v-goodie">0 - 1000 sales monthly</span>
														</li>
													</ul>
													<div className="v-cta-container">
														<a href="" className="v-cta">Choose plan</a>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</main>
						<main>
							<div className="v-parent-plan-container">
								<div className="mt-4 d-flex flex-column align-items-start flex-sm-row column-gap-2">
									<h5 className="v-parent-container-title">Large Businesses</h5>
									<span className="v-price-range">(above 10M monthly in sales)</span>
								</div>
								<div className="v-each-4-cards-container">
									<div>
										<div className="v-each-main-card">
											<div className="v-each-main-card-inner">
												<header className="v-card-header">
													<h5 className="v-card-type">Premium</h5>
													<div className="v-card-price">
														<h2 className="v-card-price-value">&#8358; 50,000</h2>
														<span className="v-subtext">/mo</span>
													</div>
												</header>
												<div className="v-card-body">
													<ul className="v-listed-goodies">
														<li className="v-each-goodie">
															<span className="v-icon">
																<CheckedIcon />
															</span>
															<span className="v-goodie"> 7 Stores</span>
														</li>
														<li className="v-each-goodie">
															<span className="v-icon">
																<CheckedIcon />
															</span>
															<span className="v-goodie">Unique store URL, can request custom domain, can request site modifications.</span>
														</li>
														<li className="v-each-goodie">
															<span className="v-icon">
																<CheckedIcon />
															</span>
															<span className="v-goodie">1000 messaging credits monthly</span>
														</li>
														<li className="v-each-goodie">
															<span className="v-icon">
																<CheckedIcon />
															</span>
															<span className="v-goodie">Marketing campaigns</span>
														</li>
														<li className="v-each-goodie">
															<span className="v-icon">
																<CheckedIcon />
															</span>
															<span className="v-goodie">1000 products listing</span>
														</li>
														<li className="v-each-goodie">
															<span className="v-icon">
																<CheckedIcon />
															</span>
															<span className="v-goodie">&#8358;10 service charge</span>
														</li>
														<li className="v-each-goodie">
															<span className="v-icon">
																<CheckedIcon />
															</span>
															<span className="v-goodie">Unlimited sales monthly</span>
														</li>
													</ul>
													<div className="v-cta-container">
														<a href="" className="v-cta">Choose plan</a>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div>
										<div className="v-each-main-card">
											<div className="v-each-main-card-inner">
												<header className="v-card-header">
													<h5 className="v-card-type">Enterprise</h5>
													<div className="v-card-price">
														<h2 className="v-card-price-value">Contact Sales</h2>
													</div>
												</header>
												<div className="v-card-body">
													<ul className="v-listed-goodies">
														<li className="v-each-goodie">
															<span className="v-icon">
																<CheckedIcon />
															</span>
															<span className="v-goodie"> 10 Stores and can request more stores</span>
														</li>
														<li className="v-each-goodie">
															<span className="v-icon">
																<CheckedIcon />
															</span>
															<span className="v-goodie">Unique store URL, can request custom domain, can request site modifications.</span>
														</li>
														<li className="v-each-goodie">
															<span className="v-icon">
																<CheckedIcon />
															</span>
															<span className="v-goodie">5000 messaging credits monthly</span>
														</li>
														<li className="v-each-goodie">
															<span className="v-icon">
																<CheckedIcon />
															</span>
															<span className="v-goodie">Marketing campaigns</span>
														</li>
														<li className="v-each-goodie">
															<span className="v-icon">
																<CheckedIcon />
															</span>
															<span className="v-goodie">Unlimited products listing</span>
														</li>
														<li className="v-each-goodie">
															<span className="v-icon">
																<CheckedIcon />
															</span>
															<span className="v-goodie">&#8358;10 service charge</span>
														</li>
														<li className="v-each-goodie">
															<span className="v-icon">
																<CheckedIcon />
															</span>
															<span className="v-goodie">Unlimited sales monthly</span>
														</li>
													</ul>
													<div className="v-cta-container">
														<a href="" className="v-cta">Choose plan</a>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</main>
					</div>
				</div>
			</div>
		</div>
    <>
        <MainFooter />
    </>
    </>
  )
}
