import { configureStore } from "@reduxjs/toolkit"
import  userSlice  from "./slice/userSlice"
import { shopDataReducer,setStoreReducer, setStoreReviewReducer, setStoreDeliveryReducer } from "./reducer/shopReducer"
import { addProductReducer, categoriesReducer, singleProductReducer, storeProductsReducer } from "./reducer/productReducer"
import { userDataReducer } from "./reducer/userDataReducer"
import { availableThemesReducer, changePreviewStateReducer, defaultThemeReducer, themePageReducer } from "./reducer/themesReducer"
import  productSlice  from "./slice/productSlice"
import shopSlice from "./slice/shopSlice"
import transactionSlice from "./slice/transactionSlice"
import orderSlice from "./slice/orderSlice"
import reviewSlice from "./slice/reviewSlice"


export default configureStore({
    reducer: {
        user:userSlice,
        products: productSlice,
        shop: shopSlice,
        transactionData: transactionSlice,
        userOrders : orderSlice,
        reviews: reviewSlice,
        shops:shopDataReducer,
        currentShop:setStoreReducer,
        product: addProductReducer,
        currentUser:userDataReducer,
        currentShopProducts:storeProductsReducer,
        singleProduct:singleProductReducer,
        availableThemes:availableThemesReducer,
        defaultTheme:defaultThemeReducer,
        themePreviewState:changePreviewStateReducer,
        themePage:themePageReducer,
        categories:categoriesReducer,
        delivery_data:setStoreDeliveryReducer
        // reviews:setStoreReviewReducer
    }
})