import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../assets/images/shopafrica-logo-2.png'
import Nav from './landing/Nav'
function MainNav(){
    return(
        <>
            <div className="main-banner" id="home">
                <Nav />
                <div className="banner-info">
                    <h3 className="mb-4">DREAM IT.BUILD IT. SELL IT.</h3>
                    <p>Create a stunning online store that reflects your brand's uniqueness, and start selling today.</p>
                    <div className="ban-buttons">
                        <Link to="/login" className="btn mt-2 mx-2">START SELLING</Link>
                    </div>
                </div>

            </div>
        </>
    );
}

export default MainNav