import { Accordion } from "react-bootstrap"
import { Link } from "react-router-dom"
import Dropdown from 'react-bootstrap/Dropdown';
import { useState,useEffect } from "react";
import { CustomFeatureDetails, CustomButtonSetting, CustomeDetailsSettings, ImageBannerSettings, NavBarSetting, CustomNewsletterDetails } from "../../../components/shop/themes/defaultThemeComponents";
import { FlashAutoOutlined } from "@mui/icons-material";
function CSideNavInner(){
const [navBarSettingShow,setNavBarSettingShow] = useState(false)
const [imageBannerShow,setImageBannerShow] =  useState(false)
const [customeDetailsShow,setCustomDetailShow] = useState(false)
const [customButton,setCustomButtonShow] = useState(false)
const [featureDescrition,setFeatureDescriptionShow] = useState(false)
const [showProductCollection,setCollectionProductsShow] = useState(false) 
const [showNewLetterSetting,setNewletterSetting] = useState(false)
  useEffect(()=>{

  },[])
    return (
        <>
          <>
          <NavBarSetting
            show={navBarSettingShow}
            onHide={()=>{setNavBarSettingShow(false)}}
          />
          <CustomNewsletterDetails
            show={showNewLetterSetting}
            onHide={()=>{setNewletterSetting(false)}}
          />
          <CustomFeatureDetails
          show = {featureDescrition}
          onHide = {()=> setFeatureDescriptionShow(false)}
          />
          <ImageBannerSettings
           show={imageBannerShow}
           onHide={()=>{setImageBannerShow(false)}}
           />
           <CustomeDetailsSettings
           show ={customeDetailsShow}
           onHide={()=>setCustomDetailShow(false)}
           />
           <CustomButtonSetting
           show = {customButton}
           onHide={()=>setCustomButtonShow(false)}
           />
          </>
           <Accordion defaultActiveKey="0" style={{
            border:'none'
           }}>
      <Accordion.Item eventKey="0" style={{
            border:'none'
           }}>
        <Accordion.Header>
          <i className="icon material-icons md-home"></i> 
        <span className="text">Header</span> </Accordion.Header>
        <Accordion.Body>
        <ul className="nav-menu">
          <a href="#" style={{fontSize:13}}  onClick={()=>{
            setNavBarSettingShow(true)
          }} className='nav-link'>Menu</a>
        </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1" style={{
            border:'none'
           }}>
        <Accordion.Header><i className="icon material-icons md-stars"></i> 
        <span className="text">Template</span> </Accordion.Header>
        <Accordion.Body>
        <ul className="nav-menu">
            <a href="#" onClick={()=>{
            setImageBannerShow(true)
          }}  className='nav-link' style={{fontSize:13}}>Image Banner</a>
            <a href="#"  className='nav-link' style={{fontSize:13}} onClick={()=> setCustomDetailShow(true)}>Custom details</a>
            <a href="#"  className='nav-link' style={{fontSize:13}} onClick={()=>setCustomButtonShow(true)}>Button</a>
        </ul>
        </Accordion.Body>
      </Accordion.Item >
      <Accordion.Item eventKey="2" style={{
            border:'none'
           }}>
        <Accordion.Header><i className="icon material-icons md-comment"></i> 
        <span className="text">Feature Collection</span> </Accordion.Header>
        <Accordion.Body>
        <ul className="nav-menu">
            <a href="#" onClick={()=>{
            setFeatureDescriptionShow(true)
          }}  className='nav-link' style={{fontSize:13}}>Collection description</a>
            <a href="#"  className='nav-link' style={{fontSize:13}} onClick={()=> setCollectionProductsShow(true)}>Products</a>
        </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3" style={{
            border:'none'
           }}>
        <Accordion.Header><i className="icon material-icons md-comment"></i> 
        <span className="text">Newsletter details</span> </Accordion.Header>
        <Accordion.Body>
        <ul className="nav-menu">
            <a href="#" onClick={()=>{
              setNewletterSetting(true)
          }}  className='nav-link' style={{fontSize:13}}>Newsletter</a>
        </ul>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
        </>
    )
}

export default CSideNavInner