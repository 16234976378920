import { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Axios } from "../../../api/axios";
import Swal from "sweetalert2";
import { Spinner } from "react-bootstrap";

function Checkout(){
    const {planId} = useParams();
    const [loading,setLoading] = useState(false)
    const [plan,setPlans] = useState(null)
    const [isSubscribing,setSubscribingStatus] = useState(false)
    const [checkoutData,setCheckoutData] = useState({
        number_of_month:1,price:0,planId
    });
    const navigator  =  useNavigate()
    const subscribe = async () => {
        setSubscribingStatus(true)
        const formdata = new FormData()
        for (const key in checkoutData) {
            formdata.append(key,checkoutData[key])
        }
        try {
            const res = await Axios.post('api?subscribe',formdata)
            const response = res.data
            if (response.success) {
                const data = response.data
                if(data.status == 'success'){
                    window.location.assign(data.url)
                }else{
                    Swal.fire({
                        text: data.message,
                        icon: 'error',
                        timer: 5000,
                        toast: true,
                        showConfirmButton: false,
                        position: 'top-end'
                    });
                }
            } else {
                Swal.fire({
                    text: response.message,
                    icon: 'error',
                    timer: 5000,
                    toast: true,
                    showConfirmButton: false,
                    position: 'top-end'
                });
            }
        } catch (error) {
            Swal.fire({
                text: error.message,
                icon: 'error',
                timer: 5000,
                toast: true,
                showConfirmButton: false,
                position: 'top-end'
            });
        }
        finally{
        setSubscribingStatus(false)
        }
    }
    const getPlanDetail = async () => {
        setLoading(true)
        try {
            const res = await Axios.get('api?plans',{
                params:{
                    planId
                }
            })
            const response = res.data
            if (response.success) {
                setCheckoutData({...checkoutData,price:response.data.price,})
                setPlans(response.data)
            } else {
                Swal.fire({
                    text: response.message,
                    icon: 'error',
                    timer: 5000,
                    toast: true,
                    showConfirmButton: false,
                    position: 'top-end'
                });
            }
        } catch (error) {
            Swal.fire({
                text: error.message,
                icon: 'error',
                timer: 5000,
                toast: true,
                showConfirmButton: false,
                position: 'top-end'
            });
        }
        finally{
            setLoading(false)
        }
    }
    function calculateFutureDate(numberOfMonths) {
        return Date()
      }
    
    useEffect(()=>{
        getPlanDetail()
    },[])
    return (
        loading ?
        <>
        <div className='page-preloader'>
                <Spinner animation="border" >
                </Spinner>
            </div>
        </>
        :
        <>
        <>
        <b className="screen-overlay"></b>
        <header className="main-header navbar">
            <div className="col-nav w-100 d-flex justify-content-end">
            <ul className="nav">
                <li className="nav-item">
                <Link className="nav-link" to={'/my-shops'}>Stores</Link>
                </li>
            </ul> 
            </div>
        </header>
       <section className="content-main" style={{maxWidth:600}}>

            {
                plan ? 
                <>
                <div className="card mb-4 mx-auto">
                <div className="card-body">
                <div className='card-header'>
                <h5 className="card-title mb-0">Billing Info</h5>
                  {
                    //<span>Enter the number of months for this plan(Maximum of 3 months)</span>
                    }
                </div>
                {
                    /*<div className="form-group w-40">
                    <input type="number" inputMode="numeric" min={1} max={3} value={checkoutData.number_of_month} onChange={(e)=>setCheckoutData({...checkoutData,number_of_month:e.target.value})} className="form-control " defaultValue={1} />
            </div>*/
                }
                <ul>
                <li className="d-flex mt-4 justify-content-between align-items-center">
                        <span>Plan</span>
                        <strong className="text-bold">{plan.plan}</strong>
                    </li>
                    <li className="d-flex mt-4 justify-content-between align-items-center">
                        <span>Price</span>
                        <strong className="text-bold">NGN {new Number(plan.price).toLocaleString()}</strong>
                    </li>
                    <li className="d-flex mt-4 justify-content-between align-items-center">
                        <span>Start</span>
                        <strong className="text-bold">Today</strong>
                    </li>
                    <li className="d-flex mt-4 justify-content-between align-items-center">
                        <span>Number of month</span>
                        <strong className="text-bold">{checkoutData.number_of_month}</strong>
                    </li>
                    <li className="d-flex mt-4 justify-content-between align-items-center">
                        <span>Total Amount</span>
                        <strong className="text-bold">NGN {(new Number(checkoutData.number_of_month * checkoutData.price).toLocaleString())}</strong>
                    </li>
                </ul>
                <button className="btn t-btn w-100 mt-4" disabled={isSubscribing} onClick={()=>{
                    subscribe()
                }}>{
                    isSubscribing ? 
                    <>
                    <Spinner animation="border" >
                    </Spinner>
                    </>:
                    <>Proceed</>

                }</button>
                </div>
        </div>
                </>:
                <>
                    <div className="alert alert-warning">No plan selected!</div>
                </>
            }
  
       </section>
    </>
        </>
    )
}
export default Checkout