import React, { useState } from 'react'
import ReactDOM from 'react-dom/client';
import { useEffect,useRef } from 'react'
import { Provider, useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, Route, Routes, useLocation, useParams,} from 'react-router-dom'
import { pageUrl } from '../../../api/api'
import CNav, { CHeader } from '../../../components/dashboard/CNav'
import { setCurrentStore } from '../../../redux/action/shop'
import store from '../../../redux/store'
import T1_Landing from '../themes/default/pages/landing/T1_Landing'
import CSideNavInner from './CSideNavInner'
import { dispatchMediaStyleForComponent } from '../../../components/shop/theme-constants';
import { DefaultThemeMobileNav } from '../../../components/shop/themes/defaultThemeComponents';
import T1_Shop from '../themes/default/pages/shop/T1_Shop';
import ThemeGroup from '../../../route/ThemeGroup';
import Protected from '../../../route/protected';



function CIndex(){
    const {shop} = useParams()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const {currentShop,themePreviewState,themePage} = useSelector(state => state)
    const dispatch = useDispatch()
    //const histor
    const [urlToComponent,setUrlToComponent] =  useState('../themes/default/pages/T1_Landing.jsx')
    const iframeRef = useRef(null);
    useEffect(()=>{
        !currentShop.data && dispatch(setCurrentStore(shop))
    
        
    },[currentShop.data,currentShop,themePage])
    return (
        <>
        <CNav />
        <>
            <main className='main-wrap'>
                <CHeader />
                <React.Fragment>
                <div className='row'>
                    <div className='col-lg-3 col-sm-12' style={{
                        display:`${themePreviewState.state !== 1 ? 'block':'none'}`
                    }}>
                    <div className="card m-1">
                    <div className="card-body h-sm-auto" style={{
                        //minHeight:"85vh",
                        padding:5
                    }}  >
                        <CSideNavInner />
                        </div>
                        
                        </div>
                    </div>
                    <div className={` 
                     ${themePreviewState.state === 2 && 'col-lg-9'}
                     ${themePreviewState.state === 1 && 'col-lg-12'}
                     ${themePreviewState.state === 3 && 'col-lg-5' } col-sm-12`}
                    style={ themePreviewState.state === 3 ? {
                        margin:'auto'
                    }:{}}
                    >
                    <div className="card m-1">
                    <div className="card-body" style={{
                        padding:5
                    }}>
                        <iframe 
                        ref={iframeRef}
                        srcDoc={`
                                        <html lang="en">
                                        <head>
                                            <meta charset="UTF-8" />
                                            <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                                                                                    
                                            <link rel="icon" href="/cloubank_logo.png" />
                                            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                                            <title>Document</title>
                                            <link
                                                rel="stylesheet"
                                                href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css"
                                            />
                                            <link
                                                rel="stylesheet"
                                                href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
                                            />
                        
                                            <link rel="preconnect" href="https://fonts.googleapis.com" />
                                            <link rel="stylesheet" href="../themes/default/assets/css/shops.css" />
                                            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
                                            <link rel="preconnect" href="https://fonts.googleapis.com" />
                                            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
                                            <link
                                                href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@500&display=swap"
                                                rel="stylesheet"
                                            />
                                            <link rel="stylesheet" href="/themes/default/style.css" />
                                            <link rel="stylesheet" href="/themes/default/shops.css" />
                                            <link rel="stylesheet" href="/../node_modules/grapesjs/dist/css/grapes.min.css" />
                                        </head>
                                        <body className='c1body'>
                                            <div id="iframe-root">
                                            </div>
                                        </body>
                                    </html>
                        
                        `}
                            onLoad={()=>{
                                const iframe = iframeRef.current;
                                // Get a reference to the iframe's document and window objects
                                const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
                                const iframeWin = iframe.contentWindow;
                                const root = ReactDOM.createRoot(iframeDoc.getElementById('iframe-root'));
                                root.render(
                                    <Provider store={store}>
                                        <>
                                          <ThemeGroup />    
                                        </>
                                    </Provider>
                                )
                            }}
                        style={{
                            minHeight:"85vh",
                            width:"100%"
                        }}>
                        </iframe>
                        </div>
                        </div>
                    </div>
                </div>
                </React.Fragment>
            </main>

            </>
        </>
    )
}


export default CIndex