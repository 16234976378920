import React, { useEffect, useState } from 'react'
import Nav, { Header } from '../../../components/dashboard/Nav'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../../../assets/css/products.css'
import { useDispatch, useSelector } from 'react-redux'
import { getUserData } from '../../../redux/action/user'
import { addProduct, fetchShopProducts } from '../../../redux/action/products'
import { userShopData } from '../../../redux/action/shop'
import { setCurrentStore } from '../../../redux/action/shop'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Spinner, } from 'react-bootstrap';
import { Axios, token } from '../../../api/axios'
import { pageUrl, getCategories, createProduct, isBase64, base64toFile, createKey, } from '../../../api/api'
import { Alert } from '@mui/material';
import Modal from 'react-bootstrap/Modal';
import { initProductData, productStatus } from '../../../redux/reducer/productReducer'
import { FaPlus } from 'react-icons/fa'
import { productCategories } from '../../../redux/slice/productSlice'
import AddMoreProductDetails from './AddMoreProductDetails'
import Swal from 'sweetalert2'
import NeedHelp from '../../../components/dashboard/NeedHelp';
function AddProducts(){
    const {shop} = useParams()
    const navigate = useNavigate()
    const [shopId, setShopId] = useState(localStorage.getItem('shopId'));
    const {product,currentUser,shops,currentShop,products} = useSelector((state) => state)
    const [createState,setCreateState] = useState(null)
    const [saveToDraftState,setSaveToDraftState] = useState(false)
    const [isUploading,setIsUploading] = useState(false)
    const [tagVal,setTagVal] = useState("")
    const [addSpecification,setAddSpecification] = useState(false)
    const [addFeatures,setAddFeatures]  = useState(false)
    const dispatch = useDispatch()
    const [isURLUploadOpen,setURLUploadOpen] = useState(false) 
    const [urlProductsIMGs,setProductURLImages] =useState([""]);
    const [isHaveInventoryDetails,setInventoryDetailsFieldOpen] = useState(false)
    const [productHasDimensions,setProductHasDimension] = useState(false)
    const [variantFieldOpen,setVariantFieldOpen] = useState(false)
    const [data, setData] = useState(product);
    const [categories,setCategories] = useState(null)
    const fetchCategories = async () => {
        const response = await getCategories({shopId});
        setCategories(response);
    }
    const addField = field => {
        dispatch(addProduct({[field.name]:field.value}))
    }
    const selectCategory = (e,ind) => {
        if(e.target.checked){
        dispatch(addProduct({categories:[...product.categories,e.target.value]}))
        }else{
        dispatch(addProduct({categories:product.categories.filter((value,i) => i != ind)}))
        }
    }
    const fetProductCategories = async () => {
        const response = await getCategories({shopId});
        dispatch(productCategories(response.categories));
        setCategories(response.categories); 
        
    }
    const publishProduct = async () => {
        const form = document.getElementById("form");
        const formData = new FormData(form);
        let tosend = {...product}
        formData.delete('file_[]');
        let others = [];
        tosend.media.forEach((value, key) => {
            if (isBase64(value)) {
                let filename = 'shopafrica_image_upload'+createKey()+".jpg";
                const file = base64toFile(value,filename)
                formData.append('file_[]', file);
            }else{
                others.push(value)
            }
        });
        tosend.media = others;
        formData.append('data', JSON.stringify(tosend));
        setCreateState(null)
        setIsUploading(true)
        const response = await createProduct(formData);
        if(response.success){
            Swal.fire({
                    text: response.message,
                    icon: 'success',
                    timer: 5000,
                    toast: true,
                    showConfirmButton: false,
                    position: 'top-end'
                });
            dispatch(addProduct(initProductData));
            dispatch(fetchShopProducts(shopId));
            navigate(`${pageUrl(shop,'/products')}`);
        }else{
            Swal.fire({
                text: response.message,
                icon: 'error',
                timer: 5000,
                toast: true,
                showConfirmButton: false,
                position: 'top-end'
            });
            // setCreateState({success:false,message:error.message})
            setIsUploading(false)
        }
    }
    const saveToDraft = (product) => {
        if(data.title != ""){
            var drafts = JSON.parse(localStorage.getItem("drafts"))
        if(drafts){
            drafts.push(data)
            localStorage.setItem("drafts",JSON.stringify(drafts))
        }else{
            localStorage.setItem("drafts",JSON.stringify([data]))
        }
        setSaveToDraftState(true)
        setTimeout(()=>{
            setSaveToDraftState(false)
        },5000)
        }
    }
    useEffect(()=>{
      !currentUser.data && dispatch(getUserData())
      !currentShop.data && dispatch(setCurrentStore(shop))
      !shops.data && dispatch(userShopData());
      dispatch(addProduct({shopId:currentShop.id}));
    //   !categories.data && dispatch(getProductCategories())

      return () =>{
        return setCreateState(null);
      }

    },[shops.loading,])

    useEffect(() => {
        // !categories && fetProductCategories()
        fetchCategories()
    }, [])
    const [selectedProductImages,setSelectedProductImages] = useState({
        uploaded:[],links:[]
    });
    useEffect(() => {
        let media = product.media.filter((img)=> typeof(img) === 'string' );
        setData({
            ...product, media
        })
    }, [product])
    return (
        shops.loading || currentShop.loading || currentUser.loading ?
    <>
    <div className='page-preloader'>
    <Spinner animation="border" >
    <>{shops.err && "Network failure,Please refresh"}</></Spinner>
</div></> :
        <>
            <Nav activeState={''}/>
            <>
            <main className='main-wrap'>
                <Header />
                <React.Fragment>
                    
                       
                    <section className="content-main" style={{maxWidth:1200}}>
                        <form method='POST' onSubmit={(e) =>{
                            e.preventDefault()
                            publishProduct(e)
                        }}
                        id="form"
                        >
                            <div className="content-header">
                                <h2 className="content-title">Add Product Details</h2>
                                <div>
                                    <button type='button' onClick={()=>{
                                        saveToDraft(product);

                                    }} className="btn btn-light mx-1">Save to draft</button>
                                    <button disabled={isUploading} className="btn text-light"
                                    style={{ 
                                        backgroundColor: "#1BBC9B"
                                    }}
                                    >{isUploading? "Publishing...":"Publish now"}</button>
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-xl-8 col-lg-8">
                                    <div className="card mb-4">
                                        <div className="card-body">
                                            {
                                                saveToDraftState && <Alert severity={'success'}>This product has been saved to draft!</Alert>
                                            }
                                            {
                                    createState != null ?
                                    <>
                                    <Alert severity={createState.success ? 'success' :'error'}>
                                        {
                                            createState.success?
                                            <>
                                            Product and pricing saved. <Link to={`${pageUrl(shop,'/product-otherdetails')}?productId=${createState.data.productId}`}>Click to upload product images!</Link>
                                    
                                            </>:
                                            <>
                                            {
                                                createState.message
                                            }
                                            </>
                                        }
                                    </Alert>
                                    </>
                                    :""
                                }
                                                <div className="mb-4">
                                                    <label htmlFor="product_title" className="form-label">Product Title</label>
                                                    <input required type="text" placeholder="Type here" name='title' value={product.title} onChange={(e) => addField(e.target) } className="form-control" id="product_title"/>
                                                </div>
                                                <div>
                                                <label className="form-label text text-bold">Description</label>
                                                <ReactQuill className="form-control" placeholder='Enter product description' value={product.description}
                                                onChange={(value) => {addField({name:'description',value});} }
                                            />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mb-4">
                                        <div className="card-body">
                                            <div>
                                                <label className="form-label text text-bold">Pricing</label>
                                            </div>
                                            <div className='row'>
                                                <div className='form-group col-lg-6'>
                                                <label className="form-label text text-bold">Selling Price (NGN)</label>
                                                <input required className="form-control" type="number"
                                                name='sellingPrice' value={product.pricing.sellingPrice} onChange={(e) => 
                                                    dispatch(addProduct({pricing:{
                                                        ...product.pricing,
                                                        [e.target.name]:e.target.value,
                                                        profit:e.target.value - product.pricing.costPerItem,
                                                        margin:new Number((e.target.value - product.pricing.costPerItem) / (product.pricing.sellingPrice) * 100).toFixed(2)
                                                    }}))
                                                    }
                                                placeholder='NGN 0.00' />
                                                </div>
                                                <div className='form-group col-lg-6'>
                                                <label className="form-label text text-bold">Compare Price (NGN)</label>
                                                <input className="form-control" type="number"
                                                name='comparePrice' value={product.pricing.comparePrice} onChange={(e) => 
                                                    dispatch(addProduct({pricing:{
                                                        ...product.pricing,
                                                        [e.target.name]:e.target.value
                                                    }}))
                                                    }
                                                placeholder='NGN 0.00' />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='form-group col-lg-4'>
                                                <label className="form-label text text-bold">Cost per item (NGN)</label>
                                                <input required className="form-control" type="number"
                                                name='costPerItem' value={product.pricing.costPerItem} onChange={(e) => 
                                                dispatch(addProduct({pricing:{
                                                    ...product.pricing,
                                                    [e.target.name]:e.target.value,
                                                    profit:product.pricing.sellingPrice - e.target.value,
                                                    margin:new Number((product.pricing.sellingPrice - e.target.value) / (e.target.value) * 100).toFixed(2)
                                                }}))
                                                }
                                                placeholder='NGN 0.00' />
                                                </div>
                                                <div className='form-group col-lg-4'>
                                                <label className="form-label text text-bold">Profit</label>
                                                <input  className="form-control" type="number" value={product.pricing.profit} placeholder='NGN 0.00' disabled/>
                                                </div>
                                                <div className='form-group col-lg-4'>
                                                <label className="form-label text text-bold">Margin</label>
                                                <input  className="form-control" type="text" value={`${product.pricing.margin} %`} disabled/>
                                                </div>
                                            </div>
                                            <hr />

                                        </div>
                                    </div>
                                    <div className="card mb-4">
                                    <Modal show={isURLUploadOpen} onHide={()=>setURLUploadOpen(false)} centered >
                                        <Modal.Header>
                                            Enter Product URLs
                                        </Modal.Header>
                                    <Modal.Body>
                                            {
                                            urlProductsIMGs.map(function(img,index){
                                                return (
                                                    <div className='row mt-2' key={index} style={{position:'relative'}} >
                                                    <div  className='col-10'>
                                                        <input value={img} className='form-control' onChange={(e)=>{
                                                        var images = urlProductsIMGs,
                                                        id = new Number(index);
                                                        images[id] = e.target.value
                                                        setProductURLImages(images.map((val)=> val))
                                                    }} />
                                                    </div>
                                                    <div className='col-2'>
                                                    <button id={index+"URLUPLOADBTN"} value={index} type='button' onClick={(e)=>{
                                                                    var id= e.target.value
                                                                    setProductURLImages(urlProductsIMGs.filter(function(val,i){
                                                                        if (urlProductsIMGs.length != 0) {
                                                                            return index != i
                                                                        }
                                                                    }) )
                                                            }} style={{
                                                                position:'absolute',top:6,right:7
                                                            }} className='btn btn-sm btn-danger'><i className="material-icons md-delete_forever"></i></button>
                                                    </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        <button className='btn btn-sm mt-2 t-btn2'
                                            type='button' onClick={()=>{
                                                var urlimages = urlProductsIMGs
                                                urlimages.push("")
                                                setProductURLImages(urlimages.map((val)=> val))
                                            }}>Add more</button>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <div className='d-flex justify-content-end '>
                                            <button className='btn btn-sm mx-2 t-btn2' onClick={()=>{
                                                let media = [...product.media,...urlProductsIMGs]
                                                dispatch(addProduct({media}))
                                                setProductURLImages([""]);
                                                setURLUploadOpen(false);
                                            }}>
                                                Add URLs
                                            </button>
                                            <button className='btn btn-sm btn-danger' onClick={()=>{
                                                setURLUploadOpen(false);
                                                setProductURLImages([""]);
                                            }} >
                                                Cancel
                                            </button>
                                        </div>
                                    </Modal.Footer>
                                    </Modal>
                                    <div className="card-header d-flex justify-content-between">
                                        <h5 className="card-title mb-0">Product Gallery</h5>
                                        <a href='#' className='btn btn-sm btn-secondary' onClick={(e)=>{
                                            e.preventDefault();
                                            setURLUploadOpen(true)
                                        }}>Add from URL</a>
                                    </div>
                                        <div className="card-body">
                                            <div className='d-flex justify-content-center align-items-center'>
                                            <input type="file"   accept="image/*" 
                                            onChange={(e) => {
                                                const files = e.target.files;
                                                const selectedFiles = [];

                                                const readFileAsDataURL = (file) => {
                                                    return new Promise((resolve) => {
                                                        const reader = new FileReader();
                                                        reader.onload = (e) => {
                                                            resolve(e.target.result);
                                                        };
                                                        reader.readAsDataURL(file);
                                                    });
                                                };
                                                const readFiles = async () => {
                                                    for (let i = 0; i < files.length; i++) {
                                                        const file = files[i];
                                                        const base64String = await readFileAsDataURL(file);
                                                        selectedFiles.push(base64String);
                                                    }
                                                    console.log(selectedFiles);

                                                    const med = product.media.slice();
                                                    const media = [...med, ...selectedFiles];
                                                    dispatch(addProduct({ media }));
                                                };

                                                readFiles();

                                            }}
                                            style={{display:"none"}} placeholder="Type here"  multiple className="form-control" id="uploadImages"/>
                                            <label  htmlFor='uploadImages' className='upload-images-label d-flex justify-content-center align-items-center' style={{
                                                    display: "block",
                                                    width: "90%",
                                                    height:140,
                                                    borderStyle:'dashed',
                                                    borderColor:'GrayText',
                                                    borderWidth:0.5,
                                                    borderRadius:5,
                                                }
                                            }>
                                                <span style={{fontSize:14}}> Add Product Images From File </span>
                                            </label>
                                            </div>
                                            <div className='products-container d-flex justify-content-start align-items-center m-2'>
                                                {
                                                    product.media.map(function(img,index){
                                                        return (
                                                            <div  key= {index} className='product-container d-flex-column mx-2 justify-content-center align-items-center' style={{
                                                                cursor: "pointer",
                                                                borderRadius:5,
                                                                borderWidth:1,
                                                                borderColor:"#e0e2e3",
                                                    }} >
                                                            <img
                                                                src={img}
                                                                alt={index+"product_image"}
                                                                width={80}
                                                                height={80}
                                                            />
                                                            <div className='delete-product d-flex justify-content-center align-items-center' style={{color:'#a50f0f'}} onClick={()=>{
                                                                dispatch(addProduct({media:product.media.filter((_,ind)=> ind != index)}))
                                                            }}>
                                                                <i className="material-icons md-delete_forever"></i>
                                                            </div>
                                                    </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mb-4">
                                        <div className="card-body">
                                            <div className='card-header'>
                                            <h5 className="card-title mb-0">Inventory</h5>
                                            </div>
                                            <div className='d-flex justify-content-between align-items-center mt-2'>
                                                <span className='text text-bolder'>Quantity</span>
                                                <input type='number' style={{
                                                    width:150
                                                }} min={1} className='form-control' name='quantity' value={product.quantity} onChange={(e) => addField(e.target) }
                                                placeholder="Quantity"  />
                                            </div>
                                            <div className='d-flex justify-content-between align-items-center mt-3'>
                                                <span className='text text-bolder'>
                                                    <input type='checkbox' className='mr-1' onChange={(e)=>{
                                                            setInventoryDetailsFieldOpen(e.target.checked)
                                                    }} />
                                                    <span className='mx-2'>This product has a SKU or barcode</span>
                                                </span>
                                            </div>
                                            {
                                                isHaveInventoryDetails && 
                                                <div className='row mt-3'>
                                                <div className='form-group col-lg-6'>
                                                <label className="form-label text text-bold">SKU (Stock Keeping Unit)</label>
                                                <input  className="form-control" type="text"
                                                value={product.sku} onChange={(e) => 
                                                dispatch(addProduct({sku:e.target.value}))
                                                }
                                                />
                                                </div>
                                                <div className='form-group col-lg-6'>
                                                <label className="form-label text text-bold">Barcode (ISBN, UPC, GTIN, etc.)</label>
                                                <input  className="form-control" type="text"
                                                value={product.barcode} onChange={(e) => 
                                                    dispatch(addProduct({barcode:e.target.value}))
                                                } />
                                                </div>
                                            </div>
                                            }
                                            <div className='d-flex justify-content-between align-items-center mt-4'>
                                                <span className='text text-bolder'>
                                                    <input type='checkbox' className='mr-1' onChange={(e)=>{
                                                            setProductHasDimension(e.target.checked)
                                                    }} />
                                                    <span className='mx-2'>This product has dimensions</span>
                                                </span>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    {
                                        productHasDimensions && 
                                        <div className="card mb-4">
                                        <div className="card-body">
                                            <div>
                                                <label className="form-label text text-bold">Dimensions</label>
                                            </div>
                                            <div className='row'>
                                                <div className='form-group col-lg-6'>
                                                <label className="form-label text text-bold">Width</label>
                                                <input  className="form-control" type="text"
                                                value={product.meta.dimensions.width} onChange={(e) => 
                                                dispatch(addProduct({meta:{
                                                    ...product.meta,
                                                    dimensions:{
                                                        ...product.meta.dimensions,
                                                        width:e.target.value
                                                    }
                                                    }}))
                                                }
                                                placeholder='in mm' />
                                                </div>
                                                <div className='form-group col-lg-6'>
                                                <label className="form-label text text-bold">Length</label>
                                                <input  className="form-control" type="text"
                                                value={product.meta.dimensions.length} onChange={(e) => 
                                                dispatch(addProduct({meta:{
                                                    ...product.meta,
                                                    dimensions:{
                                                        ...product.meta.dimensions,
                                                        length:e.target.value
                                                    }
                                                    }}))
                                                }
                                                placeholder='in mm' />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='form-group col-lg-6'>
                                                <label className="form-label text text-bold">Weight</label>
                                                <input  className="form-control" type="text"
                                                value={product.meta.dimensions.weight} onChange={(e) => 
                                                dispatch(addProduct({meta:{
                                                    ...product.meta,
                                                    dimensions:{
                                                        ...product.meta.dimensions,
                                                        weight:e.target.value
                                                    }
                                                    }}))
                                                }
                                                placeholder='in kg' />
                                                </div>
                                                <div className='form-group col-lg-6'>
                                                <label className="form-label text text-bold">Depth</label>
                                                <input  className="form-control" type="text"
                                                value={product.meta.dimensions.depth} onChange={(e) => 
                                                dispatch(addProduct({meta:{
                                                    ...product.meta,
                                                    dimensions:{
                                                        ...product.meta.dimensions,
                                                        depth:e.target.value
                                                    }
                                                    }}))
                                                }
                                                placeholder='in mm' />
                                                </div>
                                            </div>
                                            <hr />

                                        </div>
                                    </div>
                                    }
                                    <div className="card mb-4">
                                    <div className="card-header d-flex justify-content-between">
                                        <h5 className="card-title mb-0">Variants</h5>
                                    </div>
                                        <div className="card-body">
                                            <h5 
                                            className='form-label'
                                            onClick={()=>{
                                                setVariantFieldOpen(true)
                                            }}> Add options like Size, Color, Price e.t.c </h5>
                                            
                                                {
                                                    <>
                                            <div className='table-responsive mt-2'>
                                                <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th className='w-25'>Size</th>
                                                        <th className='w-25'>Color(s)</th>
                                                        <th className='w-25'>Price</th>
                                                        <th className='w-25'>Quantity</th>
                                                        <th className='w-25'></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {   variantFieldOpen &&
                                                        product.variants.map(function(variant,variantIndex){
                                                            return (
                                                                <tr key={variantIndex}>
                                                        <td>
                                                            <input type={'text'} className='form-control' value={variant.size} onChange={(e)=>{
                                                                
                                                                var data = product.variants.slice(); 
                                                                var currentVariant = data[variantIndex];
                                                                data[variantIndex] = { ...currentVariant, size: e.target.value };
                                                                dispatch(addProduct({ variants: data }));
                                                            }} />
                                                                {/* {
                                                                    product.product_size.map((val,i)=>{
                                                                        return <option value={val}>{val}</option>
                                                                    })
                                                                }
                                                            </select> */}
                                                        </td>
                                                        <td>
                                                            <input type={'text'} className='form-control' value={variant.color} onChange={(e)=>{
                                                                var data = product.variants.slice();
                                                                var currentVariant = data[variantIndex];
                                                            data[variantIndex] = { ...currentVariant, color: e.target.value };
                                                                dispatch(addProduct({ variants: data }));
                                                            }} />
                                                                {/* {
                                                                    product.product_color.map((val,i)=>{
                                                                        return <option value={val}>{val}</option>
                                                                    })
                                                                }
                                                            </select> */}
                                                        </td>
                                                        <td><input type='number' min={0}  className='form-control' value={variant.details.price} onChange={(e)=>{
                                                                var data = product.variants.slice();
                                                                var currentVariant = data[variantIndex];
                                                                data[variantIndex] = { ...currentVariant, details:{...currentVariant.details,price:e.target.value}};
                                                                dispatch(addProduct({ variants: data }));
                                                            }} placeholder='NGN 0.00' /></td>

                                                        <td><input type='number' className='form-control' min={1} value={variant.details.quantity ?? 1} onChange={(e)=>{
                                                                var data = product.variants.slice();
                                                                var currentVariant = data[variantIndex];
                                                                data[variantIndex] = { ...currentVariant, details:{...currentVariant.details,quantity:e.target.value}};
                                                                dispatch(addProduct({ variants: data }));
                                                            }} /></td>
                                                        <td >
                                                            <div className='d-flex justify-content-between align-items-center'>
                                                <button type='button' onClick={()=>{
                                                    var data = product.variants.slice();
                                                    dispatch(addProduct({ variants: data.filter((_,ind) => ind != variantIndex ) }));
                                                    }} className='btn btn-sm btn-danger'><i className="material-icons md-delete_forever"></i></button>
                                                            </div>
                                                        </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    
                                                </tbody>
                                                
                                                </table>
                                                <button type='button' onClick={()=>{
                                                        if(variantFieldOpen){
                                                            dispatch(addProduct({variants:[...product.variants,{
                                                            size:"",
                                                            color:"",
                                                            details:{
                                                                price:0,
                                                                quantity:0,
                                                                sku:"",
                                                                barcode:""
                                                            }
                                                        }]}))
                                                        // setCurrentColorValue("")
                                                    }else{
                                                        
                                                            setVariantFieldOpen(true); 
                                                            dispatch(addProduct({variants:[{
                                                                size:"",
                                                                color:"",
                                                                details:{
                                                                    price:0,
                                                                    quantity:0,
                                                                    sku:"",
                                                                    barcode:""
                                                                }
                                                            }]}))
                                                    }

                                                    }} className='btn btn-sm t-btn2 mx-1 mt-2'>{product.variants.length > 0 ? 'Add More' : "Add Option" }
                                                    </button>
                                                </div>
                                                    </>
                                                }
                                                
                                        </div>
                                        
                                    </div>

                                    {!addSpecification && <button className='btn btn-sm mb-2 mx-3 t-btn2'
                                        type='button' onClick={()=> setAddSpecification(true)}>Add specification</button>
                                    }
                                    {
                                        addSpecification && 
                                        <div className="card mb-4">
                                        <div className="card-body">
                                            <div>
                                                <label className="form-label text text-bold">Specifications</label>
                                            </div>
                                            <div className='specs'>
                                                {
                                                    product.meta.specifications.map(function(spec,i){
                                                        return (
                                                            <div className='row' key={i}>
                                                                <div className='form-group col-6'>
                                                                    <label className="form-label text text-bold">Property</label>
                                                                    <input id={i} className="form-control" type="text"
                                                                        onChange={(e) => 
                                                                        {
                                                                            var id = e.target.id
                                                                            dispatch(addProduct({meta:{
                                                                                ...product.meta,
                                                                                specifications:product.meta.specifications.map(function(sp,a){
                                                                                    if (a == id) {
                                                                                        return {...sp,property:e.target.value}
                                                                                    }else{
                                                                                        return sp
                                                                                    }
                                                                                })
                                                                                
                                                                            }}))
                                                                            
                                                                        
                                                                        }
                                                                        }
                                                                    placeholder='Enter spec key / name' />
                                                                </div>
                                                                <div className='form-group col-6'>
                                                                <label className="form-label text text-bold">Value</label>
                                                                <input  className="form-control" type="text" name={i}
                                                                onChange={(e) => 
                                                                    {
                                                                        var id = e.target.name
                                                                        dispatch(addProduct({meta:{
                                                                            ...product.meta,
                                                                            specifications:product.meta.specifications.map(function(sp,i){
                                                                                if (i == id) {
                                                                                    return {...sp,value:e.target.value}
                                                                                }else{
                                                                                    return sp
                                                                                }
                                                                            })
                                                                            
                                                                        }}))
                                                                    }
                                                                    }
                                                                placeholder='Enter spec value' />
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            
                                            </div>
                                            <button className='btn btn-sm mt-2 t-btn2'
                                            type='button' onClick={()=>{
                                                dispatch(addProduct({meta:{
                                                    ...product.meta,
                                                    specifications:[...product.meta.specifications,{property:"",value:""}]
                                                }}))
                                            }}>Add more</button>
                                            

                                        </div>
                                    </div>
                                    }
                                    {!addFeatures && <button className='btn btn-sm text-light mb-2 t-btn2'
                                    type='button' onClick={()=> setAddFeatures(true)}>Add features</button>
                                    }
                                    {
                                        addFeatures && 
                                        <div className="card mb-4">
                                        <div className="card-body">
                                            <div>
                                                <label className="form-label text text-bold">Features</label>
                                            </div>
                                            {
                                                product.meta.features.map(function(feature,i){
                                                    return (
                                                        <div key={i}  style={{position:'relative'}}>
                                                            <div className="mb-2">
                                                                <input type="text" placeholder="Enter product feature"  name='title' value={feature} 
                                                                onChange={(e) => {
                                                                    var id = new Number(e.target.id);
                                                                    dispatch(addProduct({meta:{
                                                                        ...product.meta,
                                                                        features:product.meta.features.map(function(val,i){
                                                                            return id == i ? e.target.value : val
                                                                        })
                                                                    }}))
                                                                }} className="form-control" id={i} />
                                                            </div>
                                                            <hr />
                                                            <button id={i} type='button' onClick={(e)=>{
                                                                    var id= e.target.id
                                                                    dispatch(addProduct({meta:{
                                                                        ...product.meta,
                                                                        features:product.meta.features.filter((val,i) => i != id)
                                                                    }}))
                                                            }} style={{
                                                                position:'absolute',top:6,right:7
                                                            }} className='btn btn-sm btn-danger'><i className="material-icons md-delete_forever"></i></button>
                                                        </div>
                                                    )
                                                })
                                            }
                                            
                                            <button className='btn btn-sm t-btn2'
                                            type='button' onClick={()=>{
                                                dispatch(addProduct({meta:{
                                                    ...product.meta,
                                                    features:[...product.meta.features,'']
                                                }}))
                                            }}>Add more</button>
                                            

                                        </div>
                                    </div>
                                    }
                                </div>
                                <aside className="col-xl-4 col-lg-4">
                                    <div className="card mb-4">
                                        <div className="card-body">
                                                <div className="mb-4">
                                                    <label className="form-label text text-bold">Status</label>
                                                    <select required name='status' className="form-select" onChange={(e)=>{
                                                        addField(e.target)
                                                    }}>
                                                        <option>Select Product Status</option>
                                                        {
                                                            productStatus.map(function(status,index){
                                                                return (
                                                                    <option selected={product.status == status} key={index} value={status}>{status}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>

                                                <div className="mb-4">
                                                    <label className="form-label text text-bold">Tags(Click on 'Add' to add tag)</label>
                                                    <input  required= {product.tags > 0} type="text" name='tags' placeholder="Type here" value={tagVal}
                                                    onChange={(e)=> setTagVal(e.target.value)}
                                                    className="form-control"/>
                                                    {
                                                        tagVal !=""  && 
                                                        <button type='button' className='btn t-btn2 mt-2 float-end ' style={{
                                                            padding:"0 12px",
                                                            margin:"2px 0"
                                                        }} onClick={()=>{
                                                            dispatch(addProduct({tags:[...product.tags,tagVal]}))
                                                            setTagVal("")
                                                        }}>
                                                            Add
                                                        </button>
                                                    }
                                                </div>
                                                    {
                                                        product.tags.length > 0 ?
                                                        <>
                                                        {
                                                            product.tags.map((tag,i)=>{
                                                                return(
                                                                    <button type='button' onClick={()=>{
                                                                dispatch(addProduct({tags:product.tags.filter((val,ind) => i != ind)}))
                                                                    }} key={i} className="btn btn-sm btn-light mb-1">{tag}
                                                                    <i className="material-icons md-close" ></i></button>
                                                                )
                                                            })
                                                        }
                                                        </>:
                                                        "No tag available"
                                                    }
                                                <hr/>
                                                    <h5 className="mb-3">Categories</h5>
                                                    {
                                                    categories && categories.store_categories && categories.store_categories.map(function(category,i){
                                                            return (
                                                                <div>
                                                                    <input key={i} type='checkbox'  name='category' onClick={(e) =>{
                                                                        if(e.target.checked){
                                                                            const categories = product.categories.slice();
                                                                        addField({name:'categories',value:[...categories,e.target.value]});
                                                                        }else{
                                                                            const categories = product.categories.slice();
                                                                            const filtered = categories.filter((val,inde) => inde != i);
                                                                            addField({name:'categories',value:filtered});
                                                                            }
                                                                    }} value={category.title} />
                                                                    <label className='mx-2'>{category.title}</label>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                <div  className=' btn form-control text-end' 
                                                    style={{ 
                                                        fontWeight: 'bold',
                                                        border: 0,
                                                        justifyContent: 'center'
                                                    }}
                                                    onClick={() =>{
                                                        //  setCustomCat(!customCat)
                                                        navigate(pageUrl(shop,'/categories'))
                                                        }}
                                                    >
                                                        <FaPlus size={'14'} className='mb-1'/>
                                                        Add new category
                                                </div>
                                        </div>
                                    </div>
                                </aside> 
                            </div> 
                            </form>

                        </section>
                </React.Fragment>
            </main>
            </>
<NeedHelp />
        </>
    )
};

export default AddProducts
//You must be really trying hard enough to get everything stable - your life, studies, tech  and other things