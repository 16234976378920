import { Link, useParams } from 'react-router-dom'
import '../../../assets/css/pricing.css'
import { Axios } from '../../../api/axios'
import Swal from 'sweetalert2'
import { useEffect, useState } from 'react'
import { createKey } from '../../../api/api'
import { Spinner } from 'react-bootstrap'
import { CheckedIcon } from '../../../components/Icons'

function Plans(){
    const [plans, setPlans] = useState([])
    const [loading,setLoading] = useState(false)
    const {storename} = useParams()
    const getPlans = async () => {
        setLoading(true)
        try {
            const res = await Axios.get("api?plans")
            const response = res.data
            if (response.status) {
                setPlans(response.data)
            } else {
                setPlans([])
                Swal.fire({
                    text: response.message,
                    icon: 'error',
                    timer: 5000,
                    toast: true,
                    showConfirmButton: false,
                    position: 'top-end'
                });
            }
        } catch (error) {
            setPlans([])
            Swal.fire({
                text: error.message,
                icon: 'error',
                timer: 5000,
                toast: true,
                showConfirmButton: false,
                position: 'top-end'
            });
        }
        finally{
            setLoading(false)
        }
    }
    useEffect(()=>{
     getPlans()
        return () => {
            return null
        }
    },[])
    return (
            loading ?
            <>
             <div className='page-preloader'>
                <Spinner animation="border" >
                </Spinner>
            </div>
            </>:
            <>
            <div>
			<div className="container-lg">
				<div className="v-wrap">
					<header className="v-main-header d-flex flex-column align-items-center col-12 col-sm-10 col-md-8 col-lg-6 text-center row-gap-2 pb-4 mx-auto">
						<h3 className="v-header-title">Choose your plan</h3>
						<span className="v-subtext">Publish your passions your way. Whether you'd like to share your knowledge, experiences or latest news</span>
					</header>
					<div className="v-wrap-inner">
                    {
                        plans && plans.map(function(plan,index){
                            return (
                                
                                <main key={createKey()}>
							<div className="v-parent-plan-container">
								<div className="mt-4 d-flex flex-column align-items-start flex-sm-row column-gap-2">
									<h5 className="v-parent-container-title">{plan.name}</h5>
									<span className="v-price-range">(0 - { plan.sales_to_range != 'UNLIMITED' ? plan.sales_to_range+'M' : 'UNLIMITED'} monthly in sales)</span>
								</div>
								<div className="v-each-4-cards-container">
									{
                                        plan.plans.map(function(p,i){
                                            return (
                                                <div key={createKey()}>
										<div className="v-each-main-card">
											<div className="v-each-main-card-inner">
												<header className="v-card-header">
													<h5 className="v-card-type">{p.plan}</h5>
													<div className="v-card-price">
                                                    {
                                                        p.plan == "ENTERPRISE" ?
                                                        <>
                                                        <h2 className="v-card-price-value">Contact Sales</h2>
                                                        </>:
                                                        <>
                                                        {
                                                            p.price != "0" ?
                                                        <>
                                                        <h2 className="v-card-price-value">&#8358; {p.price}</h2>
														<span className="v-subtext">/mo</span>
                                                        </>:
                                                        <>
                                                        <h2 className="v-card-price-value">Free</h2>
                                                        </>
                                                        }
                                                        </>
                                                    }
														
                                                        
													</div>
												</header>
												<div className="v-card-body">
													<ul className="v-listed-goodies">
														<li className="v-each-goodie">
															<span className="v-icon">
																<CheckedIcon />
															</span>
															<span className="v-goodie">{p.max_store_count} Store</span>
														</li>
														<li className="v-each-goodie">
                                                            <span className="v-icon">
																<CheckedIcon />
															</span>
															<span className="v-goodie">Custom domain</span>
														</li>
														<li className="v-each-goodie">
															<span className="v-icon">
                                                            <CheckedIcon />
															</span>
															<span className="v-goodie">{p.messaging_credit} messaging credits</span>
														</li>
														<li className="v-each-goodie">
															<span className="v-icon">
                                                            <CheckedIcon />
															</span>
															<span className="v-goodie">Marketing campaigns</span>
														</li>
														<li className="v-each-goodie">
															<span className="v-icon">
                                                            <CheckedIcon />
															</span>
															<span className="v-goodie">{p.max_product} products listing</span>
														</li>
														<li className="v-each-goodie">
															<span className="v-icon">
																<CheckedIcon />
															</span>
															<span className="v-goodie">&#8358;{p.limit_exceed_service_charge} service charge</span>
														</li>
														<li className="v-each-goodie">
															<span className="v-icon">
																<CheckedIcon />
															</span>
															<span className="v-goodie">0 - {p.sales_max_count} sales monthly</span>
														</li>
													</ul>
                                                    {
                                                        p.price != "0" &&
                                                        <div className="v-cta-container">
														    <Link to={`/user/${p.id}/checkout`} className="v-cta">Choose plan</Link>
													    </div>

                                                    }
													
												</div>
											</div>
										</div>
									</div>
                                            )
                                        })
                                    }
								</div>
							</div>
						</main>
                                
                            )
                        })
                    }
						
					</div>
				</div>
			</div>
		</div>
            </>
        
    )
}

export default Plans