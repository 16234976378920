

export const availableThemes = [
    {
        name:"Default",
        themeId:"",
        image:"",
        price:"0",
        themeLink:"",
        description:"Theme could be used for ecommerce",
        isActive:true,
        numOfUsers:120

    },
    
    {
        name:"Diamond",
        themeId:"",
        image:"",
        price:"$450",
        themeLink:"",
        description:"Theme could be used for ecommerce",
        isActive:true,
        numOfUsers:120

    },
    {
        name:"Silver",
        themeId:"",
        image:"",
        price:"$950",
        themeLink:"",
        description:"Theme could be used for ecommerce, online store, hubs etc",
        isActive:true,
        numOfUsers:120

    },
    {
        name:"Gold",
        themeId:"",
        image:"",
        price:"$950",
        themeLink:"",
        description:"Theme could be used for ecommerce, online store, hubs etc",
        isActive:true,
        numOfUsers:120

    },

]
const mediaRange = {
    desk:{
    },
    tab:{
    },
    mobile:{
    }
}
export const defaultThemeComponentDOMSetup = {
    resetId:Math.random(),
    landing:{
        header:{
            siteImage:"",
            siteImageStyle:{
                desk:{
                    height:50,width:100
                },
                tab:{
                    height:50,width:100
                },
                mobile:{
                    height:50,width:100
                }

            },
            siteImageLink:"",
            navStyle:{
                ...mediaRange,
                    },
            navLinks:['Home','Shop','About'],
            socialMedia:[
        
                ]
        },
        bannerHero:{
            bannerStyle:{
                ...mediaRange
            },
            topText:"Banner header text",
            topTextStyle:{},
            text2:"Get your team in sync, no matter your location. Streamline processes, create team rituals, and watch productivity soar.",
            text2Style:{},
            button:{
                    ...mediaRange,
                text:"Shop Now",
                link:""
            },
            bannerImage:""
        },
        miniNotification:{
            heading:"Feature products",
            headingStyle:{
            },
            description:"",
            descStyle:{
            }
        },
        featureCollections:{
            featureProducts:[
                {
                    name:"",
                    price:"",
                    image:""
                }
            ]
        },
        newsLetter:{
            headerText:"Join the newsletter",
            subText:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris",
            inputTag:{
                placeholder:"Please enter your email here ...",
                style:{}
            }
        }
    },
    themeFooter:{

    }
}

/**default theme media widths */
export const defaultThemeMedia = {
    '1':'desk',
    '2':'tab',
    '3':'mobile'
}
/** functions that checks media query range : max-width used */
/**
 * function MyComponent() {
    const [isSmallScreen, setIsSmallScreen] = useState(false);
  
    useEffect(() => {
      const mediaQuery = window.matchMedia('(max-width: 768px)');
      const handleMediaQueryChange = (e) => {
        setIsSmallScreen(e.matches);
      };
      mediaQuery.addEventListener('change', handleMediaQueryChange);
      setIsSmallScreen(mediaQuery.matches);
      return () => {
        mediaQuery.removeEventListener('change', handleMediaQueryChange);
      };
    }, []);
  
    return (
      <div
        style={{
          backgroundColor: isSmallScreen ? 'green' : 'red',
          color: isSmallScreen ? 'black' : 'white',
        }}
      >
        This is my component.
      </div>
    );
  }
  '@media (maxWidth: 768px)': {
                    backgroundColor: 'green',
                    color: 'black',
                  },

 */
export const dispatchMediaStyleForComponent =(currentWidth,theme,component,section,key) =>{
    return theme[component][section][key][defaultThemeMedia[currentWidth]]
}

