import { createSlice } from "@reduxjs/toolkit";

const transactionSlice = createSlice({
    name: 'transactions',
    initialState: {
        transactions: null,
        transaction: null,
    },
    reducers: {
        transactions : (state, action) => {
            state.transactions = action.payload;
        },
        transaction : (state, action) => {
            state.transaction = action.payload
        }
    }
});

export const {transactions, transaction} = transactionSlice.actions;
export default  transactionSlice.reducer;

