import { createSlice } from "@reduxjs/toolkit";

export const productSlice = createSlice({
    name: 'product',
    initialState : {
        products: null,
        product: null,
        categories: null,
        fashions: null
    },
    reducers: {
        products : (state, action) => {
            state.products = action.payload
        },
        product : (state, action ) => {
            state.product = action.payload
        },
        productCategories : (state, action) => {
            state.categories = action.payload
        },
        productFashions : (state, action) => {
            state.fashions = action.payload
        }

    }
});

export const {products, product, productCategories, productFashions} = productSlice.actions;
export default productSlice.reducer;