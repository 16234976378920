import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import Cookie from 'react-cookies';
function LoginFromMarasoft() {
    const {token} = useParams()
    const [loading,setLoading] = useState(true)
    const navigate = useNavigate();
    useEffect(()=>{
        if (token && token != null) {
            Cookie.remove('token');
            Cookie.save('token', token) 
            setTimeout(() => {
                setLoading(false);
            }, 3000);
        } else {
            navigate("/login",{replace:true});
        }
    },[])
  return (
    <>
    {
        loading ?
        <>
         <div className='page-preloader'>
            <Spinner animation="border" >
            </Spinner>
        </div>
        </>:
        <>
            <Navigate to={"/my-shops"} />
        </>
    }
    </>
  )
}

export default LoginFromMarasoft
