import React from 'react'
import Nav from '../../components/landing/Nav'
import MainFooter from '../../components/MainFooter'

export default function () {
  return (
    <React.Fragment>
        <>
            <Nav />
        </>
        <div className="container my-5">
      <h1 className="text-center mb-4">Terms and Condition</h1>
      <p className="mb-4 text-center px-lg-4">Published date November 1, 2023</p>
      {/* Introduction Section */}
      <section className="mt-3">
        <h2>Introduction</h2>
        <p><b>Acceptance</b>. Your use of ShopAfrica constitutes your acceptance of these Terms of Service and the Incorporated Terms mentioned below (together, the “Terms”). If you do not agree with these Terms, please refrain from using the Website or Services. If you are accepting these Terms on behalf of a company, organization, government, or another legal entity, you confirm that (a) you have the authority to do so, (b) the entity agrees to be legally bound by these Terms, and (c) neither you nor the entity are prohibited from using the Services or accepting these Terms under the applicable jurisdiction’s laws.</p>
        <br/>
        <p><b>Scope</b>. These Terms apply to your usage of the Website and the Services. It's important to note that these Terms do not extend to Third-Party Products, as they are subject to their own terms of service.</p>
       </section>

      {/* Information Collection Section */}
      <section className='mt-3'>
  <h2>Eligibility</h2>
  <p><b>Legal age requirement.</b> To use the Services and access the Website, you must be of the legal age required to enter into binding contracts under the applicable law. Typically, this means you should be at least eighteen (18) years old, unless the law applicable to you designates a different age for adulthood. If you do not meet this qualification, kindly refrain from using the Services or visiting the Website.</p>
  <br/>
  <p><b>Registration.</b> To access the Services, you must create an account on ShopAfrica. During registration, you may need to provide a valid payment method that you are authorized to use for transactions. It's important to supply accurate, up-to-date, and truthful information when creating your account, and to keep this information current. As part of the registration process, you will also select a username and password for your account. ShopAfrica retains the right to decline or cancel accounts that breach these Terms. You must keep your account credentials secure. You are responsible for any activities that occur under your account.</p>
</section>

<section className='mt-3'>
  <h2>Code of Conduct</h2>
  <p><b>Illegal Activity.</b> Any illegal activity on the ShopAfrica platform is strictly prohibited. Users are not permitted to engage in any conduct that violates local, federal, state, or international laws and regulations. This includes export laws related to data or software. In the event of illegal activity, investigations will take place, and the consequences may vary depending on the specific case and at our discretion.</p>
  <br/>
  <p><b>Fraud.</b> We have a zero-tolerance policy for any form of fraudulent activity, including but not limited to money laundering.</p>
  <br/>
  <p><b>Malicious Code.</b> Users must not use the ShopAfrica Platform to transmit or distribute viruses, worms, or any other code that may be harmful or destructive in any way.</p>
  <br/>
  <p><b>Spamming.</b> Users should refrain from activities that result in sending spam to anyone on the ShopAfrica Platform, including ShopAfrica, its employees, subscribers, and purchasers.</p>
  <br/>
  <p><b>Respect.</b> We encourage all users to maintain civility and respect at all times.</p>
  <br/>
  <p><b>Exploitation.</b> The gathering of personal information from others on the ShopAfrica platform, except for limited permissible uses related to offering your content, is prohibited.</p>
  <br/>
  <p><b>Impersonation.</b> Users must not impersonate anyone, including ShopAfrica, its employees, or members of the public. In cases of impersonation, we reserve the right to deactivate the offending account immediately.</p>
  <br/>
  <p><b>Data Mining or Bots.</b> Using data mining, robots, or similar methods for data extraction is not allowed.</p>
  <br/>
  <p><b>Use Other Than Intended.</b> The Platform and its content should only be used for their intended purposes.</p>
  <br/>
  <p><b>Unlawful alteration.</b> This agreement pertains to Services, and it does not grant a license for any software. Unless expressly permitted under applicable law, you shall not reverse engineer, decompile, or attempt to discover the source code of the services or related software; modify, translate, or create derivative works based on the Services or software; copy, distribute, assign, or transfer rights to the Services or software, except for archival purposes; use the Services for the benefit of a third party or remove proprietary notices. Violation of our Code of Conduct may result in the deletion of a user's account and their content from the ShopAfrica Platform. The determination of whether conduct violates our Code of Conduct will be made at ShopAfrica's sole discretion.</p>
</section>
<section className='mt-3'>
  <h2>Copyright Policy</h2>
  <p>We highly value the intellectual property rights of others, and it is our policy to address any claims of copyright or intellectual property infringement on content posted within our Service.</p>
  <p>If you are a copyright owner or authorized representative and believe that copyrighted material has been used in a way that constitutes copyright infringement, please submit your claim via our email at [email address] with the subject line “Copyright Infringement.” In your claim, include a comprehensive description of the alleged infringement, as outlined below in the “DMCA Notice and Procedure for Copyright Infringement Claims” section.</p>
  <p>Please be aware that making false or bad-faith claims regarding content infringement on your copyright may result in you being held responsible for damages, including costs and attorneys’ fees.</p>
  <p>All content on ShopAfrica, including text, images, trademarks, and logos, are protected by intellectual property rights. You may not use or reproduce them without authorization. Users are responsible for the content they post on our platform. We do not endorse or guarantee the accuracy of any user-generated content.</p>
</section>

<section className='mt-3'>
  <h2>DMCA Notice and Procedure for Copyright Infringement Claims</h2>
  <p>To submit a notification in line with the Digital Millennium Copyright Act (DMCA), please provide our designated Copyright Agent with the following information in writing, as required by 17 U.S.C 512(c)(3):</p>
  <ul>
    <p>An electronic or physical signature from the individual authorized to represent the copyright owner's interests.</p>
    <p>A description of the copyrighted work that you allege has been infringed, along with the URL (web page address) where the copyrighted work is located or a copy of the copyrighted work itself.</p>
    <p>Identify the URL or specific location on our Service where the allegedly infringing material can be found.</p>
    <p>Provide your contact details, including your address, telephone number, and email address.</p>
    <p>Include a statement from you indicating your good faith belief that the disputed use is not authorized by the copyright owner, their agent, or the law.</p>
    <p>State, under penalty of perjury, that the information in your notice is accurate, and you either own the copyright or are authorized to act on the copyright owner's behalf.</p>
  </ul>
</section>
<section className="mt-3">
  <h2>Know Your Customer</h2>
  <p>We may require you to verify your identity in order to access our services. This verification process may involve requesting your banking details, a valid ID, and/or proof of your address. By using our services, you consent to us sharing these details with secure verification agencies and databases. Additionally, you grant us permission to submit this information to government regulatory agencies when mandated by law.</p>
</section>

<section className="mt-3">
  <h2>Fee Modification</h2>
  <p>ShopAfrica reserves the right, at its sole discretion and at any time, to make changes to fees for the transactions. These changes will take effect at the conclusion of the current Billing Cycle. ShopAfrica will give you reasonable notice before any fee adjustments are implemented, allowing you the opportunity to adjust before the new fees take effect. If you continue to use the Service after the fee change becomes effective, it will be considered an implicit agreement to pay the revised fee amount.</p>
</section>

<section className="mt-3">
  <h2>Accounts</h2>
  <p>When you register an account with us, you affirm that you are 18 years of age or older and that the information you furnish to us is truthful, comprehensive, and up-to-date. Providing inaccurate, incomplete, or outdated information may lead to the immediate termination of your account on our service.</p>
  <p>Users are responsible for safeguarding the confidentiality of their account and password, which includes limiting access to their computer and account. You consent to take responsibility for all activities that occur under your account and password, whether the password is used on our service or a third-party platform. If you detect any security breach or unauthorized use of your account, it's imperative to notify us promptly.</p>
  <p>You are not allowed to use a store name that belongs to someone else or an entity, or a name that is not lawfully available for use, or a name or trademark that is under the rights of another individual or entity other than yourself, without the proper authorization. Using a username that is offensive, vulgar, or obscene is also not allowed.</p>
  <p>We retain the right to decline service, close accounts, modify or remove content at our sole discretion without incurring any form of liability. As a User, you have the freedom to terminate your access to the Service and deactivate your account whenever you wish. You can do this by either visiting the Account settings page or sending an email to ShopAfrica's customer service at [email address].</p>
  <p>In the event that you explicitly terminate your ShopAfrica account, all your user data will be promptly removed from ShopAfrica, and it will not be possible to recover this data.</p>
</section>
<section className="mt-3">
  <h2>Link to Other Web Pages or Sites</h2>
  <p>Our service may include links to websites or services operated by third parties that are not owned or controlled by ShopAfrica. ShopAfrica has no authority over, and assumes no responsibility for, the content, privacy policies, or practices of these third-party websites or services. We do not endorse or guarantee the products or services offered by these entities or individuals or on their websites.</p>
  <p>You acknowledge and agree that ShopAfrica is not liable, directly or indirectly, for any damages or losses alleged to be caused by or connected to the use of or reliance on the content, goods, or services available on or through such third-party websites or services.</p>
  <p>We strongly recommend that you carefully review the terms of service and privacy policies of any third-party websites or services you choose to visit.</p>
</section>

<section className="mt-3">
  <h2>Providing Feedback and Reviews</h2>
  <p>ShopAfrica appreciates any suggestions or ideas for improving or adding to the Services. It's important to note that when you disclose any idea, suggestion, or related material, or when you write a review about the Services, Third Party Services, or any Third Party Provider (collectively referred to as "Feedback") to ShopAfrica, there is no obligation to keep such information confidential or to expect compensation. When you submit Feedback to ShopAfrica, you relinquish any rights to the Feedback, and ShopAfrica is free to implement and utilize the Feedback as provided by you or as modified by ShopAfrica, without the need for your permission or a license from you or any third party.</p>
  <p>If you provide reviews about a Third Party Service or Third Party Provider to ShopAfrica, it's important to ensure that the information is accurate to the best of your knowledge and that it doesn't contain any illegal, obscene, threatening, defamatory, privacy-invading, infringing on intellectual property rights, or otherwise harmful content to third parties or considered objectionable. While ShopAfrica reserves the right (but is not obligated) to remove or edit Feedback regarding Third Party Services or Third Party Providers, it does not routinely monitor all posted Feedback.</p>
</section>

<section className="mt-3">
  <h2>Modifications to the Service</h2>
  <p>We retain the authority to withdraw or modify our Service, as well as any services or materials provided through the Service, at our sole discretion, without prior notice. We shall not be held responsible if, for any reason, the entire Service or any portion of it becomes temporarily or permanently unavailable. Periodically, we may limit access to specific sections of the Service, or the Service in its entirety, for various users, including registered users.</p>
</section>
<section className="mt-3">
  <h2>Disclaimer and Limitation of Liability</h2>
  <p>While we make every effort to ensure the accuracy and reliability of the information, products, and services provided on ShopAfrica, we cannot guarantee that all content is error-free or up-to-date. We disclaim any warranties, either explicit or implied, regarding the accuracy, completeness, or reliability of our content.</p>
  <p>Under no circumstances shall ShopAfrica, its affiliates, partners, employees, or agents be held liable for any direct, indirect, incidental, special, or consequential damages, including, but not limited to, loss of profits, data, or goodwill, or any other intangible losses resulting from your use of the services or any failure of performance.</p>
  <p>The content provided on ShopAfrica is for informational purposes only and should not be considered as legal, financial, or professional advice. You should consult with qualified professionals for any specific concerns.</p>
</section>

<section className="mt-3">
  <h2>Revisions to the Terms</h2>
  <p>We have the right to make changes to these Terms at any time by posting the revised terms on this website. It is your responsibility to periodically review these Terms. Your continued utilization of the Platform after the revised Terms have been posted signifies your acceptance and agreement to these changes. You are expected to frequently check this page to stay informed of any alterations because they are binding on you.</p>
  <p>By continuing to access or use our Service once the revisions become effective, you agree to be bound by the updated terms. If you do not consent to the new terms, you are no longer authorized to use the Service.</p>
</section>

<section className="mt-3">
  <h2>Waiver and Separability</h2>
  <p>The Company's failure to enforce any term or condition in these Terms will not be interpreted as a continual or further waiver of that specific term or condition, nor as a waiver of any other terms or conditions. Any failure by the Company to assert a particular right or provision under these Terms will not constitute a waiver of that right or provision.</p>
  <p>If a court or other competent tribunal holds any provision of these Terms to be invalid, illegal, or unenforceable for any reason, that particular provision will either be removed or modified to the minimum extent required, allowing the remaining provisions of these Terms to remain in full force and effect.</p>
</section>

<section className="mt-3">
  <h2>Acknowledgment</h2>
  <p>By utilizing the Service or any other services offered by us, you confirm that you have perused these Terms of Service and consent to abide by them.</p>
</section>

<section className="mt-3">
  <h2>Contact Us</h2>
  <p>We welcome your feedback, comments, and requests for technical support:</p>
  <p>Via email: shopafricastores@gmail.com</p>
</section>

      
    </div>
    <div className="container">
            <MainFooter />
        </div>
    </React.Fragment>
  )
}
