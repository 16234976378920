import { createSlice } from "@reduxjs/toolkit";

const reviewSlice = createSlice({
    name: 'review',
    initialState: {
        loading: false,
        reviews: null,
        review: null,
        showDetail: false,
    },

    reducers : {
        reviews : (state, action) => {
            state.reviews = action.payload
        },
        review : (state, action) => {
            state.review = action.payload
        },
        reviewDetail : (state, action) => {
            state.showDetail = action.payload;
        }
    }
})

export const {reviews, review, reviewDetail} = reviewSlice.actions;
export default reviewSlice.reducer;