import React from 'react'

function Features() {
  return (
    <section className="stats py-5 bg-white" id="features">
        <div className="container py-md-3">
            <div className="row stat-grids">
                <div className="col-lg-6 ser-lt">
                <div className="d-flex services-box ser-midd">
                        <div className="icon">
                        <span><img srcSet={require("../../assets/images/icons/90940.jpg")} alt="Images" width={120} height={120} /></span>
                        </div>
                        <div className="service-content">
                            <h4>MULTIPLE STORES, ONE ACCESS</h4>
                            <p className="serp">
                                Simplify Management. Manage all your online storefronts from a single, convenient account
                            </p>
                        </div>
                    </div>
                    <div className="d-flex services-box">
                        <div className="icon">
                            <span><img srcSet={require("../../assets/images/icons/93475.jpg")} alt="Images" width={120} height={120} /></span>
                        </div>
                        <div className="service-content">
                            <h4>EFFICIENT ORDER PROCESSING</h4>
                            <p className="serp">
                            Automate order processing and reduce manual tasks, so you can focus on growing your business instead of dealing with administrative hassles.
                            </p>
                        </div>
                    </div>
                    <div className="d-flex services-box mt-4 bx-4">
                        <div className="icon">
                        <span><img srcSet={require("../../assets/images/icons/97365.jpg")} alt="Images" width={120} height={120} /></span>
                        </div>
                        <div className="service-content">
                            <h4>INBUILT WALLET</h4>
                            <p className="serp">
                            Manage your earnings seamlessly with our inbuilt wallet .
                            </p>
                        </div>
                    </div>
                    <div className="d-flex services-box mt-4">
                        <div className="icon">
                        <span><img srcSet={require("../../assets/images/icons/2005.jpg")} alt="Images" width={120} height={120} /></span>
                        </div>
                        <div className="service-content">
                            <h4>MAXIMIZE YOUR ONLINE PRESENCE</h4>
                            <p className="serp">
                            Our service empowers you to reach a global audience without the need for multiple physical locations, increasing your market reach.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 ser-lt ser-right">
                <div className="d-flex services-box bx-4">
                        <div className="icon">
                        <span><img srcSet={require("../../assets/images/icons/84942.jpg")} alt="Images" width={120} height={120} /></span>
                        </div>
                        <div className="service-content">
                            <h4>SHOP MANAGEMENT MADE EASY</h4>
                            <p className="serp">
                            Activate and deactivate shops on your terms, whenever you need.
                            </p>
                        </div>
                    </div>
                    <div className="d-flex services-box ser-midd">
                        <div className="icon">
                        <span><img srcSet={require("../../assets/images/icons/86639.jpg")} alt="Images" width={120} height={120} /></span>
                        </div>
                        <div className="service-content">
                            <h4>FAST & SECURE PAYMENTS</h4>
                            <p className="serp">
                            Access your wallet funds within 24 hours for quick, easy withdrawals. Rest easy knowing that your transactions are protected.
                            </p>
                        </div>
                    </div>
                    <div className="d-flex services-box mt-4 bx-4">
                        <div className="icon">
                        <span><img srcSet={require("../../assets/images/icons/90827.jpg")} alt="Images" width={120} height={120} /></span>
                        </div>
                        <div className="service-content">
                            <h4>CUSTOMER TRACKING</h4>
                            <p className="serp">
                            Use our features to keep track of your customers, their preferences, and their purchase history for personalized service. 
                            </p>
                        </div>
                    </div>
                    <div className="d-flex services-box mt-4">
                        <div className="icon">
                        <span><img srcSet={require("../../assets/images/icons/93215.jpg")} alt="Images" width={120} height={120} /></span>
                        </div>
                        <div className="service-content">
                            <h4>EFFECTIVE CONTACT OPTIONS</h4>
                            <p className="serp">
                            Connect with your customers easily through our communication channels and gather valuable feedbacks to improve your business.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Features