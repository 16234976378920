import React, { useEffect } from 'react'
import Nav, { Header } from '../../../components/dashboard/Nav'
import CollectionsContent from './CollectionsContent'
import '../../../assets/css/products.css'
import { useSelector,useDispatch } from 'react-redux'
import { getUserData } from '../../../redux/action/user'
import { setCurrentStore } from '../../../redux/action/shop'
import { useParams } from 'react-router-dom'
import { fetchShopProducts } from '../../../redux/action/products'
function   Collections(){
    const {shop} = useParams()
    const {currentShop,currentUser,} = useSelector(state => state)
    const dispatch = useDispatch()
    useEffect(()=>{
        !currentUser.data && dispatch(getUserData())
        !currentShop.data && dispatch(setCurrentStore(shop))

        return ()=> {
            return null
        }
    },[currentUser.loading]);
    return (
        <>
            <Nav />
            <>
            <main className='main-wrap'>
                <Header />
                <React.Fragment>
                    <CollectionsContent />
                </React.Fragment>
            </main>
            </>

        </>
    )
}

export default Collections