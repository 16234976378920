
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link ,useNavigate,useParams} from 'react-router-dom'
import { pageUrl } from '../../../api/api'
import { addProduct, fetchShopProducts, getProductById } from '../../../redux/action/products'
import  Carousel  from 'react-bootstrap/Carousel'
import { Axios } from '../../../api/axios'
import { useState } from 'react'
import { Spinner } from 'react-bootstrap';
import ImportProducts from '../../../components/dashboard/ImportProducts'

function CollectionsContent(){
    const {shop} = useParams()
    const navigate = useNavigate()
    const {currentShop,currentUser,currentShopProducts} = useSelector(state => state)
    const [deleteState,setDelState] = useState(null)
    const [showImportModal,handleImportModal] = useState(false)
    const dispatch = useDispatch()
    const deleteProduct = async productId => {
        try {
            const res = await Axios({
                url:"api?deleteProduct",
                method:"POST",
                data:{productId}
            })
        dispatch(fetchShopProducts(currentShop.id))
        } catch (error) {
            setDelState({success:false,message:error.message})

        }
    }
    
    useEffect(()=>{
        return ()=>{
            return null
        }
    },[currentShop.id])
    return (
        currentShopProducts.loading ?
        <>
            <div className='page-preloader' style={{width:"max-content"}}>
    <Spinner animation="border" >
    <>{currentUser.err && "Network failure,Please refresh"}</></Spinner>
        </div>
        </>
        :
        <React.Fragment>
            <>
                <ImportProducts
                    show={showImportModal}
                    handleClose={()=>handleImportModal(false)}
                />
            </>
            <section className="content-main">

            <div className="content-header">
                <h2 className="content-title">Collections</h2>
                <div>
                    <Link to={pageUrl(shop,'/collections/new')} className="btn btn-primary"><i className="material-icons md-plus"></i> Create new</Link>
                </div>
            </div>

            <div className="card mb-4">
                <header className="card-header">
                    <div className="row align-items-center">
                        <div className="col-md-3 col-12 me-auto mb-md-0 mb-3">
                            <input className='form-control' type='text' placeholder='Search for collection...' />
                        </div>
                    </div>
                </header>
                
                <div className="card-body collections" style={{marginTop:65}}>

                    <article className="itemlist">
                        <div className="row align-items-center">
                            <div className="col col-check flex-grow-0">
                                 <div className="form-check">
                                    <input className="form-check-input" type="checkbox" />
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-4 col-8 flex-grow-1 col-name">
                                <a className="itemside" href="#">
                                    <div className="left">
                                        <img src={currentShop.image} className="img-sm img-thumbnail" alt="Item"/>
                                    </div>
                                    <div className="info">
                                        <h6 className="mb-0">T-shirt for men medium size</h6>
                                    </div>
                                </a>
                            </div>
                            <div className="col-lg-2 col-sm-2 col-4 col-price"> <span>$34.50</span> </div>
                            <div className="col-lg-2 col-sm-2 col-4 col-status">
                                <span className="badge rounded-pill alert-success">Active</span>
                            </div>
                            <div className="col-lg-2 col-sm-2 col-4 col-date">
                                <span>04.12.2023</span>
                            </div>
                            <div className="col-lg-1 col-sm-2 col-4 col-action">
                                <div >
                                    <button type='button' className='btn btn-sm btn-info m-1'>View</button>
                                    <button type='button' className='btn btn-sm btn-danger'>Delete</button>
                                </div>
                            </div>
                        </div>
                    </article>  
                </div>
            </div> 


            </section> 
        </React.Fragment>
    )
}

export default CollectionsContent