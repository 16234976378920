import { createSlice } from "@reduxjs/toolkit";

const orderSlice = createSlice({
    name: 'order',
    initialState: {
        loading: false,
        orders: null,
        order: null,
        showDetail: false,
        detail: false
    },

    reducers : {
        orders : (state, action) => {
            state.orders = action.payload
        },
        order : (state, action) => {
            state.order = action.payload
        },
        orderDetail : (state, action) => {
            state.showDetail = action.payload;
        },
        detail : (state, action) => {
            state.detail = action.payload;
        }
    }
})

export const {orders, order, orderDetail, detail} = orderSlice.actions;
export default orderSlice.reducer;