import React, { useEffect } from 'react'
import Nav, { Header } from '../../../components/dashboard/Nav'
import Transactions from './Transactions'
import '../../../assets/css/products.css'
import { useSelector,useDispatch } from 'react-redux'
import { getUserData } from '../../../redux/action/user'
import { setCurrentStore } from '../../../redux/action/shop'
import { useParams } from 'react-router-dom'
import NeedHelp from '../../../components/dashboard/NeedHelp'
//import { fetchShopTransactionWrapper } from '../../../redux/action/products'
function TransactionWrapper(){
    const {shop} = useParams()
    const {currentShop,currentUser} = useSelector(state => state)
    const dispatch = useDispatch()
    useEffect(()=>{
       
        return ()=> {
            return null
        }
    },[currentUser.loading]);
    return (
        <>
            <Nav activeState={'transactions'}/>
            <>
            <main className='main-wrap'>
                <Header />
                <React.Fragment>
                    <Transactions />
                </React.Fragment>
            </main>
            </>
        <NeedHelp />
        </>
    )
}

export default TransactionWrapper