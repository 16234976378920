import React from 'react'
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getUserData } from "../../../redux/action/user"
import { Link, useNavigate } from "react-router-dom"
import Cookie from 'react-cookies'
import { Axios } from "../../../api/axios"
import Swal from "sweetalert2"
import ProfileNav from "./ProfileNav"
import { createKey } from '../../../api/api'
import { userShopData } from '../../../redux/action/shop'
function SMS(){
    const {currentUser,shops} = useSelector(state => state)
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [customerLoading,setCustomerLoadingStatus] = useState(false)
    const [customers,setCustomers]  = useState(null)
    const sendSMSToCustomer = async (e) => {
        setCustomerLoadingStatus(true)
        e.preventDefault();
        try {
            const response = await Axios.post('api?sendCustomerSMS',new FormData(e.currentTarget))
            const res = response.data
            if (res.success && res.success === true) {
                Swal.fire({
                    text: res.message,
                    icon: 'success',
                    timer: 5000,
                    toast: true,
                    showConfirmButton: false,
                    position: 'top-end'
                });
                dispatch(getUserData())
            } else {
                Swal.fire({
                    text: res.message,
                    icon: 'error',
                    timer: 5000,
                    toast: true,
                    showConfirmButton: false,
                    position: 'top-end'
                });
            }
        } catch (error) {
            Swal.fire({
                text: error.message,
                icon: 'error',
                timer: 5000,
                toast: true,
                showConfirmButton: false,
                position: 'top-end'
            });
        }finally{
            setCustomerLoadingStatus(false)
        }
    }
    const fetchCustomers = async (storeId) => {
        try {
            const response = await Axios.get('api?getCustomers',{params:{storeId}})
            const res = response.data
            if (res.status && res.status === true) {
                setCustomers(res.data)
            } else {
                setCustomers(null)
            }
        } catch (error) {
            Swal.fire({
                text: error.message,
                icon: 'error',
                timer: 5000,
                toast: true,
                showConfirmButton: false,
                position: 'top-end'
            });
        }finally{
            setCustomerLoadingStatus(false)
        }
    }
        useEffect(()=>{
            !currentUser.data && dispatch(getUserData());
            !shops.data && dispatch(userShopData());
            //fetchCustomers()
            return ()=> {
                return null
            }
        },[])
    return (
        <>
            <b className="screen-overlay"></b>
            <>
            <React.Fragment>
    <div className="">
			<div className="v-body-container">
				<div className="v-h-parent-grid">
					<>
          <ProfileNav page={'SMS'}/>
          </>
					<div className="p-0 flex-grow-1">
						<div className="v-main-brands-board">
							<div className="v-brand-board-title d-flex align-items-center justify-content-between">
								<h3 className="v-title">SMS</h3>
								<div className="dropdown" style={{margin:20}}>
        <a href="#" data-bs-toggle="dropdown" className="btn btn-light"> <i className="material-icons md-account_circle"></i> </a>
        <div className="dropdown-menu" style={{minWidth:200}}>
          <div className='w-100 px-2 d-flex justify-content-center align-items-center flex-column'>
            <h5 style={{fontSize:15}}>{currentUser && currentUser.name}</h5>
            <small style={{fontSize:13}}>{ currentUser && currentUser.email}</small>
          </div>
          <ul className='mt-2' style={{listStyle:"none"}}>
            <li><Link className="dropdown-item" to={'/profile'}>View Profile </Link></li>
            <li onClick={()=>{
              Cookie.remove('token',{maxAge:-1,expires:-1});
              setTimeout(() => {
                navigate("/")
              }, 2000)  
            }}><Link className="dropdown-item text-danger" >Logout</Link></li>
          </ul>
        </div>
                </div>
							</div>
							<div className="v-grid-container mt-3 mt-lg-5">
                            <section className="content-body p-xl-4">
            <div><span style={{
                fontSize:20,fontWeight:"bolder"
            }}>Available SMS Credit:</span> <span className='text-success'>{currentUser && currentUser.available_credit}</span></div>
           <div className='container mt-3'>
           <form className='sms-credit-message' method='POST' onSubmit={(e)=>{
            sendSMSToCustomer(e)
           }}>
                <div className='form-group'>
                    <label className='form-label'>Select Store</label>
                    <select className='form-control' name='customerId' required onChange={(e)=>{
                        if (e.target.value.length !== 0) {
                            fetchCustomers(e.target.value)
                        }
                    }}>
                        <option value={""}>--Select store --</option>
                        {
                             shops.data && shops.shops.length > 0 && 
                             shops.shops.map(function(shop){
                                return <option key={shop.id} value={shop.id}>{shop.name}</option>
                             })
                        }
                    </select>
                </div>
                <div className='form-group mt-2'>
                    <label className='form-label'>Select Recipient</label>
                    <select className='form-control' name='customerId' required>
                        <option value={""}>--Select User --</option>
                        {
                            customers && 
                            customers.map(function(customer){
                                return <option key={createKey()} value={customer.phone_number}>{customer.phone_number} ({customer.firstname } {customer.lastname})</option>
                            })
                        }
                    </select>
                </div>
                <div className='form-group mt-2'>
                    <label className='form-label'>Subject</label>
                    <input className='form-control' placeholder='Enter subject' name='subject' maxLength={10} required />
                </div>
                <div className='form-group mt-2'>
                    <label className='form-label'>Message</label>
                    <textarea className='form-control' name='message' placeholder='Enter message' required />
                </div>
                
                <div className='form-group mt-2'>
                    <button disabled={customerLoading} className='btn t-btn'>{customerLoading ? 'Sending...':"Send"}</button>
                </div>
            </form>
           </div> 
                            </section>
                            </div>
						</div>
					</div>
				</div>
			</div>
		</div>

    </React.Fragment>
            </>
        </>
    )
}
export default SMS
