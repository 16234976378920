import { createSlice } from "@reduxjs/toolkit";

const shopSlice = createSlice({
    name: 'shop',
    initialState: {
        shop: null,
        shopTransactions: null,
        shopReviews: null
    },

    reducers : {
        shopReviews : (state, action) => {
            state.shopReviews = action.payload;
        } 
    }
})

export const {shopReviews} = shopSlice.actions;
export default shopSlice.reducer