
import { Link } from 'react-router-dom'
import logo from "../../assets/images/shopafrica-logo-2.png"
function Nav(){
    return(
        <>
            <header className="header">
                    <Link to={"/"}>
                    <div id='site-logo'>
                        <img src={logo} alt='ShopAfrica' />
                    </div>
                    </Link>
                    <nav className="py-4 main-nav" style={{paddingRight:10}}>
                            <label htmlFor="drop" className="toggle"><i className='fa fa-bars' style={{color:"black"}}></i></label>
                            <input type="checkbox" id="drop" />
                            <ul className="menu mt-2 d-lg-flex align-items-center">
                                <li>
                                    <label for="drop-2" class="toggle">Lean More<span class="fa fa-angle-down" aria-hidden="true"></span> </label>
                                    <a href="#">Lean More <span class="fa fa-angle-down" aria-hidden="true"></span></a>
                                    <input type="checkbox" id="drop-2" />
                                    <ul>
                                        <li><Link to={"/about"} >About</Link></li>
                                        <li><a href="https://drive.google.com/drive/folders/10XaJ3BTKEk9BaEh2JyOfG2j1j1sYOqyR"target='_blank' >How to</a></li>
                                        <li><Link to={"/privacy-policy"} >Privacy Policy</Link></li>
                                    </ul>
                                </li>
                                <li><Link to={"/contact"}>Contact</Link></li>
                                <li>
                                    <Link to={"/pricing"}>Pricing</Link>
                                </li>
                                <li className='d-none'>
                                    <Link to={"#"}>Features</Link>
                                </li>
                                <li className='d-none'>
                                    <label for="drop-2" className="toggle">Features<span className="fa fa-angle-down" aria-hidden="true"></span> </label>
                                    <a href="#">Features<span className="fa fa-angle-down" aria-hidden="true"></span></a>
                                    <input type="checkbox" id="drop-2" />
                                    <ul style={{
                                        right:0,left:0
                                    }}>
                                        <li><Link to={"/login"}>Login</Link></li>
                                        <li><Link to={"/signup"}>Signup</Link></li>
                                    </ul>
                                </li>
                                <Link className='btn t-btn w-80 mr-2' to={"/login"}>Get Started</Link>
                            </ul>
                        </nav>
                </header>
        </>
    );
}

export default Nav